// @flow
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLoggedInUser } from '../helpers/authUtils';
import logo from '../assets/images/fcd-logo-orange.svg';
import profilePic from '../assets/images/users/avatar-1.jpg';

const ProfileMenus = [
    {
        label: 'Logout',
        icon: 'uil uil-exit',
        redirectTo: '/account/logout',
    },
];

class Topbar extends Component {
    static propTypes = {
        hideLogo: PropTypes.bool,
        navCssClasses: PropTypes.string,
        openLeftMenuCallBack: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.user = getLoggedInUser();
    }

    render() {
        const hideLogo = this.props.hideLogo || false;
        const navCssClasses = this.props.navCssClasses || '';
        const containerCssClasses = !hideLogo ? 'container-fluid' : '';
        return (
            <React.Fragment>
                <div className={`navbar-custom ${navCssClasses}`}>
                    <div className={containerCssClasses}>
                        {!hideLogo && (
                            <Link to="/" className="topnav-logo">
                                <img src={logo} alt="First Class Data" />
                            </Link>
                        )}

                        <button
                            className="button-menu-mobile open-left disable-btn float-right"
                            onClick={this.props.openLeftMenuCallBack}>
                            <i className="uil-align-justify"></i>
                        </button>

                        <ul className="list-unstyled topbar-right-menu float-right mb-0">
                            <li className="notification-list">
                                <ProfileDropdown
                                    profilePic={profilePic}
                                    menuItems={ProfileMenus}
                                    username={this.user.firstName + ' ' + this.user.lastName}
                                    userTitle={''}
                                />
                            </li>
                        </ul>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    { }
)(Topbar);
