export default [
    { value: 'Life / Health', label: 'Life / Health' },
    { value: 'Property / Casualty', label: 'Property / Casualty' },

    { value: 'Health', label: 'Health' },
    { value: 'Life', label: 'Life' },
    { value: 'Property', label: 'Property' },
    { value: 'Casualty', label: 'Casualty' },
    { value: 'Variable Licensed', label: 'Variable Licensed' },
    { value: '', label: '-----', isDisabled: true },

    { value: 'Accident', label: 'Accident' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Disability', label: 'Disability' },
    { value: 'Fraternal Life, Accident, Health', label: 'Fraternal Life, Accident, Health' },
    { value: 'General', label: 'General' },
    { value: 'Life Settlements / Viatical', label: 'Life Settlements / Viatical' },
    { value: 'Limited Lines - Agricultural', label: 'Limited Lines - Agricultural' },
    { value: 'Limited Lines - Dental', label: 'Limited Lines - Dental' },
    { value: 'Limited Lines - Fire', label: 'Limited Lines - Fire' },
    { value: 'Limited Lines - Health Maintenance', label: 'Limited Lines - Health Maintenance' },
    { value: 'Limited Lines - Marine', label: 'Limited Lines - Marine' },
    { value: 'Limited Lines - Workers Comp', label: 'Limited Lines - Workers Comp' },
    { value: 'Long Term Care', label: 'Long Term Care' },
    { value: 'Managing General Agent', label: 'Managing General Agent' },
    { value: 'Personal Lines', label: 'Personal Lines' },
    { value: 'Surety', label: 'Surety' },
    { value: 'Surplus Lines', label: 'Surplus Lines' },
];
