// @flow
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loggedInUserHasPermission } from '../helpers/authUtils';

const clickedOnLink = (name) => {
    localStorage.setItem('searchFor', name)
}

const MenuItem = ({ item, className, linkClassName }) => {
    return (
        <li className={classNames('side-nav-item', className)} onClick={()=>{clickedOnLink(item.name)}}>
            <MenuItemLink item={item} className={linkClassName} />
        </li>
    );
};

const MenuItemLink = ({ item, className }) => {
    return (
        <Link to={item.path} className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}>
            {item.icon && <i className={item.icon}></i>}
            {item.badge && <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
            <span> {item.name} </span>
        </Link>
    );
};

/**
 * Renders the application menu
 */
type Props = {
    history: PropTypes.object,
};
class AppMenu extends Component<Props> {
    menuItems = [
        {
            path: '/group/search',
            name: 'Insurance Search',
            icon: 'uil-users-alt',
            permission: 'search_insurance'
        },
        // {
        //     path: '/agent/search',
        //     name: 'Individual Agent Search',
        //     icon: 'uil-user',
        //     permission: 'search_insurance',
        // },
        {
            path: '/reports/saved',
            name: 'Saved Searches',
            icon: 'uil-comments-alt',
        },
        {
            path: '/riafirm/search',
            name: 'RIA Firm Search',
            icon: 'uil-building',
            permission: 'search_ria',
        },
        {
            path: '/iar/search',
            name: 'IAR Search',
            icon: 'uil-user',
            permission: 'search_ria',
        },
        {
            path: '/bd/search',
            name: 'BD Search',
            icon: 'uil-building',
            permission: 'search_ria',
        },
        {
            path: '/rr/search',
            name: 'RR Search',
            icon: 'uil-user',
            permission: 'search_ria',
        },
    ]

    render() {
        const currentUrlPath = this.props.history.location.pathname || '';
        const activeMenuItem = this.menuItems.findIndex(item => currentUrlPath.indexOf(item.path) === 0);

        return (
            <React.Fragment>
                <div className='topbar-nav'>
                    <ul className="metismenu side-nav" id="menu-bar">
                        {this.menuItems.map((item, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    item={item}
                                    className={((activeMenuItem === i) ? 'mm-active' : '')}
                                    linkClassName="side-nav-link"
                                />
                            );
                        })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(AppMenu);
