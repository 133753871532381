import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner
} from 'reactstrap';
import { Column, HeaderCell, Cell } from 'rsuite-table';

import APIClient from '../utils/API';
import SortableRsuiteTable from './SortableRsuiteTable';
import EmailLevelIcon from './EmailLevelIcon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import _ from "lodash";
import {  renderTooltipPrev,  renderTooltipNext , getValueData, getPaginationData } from '../helpers/navigate';

const Emails = ({ emails }) => {
    return (!!emails?.length &&
        <>
            <dt className="col-sm-4">Emails</dt>
            <dd className="col-sm-8">
                {emails.map((email, i) => {
                    if (email.email_status <= 2 && email.email_status != '') {
                        // Make the email address as clickable
                        let email_link = "mailto:" + email?.email
                        return (
                            <>
                                <div >
                                    {/* Show the email address verification status icon */}
                                    <EmailLevelIcon level={email.email_status} key={i} />
                                    <a href={email_link} target="_blank" rel="noopener noreferrer">{email?.email.toLowerCase()}</a>
                                    <br />
                                </div>
                            </>
                        )
                    }
                })}
            </dd>
        </>
    )
}
const OtherBusiness = ({other_business}) => {
    return (
        <Row className="mt-2">
            <Col>
                <h4>Other Business</h4>
            </Col>
            <Row className="ml-2">
                    <Col>
                        <p className="ml-2 text-monospace">{other_business}</p>
                    </Col>
            </Row>
        </Row>
    )    
}

class RRIndivDetailsPage extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
        onIarModalChanged: PropTypes.func,
    };

    render() {
        let indiv = this.props.indivData;
        let disclosure = indiv['has_bc_disclosure_fl'];
        if(disclosure == 'TRUE') {
            disclosure = "Yes"
        } 
        else if(disclosure == 'FALSE') {
            disclosure = "No"
        }
        let executives = this.props.indivData['executives'];
        let emailValue = this.props.indivData['emails'];
        {
            Object.keys(this.props.indivData).length > 0 && (
                (() => {
                    emailValue = emailValue.map((email) => (email.email_status <= 2 && email.email_status !== ''));
                })()
            )
        }

        return (<>
            {!Object.keys(this.props.indivData).length ?
                <span className="span-values">No individual Records found in RR Details</span> : <h4>Individual Details</h4>
            }
            {Object.keys(this.props.indivData).length > 0 &&
                [
                    <Row className="ml-2">
                        <Col lg={6}>
                            <dl className="row">
                                <dt className="col-sm-4">CRD</dt>
                                <dd className="col-sm-8">{indiv['indiv_crd']}</dd>
                                <dt className="col-sm-4">Firm Name</dt>
                                <dd className="col-sm-8">{indiv['firm_name']}</dd>
                                <dt className="col-sm-4">First Name</dt>
                                <dd className="col-sm-8">{indiv['first_name']}</dd>
                                <dt className="col-sm-4">Middle Name</dt>
                                <dd className="col-sm-8">{indiv['middle_name']}</dd>
                                <dt className="col-sm-4">Last Name</dt>
                                <dd className="col-sm-8">{indiv['last_name']}</dd>
                                <dt className="col-sm-4">Gender</dt>
                                <dd className="col-sm-8">{indiv['gender'] == 'M' ? 'Male' : 'Female'}</dd>
                                {emailValue.includes(true) &&
                                    <Emails emails={indiv?.emails} />}
                                <dt className="col-sm-4">Disclosure Type</dt>
                                <dd className="col-sm-8">{[disclosure]}</dd>
                            </dl>

                </Col>

                <Col lg={6}>
                    {indiv['other_names'] && indiv['other_names'].length>0 &&
                        <dl>
                            <dt>Other Name(s)</dt>
                            <dd>
                                {indiv['other_names'].map(name => <React.Fragment key={name}>
                                    {name.toLowerCase()}<br/>
                                </React.Fragment>)}
                            </dd>
                        </dl>
                    }
                    <DisclosuresBlock indivData={this.props.indivData} />
                     {indiv['designations'].length > 0 && <>
                     <dl class="row">
                            <dt className="col-sm-4">Designations</dt>
                            <dd className="col-sm-8">
                                {indiv['designations'].map((designation, i) => <React.Fragment key={i}>
                                    {designation}<br />
                                </React.Fragment>)}
                            </dd>
                            </dl>
                        </>}
                    {executives && <>
                    <dl class="row">
                        <dt className="col-sm-4">Executive(s)</dt>
                        <dd className="col-sm-8">{[...new Set(executives)]}</dd>
                    </dl>
                    </>}
                    {indiv['linkedin_profile'] && indiv['linkedin_profile'].length > 0 && <>
                        <div class="row" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                            <dt className="col-sm-4">LinkedIn Profile</dt>
                            <dd className="col-sm-8">
                                <a href={indiv['linkedin_profile']} target="_blank" rel="noopener noreferrer">{indiv['linkedin_profile'].toLowerCase()}</a><br/>
                            </dd>
                        </div>
                    </>}
                    <dl className="row">
                        <dt className="col-sm-4">Employment Count</dt>
                        <dd className="col-sm-8">{indiv['employments_count']}</dd>
                    </dl>
                    {indiv['indiv_crd'] && indiv['has_iar_indiv'] == true && <>
                        <dl class="row">
                            <dt className="col-sm-4">Dually Registered? : </dt>
                            <dd className="col-sm-8 license" role="button" onClick={row => this.props.onIarModalChanged(indiv['indiv_crd'], "has_iar_indiv")}>IAR Profile</dd>

                                </dl>
                            </>}
                        </Col>
                    </Row>,
                    <RegistrationsTable indivData={this.props.indivData} />,
                    <ExamsTable indivData={this.props.indivData} />,
                    <EmployersTable indivData={this.props.indivData} onModalChanged={this.props.onModalChanged} />,
                    <BranchOfficesTable indivData={this.props.indivData} />,
                    <EmploymentTable indivData={this.props.indivData} />,
                ]}
            {!!indiv?.other_business && Object.keys(this.props.indivData).length > 0 && <OtherBusiness other_business={indiv.other_business} />}
        </>)
    }
}


class RegistrationsTable extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
    };

    render() {
        let rowData = this.props.indivData['registrations'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Registrations</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={rowData}
                defaultSortColumn='reg_auth'
                defaultSortDirection='asc'>

                <Column width={140} sortable >
                    <HeaderCell>Authority</HeaderCell>
                    <Cell dataKey="reg_auth" />
                </Column>
                <Column width={140} sortable>
                    <HeaderCell>Category</HeaderCell>
                    <Cell dataKey="reg_cat" />
                </Column>
                <Column width={180} sortable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="reg_status" />
                </Column>
                <Column width={180} sortable>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="reg_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class ExamsTable extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
    };

    render() {
        let rowData = this.props.indivData['exams'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Exams</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={rowData}
                defaultSortColumn='exam_code'
                defaultSortDirection='asc'>

                <Column width={120} sortable >
                    <HeaderCell>Exam Code</HeaderCell>
                    <Cell dataKey="exam_code" />
                </Column>
                <Column width={450} sortable resizable>
                    <HeaderCell>Exam Name</HeaderCell>
                    <Cell dataKey="exam_name" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Exam Scope</HeaderCell>
                    <Cell dataKey="exam_scope" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="exam_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class EmployersTable extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let rowData = this.props.indivData['employers'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Employers</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                onRowClick={row => this.props.onModalChanged(row.firm_crd, "employers")}
                data={rowData}
                rowClassName="row-hovered"
                defaultSortColumn='firm_crd'
                defaultSortDirection='asc'>

                <Column width={120} sortable >
                    <HeaderCell>Firm CRD</HeaderCell>
                    <Cell dataKey="firm_crd" />
                </Column>
                <Column width={250} sortable resizable>
                    <HeaderCell>Firm Name</HeaderCell>
                    <Cell dataKey="firm_name" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Hire Date</HeaderCell>
                    <Cell dataKey="hire_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class BranchOfficesTable extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
    };

    render() {
        let rowData = this.props.indivData['branch_office_locations'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Branch Office Locations</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={rowData}
                defaultSortColumn='state'
                defaultSortDirection='asc'>

                <Column width={280} sortable resizable>
                    <HeaderCell>Address</HeaderCell>
                    <Cell dataKey="full_address" />
                </Column>
                <Column width={130} sortable resizable>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="state" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Zip</HeaderCell>
                    <Cell dataKey="zip" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Country</HeaderCell>
                    <Cell dataKey="country" />
                </Column>
                <Column width={70} fixed="right">
                    <HeaderCell>Type</HeaderCell>

                    <Cell>{rowData => <>
                        {rowData.located_at_flag && <i className="uil uil-location-point" title="Branch Office Location"></i>}
                        {rowData.supervised_from_flag && <i className="uil uil-building" title="Supervised By Branch Office"></i>}
                        {rowData.private_residence_flag && <i className="uil uil-home" title="Private Residence"></i>}
                    </>
                    }</Cell>
                </Column>    
            </SortableRsuiteTable>
        </>)
    }
}


class EmploymentTable extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
    };

    render() {
        let firmCrd;
        let rowData = this.props.indivData['employment_history'];
        {rowData.map(firm_crd =>{
            {Object.keys(firm_crd).map((key) => {
                firmCrd = firm_crd['firm_crd'];
                firm_crd['firm'] = parseInt(firmCrd);
            })}
        })}

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Employment History</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={rowData}
                defaultSortColumn='firm'
                defaultSortDirection='asc'>

                <Column width={130} sortable>
                    <HeaderCell>Firm CRD</HeaderCell>
                    <Cell dataKey="firm" />
                </Column>
                <Column width={280} sortable resizable>
                    <HeaderCell>Firm Name</HeaderCell>
                    <Cell dataKey="firm_name" />
                </Column>
                <Column width={130} sortable resizable>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="state" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>From Date</HeaderCell>
                    <Cell dataKey="from_date" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>To Date</HeaderCell>
                    <Cell dataKey="to_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class DisclosuresBlock extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
    };

    disclosureTypes = {
        'hascivilbond': 'Bond',
        'hascivil': 'Civil Judgement',
        'hascriminal': 'Criminal Record',
        'hascustomerdispute': 'Customer Dispute',
        'hasfinancial': 'Declared Bankruptcy',
        'hasinvestigation': 'Investigation',
        'hasjudgment': 'Judgement',
        'hasregulatory': 'Regulatory Action',
        'hasterminated': 'Terminated',
    }

    render() {
        let disclosureRows = Object.keys(this.disclosureTypes)
            .filter((k) => this.props.indivData[k])
            .map((k) => {
                return (
                    <div key={k} className={'bool-attrib-active'}>
                        {this.disclosureTypes[k]}
                    </div>
                );
            });

        if (disclosureRows.length < 1) return <div></div>;

        return (
            <dl className="row">
                <dt className="col-sm-4">Disclosures</dt>
                <dd className="col-sm-8" children={disclosureRows} />
            </dl>
        )
    }
}

export default class RrIndivDetailsModal extends React.Component {
    static propTypes = {
        rowId: PropTypes.any,
        listRow: PropTypes.any,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onModalChanged: PropTypes.func,
        onIarModalChanged: PropTypes.func,
        updateId: PropTypes.any,
        countId: PropTypes.any,
        onClickChanged: PropTypes.func,
        listValue: PropTypes.any,
        rowIndiv: PropTypes.any,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoadingData: true,
            indivData: null,
            prev: true,
            next: true,
            rowIndiv: null,
        };
        this.loadData = this.loadData.bind(this);
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.handleRrIndividualDetails = this.handleRrIndividualDetails.bind(this);
        this.nextRecord = this.nextRecord.bind(this);
        this.previousRecord = this.previousRecord.bind(this);
        this.keyPress = this.keyPress.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.rowId !== this.props.rowId) {
            this.loadData();
        }
    }

    loadData() {
        this.setState({
            isLoadingData: true,
        }, () => {
            let client = new APIClient();
            return client.getRRIndivRow(this.props.rowId)
                .then(response => {
                    this.setState({
                        indivData: response.data,
                        isLoadingData: false,
                    });
                });
        });
    }

    handleIndividualDetails(id, employers) {
        if (this.onModalChanged !== this.props.onModalChanged) {
            this.props.onClose();
            this.props.onModalChanged(id, employers);
        }
    }

    handleRrIndividualDetails(id, hasIarProfile) {
        if (this.onIarModalChanged !== this.props.onIarModalChanged) {
            this.props.onClose();
            this.props.onIarModalChanged(id, hasIarProfile);
        }
    }

    async keyPress(event) {
        let evenKey = event.keyCode

        if (this.props.isOpen) {
            switch (evenKey) {
                case 37:
                    this.previousRecord();
                    break;
                case 39:
                    this.nextRecord()
                    break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyPress);
    }

    nextRecord() {
        let rowList = this.props.updateId;
        let rowValue = this.props.listValue;

        if (rowList) {
            let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
            if (this.onClickChanged !== this.props.onClickChanged && currentIndex != this.props.countId - 1) {
                this.props.onClickChanged(rowList[parseInt(currentIndex) + 1].CRD);
            }
        }

        else if (typeof(rowValue) === 'object' && rowValue) {
            if (this.state.rowIndiv == 'individuals' && rowValue['indivCrd']) {
                this.state.rowIndiv = this.props.rowIndiv;
                let currentIndexIndiv = _.findIndex(rowValue['indivCrd'], (row) => row === this.props.rowId);
                if (this.onClickChanged !== this.props.onClickChanged && currentIndexIndiv != this.props.listValue['indivCrd'].length - 1) {
                    this.props.onClickChanged(rowValue['indivCrd'][parseInt(currentIndexIndiv) + 1], this.props.listValue, this.props.rowIndiv);
                }
            }

            else if (this.state.rowIndiv == 'executives' && rowValue['executives']) {
                let currentIndexIndiv = _.findIndex(rowValue['executives'], (row) => row === this.props.rowId);
                if (this.onClickChanged !== this.props.onClickChanged && currentIndexIndiv != this.props.listValue['executives'].length - 1) {
                    this.props.onClickChanged(rowValue['executives'][parseInt(currentIndexIndiv) + 1], this.props.listValue, this.props.rowIndiv);
                }
            }
        }
    }

    previousRecord() {
        let rowList = this.props.updateId;
        let rowValue = this.props.listValue;

        if (rowList) {
            let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
            if (this.onClickChanged !== this.props.onClickChanged && currentIndex != 0) {
                this.props.onClickChanged(rowList[parseInt(currentIndex) - 1].CRD);
            }
        }

        else if (typeof(rowValue) === 'object' && rowValue) {
            this.state.rowIndiv = this.props.rowIndiv;
            if (this.state.rowIndiv == 'individuals' && rowValue['indivCrd']) {
                let currentIndexIndiv = _.findIndex(rowValue['indivCrd'], (row) => row === this.props.rowId);
                if (this.onClickChanged !== this.props.onClickChanged && currentIndexIndiv != 0) {
                    this.props.onClickChanged(rowValue['indivCrd'][parseInt(currentIndexIndiv) - 1], this.props.listValue, this.props.rowIndiv);
                }
            }
            else if (this.state.rowIndiv == 'executives' && rowValue['executives']) {
                let currentIndexIndiv = _.findIndex(rowValue['executives'], (row) => row === this.props.rowId);
                if (this.onClickChanged !== this.props.onClickChanged && currentIndexIndiv != 0) {
                    this.props.onClickChanged(rowValue['executives'][parseInt(currentIndexIndiv) - 1], this.props.listValue, this.props.rowIndiv);
                }
            }
        }

    }

    render() {
        let rowList = this.props.updateId;
        this.state.rowIndiv = this.props.rowIndiv;

        if (rowList) {
            let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
            currentIndex > 0 ? this.state.prev = true : this.state.prev = false;
            currentIndex != this.props.countId - 1 ? this.state.next = true : this.state.next = false;
        }

        this.state.prev = getPaginationData(this.props.listValue, this.state.rowIndiv, this.props.rowId, this.state.prev, 'prev')
        this.state.next = getPaginationData(this.props.listValue, this.state.rowIndiv, this.props.rowId, this.state.next, 'next')
        this.state.prev = getValueData(this.props.listIarRow, this.props.rowIndiv, this.props.listValue, "has_rr_indiv", this.state.prev);
        this.state.next = getValueData(this.props.listIarRow, this.props.rowIndiv, this.props.listValue, "has_rr_indiv", this.state.next);

        if (this.props.listValue == 'has_iar_indiv') {
            this.props.listValue != 'has_iar_indiv' ? this.state.prev = true : this.state.prev = false;
            this.props.listValue != 'has_iar_indiv' ? this.state.next = true : this.state.next = false;
        }
        return (
            <Modal isOpen={this.props.isOpen}
                toggle={this.props.onClose}
                className={!Object.keys(this.state.indivData ? this.state.indivData : []).length ? "agent-details-modal dynamic-header" : "agent-details-modal"}
            >
                {this.state.prev &&
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltipPrev}
                    >
                        <button
                            className="c-controls prev b"
                            onClick={this.previousRecord}
                        >
                            <span class="material-icons">&#10094;</span>
                            <span class="material-icons next-btn-text">Prev</span>
                        </button>
                    </OverlayTrigger>
                }
                {this.state.next &&
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltipNext}
                    >
                        <button
                            className="c-controls next b"
                            onClick={this.nextRecord}
                        >
                            <span class="material-icons">&#10095;</span>
                            <span class="material-icons next-btn-text">Next</span>
                        </button>
                    </OverlayTrigger>
                }

                <ModalHeader>RR Details</ModalHeader>
                <ModalBody>
                    {this.state.isLoadingData
                        ? <div className="text-center">
                            <Spinner as="span" animation="border" size="lg" />
                        </div>
                        : <RRIndivDetailsPage
                            indivData={this.state.indivData}
                            onModalChanged={this.handleIndividualDetails}
                            onIarModalChanged={this.handleRrIndividualDetails}
                        />
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onClose}>Done</Button>
                </ModalFooter>
            </Modal>
        );
    }
}