import React, {Component} from 'react';
import PropTypes from 'prop-types';

function getFormInputs() {
    const {formData} = this.props;

    if (formData === undefined) {
        return null;
    }

    let formInputs = [];
    for (const property in formData) {
        if (formData.hasOwnProperty(property)) {
            formInputs.push(
                <input
                    key={property}
                    name={property}
                    type="hidden"
                    value={formData[property]}
                />
            );
        }
    }
    return formInputs;
}

export default class FileDownloadForm extends Component {

    static propTypes = {
        actionPath: PropTypes.string.isRequired,
        method: PropTypes.string,
        onDownloadComplete: PropTypes.func.isRequired,
        formData: PropTypes.object
    };

    static defaultProps = {
        method: 'POST'
    };

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.formRef.current.submit();

        // unfortunately it does not appear possible to know when the download actually starts / completes
        // using an iframe "target" of the form works when the result is a page load, but not when it's a
        // file download

        if (this.props.onDownloadComplete) {
            this.props.onDownloadComplete();
        }
    }

    render() {
        const {actionPath, method} = this.props;

        return (
            <div className="hidden">
                <form action={actionPath} method={method} ref={this.formRef}>
                    {getFormInputs.call(this)}
                </form>
            </div>
        );
    }
}
