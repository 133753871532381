// @flow
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import APIClient from '../utils/API';
import { connect } from 'react-redux';
import { loggedInUserHasPermission } from '../helpers/authUtils';
import { quicksearchSubmitted } from '../redux/firstClassData/actions';
import { SearchFormState } from '../redux/firstClassData/dtos';
import { RiaFirmSearchState } from '../redux/firstClassData/dtos';
import { IarSearchState } from '../redux/firstClassData/dtos';
import { BdSearchState } from '../redux/firstClassData/dtos'; 
import { RrSearchState } from '../redux/firstClassData/dtos'; 
import {
    IAR_SEARCH,
    GROUP_SEARCH,
    RIA_FIRM_SEARCH,
    INDIVIDUAL_AGENT_SEARCH,
    SAVED_SEARCHES_PAGE,
    SEARCH_RIA,
    SEARCH_INSURANCE,
    RR_SEARCH,
    BD_SEARCH,

  } from '../redux/layout/constants';

function QuickSearch(props) {
    const history = useHistory();
    const [quickSearchText, setQuicksearchText] = useState('');

    const performSearch = (event) => {
        event.preventDefault();
        let quicksearch;
        let initialPage = loggedInUserHasPermission(SEARCH_INSURANCE) ? GROUP_SEARCH : RIA_FIRM_SEARCH
        const searchtype = localStorage.getItem('searchFor') ? localStorage.getItem('searchFor'):initialPage

        //  Function for displaying the quick search page based on the user permession 
        if (searchtype == IAR_SEARCH && loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new IarSearchState({ quicksearch: quickSearchText });
        }

        else if (searchtype == RIA_FIRM_SEARCH && loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new RiaFirmSearchState({ quicksearch: quickSearchText });
        }
        else if (searchtype == RR_SEARCH && loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new RrSearchState({ quicksearch: quickSearchText });
        }
        else if (searchtype == BD_SEARCH && loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new BdSearchState({ quicksearch: quickSearchText });
        }
        else if ((searchtype == GROUP_SEARCH || searchtype == INDIVIDUAL_AGENT_SEARCH) &&
            loggedInUserHasPermission(SEARCH_INSURANCE)) {
            quicksearch = new SearchFormState({ quicksearch: quickSearchText });
        }

        else if (searchtype == SAVED_SEARCHES_PAGE && loggedInUserHasPermission(SEARCH_INSURANCE)) {
            quicksearch = new SearchFormState({ quicksearch: quickSearchText });
        }

        else if (searchtype == IAR_SEARCH && !loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new SearchFormState({ quicksearch: quickSearchText });
        }

        else if (searchtype == RR_SEARCH && !loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new SearchFormState({ quicksearch: quickSearchText });
        }

        else if (searchtype == BD_SEARCH && !loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new SearchFormState({ quicksearch: quickSearchText });
        }

        else if (searchtype == RIA_FIRM_SEARCH && !loggedInUserHasPermission(SEARCH_RIA)) {
            quicksearch = new SearchFormState({ quicksearch: quickSearchText });
        }

        else if ((searchtype == GROUP_SEARCH || searchtype == INDIVIDUAL_AGENT_SEARCH) &&
            !loggedInUserHasPermission(SEARCH_INSURANCE)) {
            quicksearch = new RiaFirmSearchState({ quicksearch: quickSearchText });
        }

        else if (searchtype == SAVED_SEARCHES_PAGE && !loggedInUserHasPermission(SEARCH_INSURANCE)) {
            quicksearch = new RiaFirmSearchState({ quicksearch: quickSearchText });
        }

        let client = new APIClient();
        return client.getSearchTotalCount(quicksearch)
            .then(response => {
                props.quicksearchSubmitted(quickSearchText, response.data.count, quicksearch,);
                history.push("/quicksearch");
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Form onSubmit={performSearch}>
            <InputGroup>
                <Input type="text" placeholder="Quick search" value={quickSearchText}
                    onChange={event => setQuicksearchText(event.target.value)} />
                <InputGroupAddon addonType="append">
                    <Button type="submit" color="secondary" disabled={!quickSearchText}>Go!</Button>
                </InputGroupAddon>
            </InputGroup>
        </Form>
    );
}


const mapDispatchToProps = dispatch => ({
    quicksearchSubmitted: (searchQuery, rowCount, quicksearch) => dispatch(quicksearchSubmitted(searchQuery, rowCount, quicksearch)),
});

export default connect(null, mapDispatchToProps)(QuickSearch)
