// @flow
import React from 'react';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import riaAssetTypes from '../../constants/riaAssetTypes';
import { assetAmounts, clientAmounts } from '../../constants/riaAssetSliderAmounts';
import AssetRangeSlider from '../AssetRangeSlider';

export default class RiaFirmAssetTypesAndAmountsFields extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.getAssetTypeSelectOptions = this.getAssetTypeSelectOptions.bind(this);
        this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.assetAmountTicks = this.assetAmountTicks.bind(this);
        this.clientAmountTicks = this.clientAmountTicks.bind(this);
        this.handleAddAssetFilter = this.handleAddAssetFilter.bind(this);

        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    }


    handleDeleteButtonClick() {
        let licenseStatesAndGroups = this.props.formState.license_states_and_groups;
        licenseStatesAndGroups.splice(this.props.index, 1);
        let newCopy = [...licenseStatesAndGroups];

        this.props.onChange({
            target: {
                name: `license_states_and_groups`,
                value: newCopy,
            }
        });
    }

    handleAddAssetFilter() {
        let value = Object.values(this.props.formState.asset_types_and_amounts);

        let maxValueList = []
        for (let assetClause of value) {
            if (assetClause['i'] == -Infinity) {
                maxValueList.push(0)
            }
            else {
                maxValueList.push(assetClause['i'])
            }
        }
        let assetMaxValue = Math.max(...maxValueList)
        this.props.onChange({
            target: {
                name: 'asset_types_and_amounts.',
                value: {
                    i: assetMaxValue+1, 
                    client_range: [],
                    asset_range: []
                },
            }
        });
    }


    handleAssetTypeChange(prevAssetTypeName, newSelection) {
        let assetTypes = this.props.formState.asset_types_and_amounts;
        if (newSelection && prevAssetTypeName !== newSelection.value) {
        
            if (newSelection) {
                let newAssetTypeName = newSelection.value;
                assetTypes[newAssetTypeName] = assetTypes[prevAssetTypeName];
            }
            delete assetTypes[prevAssetTypeName];

            this.props.onChange({
                target: {
                    name: 'asset_types_and_amounts',
                    value: assetTypes,
              }
           });

            if (!assetTypes || Object.keys(assetTypes).length === 0) {
            // ensure there's always an empty one waiting to be used
                this.handleAddAssetFilter();
            }
        }
      

        else if(!newSelection){
            // Delete the previous select count type while clearing the count type 
            delete assetTypes[prevAssetTypeName];
            this.props.onChange({
                target: {
                    name: 'asset_types_and_amounts',
                    value: assetTypes,
                }
            });
        }
    }
    

    handleSliderChange(fieldName, value) {
        // console.log('handleSliderChange', fieldName, value)
        this.props.onChange({
            target: {
                name: fieldName,
                value: value,
            }
        });
    }

    getAssetTypeSelectOptions(currentValue) {
        // only show unchosen options, plus the current value
        return riaAssetTypes.filter(row => {
            return row.value === currentValue || !(row.value in this.props.formState.asset_types_and_amounts)
        });
    }

    assetAmountTicks(mark) {
        return assetAmounts.labels[mark];
    }

    clientAmountTicks(mark) {
        return clientAmounts.labels[mark];
    }

    render() {
        let assetFilters = this.props.formState.asset_types_and_amounts;

        // to ensure the license group rows don't jump around when things change, we need a deterministic
        // react "key" for each row, which can't be the license type as this does change
        // so instead we have a simple incrementing index "i", and make sure the groups are ordered by that
        let orderedAssetClauses = Object.keys(assetFilters).map(assetType => {
            return {...assetFilters[assetType], assetType: assetType}
        });
        orderedAssetClauses.sort((a, b) => a.i - b.i);

        // show the "add license search" button unless we already have a "blank" row
        let showAddAssetFilterButton = !('' in assetFilters);

        return <Card>
            <CardBody>
                <h4 className="header-title">Assets</h4>

                <strong>Filter by asset types, amounts, and number of clients</strong>
                {orderedAssetClauses.map((assetClause, i) =>
                    <Row className={"ria-firm-asset-filter-row px-2 py-2 " + ((i % 2) ? 'odd' : 'even')} key={i}>
                        <Col xs={5}>
                            <Select
                                value={riaAssetTypes.filter(row => row.value === assetClause.assetType)[0]}
                                options={this.getAssetTypeSelectOptions(assetClause.assetType)}
                                isSearchable={true}
                                isClearable={true}
                                autoFocus={false}
                                onChange={value => this.handleAssetTypeChange(assetClause.assetType, value)}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={2}>$ Assets</Col>
                                <Col>
                                    <AssetRangeSlider
                                        disabled={assetClause.assetType === 'default' || assetClause.assetType === ''}
                                        value={assetClause.asset_range}
                                        values={assetAmounts.values} labels={assetAmounts.labels}
                                        onChange={value => this.handleSliderChange(`asset_types_and_amounts.${assetClause.assetType}.asset_range`, value)}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={2}># Clients</Col>
                                <Col>
                                    <AssetRangeSlider
                                        disabled={assetClause.assetType === 'default' || assetClause.assetType === ''}
                                        value={assetClause.client_range}
                                        values={clientAmounts.values} labels={clientAmounts.labels}
                                        onChange={value => this.handleSliderChange(`asset_types_and_amounts.${assetClause.assetType}.client_range`, value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                {(showAddAssetFilterButton) &&
                <Row>
                    <Col xs={6} className="pl-3">
                        {showAddAssetFilterButton &&
                        <Button color="link" onClick={this.handleAddAssetFilter}>+ Add asset type</Button>
                        }
                    </Col>
                </Row>
                }

            </CardBody>
        </Card>
    }
}
