function column(field, width) {
    return {field: field, text: field, width: width}
}

let allColumnNames = [
    // RR Indiv columns
    {field: 'CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'First Name', width: 150, fixed: 'left'},
    {field: 'Middle Name', width: 150, fixed: 'left'},
    {field: 'Last Name', width: 150, fixed: 'left'},
    column('Suffix', 70),
    'Gender',
    'Date Hired',
    'Other Name 1',
    'Other Name 2',
    'Other Name 3',
    'Firm CRD',
    {field: 'Firm Name', width: 250, resizable: true},
    {field: 'BD Main Office Phone', width: 250, resizable: true},
    'BD Main Office Phone Ext',
    'Disclosure',
    'Employments Count',
    'Exam 1 Code',
    'Exam 1 Description',
    'Exam 1 Date',
    'Exam 1 Scope',
    'Exam 2 Code',
    'Exam 2 Description',
    'Exam 2 Date',
    'Exam 2 Scope',
    'Exam 3 Code',
    'Exam 3 Description',
    'Exam 3 Date',
    'Exam 3 Scope',
    'Employer 1 CRD',
    'Employer 1 Name',
    'Employer 1 Hire Date',
    'Employer 2 CRD',
    'Employer 2 Name',
    'Employer 2 Hire Date',
    'Employer 3 CRD',
    'Employer 3 Name',
    'Employer 3 Hire Date',
    {field: 'Business 1 Name', width: 250, resizable: true},
    'Employment 1 Start Date',
    'Employment 1 End Date',
    {field: 'Business 2 Name', width: 250, resizable: true},
    'Employment 2 Start Date',
    'Employment 2 End Date',
    {field: 'Business 3 Name', width: 250, resizable: true},
    'Employment 3 Start Date',
    'Employment 3 End Date',
    {field: 'Primary Email Address', width: 250, resizable: true},
    {field: 'Primary Email Domain', width: 150, resizable: true},
    'Alternate Sourced Email Indicator',
    {field: 'Secondary Email Address', width: 250, resizable: true},
    {field: 'Secondary Email Domain', width: 150, resizable: true},
    {field: 'Alternate Email Address', width: 250, resizable: true},
    {field: 'Alternate Email Domain', width: 150, resizable: true},
    'Registration 1 Authority',
    'Registration 1 Category',
    'Registration 1 Status',
    'Registration 1 Date',
    'Registration 2 Authority',
    'Registration 2 Category',
    'Registration 2 Status',
    'Registration 2 Date',
    'Registration 3 Authority',
    'Registration 3 Category',
    'Registration 3 Status',
    'Registration 3 Date',
    'Branch Office 1 id',
    'Branch Office 1 Firm CRD',
    {field: 'Branch Office 1 Address', width: 250, resizable: true},
    {field: 'Branch Office 2 Address', width: 250, resizable: true},
    {field: 'Branch Office 1 City', width: 150, resizable: true},
    'Branch Office 1 State',
    'Branch Office 1 Zip',
    'Branch Office 1 Zip4',
    {field: 'Branch Office 1 County', width: 150, resizable: true},
    'Branch Office 1 Country',
    'Branch Office 1 Located At Flag',
    'Branch Office 1 Supervised From Flag',
    'Branch Office 1 Private Residence Flag',
    'Branch Office 1 Non Registered Office Flag',
    'Branch Office 1 Ela Begin Date',
    'Branch Office 2 id',
    'Branch Office 2 Firm CRD',
    'Branch Office 2 City',
    'Branch Office 2 State',
    'Branch Office 2 County',
    'Branch Office 2 Country',
    'Branch Office 2 Zip',
    'Branch Office 2 Located At Flag',
    'Branch Office 2 Supervised From Flag',
    'Branch Office 2 Private Residence Flag',
    'Branch Office 2 Non Registered Office Flag',
    'Branch Office 2 Ela Begin Date',
    'Branch Office 3 id',
    'Branch Office 3 Firm CRD',
    'Branch Office 3 Address',
    'Branch Office 3 City',
    'Branch Office 3 State',
    'Branch Office 3 County',
    'Branch Office 3 Country',
    'Branch Office 3 Zip',
    'Branch Office 3 Located At Flag',
    'Branch Office 3 Supervised From Flag',
    'Branch Office 3 Private Residence Flag',
    'Branch Office 3 Non Registered Office Flag',
    'Branch Office 3 Ela Begin Date',
    {field: 'Main Office Address 1', width: 250, resizable: true},
    {field: 'Main Office Address 2', width: 250, resizable: true},
    'Main Office City',
    'Main Office State',
    'Main Office Zip',
    'Main Office Zip4',
    'Main Office County',
    'Main Office Country',
    'Total RRs at Firm',
    'Earliest Exam Date',
    'Number of Exams',
    'Earliest Exam Date Year',
    'Number of Licenses',
    'Earliest Employment Date RR',
    'Years Employed RR',
    'Number of States Registered',
    {field: 'Professional Designations 1', width: 250, resizable: true},
    {field: 'Professional Designations 2', width: 250, resizable: true},
    {field: 'BD Executive', width: 250, resizable: true},
    'Insurance Agent',
    {field: 'LinkedIn Profile', width: 400, resizable: true},
];

// export a list suitable for the datatable, eg every one needs a "field" and "text"
let allColumns = [];
for (let i=0; i<allColumnNames.length; i++) {
    let col = allColumnNames[i];
    if (typeof col === 'string') {
        allColumns.push({ field: col, text: col, width: 100 });
    } else {
        if (!('text' in col)) {
            col['text'] = col['field'];
        }
        if (!('width' in col)) {
            col['width'] = 100;
        }
        allColumns.push(col);
    }
}

export default allColumns;