import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import {Button, Card, CardBody, CardFooter, CardTitle, Col, FormGroup, Label} from 'reactstrap';
import SearchParamsListAndUnset from './SearchParamsListAndUnset';
import MultiSelect from './MultiSelectAll';
import styles from '../assets/scss/custom/pages/savedSearches.module.scss';

const SavedSearchCard = (props) => {
    const {
        savedSearch,
        index,
        layout,
        userId,
        onSearchDelete,
        onSearchEdit,
        onSearchView,
        onSearchShare,
        companyUsers,
    } = props;
    const [sharedUsers, setSharedUsers] = useState([]);
    const [searchOwner, setSearchOwner] = useState(false);
    const [isModified, setIsModified] = useState(false);

    const handleUsersChanged = (activeOptions) => {
        setSharedUsers(activeOptions);
        const users = activeOptions.map(({ value }) => value);
        onSearchShare(savedSearch.id, users);
    };

    useEffect(() => {
        setSearchOwner(savedSearch.user === userId);
    }, [userId, savedSearch.user]);

    useEffect(() => {
        const activeUsers = companyUsers.filter(({ value }) => savedSearch.search_users.includes(value));
        setSharedUsers(activeUsers);
    }, [savedSearch.search_users, companyUsers])

    useEffect(() => {
        let created_date = Date.parse(savedSearch.created)
        let modified_date = Date.parse(savedSearch.modified)
        let data_diff = modified_date - created_date
        let isModified = (data_diff>10) ? false : true;
        setIsModified(isModified);
    }, [savedSearch.created, savedSearch.modified])

    return (
        <Col className={styles.col} lg={layout === 'card' ? 6 : 12} xl={layout === 'card' ? 4 : 12} key={savedSearch.id}>
            <Card className={`${styles.card} ${!searchOwner ? 'shared-with-me' : (!!sharedUsers.length && savedSearch.is_shared == true ? 'shared' : '')}`}>
                <CardBody className={styles.body}>
                    <CardTitle tag="h2">{savedSearch.name}</CardTitle>

                    {searchOwner && !!sharedUsers.length && savedSearch.is_shared == true &&
                    <i>Shared with your company <br/></i>
                    }
                    {savedSearch.user !== userId && savedSearch.is_shared == true &&
                    <i>Shared by {savedSearch.user_first_name} {savedSearch.user_last_name} <br/></i>
                    }

                    Matched {savedSearch.row_count.toLocaleString()} rows, when 
                    {/* Remove the countdown format and Add the time in date format */}
                    {/* {isModified ? <> created <TimeAgo date={savedSearch.created} /></> : <> modified <TimeAgo date={savedSearch.modified} /></> } */}
                    {isModified ? <> created on {moment(savedSearch.created).format('MM-DD-YYYY')} </> :
                    <> modified on {moment(savedSearch.modified).format('MM-DD-YYYY')} </> 
                    }

                    <SearchParamsListAndUnset allowUnset={false} formState={savedSearch.formState} />
                </CardBody>
                <CardFooter className={styles.cardFooter}>
                    {searchOwner &&
                    <FormGroup className="m-0 flex-grow-1">
                        <Label
                            for="shareWithCompany"
                        >
                            Share with all company
                        </Label>
                        <MultiSelect
                            id="shareWithCompany"
                            isDisabled={!companyUsers.length}
                            options={companyUsers}
                            value={(sharedUsers && savedSearch.is_shared == true?sharedUsers:[])}
                            onChange={handleUsersChanged}
                            hideSelectedOptions={false}
                            isSearchable={false}
                            selectAllLabel="Share with all company"
                            placeholder="Share with..."
                            isClearable={false}
                            isMulti
                            // menuIsOpen
                        />
                    </FormGroup>
                    }
                    <div className="d-flex align-items-end justify-content-end text-nowrap pt-2">
                        <Button className="ml-2" color="secondary" onClick={() => onSearchView(index)}>View</Button>
                        <Button className="ml-2" color="info" onClick={() => onSearchEdit(index)}>Edit</Button>
                        {searchOwner && (
                            <Button
                                className="ml-2"
                                color="warning"
                                title="Delete"
                                onClick={() => onSearchDelete(index)}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </CardFooter>
            </Card>
        </Col>
    );
}

export default SavedSearchCard;