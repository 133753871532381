// @flow
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { searchFormChanged, searchCountRetrieved } from '../redux/firstClassData/actions'
import { loggedInUserHasPermission } from '../helpers/authUtils';
import { withRouter } from 'react-router'
import APIClient from '../utils/API';
import PageTitle from '../components/PageTitle';
import GenerateReportButton from '../components/GenerateReportButton';
import { SearchFormState } from '../redux/firstClassData/dtos';
import setNestedProp from '../utils/setNestedProp';
import 'react-bootstrap-typeahead/css/Typeahead.css';


class SearchPage extends React.Component {
    static propTypes = {
        pageTitle: PropTypes.string.isRequired,
        formName: PropTypes.string.isRequired,
        searchFormComponent: PropTypes.elementType.isRequired,
        searchFormStateClass: PropTypes.any,

        // these are added by the redux connect, to call the redux actions
        searchFormChanged: PropTypes.func.isRequired,
        searchCountRetrieved: PropTypes.func.isRequired,
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
        isLoading: PropTypes.bool,
    };
    static defaultProps = {
        searchFormStateClass: SearchFormState,
    };

    constructor(props) {
        super(props);
        this.state = {
            searchIsCounting: false,
        };

        this.handleSearchFormChange = this.handleSearchFormChange.bind(this);
        this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
        this.previewSearchCountDebounced = AwesomeDebouncePromise(this.previewSearchCount, 400);
        this.handleGenerateReportClick = this.handleGenerateReportClick.bind(this);

    }

    componentDidMount() {
        let initialFormState = this.props.allFormStates[this.props.formName];
        if (initialFormState) {
            this.handleSearchFormChange(initialFormState.formState);
        }
    }

    handleSearchFormChange(searchFormData) {
        this.setState({
            searchIsCounting: true,
        });
        this.props.searchFormChanged(this.props.formName, searchFormData);
        this.previewSearchCountDebounced(this, searchFormData)
            .then((response) => {
                this.props.searchCountRetrieved(this.props.formName, response?.data.count);
                this.setState({
                    searchIsCounting: false,
                });
            });
    }

    handleSearchFieldChange(event) {
        let fieldName = ''
        let fieldValue = ''

        if (event && event.target) {
            fieldName = event.target.name;
            fieldValue = event.target.value;
        }

        let newFormState = {};

        if (fieldName === '' && fieldValue instanceof this.props.searchFormStateClass) {
            newFormState = fieldValue;

        } else {
            if (event && event.target && event.target.hasOwnProperty('checked')) {
                if (!fieldValue) {
                    fieldValue = event.target.checked;
                } else if (!event.target.checked) {
                    // only use the form field "value" if it is checked
                    fieldValue = false;
                } else if (fieldValue === 'true' || fieldValue === 'on') {
                    fieldValue = true;
                }
            }

            newFormState = {...this.props.allFormStates[this.props.formName].formState};
            setNestedProp(newFormState, fieldName, fieldValue);
        }

        if (fieldName === 'npns' && newFormState.filter_is_agent === 'agents') {
            // when they actively search for NPN, remove the default filter of "agents"
            newFormState.filter_is_agent = "both";
        }

        // let selectedDesignations = [];
        // // This process is only for the IAR page designation multi select option
        // if ((this.props.formName === 'riaIndivSearch') && (!event || !event.target)) {
        //     if (event) {
        //         for(let designation of event) {
        //             selectedDesignations.push(designation.value);
        //         }
        //     }
        // }
        // newFormState.designation = selectedDesignations;

        this.handleSearchFormChange(new this.props.searchFormStateClass(newFormState));
    }

    previewSearchCount(self, searchFormData) {
        let client = new APIClient();
        return client.getSearchTotalCount(searchFormData)
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGenerateReportClick(event) {
        const resultsUrlMap = {
            'agentSearch': '/agent/results',
            'groupSearch': '/group/results',
            'riaFirmSearch': '/riafirm/results',
            'riaIndivSearch': '/iar/results',
            'bdFirmSearch' : '/bd/results',
            'rrIndivSearch' : '/rr/results'
        }
        const resultsUrl = resultsUrlMap[this.props.formName] || '/group/results';
        this.props.history.push(resultsUrl);
    }

    render() {
        const permissionMap = {
            'agentSearch': 'search_insurance',
            'groupSearch': 'search_insurance',
            'riaFirmSearch': 'search_ria',
            'riaIndivSearch': 'search_ria',
            'bdFirmSearch':'search_ria',
            'rrIndivSearch' : 'search_ria'
        }
        const hasPermission = loggedInUserHasPermission(permissionMap[this.props.formName]);
        const SearchComponent = this.props.searchFormComponent;
        const searchFormProps = this.props.allFormStates[this.props.formName];

        return (
            <>
                <PageTitle title={this.props.pageTitle} />
                <Row>
                    <Col lg={9}>
                        <SearchComponent
                            formState={searchFormProps.formState}
                            onSubmit={this.handleGenerateReportClick}
                            onFieldChange={this.handleSearchFieldChange}
                            formName={this.props.formName}
                            hasPermission = { hasPermission }
                        />
                    </Col>
                    {hasPermission &&
                    <Col lg={3}>
                        <GenerateReportButton onSubmit={this.handleGenerateReportClick}
                                                onChange={this.handleSearchFieldChange}
                                                formState={searchFormProps.formState}
                                                recordCount={searchFormProps.rowCount}
                                                searchIsCounting={this.state.searchIsCounting || this.props.isLoading} />
                    </Col>
                    }
                </Row>
            </>
        );
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
    isLoading: storeState.Layout.isLoading,
});

const mapDispatchToProps = dispatch => ({
    searchFormChanged: (formName, formState) => dispatch(searchFormChanged(formName, formState)),
    searchCountRetrieved: (formName, rowCount) => dispatch(searchCountRetrieved(formName, rowCount)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPage));