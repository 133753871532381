// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import PageTitle from '../components/PageTitle';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';
import bdFirmColumns from "../constants/bdFirmColumns";
import { bdFirmReportTypes } from '../constants/reportTypes';
import BdFirmDetailsModal from '../components/BdFirmDetailsModel';
import RRIndivDetailsModal from '../components/RRIndivDetailsModel';
import { bdExecutiveReportTypes } from '../constants/reportTypes';
import bdExecutiveColumns from '../constants/bdExecutiveColumns'
import IarDetailsModal from '../components/IarDetailsModal';
import RiaFirmDetailsModal from '../components/RiaFirmDetailsModal';


class bdSearchResultsPage extends React.Component {
    static propTypes = {
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            showAgentDetailsModal: false,
            agentDetailsId: null,
            riaDetailsId: null,
            iarDetailsId: null,
            IarModal: false,
            hasProfileModel: null,
            rowId: null,
            iarRowId: null,
            iarIndivDetailsModal: false,
            bdRiaRowId: null,
            bdRiaModel: false,
            RRBdRowId: null,
            RRBdModal: false,
            RRIarModal: false,
            RRIarRowId: null,
        }
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.handleDetailsModalClose = this.handleDetailsModalClose.bind(this);
        this.handleIarIndividualDetails = this.handleIarIndividualDetails.bind(this);
        this.handleIarDetailsModalClose = this.handleIarDetailsModalClose.bind(this);
        this.onIarIndividualDetails = this.onIarIndividualDetails.bind(this);
        this.onBdModalChanged = this.onBdModalChanged.bind(this);
        this.onBdIarIndividualDetails = this.onBdIarIndividualDetails.bind(this);
        this.onBdRRIndividualDetails = this.onBdRRIndividualDetails.bind(this);
        this.onBdIarRRIndividualDetails = this.onBdIarRRIndividualDetails.bind(this);
    }

    handleIndividualDetails(id, rowValues=[], onClickValues) {
        this.setState({
            RRBdModal: false,
            showAgentDetailsModal: true,
            agentDetailsId: id,
            riaDetailsId: rowValues,
            iarDetailsId: onClickValues,
        })
    }

    handleDetailsModalClose() {
        this.setState({
            showAgentDetailsModal: false,
            agentDetailsId: null,
            riaDetailsId: null,
            iarDetailsId: null,
            RRBdModal: false,
            RRIarModal: false,
        })
    }

    handleIarIndividualDetails(id, hasIarProfile, rowvalues) {
        this.setState({
            iarIndivDetailsModal: true,
            hasProfileModel: hasIarProfile,
            riaDetailsId: hasIarProfile,
            iarDetailsId: rowvalues,
            showAgentDetailsModal: false,
            IarModal: false,
            rowId: id,
        })
    }

    handleIarDetailsModalClose() {
        this.setState({
            iarIndivDetailsModal: false,
            IarModal: false,
            rowId: null,
            hasProfileModel: null,
        })
    }

    onIarIndividualDetails(id,hasIarProfile) {
        this.setState({
            IarModal: false,
            iarIndivDetailsModal: false,
            showAgentDetailsModal: true,
            agentDetailsId: id,
            hasProfileModel: null,
            iarDetailsId: hasIarProfile,
        })
    }

    onBdModalChanged(id) {
        this.setState({
            showAgentDetailsModal: true,
            iarDetailsId: id,
        })
    }

    onBdIarIndividualDetails(id, hasIarProfile) {
        this.setState({
            IarModal: true,
            iarIndivDetailsModal: false,
            bdRiaRowId: id,
            hasProfileModel: null,
            hasProfileModel: hasIarProfile,
        })
    }

    onBdRRIndividualDetails(id, onClickValues) {
        this.setState({
            RRBdModal: true,
            iarDetailsId: onClickValues,
            showAgentDetailsModal: false,
            RRIarModal: false,
            RRBdRowId: id,
            hasProfileModel: null,
        })
    }

    onBdIarRRIndividualDetails(id, rowValues=[], onClickValues) {
        this.setState({
            RRIarModal: true,
            showAgentDetailsModal: false,
            iarIndivDetailsModal: false,
            RRBdModal: false,
            RRIarRowId: id,
            hasProfileModel: null,
            riaDetailsId: rowValues,
            iarDetailsId: onClickValues,
        })
    }


    render() {
        const searchFormProps = this.props.allFormStates.bdFirmSearch;
        let search_executive = searchFormProps.formState.search_executive

        return <>
            <PageTitle title="<< Back to BD Firm Search" className="btn"
                    onClick={() => this.props.history.push("/bd/search")} />

            <PaginatedSearchResultsTable
                searchFormState={searchFormProps.formState}
                recordCount={searchFormProps.rowCount}
                reportTypes={search_executive?bdExecutiveReportTypes:bdFirmReportTypes}
                possibleColumns={search_executive?bdExecutiveColumns:bdFirmColumns}
                detailsModalComponent={search_executive?RRIndivDetailsModal:BdFirmDetailsModal}
                enableSaving={true}
                onModalChanged={search_executive ? this.onBdRRIndividualDetails : this.handleIndividualDetails}
                onIarModalChanged={this.handleIarIndividualDetails}
                onIrModalChanged={this.onIarIndividualDetails}
                onBdModalChanged={this.onIarIndividualDetails}
                listBdRow={this.state.iarDetailsId}
            />
             {!search_executive &&
              <RRIndivDetailsModal
                isOpen={this.state.showAgentDetailsModal}
                rowId={this.state.agentDetailsId}
                listValue={this.state.riaDetailsId}
                rowIndiv={this.state.iarDetailsId}
                hideBdModel={this.state.hasProfileModel}
                onIarModalChanged={this.handleIarIndividualDetails}
                onModalChanged={this.onBdRRIndividualDetails}
                onClickChanged={this.handleIndividualDetails}
                onClose={this.handleDetailsModalClose}
              />}

              {search_executive &&
              <RRIndivDetailsModal
                isOpen={this.state.RRIarModal}
                rowId={this.state.RRIarRowId}
                listValue={this.state.riaDetailsId}
                rowIndiv={this.state.iarDetailsId}
                hideBdModel={this.state.hasProfileModel}
                onModalChanged={this.onBdRRIndividualDetails}
                onIarModalChanged={this.handleIarIndividualDetails}
                onClickChanged={this.onBdIarRRIndividualDetails}
                onClose={this.handleDetailsModalClose}
              />}

            <IarDetailsModal
                isOpen={this.state.iarIndivDetailsModal}
                rowId={this.state.rowId}
                listValue={this.state.riaDetailsId}
                rowIndiv={this.state.iarDetailsId}
                onClickChanged={this.handleIarIndividualDetails}
                onIrModalChanged={search_executive ?this.onBdIarRRIndividualDetails : this.onIarIndividualDetails}
                onModalChanged={this.onBdIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />

            <RiaFirmDetailsModal
                isOpen={this.state.IarModal}
                rowId={this.state.bdRiaRowId}
                listRow={this.state.hasProfileModel}
                onModalChanged={this.handleIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />

            {search_executive &&
            <BdFirmDetailsModal
                isOpen={this.state.RRBdModal}
                rowId={this.state.RRBdRowId}
                listRow={this.state.iarDetailsId}
                onModalChanged={this.onBdIarRRIndividualDetails}
                onClose={this.handleDetailsModalClose} />}

            {!search_executive &&
            <BdFirmDetailsModal
                isOpen={this.state.RRBdModal}
                rowId={this.state.RRBdRowId}
                listRow={this.state.iarDetailsId}
                onModalChanged={this.handleIndividualDetails}
                onClose={this.handleDetailsModalClose} />}
                
        </>
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
});

export default connect(mapStateToProps)(bdSearchResultsPage)