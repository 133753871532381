import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, FormGroup, Button, Alert, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { loginUser } from '../../redux/actions';
import { connect } from 'react-redux';
import LoaderWidget from '../../components/Loader';
import logo from '../../assets/images/fcd-logo-orange.svg';
import APIClient from '../../utils/API';

class ResetPasswordFromEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            success: null,
            validToken: false,
            token: '',
            username: '',
            newPassword: '',
            newPasswordStrengthClass: null,
            newPasswordStrengthLabel: null
        };
        this.client = new APIClient();
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        // confirm there is a token in the URL, and make the call to verify it
        const urlParams = new URLSearchParams(window.location.search);
        let resetToken = urlParams.get('token');
        let username = urlParams.get('user');

        this.client.verifyResetPasswordToken(resetToken)
            .then(response => {
                this.setState({
                    username: username,
                    token: resetToken,
                    validToken: true
                });
            })
            .catch(err => {
                this.setState({
                    error: 'Invalid reset password link'
                });
            })
    }

    passwordStrength(pwd) {
        let score = 0;
		if (!pwd) {
			return score;
		}
		// award every unique letter until 5 repetitions
		let letters = {};
		for (let i=0; i<pwd.length; i++) {
			letters[pwd[i]] = (letters[pwd[i]] || 0) + 1;
			score += 5.0 / letters[pwd[i]];
		}

		// bonus points for mixing it up
		let variations = {
			digits: /\d/.test(pwd),
			lower: /[a-z]/.test(pwd),
			upper: /[A-Z]/.test(pwd),
			nonWords: /\W/.test(pwd),
		}

		let variationCount = 0;
		for (let check in variations) {
			variationCount += (variations[check] === true) ? 1 : 0;
		}
		score += (variationCount - 1) * 10;

		return parseInt(score, 10);
    }

    handlePasswordChange = (event) => {
        let password = event.target.value;
        let strength = this.passwordStrength(password);
        let passwordCategory = strength < 40 ? 'bad' : (strength < 75 ? 'weak' : 'ok');
        let labels = {
            'bad': 'Not strong enough',
            'weak': 'Weak',
            'ok': 'OK'
        };

        this.setState({
            newPassword: password,
            newPasswordStrengthClass: passwordCategory,
            newPasswordStrengthLabel: labels[passwordCategory],
        });
    }

    validatePassword = (value, ctx, input, cb) => {
        let strength = this.passwordStrength(value);
        cb(strength >= 40);
    }

    validateConfirmPassword = (value, ctx, input, cb) => {
        // just wait a fraction of a second before comparing, in case a password manager fills both
        // fields in and there's a race condition
        setTimeout(() => {
            let isValid = (value === this.state.newPassword);
            cb(isValid);
        }, 50);
    }

    handleValidSubmit = (event, values) => {
        this.client.resetPassword(this.state.token, this.state.newPassword)
            .then(response => {
                // any successful response means it worked
                this.setState({
                    success: 'Your password has been successfully updated, logging you in now...'
                });
                setTimeout(() => {
                    this.props.history.push('/group/search');
                    this.props.loginUser(this.state.username, this.state.newPassword);
                }, 1500);
            })
            .catch(err => {
                // there was some validation error
                console.log('handleValidSubmit error', err);
                this.setState({
                    error: 'An error occurred, please try again'
                })
            })
        return false;
    };

    render() {
        return (
            <div className="account-pages mt-5 mb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={5}>
                            <Card>
                                <div className="card-header pt-4 pb-4 text-center bg-primary">
                                    <Link to="/">
                                        <span>
                                            <img src={logo} alt="First Class Data" className="vectorlogo" />
                                        </span>
                                    </Link>
                                </div>

                                <CardBody className="p-4 position-relative">
                                    <div className="text-center w-75 m-auto">
                                        <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                                            Reset Your Password
                                        </h4>
                                    </div>

                                    {this.state.success ?
                                        <Alert
                                            color="success"
                                            isOpen={this.state.success ? true : false}>
                                            {this.state.success}
                                        </Alert>
                                        :
                                        <>
                                            {this.state.error && (
                                                <Alert color="danger" isOpen={this.state.error ? true : false}>
                                                    <div>{this.state.error}</div>
                                                </Alert>
                                            )}

                                            {this.state.loading && <LoaderWidget />}

                                            {this.state.validToken && <>
                                                <div className="text-center w-75 m-auto">
                                                    <p className="text-muted mb-4">
                                                        Enter and confirm a new password
                                                    </p>
                                                </div>

                                                <AvForm onValidSubmit={this.handleValidSubmit}>
                                                    <AvGroup>
                                                        <div className="d-flex justify-content-between">
                                                            <Label for="new_password">New Password</Label>
                                                            <span className={"password-strength " + this.state.newPasswordStrengthClass}>{this.state.newPasswordStrengthLabel}</span>
                                                        </div>
                                                        <AvInput
                                                            type="password"
                                                            name="new_password"
                                                            id="new_password"
                                                            required
                                                            onChange={this.handlePasswordChange}
                                                            validate={{async: this.validatePassword}}
                                                        />
                                                        <AvFeedback>This field is invalid</AvFeedback>
                                                    </AvGroup>

                                                    <AvGroup>
                                                        <Label for="confirm_password">Confirm Password</Label>
                                                        <AvInput
                                                            type="password"
                                                            name="confirm_password"
                                                            id="confirm_password"
                                                            required
                                                            validate={{
                                                                async: this.validateConfirmPassword
                                                            }}
                                                        />
                                                        <AvFeedback>Passwords do not match</AvFeedback>
                                                    </AvGroup>

                                                    <FormGroup>
                                                        <Button color="primary">Submit</Button>
                                                    </FormGroup>
                                                </AvForm>
                                            </>}
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(
    mapStateToProps,
    { loginUser }
)(ResetPasswordFromEmail);
