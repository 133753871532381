// @flow
import React from 'react';
import {Button, Card, CardBody, Col, Row, Input} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import riaEmployeeRepCountTypes from '../../constants/riaEmployeeRepCountTypes';
import { employeeCount, iAReps, dBReps, insuranceLicensedReps,
         firmsSolicitingClients, investmentAdvisoryClients, planningClients }
 from '../../constants/riaAssetSliderAmounts';
import AssetRangeSlider from '../AssetRangeSlider';


// Match the filter with the respective range sliders
const matchCountRangeFilter = {
    '': employeeCount,
    'default': employeeCount,
    'number_employees': employeeCount,
    'number_iareps': iAReps,
    'number_bdreps': dBReps,
    'number_insurancelicensedreps': insuranceLicensedReps,
    'number_firmssolicitingclients': firmsSolicitingClients,
    'number_investmentadvisoryclients': investmentAdvisoryClients,
    'number_planningclients': planningClients,
}

export default class RiaFirmEmployeeAndRepCount extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.getEmployeeCountSelectOptions = this.getEmployeeCountSelectOptions.bind(this);
        this.handleEmployeeCountTypeChange = this.handleEmployeeCountTypeChange.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.employeeCountTicks = this.employeeCountTicks.bind(this);
        this.iARepsTicks = this.iARepsTicks.bind(this);
        this.dBRepsTicks = this.dBRepsTicks.bind(this);
        this.insuranceLicensedRepsTicks = this.insuranceLicensedRepsTicks.bind(this);
        this.firmsSolicitingClientsTicks = this.firmsSolicitingClientsTicks.bind(this);
        this.investmentAdvisoryClientsTicks = this.investmentAdvisoryClientsTicks.bind(this);
        this.planningClientsTicks = this.planningClientsTicks.bind(this);
        this.handleAddCountFilter = this.handleAddCountFilter.bind(this);
        this.handleEmployeeCountTypeBoolChange = this.handleEmployeeCountTypeBoolChange.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.canShowFilterCondition = this.canShowFilterCondition.bind(this);
    }


    handleDeleteButtonClick() {
        let licenseStatesAndGroups = this.props.formState.license_states_and_groups;
        licenseStatesAndGroups.splice(this.props.index, 1);
        let newCopy = [...licenseStatesAndGroups];

        this.props.onChange({
            target: {
                name: `license_states_and_groups`,
                value: newCopy,
            }
        });
    }

    handleAddCountFilter() {
        let value = Object.values(this.props.formState.employee_and_rep_count);

        let maxValueList = []
        for (let employeeClause of value) {
            if (employeeClause['i'] == -Infinity) {
                maxValueList.push(0)
            }
            else {
                maxValueList.push(employeeClause['i'])
            }
        }
        let firmMaxValue = Math.max(...maxValueList)
        this.props.onChange({
            target: {
                name: 'employee_and_rep_count.',
                value: {
                    i: firmMaxValue+1,
                    count_range: [],
                },
            }
        });
    }

    handleEmployeeCountTypeChange(prevCountTypeName, newSelection) {
        // Prevent the duplicate type selection issue while count types on change
        let countTypes = this.props.formState.employee_and_rep_count;

        if (newSelection && prevCountTypeName !== newSelection.value) {
            let fieldName = '';
            let value = [];

            // Change the selected count type in the dropdown and set the ranges to default
            if (newSelection) {
                let newCountTypeName = newSelection.value;
                countTypes[newCountTypeName] = countTypes[prevCountTypeName];
                fieldName = `employee_and_rep_count.${newCountTypeName}.count_range`;
                value = [0, 'all'];
                this.handleSliderChange(fieldName, value);
            }

            delete countTypes[prevCountTypeName];
            this.props.onChange({
                target: {
                    name: 'employee_and_rep_count',
                    value: countTypes,
                }
            });

        }
        else if (!newSelection) {
            // Delete the previous select count type while clearing the count type 
            delete countTypes[prevCountTypeName];
            this.props.onChange({
                target: {
                    name: 'employee_and_rep_count',
                    value: countTypes,
                }
            });
        }

        if (!countTypes || Object.keys(countTypes).length === 0) {
            // ensure there's always an empty one waiting to be used
            this.handleAddCountFilter();
        }
        
    }

    handleEmployeeCountTypeBoolChange(field) {
        let countTypesBool = field.target.value;
        this.props.onChange({
            target: {
                name: 'employee_and_rep_count_bool',
                value: countTypesBool,
            }
        });
    }

    handleSliderChange(fieldName, value) {
        this.props.onChange({
            target: {
                name: fieldName,
                value: value,
            }
        });
    }

    getEmployeeCountSelectOptions(currentValue) {
        // only show unchosen options, plus the current value
        return riaEmployeeRepCountTypes.filter(row => {
            return row.value === currentValue || !(row.value in this.props.formState.employee_and_rep_count)
        });
    }

    employeeCountTicks(mark) {
        return employeeCount.labels[mark];
    }

    iARepsTicks(mark) {
        return iAReps.labels[mark];
    }

    dBRepsTicks(mark) {
        return dBReps.labels[mark];
    }

    insuranceLicensedRepsTicks(mark) {
        return insuranceLicensedReps.labels[mark];
    }

    firmsSolicitingClientsTicks(mark) {
        return firmsSolicitingClients.labels[mark];
    }

    investmentAdvisoryClientsTicks(mark) {
        return investmentAdvisoryClients.labels[mark];
    }

    planningClientsTicks(mark) {
        return planningClients.labels[mark];
    }

    canShowFilterCondition(orderedEmployeeClauses) {
        let usedFilterRangeLength = 0;

        if (orderedEmployeeClauses.length > 1) {
            for (let countType of Object.keys(orderedEmployeeClauses)) {
                let sliderTypes = {
                    'count_range': {
                        label: '# Counts',
                        formatFunc: value => value
                    }
                }
                if (!countType || countType === 'default') {
                    continue;
                }
                for (let sliderType of Object.keys(sliderTypes)) {    
                    const range = orderedEmployeeClauses[countType][sliderType];
                    if (!range || !range.length || (range[0] === 0 && range[1] === 'all')) {
                        // no filtering
                        continue;
                    }
                    usedFilterRangeLength ++;
                }
            }
            if (usedFilterRangeLength > 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    render() {
        let employeeFilters = this.props.formState.employee_and_rep_count;
        let employeeFiltersBool = this.props.formState.employee_and_rep_count_bool;

        // to ensure the count group rows don't jump around when things change, we need a deterministic
        // react "key" for each row, which can't be the count type as this does change
        // so instead we have a simple incrementing index "i", and make sure the groups are ordered by that
        let orderedEmployeeClauses = Object.keys(employeeFilters).map(countType => {
            return {...employeeFilters[countType], countType: countType}
        });
        orderedEmployeeClauses.sort((a, b) => a.i - b.i);

        // show the "add count search" button unless we already have a "blank" row
        let showAddEmployeeFilterButton = !('default' in employeeFilters || '' in employeeFilters);

        return <Card>
            <CardBody>
                <h4 className="header-title">Employees and Reps count</h4>

                <strong>Filter by total number of employee, rep counts</strong>
                {orderedEmployeeClauses.map((employeeClause, i) =>
                    <Row className={"ria-firm-asset-filter-row px-2 py-2 " + ((i % 2) ? 'odd' : 'even')} key={i}>
                        <Col xs={5}>
                            <Select
                                value={riaEmployeeRepCountTypes.filter(row => row.value === employeeClause.countType)[0]}
                                options={this.getEmployeeCountSelectOptions(employeeClause.countType)}
                                isSearchable={true}
                                isClearable={true}
                                autoFocus={false}
                                onChange={value => this.handleEmployeeCountTypeChange(employeeClause.countType, value)}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={2}># Counts</Col>
                                <Col>
                                    <AssetRangeSlider
                                        disabled={employeeClause.countType === 'default' || employeeClause.countType === ''}
                                        value={employeeClause.count_range}
                                        values={matchCountRangeFilter[employeeClause.countType].values} labels={matchCountRangeFilter[employeeClause.countType].labels}
                                        onChange={value => this.handleSliderChange(`employee_and_rep_count.${employeeClause.countType}.count_range`, value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                {(this.canShowFilterCondition(orderedEmployeeClauses) &&
                    <div className="form-inline flex-row-reverse pt-1">
                        <p>
                            Search for firms has &nbsp;
                            <Input type="select"
                                name="disclosure_type_bool"
                                bsSize="sm"
                                value={employeeFiltersBool}
                                onChange={(event) => this.handleEmployeeCountTypeBoolChange(event)}>
                                <option value="and">all of these count types</option>
                                <option value="or">any of these count types</option>
                            </Input>
                        </p>
                    </div>
                )}
                {(showAddEmployeeFilterButton) &&
                <Row>
                    <Col xs={6} className="pl-3">
                        {showAddEmployeeFilterButton &&
                        <Button color="link" onClick={this.handleAddCountFilter}>+ Add count type</Button>
                        }
                    </Col>
                </Row>
                }

            </CardBody>
        </Card>
    }
}
