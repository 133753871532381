// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';

const EmailAddressSelect = ({ formName }) => {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.FirstClassData.formStates[formName].formState.email_address);
    const updateEmail = useSelector((state) => state.FirstClassData.formStates[formName].formState.emails);
    const deleteEmail = useSelector((state) => state.FirstClassData.formStates[formName].formState.emails = '');

    const [email_address, canAddAnotherEmailClause] = useMemo(() => {
        const canAddAnotherEmailClause = email.filter(row => {

            if (updateEmail) {
                row.emails = updateEmail.toString()
            }
                    return row.emails.length === 0;
                }).length === 0;
        return [email, canAddAnotherEmailClause];
    }, [email]);


    const onFieldChange = (event, index) => {
        let emailAddress = email_address.slice();
        emailAddress[index][event.target.name] = event.target.value;
        dispatch(mergeSearch({ email_address: emailAddress }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let emailAddress = email_address.slice();
        emailAddress[index].exclude = !exclude;
        dispatch(mergeSearch({ email_address: emailAddress }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let emailAddress = email_address.slice();
        emailAddress.splice(index, 1);
        dispatch(mergeSearch({ email_address: emailAddress }, formName));
    };
    const handleAddStateClause = () => {
        let emailAddress = email_address.slice();
        emailAddress.push({
            exclude: false,
            email_address_bool: 'or',
            emails: []
        });
        dispatch(mergeSearch({ email_address: emailAddress }, formName));
    };

    return (
        <Col lg={12}>
            {email_address.map((emailAddressClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(emailAddressClause.exclude, index)}
                        >
                            {emailAddressClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these Emails:

                        {email_address.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Email(s)</Label>
                                        <Col lg={13}>
                                            <Input type="text" name="emails" value={emailAddressClause.emails.toString().split(",")}
                                            onChange={(event) => onFieldChange(event, index)} placeholder="Email(s)" />
                                        </Col>
                                    {emailAddressClause.emails.toString().split(",").length > 1 &&
                                    <div className="form-inline flex-row-reverse pt-1">
                                        <p>
                                            Search for emails in &nbsp;
                                            <Input type="select"
                                                   name="email_address_bool"
                                                   bsSize="sm"
                                                   value={emailAddressClause.email_address_bool}
                                                   onChange={(event) => onFieldChange(event, index)}>
                                                <option value="and">all of these emails</option>
                                                <option value="or">any of these emails</option>
                                            </Input>
                                        </p>
                                    </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
            {canAddAnotherEmailClause && (
                <Button
                    className="p-0 ml-3"
                    color="link"
                    onClick={handleAddStateClause}>
                    + Add another email clause
                </Button>
            )}
        </Col>
    );
};

export default EmailAddressSelect;