// @flow
import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { RangeSlider } from 'rsuite';
import Select from 'react-select';
import USStateSelect from '../USStateSelect';
import PropTypes from 'prop-types';
import licenseGroups from '../../constants/licenseGroups';


export default class LicenseStatesAndTypesSelects extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.onFieldChange = this.onFieldChange.bind(this);
        this.handleIncludeExcludeClick = this.handleIncludeExcludeClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleLicenseGroupChange = this.handleLicenseGroupChange.bind(this);
        this.handleAddLicenseGroup = this.handleAddLicenseGroup.bind(this);
        this.getLicenseGroupSelectOptions = this.getLicenseGroupSelectOptions.bind(this);
    }

    onFieldChange(event) {
        this.props.onChange({
            target: {
                name: `license_states_and_groups.${this.props.index}.${event.target.name}`,
                value: event.target.value,
            }
        });
    }

    handleIncludeExcludeClick() {
        this.props.onChange({
            target: {
                name: `license_states_and_groups.${this.props.index}.exclude`,
                value: !this.props.formState.license_states_and_groups[this.props.index].exclude,
            }
        });
    }

    handleDeleteButtonClick() {
        let licenseStatesAndGroups = this.props.formState.license_states_and_groups;
        licenseStatesAndGroups.splice(this.props.index, 1);
        let newCopy = [...licenseStatesAndGroups];

        this.props.onChange({
            target: {
                name: `license_states_and_groups`,
                value: newCopy,
            }
        });
    }

    handleSliderChange(fieldName, value) {
        if (value[0] > value[1])
        {
            var temp = value[0];
            value[0] = value[1];
            value[1] = temp;
        }
        this.props.onChange({
            target: {
                name: `license_states_and_groups.${this.props.index}.${fieldName}`,
                value: value,
            }
        });
    }

    handleLicenseGroupChange(prevLicenseGroupName, newSelection) {
        let licenseGroups = this.props.formState.license_states_and_groups[this.props.index].license_groups;
        
        if(newSelection && prevLicenseGroupName !== newSelection.value) {
  
            if (newSelection) {
                let newLicenseGroupName = newSelection.value;
                licenseGroups[newLicenseGroupName] = licenseGroups[prevLicenseGroupName];
            }
            delete licenseGroups[prevLicenseGroupName];

            this.props.onChange({
                target: {
                    name: `license_states_and_groups.${this.props.index}.license_groups`,
                    value: licenseGroups,
                }
            });

            if (!licenseGroups || Object.keys(licenseGroups).length === 0) {
                this.handleAddLicenseGroup();
            }      
        }
        else if(!newSelection) {
            // Delete the previous select count type while clearing the count type
            delete licenseGroups[prevLicenseGroupName];
            this.props.onChange({
                target: {
                    name: `license_states_and_groups.${this.props.index}.license_groups`,
                    value:licenseGroups,
                }
            });
        }
    }

    handleAddLicenseGroup() {
        let value = Object.values(this.props.formState.license_states_and_groups[this.props.index].license_groups);

        let maxValueList = []
        for (let employeeClause of value) {
            if (employeeClause['i'] == -Infinity) {
                maxValueList.push(0)
            }
            else {
                maxValueList.push(employeeClause['i'])
            }
        }
        let maxValue = Math.max(...maxValueList)
        this.props.onChange({
            target: {
                name: `license_states_and_groups.${this.props.index}.license_groups.`,
                value: {
                    i: maxValue+1,
                    range: [1, 50]
                },
            }
        });
    }

    getLicenseGroupSelectOptions(currentValue) {
        // only show unchosen options, plus the current value
        return licenseGroups.filter(row => {
            return row.value === currentValue || !(row.value in this.props.formState.license_states_and_groups[this.props.index].license_groups)
        });
    }

    licensedStatesCountTicks(mark) {
        if ([1, 5, 10, 20, 30, 50].includes(mark)) {
            return <span>{mark < 50 ? mark : 'all states'}</span>;
        }
        return null;
    }

    render() {
        let formLicenseState = this.props.formState.license_states_and_groups[this.props.index];
        let selectedLicenseGroups = Object.keys(formLicenseState.license_groups);

        // to ensure the license group rows don't jump around when things change, we need a deterministic
        // react "key" for each row, which can't be the license type as this does change
        // so instead we have a simple incrementing index "i", and make sure the groups are ordered by that
        let orderedLicenseGroups = Object.keys(formLicenseState.license_groups).map(groupName => {
            return {...formLicenseState.license_groups[groupName], groupName: groupName}
        });
        orderedLicenseGroups.sort((a, b) => a.i - b.i);

        let showMultipleGroupsBoolSelect = selectedLicenseGroups.length > 1;
        // show the "add license search" button unless we already have a "blank" row
        let showAddLicenseSearchButton = !('' in formLicenseState.license_groups);

        let showDeleteLicenseSearchButton = this.props.formState.license_states_and_groups.length > 1;

        return <Card className="my-1 license-groups-card-border">
            <CardHeader className="bg-light">
                <a className="link" onClick={this.handleIncludeExcludeClick}>{formLicenseState.exclude ? 'Exclude' : 'Include'}</a>
                &nbsp;records matching these licenses:

                {showDeleteLicenseSearchButton &&
                    <button onClick={this.handleDeleteButtonClick} type="button" className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                }
            </CardHeader>
            <CardBody className="pt-1">
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Licensed in state(s)</Label>
                            <USStateSelect name="license_states" multi={true}
                                            value={formLicenseState.license_states}
                                            onChange={this.onFieldChange} />

                            {formLicenseState.license_states.length > 1 &&
                                <div className="form-inline flex-row-reverse">
                                    <p>
                                        Search for agents licensed in &nbsp;
                                        <Input type="select" name="license_states_bool" bsSize="sm"
                                                value={formLicenseState.license_states_bool} onChange={this.onFieldChange}>
                                            <option value="and">all of these states</option>
                                            <option value="or">any of these states</option>
                                        </Input>
                                    </p>
                                </div>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Filter by license types, and number of states licensed in</strong>
                        {orderedLicenseGroups.map(licenseGroup =>
                            <Row className="px-2 py-1" key={licenseGroup.i}>
                                <Col xs={5}>
                                    <Select
                                        value={licenseGroups.filter(group => group.value === licenseGroup.groupName)[0]}
                                        options={this.getLicenseGroupSelectOptions(licenseGroup.groupName)}
                                        isSearchable={true}
                                        isClearable={true}
                                        autoFocus={false}
                                        onChange={value => this.handleLicenseGroupChange(licenseGroup.groupName, value)}
                                    />
                                </Col>
                                <Col>
                                    <RangeSlider min={1} max={50}
                                                    disabled={licenseGroup.groupName === 'default' || licenseGroup.groupName === ''}
                                                    value={licenseGroup.range}
                                                    onChange={value => this.handleSliderChange(`license_groups.${licenseGroup.groupName}.range`, value)}
                                                    renderMark={this.licensedStatesCountTicks}
                                                    graduated={true}
                                    />
                                </Col>
                            </Row>
                        )}
                        {(showAddLicenseSearchButton || showMultipleGroupsBoolSelect) &&
                        <Row>
                            <Col xs={6} className="pl-3">
                                {showAddLicenseSearchButton &&
                                <Button color="link" onClick={this.handleAddLicenseGroup}>+ Add license type</Button>
                                }
                            </Col>
                            <Col>
                                {showMultipleGroupsBoolSelect &&
                                <div className="form-inline text-right">
                                    <p>
                                        Search for agents with &nbsp;
                                        <Input type="select" name="license_groups_bool" bsSize="sm"
                                                value={formLicenseState.license_groups_bool} onChange={this.onFieldChange}>
                                            <option value="and">all of these license types</option>
                                            <option value="or">any of these license types</option>
                                        </Input>
                                    </p>
                                </div>
                                }
                            </Col>
                        </Row>
                        }

                    </Col>
                </Row>
            </CardBody>
        </Card>
    }
}
