// @flow
import { all, fork, put, takeEvery, select } from 'redux-saga/effects';
import * as actions from '../actions';
import { SEARCH_MERGE } from './constants';
import APIClient from '../../utils/API';
import { loggedInUserHasPermission } from '../../helpers/authUtils';

function previewSearchCount(searchFormData) {
    let client = new APIClient();
    return client.getSearchTotalCount(searchFormData);
}

function saveStateToLocalStorage(formName, formState) {
    window.localStorage.setItem(formName, JSON.stringify(formState));
}

function ensureUserSearchPermissions(formState) {
    const hasSearchAgentsOnlyPermission = loggedInUserHasPermission('search_agents_only');
    const hasSearchAgenciesOnlyPermission = loggedInUserHasPermission('search_agencies_only');

    // ensure that if their user permission says "only agents / agencies", then that's what
    // we are searching for
    if (hasSearchAgentsOnlyPermission && 'filter_is_agent' in formState && formState.filter_is_agent !== 'agents') {
        formState.filter_is_agent = 'agents';
    }

    if (hasSearchAgenciesOnlyPermission && 'filter_is_agent' in formState && formState.filter_is_agent !== 'agencies') {
        formState.filter_is_agent = 'agencies';
    }

    return formState;
}

/**
 * Merge Search params
 * temporary solution to avoid drilling props through the page components
 * @param {*} payload - form data
 */
function* mergeSearch({ payload, formName }) {
    try {
        yield put(actions.setLoadingOn());
        let searchData = yield select((state) => state.FirstClassData.formStates[formName].formState);
        Object.keys(payload).forEach((formField) => {
            searchData[formField] = payload[formField];
        })
        searchData = ensureUserSearchPermissions(searchData);
        const {data} = yield previewSearchCount(searchData);
        yield saveStateToLocalStorage(formName, searchData);
        yield put(actions.updateSearch(searchData, data?.count || 0, formName));
        yield put(actions.setLoadingOff());
    } catch (error) {
        console.log(error);
        yield put(actions.setLoadingOff());
    }
}

function* watchMergeSearch() {
    yield takeEvery(SEARCH_MERGE, mergeSearch);
}

function* searchSaga() {
    yield all([fork(watchMergeSearch)]);
}

export default searchSaga;
