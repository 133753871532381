import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const licenceTypes = [
    { value: 'F04', label: 'F04' },
    { value: 'SIE', label: 'SIE' },
    { value: 'SERIES 00', label: 'Series 00' },
    { value: 'SERIES 000', label: 'Series 000' },
    { value: 'SERIES 1', label: 'Series 1' },
    { value: 'SERIES 2', label: 'Series 2' },
    { value: 'SERIES 3', label: 'Series 3' },
    { value: 'SERIES 4', label: 'Series 4' },
    { value: 'SERIES 5PC', label: 'Series 5PC' },
    { value: 'SERIES 6', label: 'Series 6' },
    { value: 'SERIES 6TO', label: 'Series 6TO' },
    { value: 'SERIES 7', label: 'Series 7' },
    { value: 'SERIES 7A', label: 'Series 7A' },
    { value: 'SERIES 7B', label: 'Series 7B' },
    { value: 'SERIES 7TO', label: 'Series 7TO' },
    { value: 'SERIES 8', label: 'Series 8' },
    { value: 'SERIES 9', label: 'Series 9' },
    { value: 'SERIES 10', label: 'Series 10' },
    { value: 'SERIES 11', label: 'Series 11' },
    { value: 'SERIES 12', label: 'Series 12' },
    { value: 'SERIES 14', label: 'Series 14' },
    { value: 'SERIES 14A', label: 'Series 14A' },
    { value: 'SERIES 15', label: 'Series 15' },
    { value: 'SERIES 16', label: 'Series 16' },
    { value: 'SERIES 17', label: 'Series 17' },
    { value: 'SERIES 18,', label: 'Series 18' },
    { value: 'SERIES 19', label: 'Series 19' },
    { value: 'SERIES 21', label: 'Series 21' },
    { value: 'SERIES 22', label: 'Series 22' },
    { value: 'SERIES 22TO', label: 'Series 22TO' },
    { value: 'SERIES 23', label: 'Series 23' },
    { value: 'SERIES 24', label: 'Series 24' },
    { value: 'SERIES 25', label: 'Series 25' },
    { value: 'SERIES 26', label: 'Series 26' },
    { value: 'SERIES 27', label: 'Series 27' },
    { value: 'SERIES 28', label: 'Series 28' },
    { value: 'SERIES 30', label: 'Series 30' },
    { value: 'SERIES 31', label: 'Series 31' },
    { value: 'SERIES 32', label: 'Series 32' },
    { value: 'SERIES 33', label: 'Series 33' },
    { value: 'SERIES 34', label: 'Series 34' },
    { value: 'SERIES 37', label: 'Series 37' },
    { value: 'SERIES 38', label: 'Series 38' },
    { value: 'SERIES 39', label: 'Series 39' },
    { value: 'SERIES 40', label: 'Series 40' },
    { value: 'SERIES 41', label: 'Series 41' },
    { value: 'SERIES 42', label: 'Series 42' },
    { value: 'SERIES 44', label: 'Series 44' },
    { value: 'SERIES 45', label: 'Series 45' },
    { value: 'SERIES 50', label: 'Series 50' },
    { value: 'SERIES 51', label: 'Series 51' },
    { value: 'SERIES 52', label: 'Series 52' },
    { value: 'SERIES 52TO', label: 'Series 52TO' },
    { value: 'SERIES 53', label: 'Series 53' },
    { value: 'SERIES 54', label: 'Series 54' },
    { value: 'SERIES 54FN', label: 'Series 54FN' },
    { value: 'SERIES 55', label: 'Series 55' },
    { value: 'SERIES 56', label: 'Series 56' },
    { value: 'SERIES 57', label: 'Series 57' },
    { value: 'SERIES 57TO', label: 'Series 57TO' },
    { value: 'SERIES 62', label: 'Series 62' },
    { value: 'SERIES 63', label: 'Series 63' },
    { value: 'SERIES 65', label: 'Series 65' },
    { value: 'SERIES 66', label: 'Series 66' },
    { value: 'SERIES 72', label: 'Series 72' },
    { value: 'SERIES 79', label: 'Series 79' },
    { value: 'SERIES 79TO', label: 'Series 79TO' },
    { value: 'SERIES 82', label: 'Series 82' },
    { value: 'SERIES 82TO', label: 'Series 82TO' },
    { value: 'SERIES 86', label: 'Series 86' },
    { value: 'SERIES 87', label: 'Series 87' },
    { value: 'SERIES 99', label: 'Series 99' },
    { value: 'SERIES 99TO', label: 'Series 99TO' },
    { value: 'V06', label: 'V06' },
];

export default class LicenseTypesRR extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideTypes: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOptions) {
        let selectedValue = null;
        if (this.props.multi) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        } else if (selectedOptions) {
            selectedValue = selectedOptions.value;
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedValue,
            }
        });
    }

    render() {
        let selectedTypes = this.props.value
            ? licenceTypes.filter(row => this.props.value.indexOf(row.value) >= 0)
            : [];
        let displayTypes = this.props.hideTypes
            ? licenceTypes.filter(row => this.props.hideTypes.indexOf(row.value) < 0)
            : licenceTypes;

        return <Select
            closeMenuOnSelect={!this.props.multi}
            value={selectedTypes}
            isMulti={this.props.multi}
            options={displayTypes}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={this.props.multi ? 'Select License Type(s)...' : 'Select license type...'}
        />;
    }
}
