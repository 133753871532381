// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { insuranceAgentReportTypes } from '../constants/reportTypes';
import { loggedInUserHasPermission } from '../helpers/authUtils';
import PageTitle from '../components/PageTitle';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';


class GroupSearchResultsPage extends React.Component {
    static propTypes = {
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
    };

    render() {
        const searchFormProps = this.props.allFormStates.groupSearch;
        const reportTypes = insuranceAgentReportTypes.filter(report => loggedInUserHasPermission(report.permission));

        return <>
            <PageTitle title="<< Back to Insurance Search" className="btn"
                    onClick={() => this.props.history.push("/group/search")} />

            <PaginatedSearchResultsTable
                searchFormState={searchFormProps.formState}
                recordCount={searchFormProps.rowCount}
                reportTypes={reportTypes}
                enableSaving={true}
            />
        </>
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
});

export default connect(mapStateToProps)(GroupSearchResultsPage)