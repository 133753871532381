import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader, Spinner,
} from 'reactstrap';


export default class SaveReportModal extends React.Component {
    static propTypes = {
        onSaveReport: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isSavingReport: false,
        };
        this.newReportNameRef = React.createRef();
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.setState({
            isSavingReport: true,
        });

        const reportName = this.newReportNameRef.current.value;
        this.props.onSaveReport(reportName)
            .finally(() => {
                this.setState({
                    isSavingReport: false,
                });
            });
    }


    render() {
        return (
            <Modal isOpen={this.props.isOpen}
                   toggle={this.props.onClose} >
                <ModalHeader>Save Report</ModalHeader>
                <Form onSubmit={this.onFormSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="savedReportName">Report Name</Label>
                            <Input type="text" defaultValue={this.props.editReportName} innerRef={this.newReportNameRef} placeholder="" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={this.state.isSavingReport}>
                            {this.state.isSavingReport
                                ? <>Saving <Spinner as="span" animation="border" size="sm"/></>
                                : 'Save'
                            }
                        </Button>
                        <Button color="primary" onClick={this.props.onClose}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}


export class SaveAsReportModal extends React.Component {
    static propTypes = {
        onSaveReport: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isSavingReport: false,
        };
        this.newReportNameRef = React.createRef();
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.setState({
            isSavingReport: true,
        });

        const reportName = this.newReportNameRef.current.value;
        this.props.onSaveReport(reportName)
            .finally(() => {
                this.setState({
                    isSavingReport: false,
                });
            });
    }


    render() {
        return (
            <Modal isOpen={this.props.isOpen}
                   toggle={this.props.onClose} >
                <ModalHeader>Save Report</ModalHeader>
                <Form onSubmit={this.onFormSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="savedReportName">Report Name</Label>
                            <Input type="text" innerRef={this.newReportNameRef} placeholder="" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={this.state.isSavingReport}>
                            {this.state.isSavingReport
                                ? <>Saving <Spinner as="span" animation="border" size="sm"/></>
                                : 'Save'
                            }
                        </Button>
                        <Button color="primary" onClick={this.props.onClose}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}
