import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const titleTypes = [
    { value: 'Member', label: 'Member' },
    { value: 'C Suite', label: 'C Suite' },
    { value: 'Chief Compliance Officer', label: 'Chief Compliance Officer' },
    { value: 'Partner', label: 'Partner' },
    { value: 'Owner', label: 'Owner' },
    { value: 'President', label: 'President' },
    { value: 'Director', label: 'Director' },
    { value: 'Chief Executive Officer', label: 'Chief Executive Officer' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Shareholder', label: 'Shareholder' },
    { value: 'Vice President', label: 'Vice President' },
    { value: 'Chief Investment Officer', label: 'Chief Investment Officer' },
    { value: 'Treasurer', label: 'Treasurer' },
    { value: 'General Counsel', label: 'General Counsel' },
    { value: 'Board', label: 'Board' },
    { value: 'Chairman', label: 'Chairman' },
    { value: 'Secretary', label: 'Secretary' },
    { value: 'Chief Financial Officer', label: 'Chief Financial Officer' },
    { value: 'Trustee', label: 'Trustee' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Chief Operating Officer', label: 'Chief Operating Officer' },
    { value: 'Chief Administrative Officer', label: 'Chief Administrative Officer' },
    { value: 'Chief Information Officer', label: 'Chief Information Officer' },
];


export default class TitleTypes extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideTypes: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOptions) {
        let selectedValue = null;
        if (this.props.multi) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        } else if (selectedOptions) {
            selectedValue = selectedOptions.value;
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedValue,
            }
        });
    }

    render() {
        let selectedTypes = this.props.value
            ? titleTypes.filter(row => this.props.value.indexOf(row.value) >= 0)
            : [];
        let displayTypes = this.props.hideTypes
            ? titleTypes.filter(row => this.props.hideTypes.indexOf(row.value) < 0)
            : titleTypes;

        return <Select
            closeMenuOnSelect={!this.props.multi}
            value={selectedTypes}
            isMulti={this.props.multi}
            options={displayTypes}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={this.props.multi ? 'Select Title(s)...' : 'Select title...'}
        />;
    }
}
