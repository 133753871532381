
import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip'; 
import _ from "lodash";
 
 const renderTooltipPrev = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Previous Record
    </Tooltip>
);

const renderTooltipNext = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Next Record
    </Tooltip>
);

const getValueData = (listIarRow, rowIndiv, listValue, type, value) => {

    if (listIarRow) value = false;
    if (rowIndiv == type) value = false;
    if (listValue == type) value = false;

    return value;

}

const getPaginationData = (listValues, rowIndiv, rowId, previous, type) => {

    if (typeof (listValues) === 'object' && listValues) {
        if (rowIndiv == 'individuals' && listValues['indivCrd']) {
            let currentIndexIndiv = _.findIndex(listValues['indivCrd'], (row) => row === rowId);
            let bool = (type === "prev" ? (currentIndexIndiv > 0) : (currentIndexIndiv != listValues['indivCrd'].length - 1));
            bool ? previous = true : previous = false;
        }
        else if (rowIndiv == 'executives' && listValues['executives']) {
            let currentIndexExecutive = _.findIndex(listValues['executives'], (row) => row === rowId);
            let bool = (type === "prev" ? (currentIndexExecutive > 0) : (currentIndexExecutive != listValues['executives'].length - 1));
            bool ? previous = true : previous = false;
        }
    }
    return previous

}

export { renderTooltipPrev,  renderTooltipNext , getValueData, getPaginationData};