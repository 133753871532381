// @flow
import React from 'react';
import { RangeSlider } from 'rsuite';
import PropTypes from 'prop-types';


export default class AssetRangeSlider extends React.Component {
    static propTypes = {
        values: PropTypes.array.isRequired,
        labels: PropTypes.array.isRequired,
        value: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        pushOutLastMark: PropTypes.bool,
    };
    static defaultProps = {
        pushOutLastMark: true,
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    handleSliderChange(range) {
        // the max must always be more than the min
        if (range[0] >= range[1]) {
            return;
        }

        // convert this to the corresponding values
        let values = [
            this.props.values[range[0]],
            this.props.values[range[1]]
        ]
        this.props.onChange(values);
    }

    render() {
        let valueRange = [0, this.props.labels.length -1];
        if (this.props.value && this.props.value.length === 2) {
            valueRange = [
                this.props.values.indexOf(this.props.value[0]),
                this.props.values.indexOf(this.props.value[1]),
            ];
        }

        return (
            <RangeSlider min={0} max={this.props.labels.length -1}
                        className={this.props.pushOutLastMark ? 'push-out-last-slidermark': ''}
                        value={valueRange}
                        onChange={this.handleSliderChange}
                        renderMark={mark => this.props.labels[mark]}
                        tooltip={false}
                        graduated={true}
                        disabled={this.props.disabled}
            />
        );
    }
}
