import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

class APIClient {
    constructor() {
        this.baseURL = process.env.REACT_APP_FIRST_CLASS_DATA_API
        this.instance = axios.create({
            baseURL: this.baseURL,
            responseType: 'json'
        });
        this.unauthInstance = axios.create({
            baseURL: this.baseURL,
            responseType: 'json'
        });

        this.instance.interceptors.request.use(this.appendJwtToRequest);
        createAuthRefreshInterceptor(this.instance, (failedRequest) => {
            return this.refreshExpiredJwt(failedRequest)
        });
    }

    // add session token to all requests
    appendJwtToRequest(request) {
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
        return request;
    }

    // any time we get an "unauthorized" response, we attempt to refresh the current JWT
    refreshExpiredJwt(failedRequest) {
        const refreshData = {refresh: localStorage.getItem('refresh_token')};
        return this.unauthInstance.post('/v1/token/refresh/', refreshData)
            .then(tokenRefreshResponse => {
                if (tokenRefreshResponse && tokenRefreshResponse.data && tokenRefreshResponse.data.access) {
                    // save this new token
                    localStorage.setItem('access_token', tokenRefreshResponse.data.access);
                    // and update the "attempted but failed" request
                    failedRequest.response.config = this.appendJwtToRequest(failedRequest.response.config);
                    // then retry that same request, by resolving here
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .catch(error => {
                // TODO: add a "toast" message about "your session has expired, please log in again"
                this.logout();
                // TODO: this should use the app routing
                document.location = '/account/login';
            });
    }

    getExportFileDownloadParams(searchFormState, columns, exportFilename) {
        // because this will be a raw POST, everything will be strings
        // we want to append our authorized JWT as a POST param, as we can't add a header for the direct POST
        let postParams = {
            search_params_json: JSON.stringify(searchFormState.forServer()),
            export_columns: JSON.stringify(columns),
            export_filename: exportFilename,
            jwt: localStorage.getItem('access_token')
        };
        return {
            url: this.baseURL + '/v1/agent-search/export/',
            formData: postParams
        };
    }

    testConnection() {
        return this.instance.post('/v1/test-connection/');
    }

    forgotPassword(emailOrUsername) {
        return this.unauthInstance.post('/reset-password/', {
            username: emailOrUsername
        });
    }

    verifyResetPasswordToken(token) {
        return this.unauthInstance.post('/reset-password/validate-token/', {
            token: token,
        });
    }

    resetPassword(token, newPassword) {
        return this.unauthInstance.post('/reset-password/change-password/', {
            token: token,
            password: newPassword,
        });
    }

    getSavedSearches(params) {
        const queryParams = params ? `?search=${params}` : '';
        return this.instance.get(`/v1/saved-searches/list/${queryParams}`);
    }

    getCompanyUsers() {
        return this.instance.get('/v1/saved-searches/company_users/').then(({ data }) => {
            return data.results;
        });
    }

    saveSearch(data) {
        return this.instance.post('/v1/saved-searches/create/', data);
    }

    deleteSavedSearch(rowId) {
        return this.instance.post('/v1/saved-searches/delete/', {
            'id': rowId,
        });
    }

    shareSavedSearch(savedSearchId, sharedUsers) {
        const sharedData = {
            id: savedSearchId,
        }
        if (sharedUsers.length) {
            sharedData.shouldShare = true;
            sharedData.share_with_users = sharedUsers;
        } else {
            sharedData.shouldShare = false;
        }
        return this.instance.post('/v1/saved-searches/share/', sharedData);
    }

    getSearchTotalCount(formState) {
        return this.instance.post('/v1/agent-search/count/', formState.forServer());
    }

    getSearchResults(data) {
        return this.instance.post('/v1/agent-search/', data);
    }

    getAgentRow(agentId, zipCode) {
        return this.instance.get(`/v1/agent-details/${agentId}?zipCode=${zipCode}`, {
            'id': agentId,
            'zipCode': zipCode
        });
    }

    getRiaFirmRow(firmId) {
        return this.instance.get(`/v1/ria-firm-details/${firmId}`, {
            'id': firmId
        });
    }

    getIarRow(indivId) {
        return this.instance.get(`/v1/ria-indiv-details/${indivId}`, {
            'id': indivId
        });
    }

    getBdFirmRow(firmId) {
        return this.instance.get(`v1/bd-firm-details/${firmId}`, {
            'id': firmId
        })
    }

    getRRIndivRow(indivId) {
        return this.instance.get(`v1/rr-indiv-details/${indivId}`, {
            'id': indivId
        })
    }

    getTypeaheadOptions(fieldName, inputValue, extra) {
        return this.instance.post('/v1/field-typeahead/', {
            field: fieldName,
            value: inputValue,
            extra: extra
        });
    }

    getAddressOptions(states) {
        return this.instance.get(`/v1/field-typeahead/county/?states_zip=[${states}]`);
    }

    checkExportQuotaSufficient(formState) {
        return this.instance.post('/v1/agent-search-export-quota-check/', formState.forServer());
    }

    login(username, password, userId) {
        return this.unauthInstance.post('/v1/token/', {
                username,
                password,
                user_id: userId,
            })
            .then(response => {
                let responseData = {
                    id: response.data.user_id,
                    username: response.data.username,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    permissions: response.data.permissions,
                    role: 'Admin',
                };
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
                localStorage.setItem('user', JSON.stringify(responseData));
                return responseData;
            })
            .catch(function(error) {
                console.log(error);
                return false;
            });
    }

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
    }

    userHasPermission(whichPermission) {
        let userData = this.getCurrentUser();
        return ('permissions' in userData
            && whichPermission in userData.permissions
            && userData.permissions[whichPermission] === true);
    }

    getCurrentUser() {
        let userData = localStorage.getItem('user');
        if (!userData) {
            // not logged in
            return null;
        }

        return {
            ...JSON.parse(userData),
            access_token: localStorage.getItem('access_token'),
            refresh_token: localStorage.getItem('refresh_token'),
        };
    }
}

export default APIClient;
