// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';
import DesignationTypes from '../DesignationTypes';

const IndividualDesignationTypeSelect = ({ formName }) => {
    const dispatch = useDispatch();
    const types = useSelector((state) => state.FirstClassData.formStates[formName].formState.designation_types);

    const [designation_types, canAddAnotherdesignationClause] = useMemo(() => {
        const canAddAnotherdesignationClause = types.filter(row => {
                    return row.types.length === 0;
                }).length === 0;
        return [types, canAddAnotherdesignationClause];
    }, [types]);

    const onFieldChange = (event, index) => {
        let designationTypes = designation_types.slice();
        designationTypes[index][event.target.name] = event.target.value;
        designationTypes[index]['label'] = event.target.label;
        dispatch(mergeSearch({ designation_types: designationTypes }, formName));
    };

    const onChange = (event, index) => {
        let designationTypes = designation_types.slice();
        designationTypes[index][event.target.name] = event.target.label;
        designationTypes[index]['designation_type_bool'] = event.target.value;
        dispatch(mergeSearch({ designation_types: designationTypes }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let designationTypes = designation_types.slice();
        designationTypes[index].exclude = !exclude;
        dispatch(mergeSearch({ designation_types: designationTypes }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let designationTypes = designation_types.slice();
        designationTypes.splice(index, 1);
        dispatch(mergeSearch({ designation_types: designationTypes }, formName));
    };

    const handleAdddesignationClause = () => {
        let designationTypes = designation_types.slice();
        designationTypes.push({
            exclude: false,
            designation_type_bool: 'or',
            types: []
        });
        dispatch(mergeSearch({ designation_types: designationTypes }, formName));
    };

    return (
        <Col lg={24}>
            {designation_types.map((designationTypesClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(designationTypesClause.exclude, index)}
                        >
                            {designationTypesClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these Designation:

                        {designation_types.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Designation</Label>
                                    <DesignationTypes name="types" multi={true}
                                                  value={designationTypesClause.types}
                                                  onChange={(event) => onFieldChange(event, index)} />

                                    {designationTypesClause.types.length > 1 &&
                                    <div className="form-inline flex-row-reverse pt-1">
                                        <p>
                                            Search for customers has &nbsp;
                                            <Input type="select"
                                                   name="designation_type_bool"
                                                   bsSize="sm"
                                                   value={designationTypesClause.designation_type_bool}
                                                   onChange={(event) => onChange(event, index)}>
                                                <option value="and">all of these designation</option>
                                                <option value="or">any of these designation</option>
                                            </Input>
                                        </p>
                                    </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
            {canAddAnotherdesignationClause && (
                <Button
                    className="p-0 ml-3"
                    color="link"
                    onClick={handleAdddesignationClause}>
                    + Add another Designation clause
                </Button>
            )}
        </Col>
    );
};

export default IndividualDesignationTypeSelect;