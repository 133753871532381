export default [
    { value: 'total', label: 'Total Assets' },
    { value: 'discretionary', label: 'Discretionary Assets' },
    { value: 'nondiscretionary', label: 'Non-discretionary Assets' },

    { value: 'default', label: '-----', isDisabled: true },

    { value: 'individuals', label: 'Individuals (other than high net worth individuals)' },
    { value: 'hnwindividuals', label: 'High net worth individuals' },
    { value: 'banks', label: 'Banking or thrift institutions' },
    { value: 'investmentcompanies', label: 'Investment companies' },
    { value: 'businessdevelopmentcompanies', label: 'Business development companies' },
    { value: 'pooledvehicles', label: 'Pooled investment vehicles (other than investment companies and business development companies)' },
    { value: 'retirementplans', label: 'Pension and profit sharing plans (but not the plan participants or government pension plans)' },
    { value: 'charities', label: 'Charitable organizations' },
    { value: 'municipalities', label: 'State or municipal government entities (including government pension plans)' },
    { value: 'otherinvestmentadvisers', label: 'Other investment advisers' },
    { value: 'insurancecompanies', label: 'Insurance companies' },
    { value: 'sovereignorforeign', label: 'Sovereign wealth funds and foreign official institutions' },
    { value: 'separatelymanagedaccounts', label: 'Corporations or other businesses not listed above' },
];



