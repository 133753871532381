export default function setNestedProp(obj, indices, value) {
    if (typeof indices == 'string') {
        let indicesSplit = indices.split('.').map(el => {
            if (el.match('^[0-9]+$')) {
                el = parseInt(el);
            }
            return el;
        })
        return setNestedProp(obj, indicesSplit, value);

    } else if (indices.length === 1) {
        if (value === undefined) {
            delete obj[indices[0]];
        } else {
            obj[indices[0]] = value;
        }
        return obj;

    } else if (indices.length === 0) {
        return obj;

    } else {
        return setNestedProp(obj[indices[0]], indices.slice(1), value);
    }
}
