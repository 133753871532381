// @flow
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
    Button,
    Row,
    ButtonGroup,
} from 'reactstrap';
import { SearchFormState, RiaFirmSearchState, IarSearchState, RrSearchState, BdSearchState} from '../redux/firstClassData/dtos';
import { searchFormChanged, setSavedSearchLayout } from '../redux/actions';
import APIClient from '../utils/API';
import { checkStateUserPermission } from '../helpers/authUtils';
import PageTitle from '../components/PageTitle';
import SavedSearchCard from '../components/SavedSearchCard';
import DeleteSavedSearchModal from '../components/DeleteSavedSearchModal';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';
import { insuranceAgentReportTypes, riaFirmReportTypes, iarReportTypes,bdFirmReportTypes,rrIndivReportTypes} from '../constants/reportTypes';
import styles from '../assets/scss/custom/pages/savedSearches.module.scss';
import agentColumn from '../constants/agentColumns';
import iarColumns from '../constants/iarColumns';
import riaFirmColumns from '../constants/riaFirmColumns';
import RiaFirmDetailsModal from '../components/RiaFirmDetailsModal';
import IarDetailsModal from '../components/IarDetailsModal';
import AgentDetailsModal from '../components/AgentDetailsModal';
import bdFirmColumns from "../constants/bdFirmColumns";
import rrIndivColumns from '../constants/rrIndivColumns';
import RrIndivDetailsModal from '../components/RRIndivDetailsModel';
import BdFirmDetailsModal from '../components/BdFirmDetailsModel';
import riaFirmExecutiveColumns from '../constants/riaFirmExecutiveColumns'
import { riaFirmExecutiveReportTypes } from '../constants/reportTypes';
import bdExecutiveColumns from '../constants/bdExecutiveColumns'
import { bdExecutiveReportTypes } from '../constants/reportTypes';


const client = new APIClient();

const SavedSearchesPage = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.Auth);
    const { savedSearchLayout } = useSelector((state) => state.Layout);
    const { id: currentUserId } = user;
    const [savedSearches, setSavedSearches] = useState([]);
    const [deletedSearch, setDeletedSearch] = useState(null);
    const [viewSearchIndex, setViewSearchIndex] = useState(null);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [searchParams, setSearchParams] = useState('');

    const agentReportTypes = useMemo(() => {
        return insuranceAgentReportTypes.filter(report => checkStateUserPermission(report.permission, user));
    }, [insuranceAgentReportTypes, user]);

    const riaReportTypes = useMemo(() => {
        return riaFirmReportTypes.filter(report => checkStateUserPermission(report.permission, user));
    }, [riaFirmReportTypes, user]);

    const indivReportTypes = useMemo(() => {
        return iarReportTypes.filter(report => checkStateUserPermission(report.permission, user));
    }, [iarReportTypes, user]);

    const bdReportTypes = useMemo(() => {
        return bdFirmReportTypes.filter(report => checkStateUserPermission(report.permission, user));
    }, [bdFirmReportTypes, user]);

    const rrReportTypes = useMemo(() => {
        return rrIndivReportTypes.filter(report => checkStateUserPermission(report.permission, user));
    }, [rrIndivReportTypes, user]);

    const getUsers = () => {
        client.getCompanyUsers().then((result) => {
            const activeUser = result.map((user) => {
                return {
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                }
            });
            setCompanyUsers(activeUser);
        });
    }

    const getFormColumns = (params) => {
        let search_type = params.search_type;
        // Based on the search type return the report columns
        if (search_type === 'riaFirmSearch' && params.search_executive == false) {
            return riaFirmColumns;
        }
        else if (search_type === 'riaFirmSearch' && params.search_executive == true) {
            return riaFirmExecutiveColumns;
        }
        else if (search_type === 'riaIndivSearch') {
            return iarColumns;
        }
        else if (search_type === 'bdFirmSearch' && params.search_executive == false) {
            return bdFirmColumns;
        }
        else if (search_type === 'bdFirmSearch' && params.search_executive == true) {
            return bdExecutiveColumns;
        }
        else if (search_type === 'rrIndivSearch') {
            return rrIndivColumns;
        }
        else {
            return agentColumn;
        }
    }

    const getReportTypes = (params) => {
        let search_type = params.search_type;

        // Based on the search type return the form report types
        if (search_type === 'riaFirmSearch' && params.search_executive == false) {
            return riaReportTypes;
        }
        else if (search_type === 'riaFirmSearch' && params.search_executive == true) {
            return riaFirmExecutiveReportTypes;
        }
        else if (search_type === 'riaIndivSearch') {
            return indivReportTypes;
        }
        else if (search_type === 'bdFirmSearch' && params.search_executive == false) {
            return bdReportTypes;
        }
        else if (search_type === 'bdFirmSearch' && params.search_executive == true) {
            return bdExecutiveReportTypes;
        }
        else if (search_type === 'rrIndivSearch') {
            return rrReportTypes;
        }
        else {
            return agentReportTypes;
        }
    }

    const getDetailsModel = (params) => {
        let search_type = params.search_type;

        // Based on the search type return the form details model
        if (search_type === 'riaFirmSearch' && params.search_executive == false) {
            return RiaFirmDetailsModal;
        }
        else if (search_type === 'riaFirmSearch' && params.search_executive == true) {
            return IarDetailsModal;
        } 
        else if (search_type === 'riaIndivSearch') {
            return IarDetailsModal;
        } 
        else if (search_type === 'bdFirmSearch' && params.search_executive == false) {
            return BdFirmDetailsModal;
        } 
        else if (search_type === 'bdFirmSearch' && params.search_executive == true) {
            return RrIndivDetailsModal;
        }
        else if (search_type === 'rrIndivSearch') {
            return RrIndivDetailsModal;
        }
        else {
            return AgentDetailsModal;
        }
    }

    const loadSavedSearches = (params) => {
        client.getSavedSearches(params)
            .then(result => {
                let searches = [];
                for (let i=0; i<result.data.length; i++) {
                    let savedSearch = result.data[i];

                    // Load the search param to the form state based on search types
                    if (savedSearch.search_parameters) {
                        if (savedSearch.search_parameters['search_type'] === 'riaFirmSearch') {
                            // Load the saved search if the user has access to view the RIA form
                            if (!riaReportTypes.length) {
                                continue;
                            }
                            savedSearch.formState = new RiaFirmSearchState(savedSearch.search_parameters);
                        }

                        else if(savedSearch.search_parameters['search_type'] === 'riaIndivSearch') {
                            // Load the saved search if the user has access to view the RIA form
                            if (!indivReportTypes.length) {
                                continue;
                            }
                            savedSearch.formState = new IarSearchState(savedSearch.search_parameters);
                            if (savedSearch.formState.full_names.length) {
                                savedSearch.formState.full_name[0].full_names = savedSearch.formState.full_names
                            }
                            
                            if (savedSearch.formState.emails.length) {
                                savedSearch.formState.email_address[0].emails = savedSearch.formState.emails
                            }
                        }
                        else if(savedSearch.search_parameters['search_type'] === 'bdFirmSearch') {
                            // Load the saved search if the user has access to view the db form
                            if (!bdReportTypes.length) {
                                continue;
                            }
                            savedSearch.formState = new BdSearchState(savedSearch.search_parameters);
                        }
                        else if(savedSearch.search_parameters['search_type'] === 'rrIndivSearch') {
                            // Load the saved search if the user has access to view the RR form
                            if (!rrReportTypes.length) {
                                continue;
                            }
                            savedSearch.formState = new RrSearchState(savedSearch.search_parameters);
                            if (savedSearch.formState.full_names.length) {
                                savedSearch.formState.full_name[0].full_names = savedSearch.formState.full_names
                            }
                            
                            if (savedSearch.formState.emails.length) {
                                savedSearch.formState.email_address[0].emails = savedSearch.formState.emails
                            }
                        }
                        else {
                            // Load the saved search if the user has access to view the insurance form
                            if (!agentReportTypes.length) {
                                continue;
                            }
                            savedSearch.formState = new SearchFormState(savedSearch.search_parameters);

                            if (savedSearch.formState.full_names.length) {
                                savedSearch.formState.full_name[0].full_names = savedSearch.formState.full_names
                            }
                            
                            if (savedSearch.formState.emails.length) {
                                savedSearch.formState.email_address[0].emails = savedSearch.formState.emails
                            }

                        }
                    } else {
                        continue;
                    }
                    searches.push(savedSearch);
                }
                setSavedSearches(searches);
            })
    };

    const backToAllSavedSearches = () => {
        setDeletedSearch(null);
        setViewSearchIndex(null);
    }

    const handleSearchView = (index) => {
        setViewSearchIndex(index);
    }

    const handleSearchEdit = (index) => {
        const formState = savedSearches[index].formState;

        formState.export_name = savedSearches[index].name
        formState.export_id = savedSearches[index].id

        // Based on the search type load the search param in the Insurance/RIA form
        if (formState.search_type === 'riaFirmSearch') {    
            dispatch(searchFormChanged('riaFirmSearch', formState));
            history.push("/riafirm/search");
        }
        else if (formState.search_type === 'riaIndivSearch') {
            dispatch(searchFormChanged('riaIndivSearch', formState));
            history.push("/iar/search");
        }
        else if (formState.search_type === 'bdFirmSearch') {
            dispatch(searchFormChanged('bdFirmSearch', formState));
            history.push("/bd/search");
        }
        else if (formState.search_type === 'rrIndivSearch') {
            dispatch(searchFormChanged('rrIndivSearch', formState));
            history.push("/rr/search");
        }
        else {
            // we want to show the "group search" or "individual search" page,
            // depending on whether this search includes names / NPN
            if (formState.search_type === 'agentSearch') {
                dispatch(searchFormChanged('agentSearch', formState));
                history.push("/agent/search");
            } else {
                dispatch(searchFormChanged('groupSearch', formState));
                history.push("/group/search");
            }
        }
    };

    const handleSearchDelete = (index) => {
        const { id, name } = savedSearches[index];
        setDeletedSearch({ id, name });
    };

    const handleSearchShare = (savedSearchId, sharedUsers) => {
        client.shareSavedSearch(savedSearchId, sharedUsers)
            .then(() => {
                loadSavedSearches();
            });
    }

    const onConfirmDelete = () => {
        return client.deleteSavedSearch(deletedSearch.id).then(() => {
            // hide the delete modal, and refresh the list of saved searches
            backToAllSavedSearches();
            loadSavedSearches();
        });
    };

    const setLayoutType = (layoutType) => {
        if (layoutType === savedSearchLayout) {
            return;
        }
        dispatch(setSavedSearchLayout(layoutType));
    };

    const searchAPIDebounced = AwesomeDebouncePromise(loadSavedSearches, 800);

    const handleSearchFormChanged = useCallback(async (event) => {
        const params = event.target.value;

        setSearchParams(params);
        await searchAPIDebounced(params);
    }, []);

    useEffect(() => {
        loadSavedSearches();
        getUsers();
    }, []);

    return (
        <>
            {viewSearchIndex !== null ? (
                <>
                    <PageTitle
                        title="<< Back to Saved Searches" className="btn"
                        onClick={backToAllSavedSearches}
                    />
                    <PaginatedSearchResultsTable
                        searchFormState={savedSearches[viewSearchIndex].formState}
                        recordCount={savedSearches[viewSearchIndex].row_count}
                        enableSaving={false}
                        reportTypes={getReportTypes(savedSearches[viewSearchIndex].formState)}
                        possibleColumns={getFormColumns(savedSearches[viewSearchIndex].formState)}
                        detailsModalComponent={getDetailsModel(savedSearches[viewSearchIndex].formState)}
                        currentSearchName={savedSearches[viewSearchIndex].name}
                        defaultReportType={savedSearches[viewSearchIndex].report_type}
                    />
                </>
            ) : (
                <>
                    <div className="d-md-flex align-items-center justify-content-between">
                        <PageTitle title={'Your Saved Reports'} />
                        <div className="d-flex justify-content-between align-items-center mb-3 mb-md-0">
                            <div className="mr-2">
                                <input
                                    type="text"
                                    placeholder="Search.."
                                    className="form-control"
                                    value={searchParams}
                                    onChange={handleSearchFormChanged}
                                />
                            </div>
                            <ButtonGroup className={styles.layoutBtnGroup}>
                                <Button
                                    className={styles.layoutBtn}
                                    color="warning"
                                    active={savedSearchLayout === 'card'}
                                    onClick={() => setLayoutType('card')}
                                    outline >
                                    <i className="uil uil-table"></i>
                                </Button>
                                <Button
                                    className={styles.layoutBtn}
                                    color="warning"
                                    active={savedSearchLayout === 'list'}
                                    onClick={() => setLayoutType('list')}
                                    outline >
                                    <i className="uil uil-list-ul"></i>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <Row className={`${styles.searchList} ${savedSearchLayout === 'card' ? styles.cardRow : styles.listRow}`}>
                        {!!searchParams.length && !savedSearches.length && (
                            <div className="col-12">
                                <h2>No results for "{searchParams}"</h2>
                            </div>
                        )}
                        {savedSearches.map((savedSearch, index) =>
                            <SavedSearchCard
                                key={savedSearch.id}
                                companyUsers={companyUsers}
                                savedSearch={savedSearch}
                                index={index}
                                layout={savedSearchLayout}
                                userId={currentUserId}
                                onSearchDelete={handleSearchDelete}
                                onSearchEdit={handleSearchEdit}
                                onSearchView={handleSearchView}
                                onSearchShare={handleSearchShare}
                            />
                        )}
                    </Row>

                    <DeleteSavedSearchModal
                        isOpen={!!deletedSearch}
                        savedSearch={deletedSearch}
                        onConfirmDelete={onConfirmDelete}
                        onClose={backToAllSavedSearches}
                    />
                </>
            )}
        </>
    );
}

export default SavedSearchesPage;
