import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';

import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import LoaderWidget from '../../components/Loader';
import { isUserAuthenticated } from '../../helpers/authUtils';
import { loginUser, clearUser } from '../../redux/actions';
import logo from '../../assets/images/fcd-logo-orange.svg';

const Login = () => {
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.Auth);
    const location = useLocation();
    const hash = location.hash.slice(1);
    const history = useHistory();
    const [userIsActive, setUserIsActive] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [userId, setUserId] = useState('');


    /**
     * Handles the submit
     */
    const handleValidSubmit = (event, values) => {
        dispatch(loginUser(values.username, values.password, userId, history));
    };

    /**
     * Redirect to root
     */
    useEffect(() => {
        const isActive = isUserAuthenticated();
        setUserIsActive(isActive);
        setUserId(hash);
        if(hash && !isActive) {
            history.replace(history.location.pathname);
        }
    }, []);

    useEffect(() => {

        if (userIsActive && !!userId.length) {
            dispatch(clearUser());
            setIsMounted(true);
        } else if (userIsActive && !userId.length) {

            history.push('/');
        } else {
            setIsMounted(true);
        }
    }, [userIsActive, userId]);

    if (!isMounted) {
        return null;
    }

    return (
        <div className="account-pages mt-5 mb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={5}>
                            <Card>
                                <div className="card-header pt-4 pb-4 text-center bg-primary">
                                    <Link to="/">
                                        <span>
                                            <img src={logo} alt="First Class Data" className="vectorlogo" />
                                        </span>
                                    </Link>
                                </div>

                                <CardBody className="p-4 position-relative">
                                    {loading && <LoaderWidget />}

                                    <div className="text-center w-75 m-auto">
                                        <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                                            Sign In
                                        </h4>
                                        <p className="text-muted mb-4">
                                            Enter your username and password to access admin panel.
                                        </p>
                                    </div>

                                    {error && (
                                        <Alert color="danger" isOpen={!!error}>
                                            <div>{error}</div>
                                        </Alert>
                                    )}

                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <AvField
                                            name="username"
                                            label="Username"
                                            placeholder="Enter your username"
                                            required
                                        />

                                        <AvGroup>
                                            <Label for="password">Password</Label>
                                            <AvInput
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="Enter your password"
                                                required
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>

                                        <FormGroup>
                                            <Button color="primary">Submit</Button>
                                        </FormGroup>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-1">
                        <Col className="col-12 text-center">
                            <p className="text-muted">
                                <Link to="/account/forgot-password" className="text-muted ml-1">
                                    <b>Forgot your password?</b>
                                </Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
    );
}

export default Login;
