import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'rsuite-table';
import { Col, Input, Row } from 'reactstrap';
import Paginator from './Paginator';


export default class SortableRsuiteTable extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        defaultSortColumn: PropTypes.string,
        defaultSortDirection: PropTypes.string,
        noDataNotice: PropTypes.string,
        paginate: PropTypes.bool,
        minPageSize: PropTypes.number
    };
    static defaultProps = {
        paginate: true,
        minPageSize: 10,
    }

    constructor(props) {
        super(props);
        this.state = {
            sortColumn: this.props.defaultSortColumn,
            sortType: (this.props.defaultSortDirection === 'asc' ? 'desc' : 'asc'),
            perPage: 10,
            page: 1
        };
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.sortData = this.sortData.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeSizePerPage = this.handleChangeSizePerPage.bind(this);
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            sortColumn: sortColumn,
            sortDirection: sortType,
        });
    }

    stringPrefixIntVal(str) {
        let prefix = str.substr(0, 5);
        for (var i = 0; i < prefix.length; i++) {
            alert(str.charAt(i));
        }
    }

    sortData(data, sortColumn, ascending=true) {
        return data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];

            // if both sides are strings, use string comparison
            if (typeof x === 'string' && typeof y === 'string') {
                if (x < y) {
                    return ascending ? -1 : 1;
                }
                if (x > y) {
                    return ascending ? 1 : -1;
                }
                return 0;
            }

            // if only one side is a string, just compare the first char numerically
            if (typeof x === 'string') {
                x = x.charCodeAt();
            }
            if (typeof y === 'string') {
                y = y.charCodeAt();
            }
            if (ascending) {
                return x - y;
            } else {
                return y - x;
            }
        });
    }

    handleChangePage(newPageNum) {
        this.setState({
            page: newPageNum
        });
    }

    handleChangeSizePerPage(event) {
        this.setState({
            page: 1,
            perPage: event.target.value,
        });
    }

    render() {
        let {data, defaultSortColumn, defaultSortDirection, noDataNotice, paginate, minPageSize, ...passThruProps} = this.props;
        let sortedData = this.sortData(this.props.data, this.state.sortColumn, this.state.sortDirection !== 'asc');
        let autoHeight = true;
        let tableHeight = null;
        if (sortedData.length === 0) {
            autoHeight = false;
            tableHeight = 86;
        } else if (sortedData.length > 10) {
            autoHeight = false;
            tableHeight = 510;
        }

        let usePagination = (paginate && sortedData.length > minPageSize);
        let rowFrom = (this.state.page - 1) * this.state.perPage + 1;
        let rowTo = Math.min(this.state.page * this.state.perPage, this.props.data.length);
        if (usePagination) {
            sortedData = sortedData.slice(rowFrom - 1, rowTo)
        }

        return (<>
                <Table
                    data={sortedData}
                    autoHeight={autoHeight}
                    height={tableHeight}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortDirection}
                    onSortColumn={this.handleSortColumn}
                    className="table-striped"
                    {...passThruProps}
                    children={this.props.children}>
                </Table>

                {usePagination &&
                    <Row className="mt-2">
                        <Col>
                            <Input type="select"
                                bsSize="sm"
                                className="d-inline-block paginator-rows-per-page btn-secondary mr-2"
                                value={this.state.perPage}
                                onChange={this.handleChangeSizePerPage}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="250">250</option>
                            </Input>

                            <span>Showing rows {rowFrom} to {rowTo} of {this.props.data.length.toLocaleString()}</span>
                        </Col>

                        <Col className="text-right">
                            <Paginator
                                currentPage={this.state.page}
                                maxPages={Math.ceil(this.props.data.length / this.state.perPage)}
                                onPageChange={this.handleChangePage}
                                className="d-inline-block" />
                        </Col>
                    </Row>
                }
        </>)
    }
}