// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';

const NpnSelect = ({ formName }) => {
    const dispatch = useDispatch();
    const name = useSelector((state) => state.FirstClassData.formStates[formName].formState.npn_select);

    const [npn_selected, canAddAnotherFullNameClause] = useMemo(() => {
        const canAddAnotherFullNameClause = name.filter(row => {
                    return row.npn_name;
                }).length === 0;
        return [name, canAddAnotherFullNameClause];
    }, [name]);


    const onFieldChange = (event, index) => {
        let npnName = npn_selected.slice();
        npnName[index][event.target.name] = event.target.value;
        dispatch(mergeSearch({ npn_selected: npnName }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let npnName = npn_selected.slice();
        npnName[index].exclude = !exclude;
        dispatch(mergeSearch({ npn_selected: npnName }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let npnName = npn_selected.slice();
        npnName.splice(index, 1);
        dispatch(mergeSearch({ npn_selected: npnName }, formName));
    };

    return (
        <Col lg={12}>
            {npn_selected.map((npnNameClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(npnNameClause.exclude, index)}
                        >
                            {npnNameClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these NPNs:

                        {npn_selected.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>NPN(s)</Label>
                                        <Col lg={13}>
                                            <Input type="text" name="npn_number" value={npnNameClause.npn_number}
                                            onChange={(event) => onFieldChange(event, index)} placeholder="NPN(s)" />
                                        </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
        </Col>
    );
};

export default NpnSelect;