// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import FirstClassData from './firstClassData/reducers';

export default combineReducers({
    Auth,
    Layout,
    FirstClassData,
});
