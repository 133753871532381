const fieldLookupsMap = {
    Education: {
        A: 'Completed High School',
        B: 'Completed College',
        C: 'Completed Graduate School',
        D: 'Attended Vocational / Technical',
    },
    Ethnicity: {
        F: 'African American',
        C: 'Central & Southwest Asian',
        E: 'Eastern European',
        O: 'Far Eastern',
        Y: 'Hispanic',
        J: 'Jewish',
        M: 'Mediterranean',
        I: 'Middle Eastern',
        N: 'Native American',
        P: 'Polynesian',
        S: 'Scandinavian',
        A: 'Southeast Asian',
        W: 'Western European',
    },
    Gender: {
        F: 'Female',
        M: 'Male',
    },
    'Home Built Year': {
        'A': 'Before 1900s',
        'B': '1900 - 1909',
        'C': '1910 - 1919',
        'D': '1920 - 1929',
        'E': '1930 - 1939',
        'F': '1940 - 1949',
        'G': '1950 - 1959',
        'H': '1960 - 1969',
        'I': '1970 - 1979',
        'J': '1980 - 1989',
        'K': '1990 - 1999',
        'L': '2000 - 2009',
        'M': '2010 - 2019',
    },
    'Homeowner Status': {
        O: 'Homeowner',
        R: 'Renter',
    },
    'Dwelling Type': {
        S: 'Single Family Dwelling Unit',
        M: 'Multi Family Dwelling Unit',
    },
    'Home Value': {
        'A': 'Up to $24,999',
        'B': '$25,000 to $49,999',
        'C': '$50,000 to $74,999',
        'D': '$75,000 to $99,999',
        'E': '$100,000  to $124,999',
        'F': '$125,000 to $149,999',
        'G': '$150,000 to $174,999',
        'H': '$175,000 to $199,999',
        'I': '$200,000 to $224,999',
        'J': '$225,000 to $249,999',
        'K': '$250,000 to $274,999',
        'L': '$275,000 to $299,999',
        'M': '$300,000 to $349,999',
        'N': '$350,000 to $399,999',
        'O': '$400,000 to $449,999',
        'P': '$450,000 to $499,999',
        'Q': '$500,000 to $749,999',
        'R': '$750,000 to $999,999',
        'S': '$1,000,000 to $1,499,999',
        'T': '$1,500,000 to $2,499,999',
        'U': '$2,500,000 to $4,999,999',
        'V': '$5,000,000 to $9,999,999',
        'W': 'Over $10,000,000',
    },
    'Income': {
        '1': 'Up to $10,000',
        '2': '$10,000 to $14,999',
        '3': '$15,000 to $19,999',
        '4': '$20,000 to $24,999',
        '5': '$25,000 to $29,999',
        '6': '$30,000 to $34,999',
        '7': '$35,000 to $39,999',
        '8': '$40,000 to $44,999',
        '9': '$45,000 to $49,999',
        'A': '$50,000 to $54,999',
        'B': '$55,000 to $59,999',
        'C': '$60,000 to $64,999',
        'D': '$65,000 to $74,999',
        'E': '$75,000 to $99,999',
        'F': '$100,000 to $149,999',
        'G': '$150,000 to $174,999',
        'H': '$175,000 to $199,999',
        'I': '$200,000 to $249,999',
        'J': '$250,000 to $499,999',
        'K': '$500,000 to $999,999',
        'L': '$1,000,000 to $1,999,999',
        'M': '$2,000,000 to $4,999,999',
        'N': 'Over $5,000,000',
    },
    'Marital Status': {
        M: 'Married',
        S: 'Single',
        A: 'Inferred Married',
        B: 'Inferred Single',
    },
    Children: {
        'No Children': 'No Children', 
        '1 - 2': '1 - 2', 
        '3 - 5': '3 - 5',
        '6 or more': '6 or more',
    },
    'Net Worth': {
        'A': 'Up to $30,000',
        'B': '$30,001 to $100,000',
        'C': '$100,001 to $500,000',
        'D': '$500,001 to $1,500,000',
        'E': 'Over $1,500,000',
    },
    'Nbrhood Cluster': {
        '01': 'No Place Like Home',
        '02': 'Full House',
        '03': 'Blissful in the Burbs',
        '04': 'Up and Comers',
        '05': 'Modest Middle-Agers',
        '06': 'Suburban Serenity',
        '07': 'Main Street Middles',
        '08': 'Melting Pot',
        '09': 'Middle Class Mainstream',
        '10': 'Middle-Aged Moderates',
        '11': 'Blue Bayou',
        '12': 'Suburban Setbacks',
        '13': 'Southern Sensibility',
        '14': 'Coupon Clippers',
        '15': 'Clock Watchers',
        '16': 'Community Cogwheels',
        '17': 'Carpools and Little League',
        '18': 'Single-Minded',
        '19': 'Career Couples',
        '20': 'Made in the USA',
        '21': 'Nine-to-Fivers',
        '22': 'Southern Comfort',
        '23': 'Salt of the Earth',
        '24': 'Country Kin',
        '25': 'Almost Free',
        '26': 'Median Middles',
        '27': 'Growing Pains',
        '28': 'Serve and Protect',
        '29': 'Gracious Homes',
        '30': 'Working Class Woes',
        '31': 'Farmland Frugals',
        '32': 'Urban Challenges',
        '33': 'Golden Age',
        '34': 'Small Town Travails',
        '35': 'Struggling Suburban Singles',
        '36': 'Dressed in Success',
        '37': 'At the Starting Gate',
        '38': 'Under Paid Urbanites',
        '39': 'Mid-Life Metros',
        '40': 'High Rise Hipsters',
        '41': 'Lap of Luxury',
        '42': 'Backwoods Blues',
        '43': 'City Limited',
        '44': 'Down and Out Downtown',
        '45': 'Solo Suburbanites',
        '46': 'Between Sunset and Dusk',
        '47': 'Small Town Toilers',
        '48': 'Suburban Security',
        '49': 'Provincial Paupers',
        '50': 'Plugged-In Players',
        '51': 'IntenseCities',
        '52': 'Country Money Crunch',
        '53': 'Downtown Digs',
        '54': 'Textbook Townies',
        '55': 'Country Classics',
        '56': 'Middle-Aged Misfortune',
        '57': 'Small Town Blues',
        '58': 'Labored Force',
        '59': 'Old Guard',
        '60': 'Hard Row to Hoe',
        '61': 'Family Portrait',
        '62': 'Urban Utopia',
        '63': 'Simply Suburbia',
        '64': 'Dinners at Five',
        '65': 'Day by Day',
        '66': 'Utterly Urban',
        '67': 'Teenage Transitions',
        '68': 'Careers in the City',
    },
    'Household Cluster': {
        'A01': 'Single Start-Ups',
        'A02': 'Hard-Working Mamas',
        'A03': 'Road-Weary Renters',
        'A04': 'Fun for the Whole Family',
        'A05': 'Single in Suburbia',
        'A06': 'Family Focused',
        'A07': 'Humble Homebodies',
        'A08': 'Road to Retirement',
        'A09': 'Middle Class Mommies',
        'A10': 'Middle-Aged Middles',
        'A11': 'Sweet Life in Suburbia',
        'B01': 'Small Town Singles',
        'B02': 'Millennial Mommies',
        'B03': 'Single and Ready to Mingle',
        'B04': 'Tweeners',
        'B05': 'Simply Retired',
        'B06': 'Boomerangers',
        'B07': 'Reaching for Retirement',
        'B08': 'Hearth and Home',
        'B09': 'Prime Time',
        'B10': 'Family Ties',
        'B11': 'Happy Homeowners',
        'B12': 'Small Town Suburbia',
        'B13': 'Free At Last',
        'C01': 'Hittin the Ground Running',
        'C02': 'Mamas In Charge',
        'C03': 'Aging Gracefully',
        'C04': 'Sunny Suburbia',
        'C05': 'Parents Without Partners',
        'C06': 'Relaxation Maximization',
        'C07': 'Slowin Their Roll',
        'C08': 'Climbing the Ladder',
        'C09': 'Reaping the Reward',
        'C10': 'Single Savvy Savers',
        'C11': 'Carpool Lane',
        'C12': 'Pinnacle of Success',
        'C13': 'Single Sophisticates',
        'C14': 'Sittin Pretty in the Suburbs',
        'C15': 'Suburban Splendor',
        'D01': 'Fast Trackers',
        'D02': 'Momagers',
        'D03': 'Success in the City',
        'D04': 'Renaissance Retirees',
        'D05': 'Affluent Aficionados',
        'E01': 'Young and Hungry',
        'E02': 'Making It Matriarchs',
        'E03': 'Retiring Retirees',
        'E04': 'Stuck in the Middle',
        'E05': 'Home Sweet Home',
        'E06': 'Retirement Ready',
        'E07': 'Frontline Families',
        'E08': 'Settled Singles',
        'F01': 'Low Income Laborers',
        'F02': 'She Works Hard for the Money',
        'F03': 'Fixed Income',
        'F04': 'Flying Solo',
        'F05': 'Gettin By',
        'F06': 'La Familia',
        'F07': 'Daily Grind',
        'F08': 'Mamas and Papas',
        'F09': 'American Dream',
        'G01': 'Single Simplicity',
        'G02': 'Modest Means Mommies',
        'G03': 'Suburban Sunsetters',
        'G04': 'Mid-Life Matriarchs',
        'G05': 'Handy Homeowners',
        'G06': 'Family-Friendly',
        'G07': 'Home Alone',
        'G08': 'Rest and Relaxation',
        'G09': 'Sublime Suburbia',
        'G10': 'Parenthood the Sequel',
        'G11': 'Family First',
        'G12': 'Suburban Satisfaction',
        'H01': 'Overalls and Workboots',
        'H02': 'Making Ends Meet',
        'H03': 'Rural Roots',
        'H04': 'Small Town Struggles',
        'H05': 'Rustic Reality',
        'H06': 'Small Town Sensibility',
        'H07': 'Carefree in the Country',
        'H08': 'Kids Country',
        'H09': 'Rural Retirees',
        'H10': 'Small Town USA',
        'J01': 'Rural Rigors',
        'J02': 'Heartland Hardships',
        'J03': 'Twilight Time',
        'J04': 'Farmhouse Families',
        'J05': 'Heartland Homes',
        'J06': 'Peace and Plenty',
        'K01': 'Single Serenity',
        'K02': 'Single Moms Club',
        'K03': 'Rustic Retired Renters',
        'K04': 'Cozy in the Country',
        'K05': 'Small Town Seniors',
        'K06': 'Aging Xers',
        'K07': 'Country Comfort',
        'K08': 'Relaxed to the Max',
        'L01': 'Senior Struggles in the City',
        'L02': 'Unassuming Urbanites',
        'L03': 'Paycheck-to-Paycheck',
        'L04': 'City Standards',
        'M01': 'Singular Focus',
        'M02': 'Raring for Retirement',
        'M03': 'Fortuitous Families',
        'M04': 'Life in the Fam Lane',
        'N01': 'Suburban Challenges',
        'N02': 'Foundering Families',
        'N03': 'Surviving in Suburbia',
        'N04': 'Cash-Strapped Seniors',
        'N05': 'Middle-Aged Matrons',
        'N06': 'True Blue',
        'N07': 'Retired Repose',
        'N08': 'Middle-Aged Mavericks',
        'N09': 'Punch Card Parents',
        'N10': 'Working Class Warriors',
        'P01': 'Rooms for Improvement',
        'P02': 'Workin for a Livin',
        'P03': 'Final Frontier',
        'P04': 'Kiddies in the City',
        'P05': 'Everyday Earners',
        'Q01': 'Suburban Surplus',
        'Q02': 'Regal Retirees',
        'Q03': 'Posh Professionals',
        'Q04': 'Family Fortune',
        'Q05': 'Singular Sensations',
        'R01': 'City Survivors',
        'R02': 'Urban Underdogs',
        'R03': 'Underprivileged Urbanites',
        'R04': 'One Day at a Time',
        'R05': 'Livin Large',
    },
    'Pets': {
        pets_pet_owner: 'Has Pets',
        pets_cat_owner: 'Cat Owner',
        pets_dog_owner: 'Dog Owner',
        pets_other_pet_owner: 'Other Pet Owner',       
    },
    'Vehicles': [
        'boat_owner', 'truck_owner', 'motorcycle_owner', 'rv_owner'
    ],
    'Donor': [
        'donor_charitable', 'donor_animal_welfare', 'donor_arts_cultural', 'donor_childrens', 'donor_environment_wildlife', 'donor_health',
        'donor_international_aid', 'donor_political', 'donor_political_conservative', 'donor_political_liberal', 'donor_religious', 'donor_veterans',
    ],
    'Premium Credit Card': {
        Y: 'Yes',
        N: 'No',
    },
    'Lifestyle': [
        'home_living', 'diy_living', 'sporty_living', 'upscale_living', 'cultural_artistic_living', 'highbrow',
        'common_living', 'professional_living', 'broader_living',
    ],
    'Foods': [
        {value: 'food_wines', label: 'Food and Wine'}, 
        'foods_natural', 
        'cooking_general', 
        'cooking_gourmet',
    ],
    'Collectibles': [
        'collectibles_general', 'collectibles_stamps', 'collectibles_coins', 'collectibles_arts', 'collectibles_antiques', 'collectibles_sports_memorabilia',
    ],    
    'Exercise': [
        'exercise_aerobic', 'exercise_running_jogging', 'exercise_walking',
    ],
    'Games': [
        'games_computer_games', 'games_video_games', 'games_board_puzzles',
    ],    
    'Investments': [
        'investments_foreign', 'investments_personal', 'investments_real_estate', 'investments_stocks_bonds',
    ],
    'Music': [
        'music_home_stereo', 'music_player', 'music_collector', 'music_listener',
    ],    
    'Reading': [
        'reading_general', 'reading_audio_books', 'reading_magazines', 'reading_religious_inspirational', 'reading_science_fiction', 
        'reading_financial_newsletter_subscribers',
    ],
    'Outdoor': [
        'outdoor_enthusiast_general', 'outdoor_fishing', 'outdoor_boating_sailing', 'outdoor_camping_hiking', 'outdoor_hunting_shooting', 'outdoor_scuba_diving',
    ],
    'Spectator Sports': [
        'spectator_sports_general', 'spectator_sports_baseball', 'spectator_sports_basketball', 'spectator_sports_football', 'spectator_sports_hockey',
        'spectator_sports_racing', 'spectator_sports_soccer', 'spectator_sports_tv_sports',
    ],    
    'Sports': [
        'sports_equestrian', 'sports_golf', 'sports_motorcycling', 'sports_skiing', 'sports_tennis',      
    ],
    'Travel': [
        'travel_cruises', 'travel_domestic', 'travel_international', 'travel_rv',
    ],
    'Buyer': [
        'buyer_books', 'buyer_crafts_hobbies', 'buyer_gardening_farming', 'buyer_jewelry', 'buyer_luggage', 'buyer_online',
        'buyer_membership_club', 'buyer_merchandise_male', 'buyer_merchandise_female', 'buyer_health_beauty', 'buyer_childrens_babycare', 
        'buyer_childrens_learning_toys', 'buyer_childrens_back_to_school',
    ],
    'Apparel': [
        'apparel_childrens', 'apparel_infant_toddlers', 'apparel_womens', 'apparel_womens_petite', 'apparel_womens_plus_size', 'apparel_womens_young',
        'apparel_mens', 'apparel_mens_big_tall', 'apparel_mens_young',
    ],
    'Cc': [
        'cc_american_express', 'cc_american_express_gold_platinum', 'cc_discover', 'cc_visa', 'cc_mastercard', 'cc_bank',
        'cc_gas_dept_retail', 'cc_travel_entertainment', 'cc_unknown', 'cc_gold_platinum', 'cc_premium', 'cc_upscale_dept', 'cc_new_issue'
    ],

    'Compensation': {
        'feestructure_assetbasedfee': 'Assets Based Fee',
        'feestructure_hourly': 'Hourly',
        'feestructure_fixedfee': 'Fixed Fee',
        'feestructure_performancebasedfee': 'Performance Based Fee',
        'feestructure_transactionbasedcommission': 'Transaction Based Commission'
    },
    'Advisory Services': {
        'advisoryservices_portfoliomanagementforindividuals': 'Portfolio Management for Individuals',
        'advisoryservices_portfoliomanagementforinvestmentcompanies': 'Portfolio Management for Investment Companies',
        'advisoryservices_securitiesratingspricing': 'Securities Ratings Pricing',
        'advisoryservices_educationalseminars': 'Educational Seminars',
        'advisoryservices_financialplanning': 'Financial Planning',
        'advisoryservices_pensionconsulting': 'Pension Consulting',
        'advisoryservices_portfoliomanagementforcorporations': 'Portfolio Management for Corporations',
        'advisoryservices_publicationofperiodicals': 'Publication of Periodicals',
        'advisoryservices_selectionofotheradvisers': 'Selection of Other Advisers'
    },
    'Other Business': {
        'otheractivities_accountant': 'Accountant',
        'otheractivities_bank': 'Bank',
        'otheractivities_brokerdealer': 'Broker & Dealer',
        'otheractivities_commoditypooloradvisor': 'Commodity Pool or Advisor',
        'otheractivities_futurescommissionmerchant': 'Futures Commission Merchant',
        'otheractivities_insuranceagent': 'Insurance Agent',
        'otheractivities_lawyer': 'Lawyer',
        'otheractivities_otherfinancialproducts': 'Other Financial Products',
        'otheractivities_realestateagent': 'Real Estate Agent',
        'otheractivities_registeredrepresentative': 'Registered Representative',
        'otheractivities_securityswapdealer': 'Security Swap Dealer',
        'otheractivities_securityswapparticipant': 'Security Swap Participant',
        'otheractivities_trustcompany': 'Trust Company',
    },
    'Affiliations': {
        'relatedparty_otherinvestmentadviser': 'Other Investment Adviser',
        'relatedparty_municipaladvisor': 'Municipal Advisor',
        'relatedparty_accountingfirm': 'Accounting firm',
        'relatedparty_bankinginstitution': 'Banking Institution',
        'relatedparty_brokerdealer': 'Broker & Dealer',
        'relatedparty_commoditypooloradvisor': 'Commodity Pool or Advisor',
        'relatedparty_futurescommissionmerchant': 'Futures Commission Merchant',
        'relatedparty_gpininvestmentpartnership': 'Gpin Investment Partnership',
        'relatedparty_insuranceagent': 'Insurance Agent',
        'relatedparty_lawfirm': 'Law Firm',
        'relatedparty_lpsyndicator': 'LP Syndicator',
        'relatedparty_pensionconsultant': 'Pension Consultant',
        'relatedparty_realestatebroker': 'Real Estate Broker',
        'relatedparty_securityswapdealer': 'Security Swap Dealer',
        'relatedparty_securityswapparticipant': 'Security Swap Participant',
        'relatedparty_trustcompany': 'Trust Company',
    },
    'RIA Types': {
        'riatype_headquartersoutsideus': 'Headquarters Outside Us',
        'riatype_internetinvestmentadviser': 'Internet Investment Adviser',
        'riatype_wrapfeeprogramadviser': 'Wrap Fee Program Adviser'
    },
    'Discretion Over': {
        'discretionover_amountofsecuritiesboughtsold': 'Amount of Securities Bought Sold',
        'discretionover_brokerusedforbuyssells': 'Broker Used for Buys Sells',
        'discretionover_commissionspaidtobroker': 'Commissions Paid to Broker',
        'discretionover_securitiesboughtsold': 'Securities Bought Sold',
        'discretionover_brokerusedisrelatedparty': 'Broker Used is Related Party',
        'discretionover_recommendbdtoclient': 'Recommended to Client',
        'discretionover_recommendedbdisrelatedparty': 'Recommended Disrelated Party'
    },
    'Terminated':{
        '1': 'Yes',
        '2': 'No',
    },
    'Referral Compensation': {
        'compensation_receivedforclientreferrals': 'Received for Client Referrals',
        'compensation_toemployeesforobtainingclients': 'To employees For Obtaining Clients',
        'compensation_tononemployeesforreferrals': 'To Non-Employees for Referrals'
    },
}

let fieldLookups = {};
for (const [fieldName, fieldValues] of Object.entries(fieldLookupsMap)) {
    let valueMap = {};
    if (typeof fieldLookupsMap === 'object') {
        valueMap = fieldValues;
    }
    const normalFieldName = fieldName.toLowerCase().replace(' ', '_');
    if (Array.isArray(fieldValues)) {
        valueMap = fieldValues.reduce(
            (agg, item) => {
                const baseName = (item.value || item).replace(normalFieldName + '_', '');
                const valueName = normalFieldName + '_' + baseName;
                agg[valueName] = item.label ? item.label : 
                    baseName.split('_')
                        .map(word => `${word[0].toUpperCase()}${word.substring(1)}`)
                        .join(' ');
                return agg;
            }
        , {});
    }
    fieldLookups[fieldName] = valueMap;
}

export default fieldLookups;