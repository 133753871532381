import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';


export default class QuotaLimitModal extends React.Component {
    static propTypes = {
        onConfirmExport: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        quotaDailyLimit: PropTypes.number.isRequired,
        quotaRemaining: PropTypes.number.isRequired,
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} >
                <ModalHeader>Quota reached</ModalHeader>
                <ModalBody>
                    <p>
                        Your account permits exporting {this.props.quotaDailyLimit.toLocaleString()} rows per day,
                        and today you have {this.props.quotaRemaining.toLocaleString()} rows remaining.
                        {this.props.quotaRemaining > 0 &&
                        <>Would you like to export this report with {this.props.quotaRemaining.toLocaleString()} rows?</>
                        }
                    </p>
                </ModalBody>
                <ModalFooter>
                    {this.props.quotaRemaining > 0 &&
                    <Button color="primary" type="submit" onClick={this.props.onConfirmExport}>
                        Yes, export {this.props.quotaRemaining.toLocaleString()} rows
                    </Button>
                    }
                    <Button color="dark" onClick={this.props.onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
