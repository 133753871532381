const million = 1;
const billion = million * 1000;

export const assetAmounts = {
    labels: ['0', '$1 M', '$10 M', '$100 M', '$1 B', '$10 B', '$100 B', '$1 T', '<'],
    values: [0, million, 10 * million, 100 * million, billion, 10 * billion, 100 * billion, 1000 * billion, 'all'],
}

export const clientAmounts = {
    labels: ['0', '10', '100', '1000', '10K', '100K', '<'],
    values: [0, 10, 100, 1000, 10000, 100000, 'all'],
}

export const employeeCount = {
    labels: ['0', '10', '100', '500', '1000', '10K', '30K', '50K', '<'],
    values: [0, 10, 100, 500, 1000, 10000, 30000, 50000, 'all'],
}

export const iAReps = {
    labels: ['0', '10', '100', '500', '1000', '5K', '10K', '30K', '<'],
    values: [0, 10, 100, 500, 1000, 5000, 10000, 30000, 'all'],
}

export const dBReps = {
    labels: ['0', '10', '100', '500', '1000', '5K', '10K', '30K', '<'],
    values: [0, 10, 100, 500, 1000, 5000, 10000, 30000, 'all'],
}

export const insuranceLicensedReps = {
    labels: ['0', '10', '100', '500', '1000', '5K', '10K', '30K', '<'],
    values: [0, 10, 100, 500, 1000, 5000, 10000, 30000, 'all'],
}

export const firmsSolicitingClients = {
    labels: ['0', '10', '100', '500', '1000', '10K', '50k', '100k', '<'],
    values: [0, 10, 100, 500, 1000, 10000, 50000, 100000, 'all'],
}

export const investmentAdvisoryClients = {
    labels: ['0', '100', '1000', '10k', '100K', '1M', '10M', '100M', '1B', '<'],
    values: [0, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000, 'all'],
}

export const planningClients = {
    labels: ['0', '10', '25', '50', '100', '250', '500', '<'],
    values: [0, 10, 25, 50, 100, 250, 500, 'all'],
}

export const bdFirmBranchCount = {
    labels: ['0', '10', '25', '50', '100', '250', '500', '<'],
    values: [0, 10, 25, 50, 100, 250, 500, 'all'],
}

export const bdFirmContactCount = {
    labels: ['0', '10', '25', '50', '100', '250', '500', '<'],
    values: [0, 10, 25, 50, 100, 250, 500, 'all'],
}