// @flow
import React from 'react';
import {Button, Card, CardBody, Col, Row, CustomInput} from 'reactstrap';
import { RangeSlider } from 'rsuite';
import PropTypes from 'prop-types';
import CarrierGroupSelect from './CarrierGroupSelects';
import LicenseStatesAndTypesSelects from './LicenseStatesAndTypesSelects';


export default class LicenseSearchFields extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleAddCarrierClause = this.handleAddCarrierClause.bind(this);
        this.handleAddLicenseClauses = this.handleAddLicenseClauses.bind(this);
    }

    handleSliderChange(name, value) {
        if (value[0] > value[1])
        {
            var temp = value[0];
            value[0] = value[1];
            value[1] = temp;
        }
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }
    handleAddCarrierClause() {
        let carriersAndGroups = this.props.formState.carriers_and_groups;
        carriersAndGroups.push({
            'carrier_groups': [],
            'carrier_groups_exclude': false,
            'carriers': [],
            'carriers_exclude': false,
        });

        this.props.onChange({
            target: {
                name: 'carriers_and_groups',
                value: carriersAndGroups,
            }
        });
    }

    handleAddLicenseClauses() {
        let licensesAndGroups = this.props.formState.license_states_and_groups;
        let defaultExclude = (licensesAndGroups.length > 0) ? !licensesAndGroups[0].exclude : false;
        licensesAndGroups.push({
            'exclude': defaultExclude,
            'license_states_bool': 'or',
            'license_states': [],
            'license_groups_bool': 'or',
            'license_groups': {
                "": {
                    i: 0,
                    range: [1, 50]
                },
            }
        });

        this.props.onChange({
            target: {
                name: 'license_states_and_groups',
                value: licensesAndGroups,
            }
        });
    }

    render() {
        let formState = this.props.formState;

        let completelyEmptyCarrierClauses = formState.carriers_and_groups.filter(row => {
            return row.carrier_groups.length === 0 && row.carriers.length === 0;
        });
        let showAddNewCarrierClauseButton = (completelyEmptyCarrierClauses.length === 0);

        let completelyEmptyLicenseClauses = formState.license_states_and_groups.filter(row => {
            let licenseGroupKeys = Object.keys(row.license_groups);
            return row.license_states.length === 0 &&
                (licenseGroupKeys.length === 0 || (licenseGroupKeys.length === 1 && licenseGroupKeys[0] === ''));
        });
        let showAddNewLicenseFieldsButton = (completelyEmptyLicenseClauses.length === 0);

        return <Card>
            <CardBody>
                <h4 className="header-title">Licenses and Appointments</h4>
                {formState.carriers_and_groups.map((row, index) => {
                    return <CarrierGroupSelect key={index} index={index}
                        onChange={this.props.onChange} formState={this.props.formState} />
                })}

                {formState.carriers_and_groups.length > 1 && 
                    <CustomInput type="switch"
                        label="All of these carriers"
                        id="carrier_groups_bool"
                        name="carrier_groups_bool"
                        checked={formState.carrier_groups_bool}
                        onChange={this.props.onChange} />
                }

                {showAddNewCarrierClauseButton &&
                    <Row>
                        <Col className="pl-3">
                            <Button className="p-0" color="link" onClick={this.handleAddCarrierClause}>+ Add another carrier / group clause</Button>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col>
                        <Row className="mb-1">
                            <Col xs={6} className="text-right">
                                <strong>Total carrier count</strong>
                            </Col>
                            <Col xs={6} className="pt-1">
                                <RangeSlider min={0}
                                             max={50}
                                             value={formState.carrier_count_range || [0, 50]}
                                             onChange={value => this.handleSliderChange('carrier_count_range', value)}
                                             graduated={true}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col xs={6} className="text-right">
                                <strong>Total carrier group count</strong>
                            </Col>
                            <Col xs={6} className="pt-1">
                                <RangeSlider min={0} max={50}
                                             value={formState.carrier_group_range || [0, 50]}
                                             onChange={value => this.handleSliderChange('carrier_group_range', value)}
                                             graduated={true}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col xs={6} className="text-right">
                                <strong>Years licensed</strong>
                            </Col>
                            <Col xs={6} className="pt-1">
                                <RangeSlider min={0} max={50}
                                             value={formState.years_licensed_range}
                                             onChange={value => this.handleSliderChange('years_licensed_range', value)}
                                             graduated={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>


                {formState.license_states_and_groups.map((row, index) => {
                    return <LicenseStatesAndTypesSelects key={index} index={index}
                        onChange={this.props.onChange} formState={this.props.formState} />
                })}
                {showAddNewLicenseFieldsButton &&
                    <Row>
                        <Col className="pl-3">
                            <Button className="p-0" color="link" onClick={this.handleAddLicenseClauses}>+ Add another set of license clauses</Button>
                        </Col>
                    </Row>
                }
            </CardBody>
        </Card>
    }
}
