export const searchFieldTypes = (offset => ({
    'SINGLE_SELECT': offset++,
    'MULTI_SELECT': offset++,
}))(1);

const {SINGLE_SELECT, MULTI_SELECT} = searchFieldTypes
export const consumerDataSearchFields = [
    {searchFieldLabel: 'Gender', fieldType: SINGLE_SELECT},
    {searchFieldLabel: 'Ethnicity', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Income', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Net Worth', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Homeowner Status', fieldType: SINGLE_SELECT},
    {searchFieldLabel: 'Dwelling Type', fieldType: SINGLE_SELECT},
    {searchFieldLabel: 'Home Value', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Home Built Year', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Marital Status', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Children', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Pets', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Premium Credit Card', fieldType: SINGLE_SELECT},
    {searchFieldLabel: 'Vehicles', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Donor', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Foods', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Collectibles', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Exercise', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Games', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Investments', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Music', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Reading', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Outdoor', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Spectator Sports', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Sports', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Travel', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Buyer', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Apparel', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Nbrhood Cluster', searchFieldName: 'neighborhood_cluster', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Household Cluster', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Compensation', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Advisory Services', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Other Business', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Affiliations', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'RIA Types', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Discretion Over', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Referral Compensation', fieldType: MULTI_SELECT},
    {searchFieldLabel: 'Terminated', fieldType: SINGLE_SELECT},
].map(item => {
    item = (typeof item === 'string' ? {searchFieldLabel: item} : item);
    return {
        searchFieldName: item.searchFieldLabel.replace(' ', '_').toLowerCase(),
        fieldType: SINGLE_SELECT,
        initialValue: item.fieldType === MULTI_SELECT ? [] : '',
        ...item
    }
});


