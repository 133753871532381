import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner
} from 'reactstrap';
import { Column, HeaderCell, Cell } from 'rsuite-table';

import APIClient from '../utils/API';
import SortableRsuiteTable from './SortableRsuiteTable';
import renderDollars from '../utils/renderDollars';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import _ from "lodash";
import { renderTooltipPrev, renderTooltipNext } from '../helpers/navigate';

class RiaFirmDetailsPage extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let firm = this.props.firmData;

        return (<>
            <h4>Firm Details</h4>
            <Row className="ml-2">
                <Col lg={6}>
                    <dl className="row">
                        <dt className="col-sm-4">CRD</dt>
                        <dd className="col-sm-8">{firm['firm_crd']}</dd>
                        <dt className="col-sm-4">Firm Name</dt>
                        <dd className="col-sm-8">{firm['firm_name']}</dd>
                        {(firm['firm_name'] !== firm['firm_legalname']) && <>
                            <dt className="col-sm-4">Legal Name</dt>
                            <dd className="col-sm-8">{firm['firm_legalname']}</dd>
                        </>}
                        <dt className="col-sm-4">Main Office Address</dt>
                        <dd className="col-sm-8">
                            {firm['mainoffice_full_address']}
                            <br/>{firm['mainoffice_city']}
                            <br/>{firm['mainoffice_state']} {firm['mainoffice_zipcode']}
                            <br/>{firm['mainoffice_country']}
                        </dd>
                        <dt className="col-sm-4">Other offices</dt>
                        <dd className="col-sm-8">
                            {parseInt(firm['totalofficesconductingadvisorybusiness'])} offices conducting advisory business
                        </dd>

                        <dt className="col-sm-4"># Employees</dt>
                        <dd className="col-sm-8">
                            {parseInt(firm['number_employees'])} total,
                            <br/>{parseInt(firm['number_iareps'])} IA,
                            {parseInt(firm['number_bdreps'])} BD,
                            {parseInt(firm['number_insurancelicensedreps'])} Insurance Licensed reps
                        </dd>
                    </dl>

                    <AdvisoryServicesBlock firmData={this.props.firmData} />

                    <AffiliationsBlock firmData={this.props.firmData} />

                    <DiscretionOverBlock firmData={this.props.firmData} />
                </Col>

                <Col lg={6}>
                    <dl className="row">
                        <dt className="col-sm-4">Phone</dt>
                        <dd className="col-sm-8">{firm['mainoffice_phone']}</dd>
                        <dt className="col-sm-4">Fax</dt>
                        <dd className="col-sm-8">{firm['mainoffice_fax']}</dd>
                    </dl>

                    <FeeStructureBlock firmData={this.props.firmData} />

                    <WrapFeeProgramBlock firmData={this.props.firmData} />

                    <OtherBusinessActivitiesBlock firmData={this.props.firmData} />

                    <RIATypesBlock firmData={this.props.firmData} />

                    <ReferralCompensationBlock firmData={this.props.firmData} />

                    {firm['websites'] && firm['websites'].length &&
                        <dl>
                            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                            <dt>Website(s)</dt>
                            <dd>
                                {firm['websites'].map(url => <React.Fragment key={url}>
                                    <a href={url} target="_blank" rel="noopener noreferrer">{url.toLowerCase()}</a><br/>
                                </React.Fragment>)}
                            </dd>
                            </div>
                        </dl>
                    }
                </Col>
            </Row>

            <TotalAssetsTable firmData={this.props.firmData} />

            <ClientsTable firmData={this.props.firmData} />

            <RegistrationsTable firmData={this.props.firmData} />

            <IndividualsTable firmData={this.props.firmData} onModalChanged={this.props.onModalChanged} />

            <ExecutivesTable firmData={this.props.firmData} onModalChanged={this.props.onModalChanged} />

            <BranchAddressesTable firmData={this.props.firmData} />
        </>)
    }
}


class WrapFeeProgramBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let firmData = this.props.firmData;
        let hasWrapFeeProgram = firmData['riatype_wrapfeeprogramadviser'];
        if (hasWrapFeeProgram) {
            return (
                <dl className="row">
                    <dt className="col-sm-4">Wrap Fee Program</dt>
                    <dd className="col-sm-8">
                        <div>{renderDollars(firmData['assetsinmillions_wrapfee_programsponsor'], 'dollars')} acting as sponsor to</div>
                        <div>{renderDollars(firmData['assetsinmillions_wrapfee_portfoliomanager'], 'dollars')} acting as portfolio manager</div>
                        <div>{renderDollars(firmData['assetsinmillions_wrapfee_programsponsorandmanager'], 'dollars')} acting as sponsor to AND portfolio manager</div>
                    </dd>
                </dl>
            )
        }
        return (
            <dl className="row">
                <dt className="col-sm">Does not particpate in a Wrap Fee Program</dt>
            </dl>
        )
    }
}

const feeTypes = {
    'feestructure_assetbasedfee': '(1) A percentage of assets under your management',
    'feestructure_hourly': '(2) Hourly charges',
    // '': '(3) Subscription fees (for a newsletter or periodical)',
    'feestructure_fixedfee': '(4) Fixed fees (other than subscription fees)',
    'feestructure_transactionbasedcommission': '(5) Commissions',
    'feestructure_performancebasedfee': '(6) Performance-based fees',
    // '': '(7) Other (specify)',
}

class FeeStructureBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let enabledFeeTypes = Object.keys(feeTypes).filter(k => this.props.firmData[k]);
        if (!enabledFeeTypes.length) {
            return <></>;
        }

        let feeTypeRows = enabledFeeTypes.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{feeTypes[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">Compensation</dt>
                <dd className="col-sm-8" children={feeTypeRows} />
            </dl>
        )
    }
}

class AffiliationsBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    Affiliations = {
        'relatedparty_otherinvestmentadviser': 'Other Investment Adviser',
        'relatedparty_municipaladvisor': 'Municipal Advisor',
        'relatedparty_accountingfirm': 'Accounting Firm',
        'relatedparty_bankinginstitution': 'Banking Institution',
        'relatedparty_brokerdealer': 'Broker & Dealer',
        'relatedparty_commoditypooloradvisor': 'Commodity Pool or Advisor',
        'relatedparty_futurescommissionmerchant': 'Futures Commission Merchant',
        'relatedparty_gpininvestmentpartnership': 'Gpin Investment Partnership',
        'relatedparty_insuranceagent': 'Insurance Agent',
        'relatedparty_lawfirm': 'Law Firm',
        'relatedparty_lpsyndicator': 'LP Syndicator',
        'relatedparty_pensionconsultant': 'Pension Consultant',
        'relatedparty_realestatebroker': 'Real Estate Broker',
        'relatedparty_securityswapdealer': 'Security Swap Dealer',
        'relatedparty_securityswapparticipant': 'Security Swap Participant',
        'relatedparty_trustcompany': 'Trust Company',
    }
        

    render() {
        let enabledAffiliations = Object.keys(this.Affiliations).filter(k => this.props.firmData[k]);
        if (!enabledAffiliations.length) {
            return <></>;
        }

        let AffiliationsRows = enabledAffiliations.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{this.Affiliations[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">Affiliations</dt>
                <dd className="col-sm-8" children={AffiliationsRows} />
            </dl>
        )
    }
}

class RIATypesBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    RIATypes = {
        'riatype_headquartersoutsideus': 'Headquarters Outside Us',
        'riatype_internetinvestmentadviser': 'Internet Investment Adviser',
        'riatype_wrapfeeprogramadviser': 'Wrap Fee Program Adviser'
    }

    render() {
        let enabledRIATypes = Object.keys(this.RIATypes).filter(k => this.props.firmData[k]);
        if (!enabledRIATypes.length) {
            return <></>;
        }

        let RIATypesRows = enabledRIATypes.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{this.RIATypes[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">RIA Types</dt>
                <dd className="col-sm-8" children={RIATypesRows} />
            </dl>
        )
    }
}

class DiscretionOverBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    DiscretionOver = {
        'discretionover_amountofsecuritiesboughtsold': 'Amount of Securities Bought Sold',
        'discretionover_brokerusedforbuyssells': 'Broker Used for Buys Sells',
        'discretionover_commissionspaidtobroker': 'Commissions Paid to Broker',
        'discretionover_securitiesboughtsold': 'Securities Bought Sold',
        'discretionover_brokerusedisrelatedparty': 'Broker Used is Related Party',
        'discretionover_recommendbdtoclient': 'Recommended to Client',
        'discretionover_recommendedbdisrelatedparty': 'Recommended Disrelated Party'
    }

    render() {
        let enabledDiscretionOver = Object.keys(this.DiscretionOver).filter(k => this.props.firmData[k]);
        if (!enabledDiscretionOver.length) {
            return <></>;
        }

        let DiscretionOverRows = enabledDiscretionOver.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{this.DiscretionOver[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">Discretion Over</dt>
                <dd className="col-sm-8" children={DiscretionOverRows} />
            </dl>
        )
    }
}

class ReferralCompensationBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    ReferralCompensation = {
        'compensation_receivedforclientreferrals': 'Received for Client Referrals',
        'compensation_toemployeesforobtainingclients': 'To Employees for Obtaining Clients',
        'compensation_tononemployeesforreferrals': 'To Non-Employees for Referrals'
    }

    render() {
        let enabledReferralCompensation = Object.keys(this.ReferralCompensation).filter(k => this.props.firmData[k]);
        if (!enabledReferralCompensation.length) {
            return <></>;
        }

        let ReferralCompensationeRows = enabledReferralCompensation.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{this.ReferralCompensation[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">Referral Compensation</dt>
                <dd className="col-sm-8" children={ReferralCompensationeRows} />
            </dl>
        )
    }
}

const advisoryTypes = {
    'advisoryservices_financialplanning': '(1) Financial planning services',
    'advisoryservices_portfoliomanagementforindividuals': '(2) Portfolio management for individuals and/or small businesses',
    'advisoryservices_portfoliomanagementforinvestmentcompanies': '(3) Portfolio management for investment companies',
    // '': '(4) Portfolio management for pooled investment vehicles (other than investment companies)',
    'advisoryservices_portfoliomanagementforcorporations': '(5) Portfolio management for businesses (other than small businesses) or institutional clients (other than registered investment companies and other pooled investment vehicles)',
    'advisoryservices_pensionconsulting': '(6) Pension consulting services',
    'advisoryservices_selectionofotheradvisers': '(7) Selection of other advisers (including private fund managers)',
    'advisoryservices_publicationofperiodicals': '(8) Publication of periodicals or newsletters',
    'advisoryservices_securitiesratingspricing': '(9) Security ratings or pricing services',
    'advisoryservices_markettiming': '(10) Market timing services',
    'advisoryservices_educationalseminars': '(11) Educational seminars/workshops',
    // '': '(12) Other(specify):',
}

class AdvisoryServicesBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let enabledAdvisoryTypes = Object.keys(advisoryTypes).filter(k => this.props.firmData[k]);
        if (!enabledAdvisoryTypes.length) {
            return <></>;
        }

        let advisoryTypeRows = enabledAdvisoryTypes.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{advisoryTypes[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">Advisory Services Offered</dt>
                <dd className="col-sm-8" children={advisoryTypeRows} />
            </dl>
        )
    }
}

const activityTypes = {
    'otheractivities_brokerdealer': '(1) broker-dealer (registered or unregistered)',
    'otheractivities_registeredrepresentative': '(2) registered representative of a broker-dealer',
    'otheractivities_commoditypooloradvisor': '(3) commodity pool operator or commodity trading advisor (whether registered or exempt from registration)',
    'otheractivities_futurescommissionmerchant': '(4) futures commission merchant',
    'otheractivities_realestateagent': '(5) real estate broker, dealer, or agent',
    'otheractivities_insuranceagent': '(6) insurance broker or agent',
    'otheractivities_bank': '(7) bank (including a separately identifiable department or division of a bank)',
    'otheractivities_trustcompany': '(8) trust company',
    // '': '(9) registered municipal advisor',
    'otheractivities_securityswapdealer': '(10) registered security-based swap dealer',
    'otheractivities_securityswapparticipant': '(11) major security-based swap participant',
    'otheractivities_accountant': '(12) accountant or accounting firm',
    'otheractivities_lawyer': '(13) lawyer or law firm',
    'otheractivities_otherfinancialproducts': '(14) other financial product salesperson (specify)',
}

class OtherBusinessActivitiesBlock extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let enabledActivityTypes = Object.keys(activityTypes).filter(k => this.props.firmData[k]);
        if (!enabledActivityTypes.length) {
            return <></>;
        }

        let activityTypesRows = enabledActivityTypes.map(k => {
            return <div key={k} className={this.props.firmData[k] ? 'bool-attrib-active' : 'bool-attrib-inactive'}>{activityTypes[k]}</div>
        })

        return (
            <dl className="row">
                <dt className="col-sm-4">Other Business Activities</dt>
                <dd className="col-sm-8" children={activityTypesRows} />
            </dl>
        )
    }
}


const AmountMillionsCell = ({ rowData, dataKey, ...props }) => (
    <Cell dataKey={dataKey} {...props}>
        {renderDollars(rowData[dataKey], 'dollars')}
    </Cell>
);

const HeaderSummary = ({ title, summary }) => (
    <>
      <span className="text-warning tableheading">
        {summary}
      </span> <label>{title}</label>
    </>
);


class TotalAssetsTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    assetTypes = {
        'discretionary': 'Discretionary',
        'nondiscretionary': 'Non-discretionary',
    }

    render() {
        let tableRows = Object.keys(this.assetTypes).map(k => {
            return {
                assetType: this.assetTypes[k],
                accountCount: parseInt(this.props.firmData[`accounts_${k}`] || '0'),
                assetMillions: parseFloat(this.props.firmData[`assetsinmillions_${k}`] || '0'),
            };
        })
        let totalAssetsMillions = parseFloat(this.props.firmData['assetsinmillions_total'] || '0')

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Total Assets</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={tableRows}
                defaultSortColumn='clientType'
                defaultSortDirection='asc'
                paginate={false}>

                <Column width={400} sortable resizable>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey="assetType" />
                </Column>
                <Column width={150} sortable>
                    <HeaderCell>
                        <HeaderSummary title="Clients" summary={this.props.firmData['accounts_total']} />
                    </HeaderCell>
                    <Cell dataKey="accountCount" />
                </Column>
                <Column width={250} sortable>
                    <HeaderCell>
                        <HeaderSummary title="Assets" summary={renderDollars(totalAssetsMillions, 'rounded')} />
                    </HeaderCell>
                    <AmountMillionsCell dataKey="assetMillions" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class ClientsTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    clientTypes = {
        'individuals': '(a) Individuals (other than high net worth individuals)',
        'hnwindividuals': '(b) High net worth individuals',
        'banks': '(c) Banking or thrift institutions',
        'investmentcompanies': '(d) Investment companies',
        'businessdevelopmentcompanies': '(e) Business development companies',
        'pooledvehicles': '(f) Pooled investment vehicles (other than investment companies and business development companies)',
        'retirementplans': '(g) Pension and profit sharing plans (but not the plan participants or government pension plans)',
        'charities': '(h) Charitable organizations',
        'municipalities': '(i) State or municipal government entities (including government pension plans)',
        'otherinvestmentadvisers': '(j) Other investment advisers',
        'insurancecompanies': '(k) Insurance companies',
        'sovereignorforeign': '(l) Sovereign wealth funds and foreign official institutions',
        'separatelymanagedaccounts': '(m) Corporations or other businesses not listed above',
        'other': '(n) Other:',
    }

    render() {

        let clientRows = Object.keys(this.clientTypes).map(k => {
            return {
                clientType: this.clientTypes[k],
                clientCount: parseInt(this.props.firmData[`numberclients_${k}`] || '0'),
                assetMillions: parseFloat(this.props.firmData[`assetsinmillions_${k}`] || '0'),
            };
        })

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Clients</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={clientRows}
                defaultSortColumn='clientType'
                defaultSortDirection='asc'
                paginate={false}>

                <Column width={400} sortable resizable>
                    <HeaderCell>Type of Client</HeaderCell>
                    <Cell dataKey="clientType" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Number of Clients</HeaderCell>
                    <Cell dataKey="clientCount" />
                </Column>
                <Column width={250} sortable>
                    <HeaderCell>Assets under management</HeaderCell>
                    <AmountMillionsCell dataKey="assetMillions" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class RegistrationsTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let registrationRows = this.props.firmData['registration_statuses'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Registrations</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={registrationRows}
                defaultSortColumn='jurisdiction'
                defaultSortDirection='asc'>

                <Column width={250} sortable resizable>
                    <HeaderCell>Jurisdiction</HeaderCell>
                    <Cell dataKey="jurisdiction" />
                </Column>
                <Column width={150} sortable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="status" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Effective Date</HeaderCell>
                    <Cell dataKey="date" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Type</HeaderCell>
                    <Cell dataKey="type" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class IndividualsTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let indivRows = this.props.firmData['individuals'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Individuals</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                onRowClick={row => this.props.onModalChanged(row.indiv_crd, "individuals")}
                data={indivRows}
                rowClassName="row-hovered"
                defaultSortColumn='indiv_crd'
                defaultSortDirection='asc'>

                <Column width={120} sortable resizable>
                    <HeaderCell>CRD</HeaderCell>
                    <Cell dataKey="indiv_crd" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>First Name</HeaderCell>
                    <Cell dataKey="first_name" />
                </Column>
                <Column width={150} sortable>
                    <HeaderCell>Last Name</HeaderCell>
                    <Cell dataKey="last_name" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Reg Cat</HeaderCell>
                    <Cell dataKey="reg_cat" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Reg Auth</HeaderCell>
                    <Cell dataKey="reg_auth" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="reg_status" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Reg Date</HeaderCell>
                    <Cell dataKey="reg_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class ExecutivesTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let executivesRows = this.props.firmData['executives'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Executive(s)</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                onRowClick={row => this.props.onModalChanged(row.indiv_crd, "executives")}
                data={executivesRows}
                rowClassName="row-hovered"
                defaultSortColumn='indiv_crd'
                defaultSortDirection='asc'>

                <Column width={120} sortable resizable>
                    <HeaderCell>CRD</HeaderCell>
                    <Cell dataKey="indiv_crd" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>Full Name</HeaderCell>
                    <Cell dataKey="full_legal_name" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>Title</HeaderCell>
                    <Cell dataKey="title" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>LinkedIn</HeaderCell>
                    <Cell dataKey="linkedin_profile" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class BranchAddressesTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let branchesRows = this.props.firmData['branches'];
       
        let branchOfficeRows = branchesRows.filter((ele, ind) => ind === branchesRows.findIndex(elem => elem.city === ele.city && elem.full_address === ele.full_address))

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Branch Office Locations</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={branchOfficeRows}
                defaultSortColumn='state'
                defaultSortDirection='asc'>

                <Column width={300} sortable resizable>
                    <HeaderCell>Address</HeaderCell>
                    <Cell dataKey="full_address" />
                </Column>
                <Column width={170} sortable>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column width={100} sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="state" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Zip</HeaderCell>
                    <Cell dataKey="zipcode" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Country</HeaderCell>
                    <Cell dataKey="country" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

export default class RiaFirmDetailsModal extends React.Component {
    static propTypes = {
        rowId: PropTypes.any,
        listRow: PropTypes.any,
        hideModel: PropTypes.any,
        updateId: PropTypes.any,
        countId: PropTypes.any,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onModalChanged: PropTypes.func,
        onClickChanged: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            prev: true,
            next: true,
            isLoadingData: true,
            firmData: null,
            rowValues: [],
        };
        this.loadData = this.loadData.bind(this);
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.onClickIndividualDetails = this.onClickIndividualDetails.bind(this);
        this.nextRecord = this.nextRecord.bind(this);
        this.previousRecord = this.previousRecord.bind(this);
        this.keyPress = this.keyPress.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.rowId !== this.props.rowId) {
            this.loadData();
        }
    }

    loadData() {
        this.setState({
            isLoadingData: true,
        }, () => {
            let client = new APIClient();
            return client.getRiaFirmRow(this.props.rowId)
                .then(response => {
                    this.setState({
                        firmData: response.data,
                        isLoadingData: false,
                    });
                });
        });
    }

    async keyPress(event) {
        let evenKey = event.keyCode

        if (this.props.isOpen) {
            switch (evenKey) {
                case 37:
                    this.previousRecord();
                    break;
                case 39:
                    this.nextRecord()
                    break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyPress);
    }

    handleIndividualDetails(id, onClickValues) {
        if (this.onModalChanged !== this.props.onModalChanged) {
            this.props.onClose();
            this.props.onModalChanged(id, this.state.rowValues, onClickValues);
        }
    }

    onClickIndividualDetails(id) {
        if (this.onClickChanged !== this.props.onClickChanged) {
            this.props.onClose();
            this.props.onClickChanged(id);
        }
    }

    nextRecord() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
        if (this.onClickChanged !== this.props.onClickChanged && currentIndex != this.props.countId - 1) {
            this.props.onClickChanged(rowList[parseInt(currentIndex) + 1].CRD);
        }
    }

    previousRecord() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
        if (this.onClickChanged !== this.props.onClickChanged && currentIndex != 0) {
            this.props.onClickChanged(rowList[parseInt(currentIndex) - 1].CRD);
        }
    }

    render() {
        let rowList = this.props.updateId;

        if (this.state.firmData) {
            let getIndiv = []
            let getExec = []
            for (const currentIndexIndividuals in this.state.firmData['individuals']) {
                getIndiv.push(this.state.firmData['individuals'][currentIndexIndividuals].indiv_crd)
            }
            for (const currentIndexExecutives in this.state.firmData['executives']) {
                getExec.push(this.state.firmData['executives'][currentIndexExecutives].indiv_crd)
            }
            this.state.rowValues = {
                indivCrd: getIndiv.sort(function (a, b) { return a - b }),
                executives: getExec.sort(function (a, b) { return a - b }),
            }
        }

        if (rowList) {
            let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
            currentIndex > 0 ? this.state.prev = true : this.state.prev = false;
            currentIndex != this.props.countId - 1 ? this.state.next = true : this.state.next = false;
        }

        if (this.props.listRow) {
            !this.props.listRow ? this.state.prev = true : this.state.prev = false;
            !this.props.listRow ? this.state.next = true : this.state.next = false;
        }


        return (
                <Modal isOpen={this.props.isOpen}
                    toggle={this.props.onClose}
                    className="agent-details-modal"
                >
                    {this.state.prev &&
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 100, hide: 100 }}
                            overlay={renderTooltipPrev}
                        >
                            <button
                                className="c-controls prev b"
                                onClick={this.previousRecord}
                            >
                                <span class="material-icons">&#10094;</span>
                                <span class="material-icons next-btn-text">Prev</span>
                            </button>
                        </OverlayTrigger>
                    }
                    {this.state.next &&
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 100, hide: 100 }}
                            overlay={renderTooltipNext}
                        >
                            <button
                                className="c-controls next b"
                                onClick={this.nextRecord}
                            >
                                <span class="material-icons">&#10095;</span>
                                <span class="material-icons next-btn-text">Next</span>
                            </button>
                        </OverlayTrigger>
                    }


                    <ModalHeader>Firm Details</ModalHeader>
                    <ModalBody>
                        {this.state.isLoadingData
                            ? <div className="text-center">
                                <Spinner as="span" animation="border" size="lg" />
                            </div>
                            : <RiaFirmDetailsPage firmData={this.state.firmData} onModalChanged={this.handleIndividualDetails} />
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.onClose}>Done</Button>
                    </ModalFooter>
                </Modal>
        );
    }
}

export { AdvisoryServicesBlock, advisoryTypes }
export { FeeStructureBlock, feeTypes }
export { OtherBusinessActivitiesBlock, activityTypes }