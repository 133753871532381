// @flow
import React from 'react';
import {Col, FormGroup, Label, Row, Input} from 'reactstrap';
import PropTypes from 'prop-types';
import {RangeSlider} from 'rsuite';


export default class EmploymentcountRR extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    handleSliderChange(name, value) {
        if( value[0] > value[1] )
        {
            var temp = value[0]
            value[0] = value[1]
            value[1] = temp
        }

        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    handleStatesSliderChange(name, value) {
        if( value[0] > value[1] )
        {
            var temp = value[0]
            value[0] = value[1]
            value[1] = temp
        }
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    rangeTicks(mark) {
        if (mark % 10 === 0) {
            return <span>{mark}</span>;
        }
        return null;
    }

    render() {
        let formState = this.props.formState;

        return (
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Label>Employment count RR</Label>
                        <Row>
                            <Col xs={1}>
                                <input type="checkbox" name="employment_count"
                                    checked={formState.employment_count}
                                    onChange={this.props.onChange} />
                            </Col>
                            <Col>
                                <RangeSlider min={0} max={70} disabled={!formState.employment_count}
                                            value={formState.employment_count_range}
                                            onChange={value => this.handleSliderChange('employment_count_range', value)}
                                            renderMark={this.rangeTicks}
                                            graduated={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col className="pt-2">
                            <Label for="disclosure">Disclosure</Label>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Input type="select" name="disclosure" id="disclosure"
                            value={formState.disclosure} onChange={this.props.onChange}>
                            <option value="">----</option>
                            <option value="TRUE">Yes</option>
                            <option value="FALSE">No</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}
