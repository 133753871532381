// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';
import USStateSelect from '../USStateSelect';

const AddressStatesSearchSelects = ({ formName }) => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.FirstClassData.formStates[formName].formState.address_states);
    const searchType = useSelector((searchType) => searchType.FirstClassData.formStates[formName].formState.search_type);

    const [address_states, canAddAnotherStateClause] = useMemo(() => {
        const canAddAnotherStateClause = states.filter(row => {
                    return row.states.length === 0;
                }).length === 0;
        return [states, canAddAnotherStateClause];
    }, [states]);


    const onFieldChange = (event, index) => {
        let addressStates = address_states.slice();
        addressStates[index][event.target.name] = event.target.value;
        dispatch(mergeSearch({ address_states: addressStates }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let addressStates = address_states.slice();
        addressStates[index].exclude = !exclude;
        dispatch(mergeSearch({ address_states: addressStates }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let addressStates = address_states.slice();
        addressStates.splice(index, 1);
        dispatch(mergeSearch({ address_states: addressStates }, formName));
    };
    const handleAddStateClause = () => {
        let addressStates = address_states.slice();
        addressStates.push({
            exclude: false,
            address_states_bool: 'or',
            states: []
        });
        dispatch(mergeSearch({ address_states: addressStates }, formName));
    };

    return (
        <Col lg={12}>
            {address_states.map((addressStateClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(addressStateClause.exclude, index)}
                        >
                            {addressStateClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these states:

                        {address_states.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    {
                                        searchType == 'riaIndivSearch' ? <Label>Branch office in state(s)</Label> : <Label>Customer address in state(s)</Label>
                                    }
                                    <USStateSelect name="states" multi={true}
                                                   value={addressStateClause.states}
                                                   onChange={(event) => onFieldChange(event, index)} />

                                    {addressStateClause.states.length > 1 &&
                                    <div className="form-inline flex-row-reverse pt-1">
                                        <p>
                                            Search for addresses in &nbsp;
                                            <Input type="select"
                                                   name="address_states_bool"
                                                   bsSize="sm"
                                                   value={addressStateClause.address_states_bool}
                                                   onChange={(event) => onFieldChange(event, index)}>
                                                <option value="and">all of these states</option>
                                                <option value="or">any of these states</option>
                                            </Input>
                                        </p>
                                    </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
            {canAddAnotherStateClause && (
                <Button
                    className="p-0 ml-3"
                    color="link"
                    onClick={handleAddStateClause}>
                    + Add another State clause
                </Button>
            )}
        </Col>
    );
};

export default AddressStatesSearchSelects;