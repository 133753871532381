import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner
} from 'reactstrap';
import { Column, HeaderCell, Cell } from 'rsuite-table';

import APIClient from '../utils/API';
import SortableRsuiteTable from './SortableRsuiteTable';
import renderDollars from '../utils/renderDollars';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import _ from "lodash";
import {  renderTooltipPrev,  renderTooltipNext } from '../helpers/navigate';


class BranchAddressesTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
    };

    render() {
        let branchesRows = this.props.firmData['branches'];
        let branchOfficeRows = branchesRows.filter((ele, ind) => ind === branchesRows.findIndex(elem => elem.city === ele.city && elem.full_address === ele.full_address))

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Branch Office Locations</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={branchOfficeRows}
                defaultSortColumn='state'
                defaultSortDirection='asc'>

                <Column width={300} sortable resizable>
                    <HeaderCell>Address</HeaderCell>
                    <Cell dataKey="full_address" />
                </Column>
                <Column width={170} sortable>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column width={100} sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="state" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Zip</HeaderCell>
                    <Cell dataKey="zip" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Country</HeaderCell>
                    <Cell dataKey="country" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class ExecutivesTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let executivesRows = this.props.firmData['executives'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Executive(s)</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                onRowClick={row => this.props.onModalChanged(row.indiv_crd, "executives")}
                data={executivesRows}
                rowClassName="row-hovered"
                defaultSortColumn='indiv_crd'
                defaultSortDirection='asc'>

                <Column width={120} sortable resizable>
                    <HeaderCell>CRD</HeaderCell>
                    <Cell dataKey="indiv_crd" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>Full Name</HeaderCell>
                    <Cell dataKey="legal_name" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>Title</HeaderCell>
                    <Cell dataKey="position" />
                </Column>
                <Column width={200} sortable resizable>
                    <HeaderCell>BC Scope</HeaderCell>
                    <Cell dataKey="bcscope" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class IndividualsTable extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let indivRows = this.props.firmData['individuals'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Individuals</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                onRowClick={row => this.props.onModalChanged(row.indiv_crd, "individuals")}
                data={indivRows}
                rowClassName="row-hovered"
                defaultSortColumn='indiv_crd'
                defaultSortDirection='asc'>

                <Column width={120} sortable resizable>
                    <HeaderCell>CRD</HeaderCell>
                    <Cell dataKey="indiv_crd" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>First Name</HeaderCell>
                    <Cell dataKey="first_name" />
                </Column>
                <Column width={150} sortable>
                    <HeaderCell>Last Name</HeaderCell>
                    <Cell dataKey="last_name" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Reg Cat</HeaderCell>
                    <Cell dataKey="reg_cat" />
                </Column>
                <Column width={130} sortable>
                    <HeaderCell>Reg Auth</HeaderCell>
                    <Cell dataKey="reg_auth" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="reg_status" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Reg Date</HeaderCell>
                    <Cell dataKey="reg_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class BdFirmDetailsPage extends React.Component {
    static propTypes = {
        firmData: PropTypes.object.isRequired,
        onModalChanged: PropTypes.func,
    };

    render() {
        let firm = this.props.firmData;
        let disclosure = firm['disclosures'];
        if(disclosure == true) {
            disclosure = "Yes"
        } 
        else if(disclosure == false) {
            disclosure = "No"
        }

        return (<>
            <h4>Firm Details</h4>
            <Row className="ml-2">
                <Col lg={6}>
                    <dl className="row">
                        <dt className="col-sm-4">CRD</dt>
                        <dd className="col-sm-8">{firm['firm_crd']}</dd>
                        <dt className="col-sm-4">Firm Name</dt>
                        <dd className="col-sm-8">{firm['firm_name']}</dd>
                        <dt className="col-sm-4">Office Address</dt>
                        <dd className="col-sm-8">
                            {firm['office_locations'][0]['full_address']}
                            <br/>{firm['office_locations'][0]['city']}
                            <br/>{firm['office_locations'][0]['county']}
                            <br/>{firm['office_locations'][0]['state']} {firm['office_locations'][0]['zip']}
                            <br/>{firm['office_locations'][0]['country']}
                        </dd>
                        <dt className="col-sm-4">Mailing Address</dt>
                        <dd className="col-sm-8">
                            {firm['mailing_locations'][0]['full_address']}
                            <br/>{firm['mailing_locations'][0]['city']}
                            <br/>{firm['mailing_locations'][0]['county']}
                            <br/>{firm['mailing_locations'][0]['state']} {firm['mailing_locations'][0]['zip']}
                            <br/>{firm['mailing_locations'][0]['country']}
                        </dd>
                    </dl>

                </Col>

                <Col lg={6}>
                    <dl className="row">
                        <dt className="col-sm-4">Business Phone</dt>
                        <dd className="col-sm-8">{firm['business_phone']}</dd>
                        <dt className="col-sm-4">Branch Count</dt>
                        <dd className="col-sm-8">{firm['branch_count']}</dd>
                        <dt className="col-sm-4">Contact Count</dt>
                        <dd className="col-sm-8">{firm['contact_count']}</dd>
                        <dt className="col-sm-4">Disclosure Type</dt>
                        <dd className="col-sm-8">{[disclosure]}</dd>
                       </dl>
                    <DisclosuresBlock indivData={this.props.firmData} />
                    {firm['other_names'] && firm['other_names'].length &&
                        <dl>
                            <dt>Other Name(s)</dt>
                            <dd>
                                {firm['other_names'].map(name => <React.Fragment key={name}>
                                    {name.toLowerCase()}<br/>
                                </React.Fragment>)}
                            </dd>
                        </dl>
                    }
                </Col>
            </Row>

            <BranchAddressesTable firmData={this.props.firmData} />
            <IndividualsTable firmData={this.props.firmData} onModalChanged={this.props.onModalChanged} />
            <ExecutivesTable firmData={this.props.firmData} onModalChanged={this.props.onModalChanged}/>
        </>)
    }
}


class DisclosuresBlock extends React.Component {
    static propTypes = {
        indivData: PropTypes.object.isRequired,
    };

    disclosureTypes = {
        'hasregevent': 'Regulatory Event',
        'hascriminal': 'Criminal Record',
        'hasarbitration': 'Arbitration',
        'hasbond': 'Bond',
        'hasjudgment': 'Judgement',
        'hasfinancial': 'Financial',
        'hascivilevent': 'Civil Event',
    }

    render() {
        let disclosureRows = Object.keys(this.disclosureTypes)
            .filter((k) => this.props.indivData[k])
            .map((k) => {
                return (
                    <div key={k} className={'bool-attrib-active'}>
                        {this.disclosureTypes[k]}
                    </div>
                );
            });

        if (disclosureRows.length < 1) return <div></div>;

        return (
            <dl className="row">
                <dt className="col-sm-4">Disclosures</dt>
                <dd className="col-sm-8" children={disclosureRows} />
            </dl>
        )
    }
}


export default class BdFirmDetailsModal extends React.Component {
    static propTypes = {
        rowId: PropTypes.any,
        listRow: PropTypes.any,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onModalChanged: PropTypes.func,
        updateId: PropTypes.any,
        countId: PropTypes.any,
        onClickChanged: PropTypes.func,
        hideBdModel: PropTypes.any,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoadingData: true,
            firmData: null,
            prev: true,
            next: true,
            rowValues: [],
        };
        this.loadData = this.loadData.bind(this);
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.nextRecord = this.nextRecord.bind(this);
        this.previousRecord = this.previousRecord.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rowId !== this.props.rowId) {
            this.loadData();
        }
    }

    loadData() {
        this.setState({
            isLoadingData: true,
        }, () => {
            let client = new APIClient();
            return client.getBdFirmRow(this.props.rowId)
                .then(response => {
                    this.setState({
                        firmData: response.data,
                        isLoadingData: false,
                    });
                });
        });
    }

    handleIndividualDetails(id, individuals) {
        if (this.onModalChanged !== this.props.onModalChanged) {
            this.props.onClose();
            this.props.onModalChanged(id, this.state.rowValues, individuals);
        }
    }

    async keyPress(event) {
        let evenKey = event.keyCode

        if (this.props.isOpen) {
            switch (evenKey) {
                case 37:
                    this.previousRecord();
                    break;
                case 39:
                    this.nextRecord()
                    break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyPress);
    }

    nextRecord() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
        if (this.onClickChanged !== this.props.onClickChanged && currentIndex != this.props.countId - 1) {
            this.props.onClickChanged(rowList[parseInt(currentIndex) + 1].CRD);
        }
    }

    previousRecord() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
        if (this.onClickChanged !== this.props.onClickChanged && currentIndex != 0) {
            this.props.onClickChanged(rowList[parseInt(currentIndex) - 1].CRD);
        }
    }

    render() {
        let rowList = this.props.updateId;

        if (this.state.firmData) {
            let getIndiv = []
            let getExec = []
            for (const currentIndexIndividuals in this.state.firmData['individuals']) {
                getIndiv.push(this.state.firmData['individuals'][currentIndexIndividuals].indiv_crd)
            }
            for (const currentIndexExecutives in this.state.firmData['executives']) {
                getExec.push(this.state.firmData['executives'][currentIndexExecutives].indiv_crd)
            }
            this.state.rowValues = {
                indivCrd: getIndiv.sort(function (a, b) { return a - b }),
                executives: getExec.sort(function (a, b) { return a - b }),
            }
        }

        if (rowList) {

            let currentIndex = _.findIndex(rowList, (row) => row.CRD === this.props.rowId);
            currentIndex > 0 ? this.state.prev = true : this.state.prev = false;
            currentIndex != this.props.countId - 1 ? this.state.next = true : this.state.next = false;
        }

        if (this.props.listRow) {
            !this.props.listRow ? this.state.prev = true : this.state.prev = false;
            !this.props.listRow ? this.state.next = true : this.state.next = false;
        }

        return (
            <Modal isOpen={this.props.isOpen}
                toggle={this.props.onClose}
                className="agent-details-modal"
            >
                {this.state.prev &&
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltipPrev}
                    >
                        <button
                            className="c-controls prev b"
                            onClick={this.previousRecord}
                        >
                            <span class="material-icons">&#10094;</span>
                            <span class="material-icons next-btn-text">Prev</span>
                        </button>
                    </OverlayTrigger>
                }
                {this.state.next &&
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltipNext}
                    >
                        <button
                            className="c-controls next b"
                            onClick={this.nextRecord}
                        >
                            <span class="material-icons">&#10095;</span>
                            <span class="material-icons next-btn-text">Next</span>
                        </button>
                    </OverlayTrigger>
                }

                <ModalHeader>Firm Details</ModalHeader>
                <ModalBody>
                    {this.state.isLoadingData
                        ? <div className="text-center">
                            <Spinner as="span" animation="border" size="lg"/>
                        </div>
                        : <BdFirmDetailsPage firmData={this.state.firmData} onModalChanged={this.handleIndividualDetails} />
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onClose}>Done</Button>
                </ModalFooter>
            </Modal>
        );
    }
}