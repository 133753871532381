// @flow
import {
    SEARCH_FORM_CHANGED,
    SEARCH_COUNT_RETRIEVED,
    QUICKSEARCH_SUBMITTED,
    SEARCH_MERGE,
    SEARCH_UPDATE
} from './constants';


export const searchFormChanged = (formName: string, formState) => ({
    type: SEARCH_FORM_CHANGED,
    formName,
    formState,
});

export const searchCountRetrieved = (formName: string, rowCount: Number) => ({
    type: SEARCH_COUNT_RETRIEVED,
    formName,
    rowCount,
});

export const quicksearchSubmitted = (searchQuery, rowCount: Number, quicksearch) => ({
    type: QUICKSEARCH_SUBMITTED,
    formName: 'quickSearch',
    searchQuery,
    rowCount,
    quicksearch,
});

export const mergeSearch = (formData, formName) => ({
    type: SEARCH_MERGE,
    payload: formData,
    formName,
});

export const updateSearch = (formState, rowCount, formName) => ({
    type: SEARCH_UPDATE,
    formState,
    rowCount,
    formName,
});

