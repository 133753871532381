function column(field, width) {
    return {field: field, text: field, width: width}
}
// consumer data columns
const consumerDataColumns = [
    'Household Income',
    'Ethnicity',
    'Education',
    'Homeowner Status',
    'Dwelling Type',
    'Home Value',
    'Home Built Year',
    'Income',
    'Net Worth',
    'Marital Status',
    'Children',
    'Pets',
    'Premium Credit Card',
    column('Vehicles', 200),
    column('Donor', 200),
    column('Foods', 200),
    column('Collectibles', 200),
    column('Exercise', 200),
    column('Games', 200),
    column('Investments', 200),
    column('Music', 200),
    column('Reading', 200),
    column('Outdoor', 200),
    column('Spectator Sports', 200),
    column('Sports', 200),
    column('Travel', 200),
    column('Buyer', 200),
    column('Apparel', 200),
    'Nbrhood Cluster',
    'Household Cluster',
];

let allColumnNames = [
    {field: 'NPN', width: 100, fixed: 'left', sortable: true},
    {field: 'First Name', width: 120, fixed: 'left'},
    {field: 'Middle Name', width: 120, fixed: 'left'},
    {field: 'Last Name', width: 120, fixed: 'left'},
    {field: 'Agency Name', width: 200, fixed: 'left'},
    {field: 'Suffix', width: 120},
    column('Gender', 70),
    {field: 'Business Email', width: 250, resizable: true},
    {field: 'Email 1', width: 250, resizable: true},
    {field: 'Email 2', width: 250, resizable: true},
    {field: 'Email 3', width: 250, resizable: true},
    column('Business Primary Phone', 130),
    column('Phone 1', 130),
    column('Phone 2', 130),
    column('Phone 3', 130),
    column('Fax', 130),
    {field: 'Business Address', width: 200, resizable: true},
    column('Business City', 140),
    'Business State',
    'Business Zip',
    'Business County',
    {field: 'Mailing Address', width: 200, resizable: true},
    column('Mailing City', 140),
    'Mailing State',
    'Mailing Zip',
    'Mailing County',
    {field: 'Home Address', width: 200, resizable: true},
    column('Home City', 140),
    'Home State',
    'Home Zip',
    'Home County',
    // the same address fields for Agencies, have more generic names
    { field: 'Business Address', text: 'Address1', width: 200, resizable: true },
    { field: 'Business City', text: 'Address1 City', width: 140 },
    { field: 'Business State', text: 'Address1 State' },
    { field: 'Business Zip', text: 'Address1 Zip' },
    { field: 'Mailing Address', text: 'Address2', width: 200, resizable: true },
    { field: 'Mailing City', text: 'Address2 City', width: 140 },
    { field: 'Mailing State', text: 'Address2 State' },
    { field: 'Mailing Zip', text: 'Address2 Zip' },
    { field: 'Home Address', text: 'Address3', width: 200, resizable: true },
    { field: 'Home City', text: 'Address3 City', width: 140 },
    { field: 'Home State', text: 'Address3 State' },
    { field: 'Home Zip', text: 'Address3 Zip' },

    'Date Of Birth',
    column('Age', 60),
    'First License Date',
    'Years Licensed',
    'Licensed in # states',
    column('Total LOA count', 100),
    column('Total Appointment count', 110),
    'Holds Life License',
    'Holds Life License in # states',
    'Holds Health License',
    'Holds Health License in # states',
    'Holds Property License',
    'Holds Property License in # states',
    'Holds Casualty License',
    'Holds Casualty License in # states',
    'Holds Adjuster License',
    'Holds Adjuster License in # states',
    'Holds Accident License',
    'Holds Accident License in # states',
    'Holds Variable Licensed License',
    'Holds Variable Licensed License in # states',
    'Holds Annuity License',
    'Holds Annuity License in # states',
    'Holds Surplus Lines License',
    'Holds Surplus Lines License in # states',
    'Holds Viatical / Life Settlement License',
    'Holds Viatical / Life Settlement License in # states',
    'Holds Limited Lines - Credit License',
    'Holds Limited Lines - Credit License in # states',
    'Holds Reinsurance License',
    'Holds Reinsurance License in # states',
    'Holds Surety License',
    'Holds Surety License in # states',
    'Holds Limited Lines - Title License',
    'Holds Limited Lines - Title License in # states',
    'Holds Limited Lines - Travel License',
    'Holds Limited Lines - Travel License in # states',
    'Holds Other License',
    'Holds Other License in # states',
];

// add all the LOA columns
for (let i=1; i<11; i++) {
    allColumnNames.push(...[
        `License State ${i}`,
        `License Type ${i}`,
        column(`LOA Name ${i}`, 200),
        column(`LOA Category ${i}`, 150),
        `License Start Date ${i}`,
        `License Expiration Date ${i}`,
    ])
}

// and all the Carrier columns
for (let i=1; i<11; i++) {
    allColumnNames.push(...[
        column(`Carrier Name ${i}`, 250),
        `Carrier NAIC ${i}`,
        column(`Carrier Group ${i}`, 250),
        `Carrier Start Date ${i}`,
        `Carrier End Date ${i}`,
    ])
}

allColumnNames = [...allColumnNames, ...consumerDataColumns];

// export a list suitable for the datatable, eg every one needs a "field" and "text"
let allColumns = [];
for (let i=0; i<allColumnNames.length; i++) {
    let col = allColumnNames[i];
    if (typeof col === 'string') {
        allColumns.push({ field: col, text: col, width: 100 });
    } else {
        if (!('text' in col)) {
            col['text'] = col['field'];
        }
        if (!('width' in col)) {
            col['width'] = 100;
        }
        allColumns.push(col);
    }
}

export default allColumns;