// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';
import DesignationTypes from '../otherBusinessActivities';

const IndividualotherbusinessActivities = ({ formName }) => {
    const dispatch = useDispatch();
    const types = useSelector((state) => state.FirstClassData.formStates[formName].formState.otherbusiness_types);

    const [otherbusiness_types, canAddAnotherotherbusinessClause] = useMemo(() => {
        const canAddAnotherotherbusinessClause = types.filter(row => {
                    return row.types.length === 0;
                }).length === 0;
        return [types, canAddAnotherotherbusinessClause];
    }, [types]);

    const onFieldChange = (event, index) => {
        let otherbusinessTypes = otherbusiness_types.slice();
        otherbusinessTypes[index][event.target.name] = event.target.value;
        otherbusinessTypes[index]['label'] = event.target.label;
        dispatch(mergeSearch({ otherbusiness_types: otherbusinessTypes }, formName));
    };

    const onChange = (event, index) => {
        let otherbusinessTypes = otherbusiness_types.slice();
        otherbusinessTypes[index][event.target.name] = event.target.label;
        otherbusinessTypes[index]['otherbusiness_types_bool'] = event.target.value;
        dispatch(mergeSearch({ otherbusiness_types: otherbusinessTypes }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let otherbusinessTypes = otherbusiness_types.slice();
        otherbusinessTypes[index].exclude = !exclude;
        dispatch(mergeSearch({ otherbusiness_types: otherbusinessTypes }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let otherbusinessTypes = otherbusiness_types.slice();
        otherbusinessTypes.splice(index, 1);
        dispatch(mergeSearch({ otherbusiness_types: otherbusinessTypes }, formName));
    };

    const handleAddotherbusinessClause = () => {
        let otherbusinessTypes = otherbusiness_types.slice();
        otherbusinessTypes.push({
            exclude: false,
            otherbusiness_types_bool: 'or',
            types: []
        });
        dispatch(mergeSearch({ otherbusiness_types: otherbusinessTypes }, formName));
    };

    return (
        <Col lg={24}>
            {otherbusiness_types.map((otherbusinessTypesClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(otherbusinessTypesClause.exclude, index)}
                        >
                            {otherbusinessTypesClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these Other Business Activities:

                        {otherbusiness_types.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Other Business Activities</Label>
                                    <DesignationTypes name="types" multi={true}
                                                  value={otherbusinessTypesClause.types}
                                                  onChange={(event) => onFieldChange(event, index)} />

                                    {otherbusinessTypesClause.types.length > 1 &&
                                    <div className="form-inline flex-row-reverse pt-1">
                                        <p>
                                            Search for customers has &nbsp;
                                            <Input type="select"
                                                   name="otherbusiness_types_bool"
                                                   bsSize="sm"
                                                   value={otherbusinessTypesClause.otherbusiness_types_bool}
                                                   onChange={(event) => onChange(event, index)}>
                                                <option value="and">all of these other business</option>
                                                <option value="or">any of these other business</option>
                                            </Input>
                                        </p>
                                    </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
            {canAddAnotherotherbusinessClause && (
                <Button
                    className="p-0 ml-3"
                    color="link"
                    onClick={handleAddotherbusinessClause}>
                    + Add another Other Business Activities clause
                </Button>
            )}
        </Col>
    );
};

export default IndividualotherbusinessActivities;