// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import PageTitle from '../components/PageTitle';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';
import { insuranceAgentReportTypes } from '../constants/reportTypes';
import { loggedInUserHasPermission } from '../helpers/authUtils';


class AgentSearchResultsPage extends React.Component {
    static propTypes = {
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
    };

    render() {
        const searchFormProps = this.props.allFormStates.agentSearch;
        const reportTypes = insuranceAgentReportTypes.filter(report => loggedInUserHasPermission(report.permission));
        return <>
            <PageTitle title="<< Back to Agent Search" className="btn"
                    onClick={() => this.props.history.push("/agent/search")} />

            <PaginatedSearchResultsTable
                searchFormState={searchFormProps.formState}
                reportTypes={reportTypes}
                recordCount={searchFormProps.rowCount}
                enableSaving={true}
            />
        </>
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
});

export default connect(mapStateToProps)(AgentSearchResultsPage)