let allColumnNames = [
    // Executive columns
    {field: 'Exec CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'First Name', width: 150, fixed: 'left'},
    {field: 'Middle Name', width: 150, fixed: 'left'},
    {field: 'Last Name', width: 150, fixed: 'left'},
    {field: 'Suffix Name', width: 150, resizable: true},
    'Gender',
    'Firm CRD',
    {field: 'Firm Name', width: 250, resizable: true},
    {field: 'RIA Main Office Phone', width: 250, resizable: true},
    'RIA Main Office Phone Ext',
    'Execs Insurance Business Phone',
    'Execs Insurance Business Phone Ext',
    'Execs Business Home Phone',
    {field: 'Primary Email Address', width: 250, resizable: true},
    {field: 'Primary Email Domain', width: 250, resizable: true},
    'Alternate Sourced Email Indicator',
    {field: 'Secondary Email Address', width: 250, resizable: true},
    {field: 'Secondary Email Domain', width: 250, resizable: true},
    {field: 'Alternate Email Address', width: 250, resizable: true},
    'Alternate Email Domain',
    {field: 'Business Personal Email Address', width: 250, resizable: true},
    'Business Personal Email Domain',
    {field: 'Business Personal Email Address', width: 250, resizable: true},
    'Business Personal Email Domain',
    'Earliest Financial Svs Date',
    {field: 'Corporate Title or Role', width: 250, resizable: true},
    'A_C Level or SR Mgmt',
    'A_Middle Mgm',
    'A__First Level Mgmt or Other',
    'B_Financial Ops',
    'B_Investment Research',
    'B_Marketing Sales',
    'B_Technology',
    'Investment Advisor Rep',
    'Registered Rep',
    'Insurance Agent',
    {field: 'IA SEC Profile Link', width: 450, resizable: true},
    {field: 'LinkedIn Profile', width: 400, resizable: true},
];

// export a list suitable for the datatable, e.g., every one needs a "field" and "text"
let allColumns = [];
for (let i=0; i<allColumnNames.length; i++) {
    let col = allColumnNames[i];
    if (typeof col === 'string') {
        allColumns.push({ field: col, text: col, width: 100 });
    } else {
        if (!('text' in col)) {
            col['text'] = col['field'];
        }
        if (!('width' in col)) {
            col['width'] = 100;
        }
        allColumns.push(col);
    }
}

export default allColumns;