import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Label,
    Button,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import {PAGE_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import { BdSearchState } from '../redux/firstClassData/dtos';
import APIClient from '../utils/API';
import ContactInfoSearchFields from './SearchFormCards/ContactInfoSearchFields';
import AssetRangeSlider from './AssetRangeSlider';
import { bdFirmBranchCount, bdFirmContactCount } from '../constants/riaAssetSliderAmounts';
import IndividualDisclosureTypeSelect from './SearchFormCards/IndividualDisclosureTypeSelect';
import IndividualTitleSelect from './SearchFormCards/IndividualExecutiveTitleSelect';

export default class BDFirmSearchForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        formState: PropTypes.instanceOf(BdSearchState).isRequired,
    };

    constructor(props) {
        super(props);
        this.apiClient = new APIClient();

        this.getTypeaheadOptions = this.getTypeaheadOptions.bind(this);
        this.formatCsv = this.formatCsv.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    getTypeaheadOptions = (fieldName, inputValue, callback) => {
        this.apiClient.getTypeaheadOptions(fieldName, inputValue)
            .then(response => {
                callback(response.data.results);
            });
    };

    formatCsv(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];

        this.props.onFieldChange({
            target: {
                name: fieldName,
                value: selectedValue,
            }
        });
    }

    handleSliderChange(name, value) {
        this.props.onFieldChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    render() {
        let formState = this.props.formState;
        let selectedFirmNames = formState.firm_names.map(firmName => {
            return {value: firmName, label: firmName};
        });
        let hasPermission = this.props.hasPermission

        return (
            <>
        <Form onSubmit={this.props.onSubmit} className={ !hasPermission ? "permission-card-section restricted" : "" } >
            <Card >
                <CardBody>
                    <h4 className="header-title">Firm Details</h4>
                    <Row className="mb-2">
                        <Col lg={12}>
                            <Row>
                                <Col lg={8}>

                                    <FormGroup>
                                        <AsyncSelect
                                            placeholder="Firm Names"
                                            isMulti
                                            value={selectedFirmNames|| null}
                                            isClearable
                                            defaultOptions
                                            cacheOptions
                                            loadOptions={(inputValue, callback) => this.getTypeaheadOptions('bd_firm', inputValue, callback)}
                                            onChange={value => this.handleSelectChange('firm_names', value)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Input type="text" name="crds" value={this.formatCsv(formState.crds)}
                                            onChange={this.props.onFieldChange} placeholder="CRD(s)" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <ContactInfoSearchFields formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} showAddressTypeCheckboxes={true} />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                <IndividualTitleSelect formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} />
                <Row className="disclosure-type-selection">
                        <Col>
                            <IndividualDisclosureTypeSelect formName={this.props.formName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={9}>
                            <FormGroup>
                                <Label>Disclosure</Label>
                                <Input type="select" name="disclosure" id="disclosure"
                                    value={formState.disclosure} data-ignore={true} onChange={this.props.onFieldChange}>
                                    <option value="">----</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={9}>
                            <Label className="col-sm-4">Branch Count</Label>
                            <AssetRangeSlider
                                value={formState.branch_count_range}
                                values={bdFirmBranchCount.values} labels={bdFirmBranchCount.labels}
                                onChange={value => this.handleSliderChange('branch_count_range', value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={9}>
                            <Label className="col-sm-4">Contact Count</Label>
                            <AssetRangeSlider
                                value={formState.contact_count_range}
                                values={bdFirmContactCount.values} labels={bdFirmContactCount.labels}
                                onChange={value => this.handleSliderChange('contact_count_range', value)}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Form>
    { !hasPermission ?
            <div className="permission-card">
            <div className='card permission'>
                <h6 className="permission-header">{PAGE_RESTRICTING_MESSAGE}</h6>
            </div>
        </div>:""
    }
        </>
        );
    }
}
