import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
const examCode = [
            { value: 'S63', label: 'S63 - UNIFORM SECURITIES AGENT STATE LAW EXAMINATION' },
            { value: 'S65', label: 'S65 - UNIFORM INVESTMENT ADVISER LAW EXAMINATION' },
            { value: 'S66', label: 'S66 - UNIFORM COMBINED STATE LAW EXAMINATION' }
];
export default class ExamCodeSearch extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideStates: PropTypes.array,
    };
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(selectedOptions) {
        let selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        this.props.onChange({
            target: {
                name: "exam_codes",
                value: selectedValue,
            }
        });
    }
    render() {
        let selectedExamcode = this.props.formState.exam_codes
        .map(code => {
            return { value: code, label: code };
            });
        return (
            <Col lg={13}>
                <Card className="my-1 license-groups-card-border">
                    <CardHeader className="bg-light">
                        &nbsp;Search records based on exam codes:
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Exam Codes</Label>
                                    <Select
            value={selectedExamcode}
            isMulti={true}
            options={examCode}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={'Select exams(s)...'}
        />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
        </Col> 
        )
        
         }
    }