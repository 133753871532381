// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';


type PageTitleProps = {
    breadCrumbItems: PropTypes.object,
    title: string,
    className: string,
    onClick: PropTypes.func,
};

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {
    return (
        <Row>
            <Col>
                <div className="page-title-box">
                    <div className="page-title-right">
                    </div>
                    <h4 className={"page-title " + props.className} onClick={props.onClick}>{props.title}</h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
