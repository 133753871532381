// @flow
import React from 'react';
import RiaFirmSearchForm from '../components/RiaFirmSearchForm';
import { RiaFirmSearchState } from '../redux/firstClassData/dtos';
import SearchPage from './SearchPage';


export default class RiaFirmSearch extends React.Component {

    render() {
        return <SearchPage pageTitle="RIA Firm Search" formName="riaFirmSearch" searchFormStateClass={RiaFirmSearchState} searchFormComponent={RiaFirmSearchForm} />
    }
}
