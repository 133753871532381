// @flow
import React from 'react';
import {
    Col,
    Row,
} from 'reactstrap';
import ConsumerDataSelect from './ConsumerDataSelect';
import PropTypes from 'prop-types';
import { consumerDataSearchFields } from '../../constants/agentSearchFields';
import { loggedInUserHasPermission } from '../../helpers/authUtils';
import { PAGE_RESTRICTING_MESSAGE } from '../../redux/layout/constants';

const riaFirmsSearchFields = [
    'compensation',
    'advisory_services',
    'other_business',
    'affiliations',
    'ria_types',
    'discretion_over',
    'referral_compensation',
    'terminated']

const insuranceForms = ['groupSearch', 'agentSearch']
const riaFirmForm = ['riaFirmSearch']
const excludeOptions = ['gender',
                        'ethnicity',
                        'income',
                        'compensation',
                        'advisory_services',
                        'other_business',
                        'affiliations',
                        'ria_types',
                        'discretion_over',
                        'referral_compensation',
                        'terminated'
                        ]

export default class ConsumerDataFiltersFields extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    filterFieldsByRole = field => {

        if (excludeOptions.includes(field.searchFieldName)) return

        if (loggedInUserHasPermission('search_search_nbrhood_household_cluster')) {
            return field
        }
        if (field.searchFieldName === 'neighborhood_cluster') return
        if (field.searchFieldName === 'household_cluster') return
        return field
    }

    riaFirmsFields = field => {
        if(riaFirmsSearchFields.includes(field.searchFieldName)) {
            return field;
        }
        return ;
    }

    render() {
        const formState = this.props.formState;
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');

        return <>
            {insuranceForms.includes(formState.search_type) &&
                <Row>
                    <h4 className="header-title">Consumer Data Filters</h4>
                    <Col lg={12}>
                        {insuranceForms.includes(formState.search_type) &&
                            <Row>
                                <Col lg={6}>
                                    {consumerDataSearchFields.filter(field => this.filterFieldsByRole(field)).map((field, index) => (
                                        <Row>
                                            <Col>
                                                {index %2 ?
                                                (<ConsumerDataSelect
                                                    key={`ConsumerDataSelect-${field.searchFieldName}`}
                                                    onChange={this.props.onChange}
                                                    formState={formState}
                                                    {...field}
                                                />)
                                                :<></>}
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                                <Col lg={6}>
                                    {consumerDataSearchFields.filter(field => this.filterFieldsByRole(field)).map((field, index) => (
                                        <Row>
                                            <Col lg={12}>
                                                {index %2 ?
                                                <></>
                                                :(<ConsumerDataSelect
                                                    key={`ConsumerDataSelect-${field.searchFieldName}`}
                                                    onChange={this.props.onChange}
                                                    formState={formState}
                                                    {...field}
                                                />)}
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            }
            {riaFirmForm.includes(formState.search_type) &&
                <Row>
                    <h4 className="header-title">Additional Data Filters</h4>
                    <Col lg={12}>
                        {riaFirmForm.includes(formState.search_type) && 
                            <Row>
                                <Col lg={6}>
                                    {consumerDataSearchFields.filter(field => this.riaFirmsFields(field)).map((field, index) => (
                                        <Row>
                                            <Col lg={12}>
                                                {index %2 ?
                                                <></>:
                                                (<ConsumerDataSelect
                                                    key={`ConsumerDataSelect-${field.searchFieldName}`}
                                                    onChange={this.props.onChange}
                                                    formState={formState}
                                                    {...field}
                                                />)}
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                                <Col lg={6}>
                                    {consumerDataSearchFields.filter(field => this.riaFirmsFields(field)).map((field, index) => (
                                        <Row>
                                        <Col lg={12}>
                                            {index %2 ?
                                            (<ConsumerDataSelect
                                                key={`ConsumerDataSelect-${field.searchFieldName}`}
                                                onChange={this.props.onChange}
                                                formState={formState}
                                                {...field}
                                            />):<></>}
                                        </Col>
                                    </Row>
                                    ))}
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            }
        </>
    }
}
