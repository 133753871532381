import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner
} from 'reactstrap';
import { Column, HeaderCell, Cell } from 'rsuite-table';

import APIClient from '../utils/API';
import { loggedInUserHasPermission } from '../helpers/authUtils';
import fieldLookups from '../constants/fieldLookups';
import SortableRsuiteTable from './SortableRsuiteTable';
import EmailLevelIcon from './EmailLevelIcon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import _ from "lodash";
import {  renderTooltipPrev,  renderTooltipNext } from '../helpers/navigate';


const monthNames = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
}

const Emails = ({ emails }) => {
    return (!!emails?.length &&
            <>
                <div class="row" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                    <dt className="col-sm-4">Emails</dt>
                    <dd className="col-sm-8">
                        {emails.map((email, i) => {
                        if (email.email_status <= 2 && email.email_status != '' && email.captive_company_priority <= 1) {
                            // Make the email address as clickable
                            let email_link = "mailto:" + email?.email
                            return (
                                <>
                                    <div >
                                        {/* Show the email address verification status icon */}
                                        <EmailLevelIcon level={email.email_status} key={i}/>
                                            <a href={email_link} target="_blank" rel="noopener noreferrer">{email?.email.toLowerCase()}</a>
                                        <br />
                                    </div>
                                </> 
                                )
                        }
                        })}
                    </dd>
                </div>
            </>
            )
        }
class AgentDetailsPage extends React.Component {
    static propTypes = {
        agentData: PropTypes.object.isRequired,
    };

    render() {
        let eamail_list = this.props.agentData['emails'];
        // let intArray = []

        let captiveCompanies = []
        let removecaptive = []
        for (let data of eamail_list){
            if(parseInt(data.captive_company_priority) < 2 && data.captive_company != ""){
                captiveCompanies.push(data.captive_company)
            }
        }
        let dobString = '';
        if (this.props.agentData['dob']) {
            try {
                // expect yyyy-mm-dd, but the dd is always 01
                let parts = this.props.agentData['dob'].split('-');
                dobString = monthNames[parts[1]] + ' ' + parts[0];
            } catch {
                // if the dob isn't formatted correctly, ignore
            }
        }

        let domicilestate = this.props.agentData['domicilestate'];
        let agency_name = this.props.agentData['agency_name'];
        let uniqueCaptiveCompanies = [...new Set(captiveCompanies)];
        let emailValue = this.props.agentData['emails'].map((email) => (email.email_status <= 2 && email.email_status != ''))

        return (<>
            <h4>Personal Details</h4>
            <Row className="ml-2">
                <Col lg={6}>
                    <dl className="row">
                        <dt className="col-sm-4">NPN</dt>
                        <dd className="col-sm-8">{this.props.agentData['npn']}</dd>
                        <dt className="col-sm-4">First Name</dt>
                        <dd className="col-sm-8">{this.props.agentData['first_name']}</dd>
                        {this.props.agentData['middle_name'] && <>
                            <dt className="col-sm-4">Middle Name</dt>
                            <dd className="col-sm-8">{this.props.agentData['middle_name']}</dd>
                        </>}
                        <dt className="col-sm-4">Last Name</dt>
                        <dd className="col-sm-8">{this.props.agentData['last_name']}</dd>
                        {this.props.agentData['suffix'] && <>
                            <dt className="col-sm-4">Suffix</dt>
                            <dd className="col-sm-8">{this.props.agentData['suffix']}</dd>
                        </>}
                        {this.props.agentData['gender'] && <>
                            <dt className="col-sm-4">Gender</dt>
                            <dd className="col-sm-8">{this.props.agentData['gender']}</dd>
                        </>}
                        {dobString && <>
                            <dt className="col-sm-4">DOB</dt>
                            <dd className="col-sm-8">{dobString}</dd>
                        </>}
                        {domicilestate && <>
                            <dt className="col-sm-4">Domicile State</dt>
                            <dd className="col-sm-8">{domicilestate}</dd>
                        </>}
                        <dt className="col-sm-4">Licensed in</dt>
                        <dd className="col-sm-8">{this.props.agentData['loa_counts_by_state'].length} states</dd>
                        {uniqueCaptiveCompanies.length > 0 && <>
                            <dt className="col-sm-4">Captured by</dt>
                            <dd className="col-sm-8">{uniqueCaptiveCompanies.join(', ')}</dd>
                        </>}
                        {agency_name && <>
                            <dt className="col-sm-4">Agency Name</dt>
                            <dd className="col-sm-8">{agency_name}</dd>
                        </>}
                    </dl>
                </Col>

                <Col lg={6}>
                    <PhonesList agentData={this.props.agentData} />
                    {emailValue.includes(true) &&
                    <Emails emails={this.props.agentData?.emails} />
                    }
                </Col>
            </Row>

            <AddressTable agentData={this.props.agentData} />

            <LOATable agentData={this.props.agentData} />

            <AppointmentTable agentData={this.props.agentData} />

            <ConsumerDataSection agentData={this.props.agentData} />
        </>)
    }
}

class PhonesList extends React.Component {
    static propTypes = {
        agentData: PropTypes.object.isRequired,
    };

    render() {
        let phones = this.props.agentData['phones']
        let phonesByCategory = {};
        for (let i=0; i<phones.length; i++) {
            let phone = phones[i];
            let label = 'Phone';
            if (phone['is_fax']) {
                label = 'Fax';
            } else if (phone['is_business'] && !phone['is_home']) {
                label = 'Business Phone';
            } else if (phone['is_home'] && !phone['is_business']) {
                label = 'Home Phone';
            }

            if (!(label in phonesByCategory)) {
                phonesByCategory[label] = [];
            }
            phonesByCategory[label].push(phone['phone']);
        }

        let phoneCats = Object.keys(phonesByCategory).sort();
        let phoneElements = [];

        for (let i=0; i<phoneCats.length; i++) {
            let phoneCat = phoneCats[i];
            let phones = phonesByCategory[phoneCat];

            phoneElements.push(<dt className="col-sm-4" key={phoneCat}>{phoneCat}</dt>);
            phoneElements.push(
                <dd className="col-sm-8" key={`${phoneCat}-phones`}>
                    {phones.map(phone => <React.Fragment key={phone}>
                        {phone}<br/>
                    </React.Fragment>)}
                </dd>
            );
        }

        return (
            <dl className="row" children={phoneElements}>
            </dl>
        )
    }
}

class AddressTable extends React.Component {
    static propTypes = {
        agentData: PropTypes.object.isRequired,
    };

    render() {
        let addressRows = this.props.agentData['addresses'];

        // if there are no "entity names" in any row, don't show that column
        let anyRowsHaveEntity = addressRows.some(row => !!row['entity_name']);

        // if we have any addresses with a full_address, then don't show addresses without
        let addressesWithFullAddress = addressRows.filter(row => !!row['full_address']);
        if (addressesWithFullAddress.length > 0) {
            addressRows = addressesWithFullAddress;
        }

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Addresses</h4>
                </Col>
            </Row>
            <SortableRsuiteTable
                data={addressRows}
                defaultSortColumn='license_state'
                defaultSortDirection='asc'>

                {anyRowsHaveEntity &&
                    <Column width={150} sortable resizable>
                        <HeaderCell>Entity Name</HeaderCell>
                        <Cell dataKey="entity_name" />
                    </Column>
                }
                <Column width={250} sortable resizable>
                    <HeaderCell>Address</HeaderCell>
                    <Cell dataKey="full_address" />
                </Column>
                <Column width={150} sortable resizable>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column width={120} sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="state" />
                </Column>
                <Column width={160} sortable>
                    <HeaderCell>Zip</HeaderCell>
                    <Cell dataKey="zip" />
                </Column>
                <Column width={70} fixed="right">
                    <HeaderCell>Type</HeaderCell>

                    <Cell>{rowData => <>
                        {rowData.is_home && <i className="uil uil-home" title="Residential"></i>}
                        {rowData.is_business && <i className="uil uil-building" title="Business"></i>}
                        {rowData.is_mailing && <i className="uil uil-envelope" title="Mailing"></i>}
                    </>
                    }</Cell>
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}

class LOATable extends React.Component {
    static propTypes = {
        agentData: PropTypes.object.isRequired,
    };

    render() {
        let loaRows = this.props.agentData['loas']
        // only show LOA rows that have a valid "LOA" name
        loaRows = loaRows.filter(row => row['loa']);

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Lines of Authority</h4>
                </Col>
                <Col className="text-right align-self-end pb-1">
                    {loaRows.length.toLocaleString()} total
                </Col>
            </Row>
            <SortableRsuiteTable
                data={loaRows}
                defaultSortColumn='license_state'
                defaultSortDirection='asc'>

                <Column width={150} sortable resizable>
                    <HeaderCell>License Type</HeaderCell>
                    <Cell dataKey="license_type" />
                </Column>
                <Column width={250} sortable resizable>
                    <HeaderCell>LOA</HeaderCell>
                    <Cell dataKey="loa" />
                </Column>
                <Column width={120} sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="license_state" />
                </Column>
                <Column width={120}>
                    <HeaderCell>Start Date</HeaderCell>
                    <Cell dataKey="start_date" />
                </Column>
                <Column width={160}>
                    <HeaderCell>Expiration Date</HeaderCell>
                    <Cell dataKey="exp_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class AppointmentTable extends React.Component {
    static propTypes = {
        agentData: PropTypes.object.isRequired,
    };

    render() {
        let apptRows = this.props.agentData['appointments'];

        return (<>
            <Row className="mt-2">
                <Col>
                    <h4>Carrier Appointments</h4>
                </Col>
                <Col className="text-right align-self-end pb-1">
                    {apptRows.length.toLocaleString()} total
                </Col>
            </Row>
            <SortableRsuiteTable
                data={apptRows}
                defaultSortColumn='company_name'
                defaultSortDirection='asc'>

                <Column width={250} sortable resizable>
                    <HeaderCell>Carrier</HeaderCell>
                    <Cell dataKey="company_name" />
                </Column>
                <Column width={70} sortable>
                    <HeaderCell>NAIC</HeaderCell>
                    <Cell dataKey="naic" />
                </Column>
                <Column width={250} sortable resizable>
                    <HeaderCell>Carrier Group</HeaderCell>
                    <Cell dataKey="carrier_group_name" />
                </Column>
                <Column width={120} sortable>
                    <HeaderCell>Start Date</HeaderCell>
                    <Cell dataKey="start_date" />
                </Column>
                <Column width={160}>
                    <HeaderCell>Expiration Date</HeaderCell>
                    <Cell dataKey="exp_date" />
                </Column>
            </SortableRsuiteTable>
        </>)
    }
}


class ConsumerDataSection extends React.Component {
    static propTypes = {
        agentData: PropTypes.object.isRequired,
    };

    render() {
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');

        const consumerdataFieldGroupings = {
            'Family': [
                'Ethnicity', 'Marital Status', 'Children', 'Pets'
            ],
            'Financial': [
                'Income', 'Net Worth', 'Premium Credit Card', 'Cc'
            ],
            'Home': [
                'Homeowner Status', 'Home Value', 'Home Built Year', 'Dwelling Type'
            ],
            'Interests': [
                'Vehicles', 'Donor', 'Foods', 'Collectibles', 'Exercise', 'Games', 'Investments', 
                'Music', 'Reading', 'Outdoor', 'Spectator Sports', 'Sports', 'Travel', 'Buyer', 'Apparel', 'Lifestyle'
            ],
            'Cluster': [
                'Nbrhood Cluster', 'Household Cluster'
            ]
        };

        const {consumerdata={}} = this.props.agentData;

        const getConsumerDataValues = field => {
            const value = (consumerdata[field] || '').split(', ');
            return value.map(v =>fieldLookups[field][v] || v).join(', ');
        };

        return (<>
            {hasConsumerDataSearchPermission && <>
                <h4>Consumer Data Details</h4>
                {Object.keys(consumerdataFieldGroupings).map(group => (<React.Fragment key={group}>
                    <h5>{group}</h5>
                    <Row className="ml-2">
                        <Col lg={12}>
                            <dl className="row">
                                {consumerdataFieldGroupings[group].map((field, index) => {
                                    const val = getConsumerDataValues(field);
                                    return (<React.Fragment key={`${index}-${field}`}>
                                        {val && <>
                                            <dt className="col-sm-4">{field}</dt>
                                            <dd className="col-sm-8">{val}</dd>
                                        </>}
                                    </React.Fragment>);
                                })}
                            </dl>
                        </Col>
                    </Row>
                </React.Fragment>))}
            </>}
        </>)
    }
}


export default class AgentDetailsModal extends React.Component {
    static propTypes = {
        rowId: PropTypes.any,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        updateId: PropTypes.any,
        countId: PropTypes.any,
        onClickChanged: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoadingData: true,
            agentData: null,
            prev: true,
            next: true,
        };
        this.loadAgent = this.loadAgent.bind(this);
        this.nextRecord = this.nextRecord.bind(this);
        this.previousRecord = this.previousRecord.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.rowId && prevProps.rowId !== this.props.rowId) {
            this.loadAgent();
        }
    }

    loadAgent() {
        this.setState({
            isLoadingData: true,
        }, () => {
            let client = new APIClient();
            return client.getAgentRow(this.props.rowId, this.props.zipCode)
                .then(response => {
                    this.setState({
                        agentData: response.data,
                        isLoadingData: false,
                    });
                });
        });
    }

    async keyPress(event) {
        let evenKey = event.keyCode

        switch (evenKey) {
            case 37:
                this.previousRecord();
                break;
            case 39:
                this.nextRecord()
                break;
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyPress);
    }

    nextRecord() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.id === this.props.rowId);
        if (this.onClickChanged !== this.props.onClickChanged && currentIndex != this.props.countId - 1) {
            this.props.onClickChanged(rowList[parseInt(currentIndex) + 1].id);
        }
    }

    previousRecord() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.id === this.props.rowId);
        if (this.onClickChanged !== this.props.onClickChanged && currentIndex != 0) {
            this.props.onClickChanged(rowList[parseInt(currentIndex) - 1].id);
        }
    }

    render() {
        let rowList = this.props.updateId;

        let currentIndex = _.findIndex(rowList, (row) => row.id === this.props.rowId);
        currentIndex > 0 ? this.state.prev = true : this.state.prev = false;
        currentIndex != this.props.countId - 1 ? this.state.next = true : this.state.next = false;

        return (
            <Modal isOpen={this.props.isOpen}
                toggle={this.props.onClose}
                className="agent-details-modal"
            >
                {this.state.prev &&
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltipPrev}
                    >
                        <button
                            className="c-controls prev b"
                            onClick={this.previousRecord}
                        >
                            <span class="material-icons">&#10094;</span>
                            <span class="material-icons next-btn-text">Prev</span>
                        </button>
                    </OverlayTrigger>
                }
                {this.state.next &&
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltipNext}
                    >
                        <button
                            className="c-controls next b"
                            onClick={this.nextRecord}
                        >
                            <span class="material-icons">&#10095;</span>
                            <span class="material-icons next-btn-text">Next</span>
                        </button>
                    </OverlayTrigger>
                }

                <ModalHeader>Agent Details</ModalHeader>
                <ModalBody>
                    {this.state.isLoadingData
                        ? <div className="text-center">
                            <Spinner as="span" animation="border" size="lg"/>
                        </div>
                        : <AgentDetailsPage agentData={this.state.agentData} />
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onClose}>Done</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

