// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { SearchFormState, RiaFirmSearchState, IarSearchState,RrSearchState,BdSearchState} from '../redux/firstClassData/dtos';
import fieldLookups from '../constants/fieldLookups';
import { consumerDataSearchFields } from '../constants/agentSearchFields';
import riaAssetTypes from '../constants/riaAssetTypes';
import riaEmployeeRepCountTypes from '../constants/riaEmployeeRepCountTypes';
import rrYearEmployed from '../constants/rrYearEmployed'
import findColumnWithValue from '../utils/findColumnWithValue';
import renderDollars from '../utils/renderDollars';
import { advisoryTypes, feeTypes, activityTypes } from '../components/RiaFirmDetailsModal';




class ParamsList extends React.Component {
    static propTypes = {
        searchParams: PropTypes.array.isRequired,
        allowUnset: PropTypes.bool,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    onDeleteItem(item) {
        let fields = Array.isArray(item.field) ? item.field : [item.field];
        fields.forEach(field => {
            this.props.onChange({
                target: {
                    name: field,
                    value: item.delValue,
                }
            });
        });
    }

    render() {
        return (
            <ul>
                {this.props.searchParams.map((item, i) =>
                    <li key={i} className={item.className}>
                        {this.props.allowUnset && item.hasOwnProperty('delValue') &&
                            <>
                                <span className="delete" onClick={() => this.onDeleteItem(item)}>✖</span>&nbsp;&nbsp;
                            </>
                        }
                        <span className="label">{item.label}</span>
                        {item.children &&
                            <ParamsList searchParams={item.children}
                                onChange={this.props.onChange} allowUnset={this.props.allowUnset} />
                        }
                    </li>
                )}
            </ul>
        );
    }
}


export default class SearchParamsListAndUnset extends React.Component {
    static propTypes = {
        allowUnset: PropTypes.bool,
        onChange: PropTypes.func,
        formState: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.buildActiveSearchParamsLabels = this.buildActiveSearchParamsLabels.bind(this);
        this.buildInsuranceActiveSearchParamsLabels = this.buildInsuranceActiveSearchParamsLabels.bind(this);
        this.buildRiaFirmActiveSearchParamsLabels = this.buildRiaFirmActiveSearchParamsLabels.bind(this);
         this.buildIarActiveSearchParamsLabels = this.buildIarActiveSearchParamsLabels.bind(this);
        this.buildBDFirmActiveSearchParamsLabels = this.buildBDFirmActiveSearchParamsLabels.bind(this);
        this.buildRRIndivActiveSearchParamsLabels = this.buildRRIndivActiveSearchParamsLabels.bind(this);
        this.addIndividualPiiLabels = this.addIndividualPiiLabels.bind(this);
        this.addCRDLabels = this.addCRDLabels.bind(this);
    }

    thousandDollars(amount) {
        let thousands = Math.round(amount * 0.001);
        return `${thousands}K`;
    }

    incomeRange(range, maxValue) {
        let max = range[1] >= maxValue ? this.thousandDollars(maxValue) + '+' : this.thousandDollars(range[1]);
        let min = this.thousandDollars(range[0]);

        if (range[0] >= 1 || range[1] < maxValue) {
            return range[0] === 0 ? `less than $${max}`
                : (range[1] === maxValue ? `more than $${min}` : `in between $${min} and $${max}`);
        }
        return ''
    }
    
    buildActiveSearchParamsLabels() {
        let formState = this.props.formState;

        if (formState.search_type === 'riaFirmSearch') {
            return this.buildRiaFirmActiveSearchParamsLabels();

        } else if (formState.search_type === 'riaIndivSearch') {
            return this.buildIarActiveSearchParamsLabels();

        } else if (formState.search_type === 'bdFirmSearch') {
            return this.buildBDFirmActiveSearchParamsLabels();

        } else if (formState.search_type === 'rrIndivSearch') {
            return this.buildRRIndivActiveSearchParamsLabels();
        }

         else {
            return this.buildInsuranceActiveSearchParamsLabels();
        }
    }

    buildInsuranceActiveSearchParamsLabels() {
        let activeItems = [];
        let formState = this.props.formState.forServer();

        if (formState.quicksearch) {
            let prefix = formState.quicksearch_exclude ? 'Does not match' : 'Matches';
            activeItems.push({
                label: `${prefix} "${formState.quicksearch}" in any data`,
                field: 'quicksearch',
                delValue: '',
            });
        }

        if (formState.filter_is_agent !== 'both') {
            activeItems.push({
                label: formState.filter_is_agent === 'agents' ? 'Individuals' : 'Agencies',
                field: 'filter_is_agent',
                delValue: 'both',
            });
        }

        if (formState.filter_captive !== 'both') {
            let captive = formState.captive_companies.length ? '"' + formState.captive_companies.join('", "') + '"' : '';
            let labels = {
                'independent': 'who are independent',
                'captive': 'who are captive',
                'include': 'who are independent' + (captive ? ', or captive in one of ' + captive : ''),
                'exclude': (captive ? 'who are independent or captive, but not captive by any of ' + captive : ''),
                'only': (captive ? 'who are captive in one of ' + captive : ''),
            }
            let label = labels[formState.filter_captive];
            if (label) {
                activeItems.push({
                    label: label,
                    field: 'filter_captive',
                    delValue: 'both',
                });
            }
        }

        if (formState.npn_select && formState.npn_select.length) {
            formState.npn_select.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.npn_number?.length) {
                    let label =`NPN = ${row.npn_number}`;

                    item.children.push({
                        label: label,
                        field: `npn_select.${index}.npn_number`,
                        delValue: [],
                    });
            }

                if (row.npn_number?.length) {
                    activeItems.push(item);
                }
            });
        }

        if (formState.npns && formState.npns.length) {
            let fieldName = formState.npn_search_states ? 'NPN or state license' : 'NPN'
            let npnLabel = '';
            if (formState.npns.length === 1) {
                npnLabel = `${fieldName} = "${formState.npns[0]}"`;
            } else if (formState.npns.length <= 10) {
                npnLabel = `${fieldName} is one of "${formState.npns.join('", "')}"`;
            } else {
                npnLabel = `${fieldName} is one of ${formState.npns.length} values`;
            }
            activeItems.push({
                label: npnLabel,
                field: 'npns',
                delValue: [],
            });
        }

        this.addIndividualPiiLabels(formState, activeItems);

        if (formState.carrier_count_range) {
            let maxValue = 50;
            let [min, max] = formState.carrier_count_range;

            if (min >= 1 || max < maxValue) {
                let label = min === 0 ? `${max} or fewer`
                    : (max === maxValue ? `${min} or more` : `in between ${min} and ${max}`);

                activeItems.push({
                    label: `Has appointments with ${label} carriers`,
                    field: 'carrier_count_range',
                    delValue: null,
                });
            }
        }

        if (formState.carrier_group_range) {
            let maxValue = 50;
            let [min, max] = formState.carrier_group_range;

            if (min >= 1 || max < maxValue) {
                let label = min === 0 ? `${max} or fewer`
                    : (max === maxValue ? `${min} or more` : `in between ${min} and ${max}`);

                activeItems.push({
                    label: `Has appointments with ${label} carrier groups`,
                    field: 'carrier_group_range',
                    delValue: null,
                });
            }
        }

        for (let carrier of formState.carriers_and_groups) {
            if (carrier.carrier_groups.length > 0 || carrier.carriers.length > 0) {
                let item = {
                    label: formState.carrier_groups_bool ? 'All of these:' : '',
                    className: 'license-clause-parent',
                    children: [],
                };
                formState.carriers_and_groups.forEach((row, index) => {
                    if (row.carrier_groups && row.carrier_groups.length) {
                        let negate = row.carrier_groups_exclude ? ' no ' : '';
                        let label = row.carrier_groups.length === 1
                            ? `Has ${negate} appointments with a carrier in ${row.carrier_groups[0]}`
                            : `Has ${negate} appointments with a carrier in one of ${row.carrier_groups.join(', ')}`;
                        item.children.push({
                            label: label,
                            field: `carriers_and_groups.${index}.carrier_groups`,
                            delValue: [],
                        });
                    }

                    if (row.carriers && row.carriers.length) {
                        let negate = row.carriers_exclude ? ' no ' : '';
                        let label = row.carriers.length === 1
                            ? `Has ${negate} appointments with ${row.carriers[0]}`
                            : `Has ${negate} appointments with one of ${row.carriers.join(', ')}`;
                        item.children.push({
                            label: label,
                            field: `carriers_and_groups.${index}.carriers`,
                            delValue: [],
                        });
                    }
                    formState['carriers_name'] = row.carriers
                })
                if (formState.carriers_name) {
                    activeItems.push(item);
                    break;
                }
            }
        }

        if (formState.dob) {
            activeItems.push({
                label: `Born between ${formState.dob_range[0]} and ${formState.dob_range[1]}`,
                field: 'dob',
                delValue: false,
            });
        }

        if (formState.filter_income) {
            let labelSuffix = this.incomeRange(formState.income_range, 500000)

            if (labelSuffix) {
                activeItems.push({
                    label: `Income is ${labelSuffix}`,
                    field: 'income_range',
                    delValue: [0, 500000],
                });
            }
        }

        if (formState.filter_house_value) {
            let labelSuffix = this.incomeRange(formState.house_value_range, 1000000)

            if (labelSuffix) {
                activeItems.push({
                    label: `Home is valued at ${labelSuffix}`,
                    field: 'house_value_range',
                    delValue: [0, 1000000],
                });
            }
        }

        consumerDataSearchFields.forEach(({searchFieldLabel, searchFieldName, initialValue}) => {
            let value = formState[searchFieldName];
            if (!value || !value.length) return;
            value = Array.isArray(value) ? value : [value];
            let label;
            let exclude_params = formState['additional_fields_exclude'][`${searchFieldName}_exclude`];
            if (value.length > 1) {
                const selectedText = value.map(v => fieldLookups[searchFieldLabel][v] || v).join(', ');
                if (exclude_params) {
                    label = `${searchFieldLabel} except ${selectedText}`;
                }
                else {
                    label = `${searchFieldLabel} in any of ${selectedText}`;
                }
            }
            else {
                if (exclude_params) {
                    label = `${searchFieldLabel} except ${fieldLookups[searchFieldLabel][value]}`;
                }
                else {
                    label = `${searchFieldLabel} is ${fieldLookups[searchFieldLabel][value]}`;
                }
            }
            activeItems.push({
                label: label,
                field: searchFieldName,
                delValue: initialValue,
            });
        });

        if (formState.license_states_and_groups && formState.license_states_and_groups.length) {
            formState.license_states_and_groups.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.license_states.length) {
                    let anyOrAll = row.license_states_bool === 'and' ? 'all' : 'any';
                    let label = row.license_states.length === 1
                        ? `Licensed in ${row.license_states[0]}`
                        : `Licensed in ${anyOrAll} of these states: ${row.license_states.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `license_states_and_groups.${index}.license_states`,
                        delValue: [],
                    });
                }
                // the license groups by states
                let licenseGroupNames = Object.keys(row.license_groups).filter(licenseName => !!licenseName);

                // the license group ranges
                let licenseTypesWithRanges = [];
                licenseGroupNames.forEach((licenseName, index) => {
                    let range = row.license_groups[licenseName].range;
                    let max = range[1] >= 50 ? 'all' : range[1];

                    if (range[0] > 1 || range[1] < 50) {
                        let labelSuffix = range[0] === 1 ? `in ${max} or fewer`
                            : (range[1] === 50 ? `in ${range[0]} or more` : `in between ${range[0]} and ${max}`);

                        item.children.push({
                            label: `Has ${licenseName} licenses ${labelSuffix} states`,
                            field: `license_states_and_groups.0.license_groups.${licenseName}.range`,
                            delValue: [1, 50],
                        });
                        licenseTypesWithRanges.push(licenseName);
                    }
                });

                if (row.exclude && row.license_states.length === 0) {
                    // if we're excluding, with no state names chosen
                    // any license types that have a valid range should only exclude that range, not the existance of the license
                    licenseGroupNames = licenseGroupNames.filter(x => !licenseTypesWithRanges.includes(x));
                }

                if (licenseGroupNames.length) {
                    let anyOrAll = row.license_groups_bool === 'and' ? 'all' : 'any';

                    // easier to deal with case by having it in the variable string
                    let statesPrefix = 'H';
                    if (row.license_states.length) {
                        if (row.license_states.length === 1) {
                            statesPrefix = `Within ${row.license_states[0]}, h`;
                        } else if (row.license_states_bool === 'and') {
                            statesPrefix = 'Within every one of those states, h';
                        } else if (row.license_groups_bool === 'and') {
                            statesPrefix = 'Within any one of those states, h';
                        } else {
                            statesPrefix = 'Within those states, h';
                        }
                    }

                    let label = licenseGroupNames.length === 1
                        ? `${statesPrefix}olds a license in ${licenseGroupNames[0]}`
                        : `${statesPrefix}olds licenses in ${anyOrAll} of ${licenseGroupNames.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `license_states_and_groups.${index}.license_groups`,
                        delValue: {
                            "": {
                                i: 0,
                                range: [1, 50]
                            },
                        },
                    });
                }

                if (item.children.length) {
                    activeItems.push(item);
                }
            })
        }

        // years licensed
        let [min_years_licensed, max_years_licensed] = formState.years_licensed_range;
        if (min_years_licensed === 0 && max_years_licensed === 50) {
            // this is "everything", so no search

        } else {
            let label;
            if (min_years_licensed === 0) {
                label = `Has been licensed less than ${max_years_licensed} years`
            } else if (max_years_licensed === 50) {
                label = `Has been licensed more than ${min_years_licensed} years`
            } else {
                label = `Has been licensed for between ${min_years_licensed} and ${max_years_licensed} years`
            }

            activeItems.push({
                label: label,
                field: 'years_licensed_range',
                delValue: [0, 50],
            });
        }

        if (formState.email_level) {
            let label;
            if (formState.email_level === 'any') {
                label = 'Has Email Address'
            } else if (formState.email_level === '2') {
                label = 'Has Valid Email Address'
            } else if (formState.email_level === '1') {
                label = 'Has Email Verified for Mass Mailing'
            }

            activeItems.push({
                label: label,
                field: 'email_level',
                delValue: '',
            });
        }

        if (formState.filter_primary_phone) {
            activeItems.push({
                label: 'Has primary phone',
                field: 'filter_primary_phone',
                delValue: false,
            });
        }

        if (this.props.allowUnset) {
            activeItems.push({
                label: <><i>Clear all</i></>,
                field: '',
                delValue: new SearchFormState(),
                className: 'mt-1'
            });
        }

        return activeItems;
    }

    buildRiaFirmActiveSearchParamsLabels() {
        let activeItems = [];
        let formState = this.props.formState.forServer();

        if (formState.firm_names && formState.firm_names.length) {
            let label = formState.firm_names.length === 1
                ? `Firm name is "${formState.firm_names[0]}"`
                : `Firm name is one of "${formState.firm_names.join('", "')}"`;
            activeItems.push({
                label: label,
                field: 'firm_names',
                delValue: [],
            });
        }

        this.addCRDLabels(formState, activeItems);
        let simpleFields = {
            // 'first_name': 'First Name',
            // 'last_name': 'Last Name',
            'street_address': 'Street Address',
            'city': 'City',
            'area_code': 'Area code',
            'phone_number': 'Phone number',
        };

        for (const field in simpleFields) {
            if (formState[field]) {
                let fieldLabel = simpleFields[field];
                activeItems.push({
                    label: `${fieldLabel} = "${formState[field]}"`,
                    field: field,
                    delValue: '',
                });
            }
        }

        if (formState.registration_status) {
            let label;
            if (formState.registration_status === '2') {
                label = 'Has not terminated registration'
            } else if (formState.registration_status === '1') {
                label = 'Has terminated registration'
            }

            activeItems.push({
                label: label,
                field: 'registration_status',
                delValue: '',
            });
        }

        if (formState.asset_types_and_amounts && Object.keys(formState.asset_types_and_amounts).length) {
            let sliderTypes = {
                'asset_range': {
                    label: 'assets',
                    formatFunc: renderDollars
                },
                'client_range': {
                    label: '# clients',
                    formatFunc: value => value
                }
            }

            for (let assetType of Object.keys(formState.asset_types_and_amounts)) {
                if (!assetType) {
                    continue;
                }
                let assetTypeLabel = findColumnWithValue(riaAssetTypes, assetType).label;

                for (let sliderType of Object.keys(sliderTypes)) {
                    const sliderTypeProps = sliderTypes[sliderType];
                    const sliderTypeLabel = assetTypeLabel.toLowerCase().endsWith(sliderTypeProps.label) ? '' : sliderTypeProps.label;

                    const range = formState.asset_types_and_amounts[assetType][sliderType];
                    if (!range || !range.length || (range[0] === 0 && range[1] === 'all')) {
                        // no filtering
                        continue;
                    }

                    let label;
                    if (range[0] === 0) {
                        const prettyMax = sliderTypeProps.formatFunc(range[1]);
                        label = `Has "${assetTypeLabel}" ${sliderTypeLabel} less than ${prettyMax}`
                    } else if (range[1] === 'all') {
                        const prettyMin = sliderTypeProps.formatFunc(range[0]);
                        label = `Has "${assetTypeLabel}" ${sliderTypeLabel} more than ${prettyMin}`
                    } else {
                        const prettyMin = sliderTypeProps.formatFunc(range[0]);
                        const prettyMax = sliderTypeProps.formatFunc(range[1]);
                        label = `Has "${assetTypeLabel}" ${sliderTypeLabel} between ${prettyMin} and ${prettyMax}`
                    }

                    activeItems.push({
                        label: label,
                        field: `asset_types_and_amounts.${assetType}.${sliderType}`,
                        delValue: [],
                    });
                }
            }
        }

        // Filter firms using employee and rep and client counts 
        if (formState.employee_and_rep_count && Object.keys(formState.employee_and_rep_count).length) {
            let sliderTypes = {
                'count_range': {
                    label: '# Counts',
                    formatFunc: value => value
                }
            }
            let countWithFilterRange = 0;
            let filterCondition = '';
            for (let countType of Object.keys(formState.employee_and_rep_count)) {
                if (!countType || countType === 'default') {
                    continue;
                }
                let countTypeLabel = findColumnWithValue(riaEmployeeRepCountTypes, countType).label;

                for (let sliderType of Object.keys(sliderTypes)) {
                    const sliderTypeProps = sliderTypes[sliderType];
                    const sliderTypeLabel = countTypeLabel.toLowerCase().endsWith(sliderTypeProps.label) ? '' : sliderTypeProps.label;

                    const range = formState.employee_and_rep_count[countType][sliderType];
                    if (!range || !range.length || (range[0] === 0 && range[1] === 'all')) {
                        // no filtering
                        continue;
                    }
                    countWithFilterRange ++;
                    
                    let label;
                    if (countWithFilterRange > 1) {
                        filterCondition = formState.employee_and_rep_count_bool;
                    }
                    if (range[0] === 0) {
                        const prettyMax = sliderTypeProps.formatFunc(range[1]);
                        label = `Has "${countTypeLabel}" ${sliderTypeLabel} less than ${prettyMax}`
                    } else if (range[1] === 'all') {
                        const prettyMin = sliderTypeProps.formatFunc(range[0]);
                        label = `Has "${countTypeLabel}" ${sliderTypeLabel} more than ${prettyMin}`
                    } else {
                        const prettyMin = sliderTypeProps.formatFunc(range[0]);
                        const prettyMax = sliderTypeProps.formatFunc(range[1]);
                        label = `Has "${countTypeLabel}" ${sliderTypeLabel} between ${prettyMin} and ${prettyMax}`
                    }

                    activeItems.push({
                        label: label,
                        field: `employee_and_rep_count.${countType}.${sliderType}`,
                        delValue: [],
                    });
                }
            }
            if (filterCondition.length > 1) {
                let anyOrAll = filterCondition === 'and' ? 'all' : 'any';
                let label = `With firms ${anyOrAll} of these ranges`
                activeItems.push({
                    label: label,
                });
            }
        }

        // RIA State
        if (formState.address_states && formState.address_states.length) {
            formState.address_states.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.states?.length) {
                    let anyOrAll = row.address_states_bool === 'and' ? 'all' : 'any';
                    let label = row.states.length === 1
                        ? `With address in state ${row.states[0]}`
                        : `With address in ${anyOrAll} of these states: ${row.states.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `address_states.${index}.states`,
                        delValue: [],
                    });
                }
                
                if (row.states?.length) {
                    activeItems.push(item);
                }
            });
        }

        // zip code + radius
        if (formState.zipcodes) {
            let orPrefix = '';
            formState.zipcodes.forEach((zipObj, i) => {
                if (zipObj.zip) {
                    if (zipObj.zip.length < 5) {
                        activeItems.push({
                            label: `${orPrefix}Zip code starts with ${zipObj.zip}`,
                            field: `zipcodes.${i}.zip`,
                            delValue: '',
                        });
                    } else if (zipObj.radius) {
                        activeItems.push({
                            label: `${orPrefix}Within ${zipObj.radius} miles of zip code ${zipObj.zip}`,
                            field: `zipcodes.${i}.radius`,
                            delValue: '',
                        });
                    } else {
                        activeItems.push({
                            label: `${orPrefix}Zip code = ${zipObj.zip}`,
                            field: `zipcodes.${i}.zip`,
                            delValue: '',
                        });
                    }
                    orPrefix = 'or, ';
                }
            });
        }

        // RIA County
        if (!!formState.county.length) {
            activeItems.push({
                label: `County = "${formState.county}"`,
                field: 'county',
                delValue: '',
            });
        }

        // RIA Compensation
        if (formState.compensation && formState.compensation.length) {
            let riaCompensation=[]
            for(const compensationName in formState.compensation) {
                for(const [compensationKey,compensationValue] of Object.entries(feeTypes)) {
                    if(formState.compensation[compensationName] ==`${compensationKey}`) {
                        riaCompensation.push(`${compensationValue}`.substring(`${compensationValue}`.indexOf(')') + 1));
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.compensation_exclude == true) {
                label = `Compensation except "${riaCompensation}"`
            }
            else {
                label = `Compensation = "${riaCompensation}"`
            }
            activeItems.push({
                label: label,
                field: 'compensation',
                delValue: [],
            });
        }

        // RIA Advisory Services
        if (formState.advisory_services && formState.advisory_services.length) {
            let riaAdvisory=[]
            for(const fieldName in formState.advisory_services) {
                for(const [fieldKey,filedValue] of Object.entries(advisoryTypes)) {
                    if(formState.advisory_services[fieldName] ==`${fieldKey}`) {
                        riaAdvisory.push(`${filedValue}`.substring(`${filedValue}`.indexOf(')') + 1));
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.advisory_services_exclude == true) {
                label = `Advisory Services except "${riaAdvisory}"`
            }
            else {
                label = `Advisory Services = "${riaAdvisory}"`
            }
            activeItems.push({
                label: label,
                field: 'advisory_services',
                delValue: [],
            });
        }

        // RIA Other Business
        if (formState.other_business && formState.other_business.length) {
            let riaBusiness=[]
            for(const businessName in formState.other_business) {
                for(const [businessKey,businessValue] of Object.entries(activityTypes)) {
                    if(formState.other_business[businessName] ==`${businessKey}`) {
                        riaBusiness.push(`${businessValue}`.substring(`${businessValue}`.indexOf(')') + 1));
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.other_business_exclude == true) {
                label = `Other Business except "${riaBusiness}"`
            }
            else {
                label = `Other Business = "${riaBusiness}"`
            }
            activeItems.push({
                label: label,
                field: 'other_business',
                delValue: [],
            });
        }

        // RIA Affiliations
        if (formState.affiliations && formState.affiliations.length) {
            let riaAffiliations=[]
            for(const affiField in formState.affiliations){
                for(const [affiliationsKey,affiliationsValue] of Object.entries(fieldLookups['Affiliations'])) {
                    if(formState.affiliations[affiField] ==`${affiliationsKey}`) {
                        riaAffiliations.push(`${affiliationsValue}`);
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.affiliations_exclude == true) {
                label = `Affiliations except "${riaAffiliations}"`
            }
            else {
                label = `Affiliations = "${riaAffiliations}"`
            }
            activeItems.push({
                label: label,
                field: 'affiliations',
                delValue: [],
            });
        }

        // RIA Terminated
        if (formState.terminated && formState.terminated.length) {
            let terminated=[]
            for(const affiField in formState.terminated){
                for(const [terminatedKey,terminatedValue] of Object.entries(fieldLookups['Terminated'])) {
                    if(formState.terminated[affiField] ==`${terminatedKey}`) {
                        terminated.push(`${terminatedValue}`);
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.terminated_exclude == true) {
                label = `Terminated except "${terminated}"`
            }
            else {
                label = `Terminated = "${terminated}"`
            }
            activeItems.push({
                label: label,
                field: 'terminated',
                delValue: [],
            });
        }

        // RIA Ria Types
        if (formState.ria_types && formState.ria_types.length) {
            let ria_types=[]
            for(const riaField in formState.ria_types) {
                for(const [riaKey,riaValue] of Object.entries(fieldLookups['RIA Types'])) {
                    if(formState.ria_types[riaField] ==`${riaKey}`) {
                        ria_types.push(`${riaValue}`);
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.ria_types_exclude == true) {
                label = `RIA Types except "${ria_types}"`
            }
            else {
                label = `RIA Types = "${ria_types}"`
            }
            activeItems.push({
                label: label,
                field: 'ria_types',
                delValue: [],
            });
        }

        // RIA Discretion Over
        if (formState.discretion_over && formState.discretion_over.length) {
            let riaDiscretion=[]
            for(const riaDis in formState.discretion_over) {
                for(const [disKey,disValue] of Object.entries(fieldLookups['Discretion Over'])) {
                    if(formState.discretion_over[riaDis] ==`${disKey}`) {
                        riaDiscretion.push(`${disValue}`);
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.discretion_over_exclude == true) {
                label = `Discretion Over except "${riaDiscretion}"`
            }
            else {
                label = `Discretion Over = "${riaDiscretion}"`
            }
            activeItems.push({
                label: label,
                field: 'discretion_over',
                delValue: [],
            });
        }

        // RIA Referral Compensation
        if (formState.referral_compensation && formState.referral_compensation.length) {
            let riaReferral=[]
            for(const riaRef in formState.referral_compensation) {
                for(const [referralKey,referralValue] of Object.entries(fieldLookups['Referral Compensation'])) {
                    if(formState.referral_compensation[riaRef] ==`${referralKey}`) {
                        riaReferral.push(`${referralValue}`);
                    }
                }
            }
            let label;
            if (formState.additional_fields_exclude.referral_compensation_exclude == true) {
                label = `Referral Compensation except "${riaReferral}"`
            }
            else {
                label = `Referral Compensation = "${riaReferral}"`
            }
            activeItems.push({
                label:label,
                field: 'referral_compensation',
                delValue: [],
            });
        }

        // RIA firm executive titles in the search param list
        if (formState.executive_titles && formState.executive_titles.length) {
            formState.executive_titles.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.titles?.length) {
                    let anyOrAll = row.executive_titles_bool === 'and' ? 'all' : 'any';
                    let label = row.titles.length === 1
                        ? `With executive in title ${row.titles[0]}`
                        : `With executive in ${anyOrAll} of these titles: ${row.titles.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `executive_titles.${index}.titles`,
                        delValue: [],
                    });
                }

                if (row.titles?.length) {
                    activeItems.push(item);
                }
            });
        }
        // RIA individuals executive titles in the search param list
        if (this.props.allowUnset && activeItems.length >= 0){
            let label;
            if (formState.search_executive) {
                label = 'Search by IAR Individual'
            } else {
                label = 'Search by Firm' 
            }
            activeItems.push({
                label: label,
                field: 'search_executive',
                className: 'mt-1'
            });
        }

        // RIA Clear All
        if (this.props.allowUnset && activeItems.length >= 0) {
            activeItems.push({
                label: <><i>Clear all</i></>,
                field: '',
                delValue: new RiaFirmSearchState(),
                className: 'mt-1'
            });
        }

        return activeItems;
    }

    buildIarActiveSearchParamsLabels() {
        let activeItems = [];
        let formState = this.props.formState.forServer();

        this.addCRDLabels(formState, activeItems);
        this.addIndividualPiiLabels(formState, activeItems);
        
        // IAR Firm names
        if (formState.firm_names && formState.firm_names.length) {
            formState.firm_names.forEach((row) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.names?.length) {
                    let label = row.names.length === 1
                    ? `Firm name is "${formState.firm_names[0].names}"`
                    : `Firm name is one of "${formState.firm_names[0].names.join('", "')}"`;

                    item.children.push({
                        label: label,
                        field: `firm_names.${[0]}.names`,
                        delValue: [],
                    });
                }

                if (row.names?.length) {
                    activeItems.push(item);
                }
            });
        }

        if (formState.email_level) {
            let label;
            if (formState.email_level === 'any') {
                label = 'Has Email Address'
            } else if (formState.email_level === '2') {
                label = 'Has Valid Email Address'
            } else if (formState.email_level === '1') {
                label = 'Has Email Verified for Mass Mailing'
            }

            activeItems.push({
                label: label,
                field: 'email_level',
                delValue: '',
            });
        }

        // IAR LinkedIn profile
        if (formState.linkedin_profile) {
            let label;
            if (formState.linkedin_profile === '2') {
                label = "Doesn't has linkedin profile"
            } else if (formState.linkedin_profile === '1') {
                label = "Has linkedin profile"
            }

            activeItems.push({
                label: label,
                field: 'linkedin_profile',
                delValue: '',
            });
        }

        // IAR Dually Registered
        if (formState.dually_licensed) {
            let label;
            if (formState.dually_licensed === '2') {
                label = "Doesn't has Dually Registered"
            } else if (formState.dually_licensed === '1') {
                label = "Has Dually Registered"
            }

            activeItems.push({
                label: label,
                field: 'dually_licensed',
                delValue: '',
            });
        }

        if (formState.high_net_worth_clients) {
            let label;
            if (formState.high_net_worth_clients === '2') {
                label = "RIA has no High Net Worth clients"
            } else if (formState.high_net_worth_clients === '1') {
                label = "RIA has High Net Worth clients"
            }

            activeItems.push({
                label: label,
                field: 'high_net_worth_clients',
                delValue: '',
            });
        }

        if (formState.gender) {
            let label
            if (formState.gender === 'M') {
                label = "Gender is Male"
            }
            else if (formState.gender === 'F') {
                label = "Gender is Female"
            }
            activeItems.push({
                label: label,
                field: 'gender',
                delValue: '',
            });
        }
        // IAR Disclosure
        if (formState.disclosure_types && formState.disclosure_types.length) {
            formState.disclosure_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.disclosure_type_bool === 'and' ? 'all' : 'any';

                    let label;
                    if (row.label?.length) {
                        label = row.label.length === 1
                            ? `With disclosure in type ${row.label[0]}`
                            : `With disclosure in ${anyOrAll} of these types: ${row.label.join(', ')}`;
                    }
                    else {
                        label = row.types.length === 1
                            ? `With disclosure in type ${row.types[0]}`
                            : `With disclosure in ${anyOrAll} of these types: ${row.types.join(', ')}`;
                    }
                    item.children.push({
                        label: label,
                        field: `disclosure_types.${index}.types`,
                        delValue: [],
                    });
                
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        // IAR Designation
        if (formState.designation_types && formState.designation_types.length) {
            formState.designation_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.designation_type_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With designation in ${row.label[0]}`
                        : `With designation in ${anyOrAll} of these types: ${row.label.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `designation_types.${index}.types`,
                        delValue: [],
                    });
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        // IAR other Business Activities
        if (formState.otherbusiness_types && formState.otherbusiness_types.length) {
            formState.otherbusiness_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.otherbusiness_types_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With other business Activities in ${row.label[0]}`
                        : `With other business Activities in ${anyOrAll} of these types: ${row.label.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `otherbusiness_types.${index}.types`,
                        delValue: [],
                    });
            }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }
        
         // IAR Ria Types
         if (formState.ria_types && formState.ria_types.length) {
            formState.ria_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.riatypes_type_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With Ria Types in ${row.label[0]}`
                        : `With Ria Types in ${anyOrAll} of these types: ${row.label.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `ria_types.${index}.types`,
                        delValue: [],
                    });
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        // RIA individuals employeed year in the search param list
        if (formState.years_employeed)
        {
            activeItems.push({
                label: `Registered between ${formState.years_employeed_range[0]} and ${formState.years_employeed_range[1]}`,
                field: 'years_employeed',
                delValue: false,
            });
        }

        // RIA individuals states registered range in the search param list
        if (formState.states_registered) {
            activeItems.push({
                label: `Registered state between ${formState.states_registered_range[0]} and ${formState.states_registered_range[1]}`,
                field: 'states_registered_range',
                delValue: [1, 50],
            });
        }


        if (formState.license_types && formState.license_types.length) {
            formState.license_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.license_type_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With license in type ${row.types[0]}`
                        : `With license in ${anyOrAll} of these types: ${row.types.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `license_types.${index}.types`,
                        delValue: [],
                    });
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        if (formState.asset_types_and_amounts && Object.keys(formState.asset_types_and_amounts).length) {
            let sliderTypes = {
                'asset_range': {
                    label: 'assets',
                    formatFunc: renderDollars
                },
                'client_range': {
                    label: '# clients',
                    formatFunc: value => value
                }
            }

            for (let assetType of Object.keys(formState.asset_types_and_amounts)) {
                if (!assetType) {
                    continue;
                }
                let assetTypeLabel = findColumnWithValue(riaAssetTypes, assetType).label;

                for (let sliderType of Object.keys(sliderTypes)) {
                    const sliderTypeProps = sliderTypes[sliderType];
                    const sliderTypeLabel = assetTypeLabel.toLowerCase().endsWith(sliderTypeProps.label) ? '' : sliderTypeProps.label;

                    const range = formState.asset_types_and_amounts[assetType][sliderType];
                    if (!range || !range.length || (range[0] === 0 && range[1] === 'all')) {
                        // no filtering
                        continue;
                    }

                    let label;
                    if (range[0] === 0) {
                        const prettyMax = sliderTypeProps.formatFunc(range[1]);
                        label = `Has "${assetTypeLabel}" ${sliderTypeLabel} less than ${prettyMax}`
                    } else if (range[1] === 'all') {
                        const prettyMin = sliderTypeProps.formatFunc(range[0]);
                        label = `Has "${assetTypeLabel}" ${sliderTypeLabel} more than ${prettyMin}`
                    } else {
                        const prettyMin = sliderTypeProps.formatFunc(range[0]);
                        const prettyMax = sliderTypeProps.formatFunc(range[1]);
                        label = `Has "${assetTypeLabel}" ${sliderTypeLabel} between ${prettyMin} and ${prettyMax}`
                    }

                    activeItems.push({
                        label: label,
                        field: `asset_types_and_amounts.${assetType}.${sliderType}`,
                        delValue: [],
                    });
                }
            }
        }

        // RIA individuals firm crds in the search param list
        if (formState.firm_crds_list && formState.firm_crds_list.length) {
            formState.firm_crds_list.forEach((row) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };
    
                if (row.crds?.length) {
                    let label = row.crds.length === 1
                    ? `Firm crd is "${formState.firm_crds_list[0].crds}"`
                    : `Firm crd is one of "${formState.firm_crds_list[0].crds.join('", "')}"`;
    
                    item.children.push({
                        label: label,
                        field: `firm_crds`,
                        delValue: [],
                    });
                }
    
                if (row.crds?.length) {
                    activeItems.push(item);
                }
            });
        }

        // IAR Clear All
        if (this.props.allowUnset && activeItems.length >= 0) {
            activeItems.push({
                label: <><i>Clear all</i></>,
                field: '',
                delValue: new IarSearchState(),
                className: 'mt-1'
            });
        }

        return activeItems;
    }

    addCRDLabels(formState, activeItems) {
        if (formState.crds && formState.crds.length) {
            let fieldName = 'CRD'
            let crdLabel = '';
            if (formState.crds.length === 1) {
                crdLabel = `${fieldName} = "${formState.crds[0]}"`;
            } else if (formState.crds.length <= 10) {
                crdLabel = `${fieldName} is one of "${formState.crds.join('", "')}"`;
            } else {
                crdLabel = `${fieldName} is one of ${formState.crds.length} values`;
            }
            activeItems.push({
                label: crdLabel,
                field: 'crds',
                delValue: [],
            });
        }
    }

    addIndividualPiiLabels(formState, activeItems) {
        let simpleFields = {
            'street_address': 'Street Address',
            'city': 'City',
            'area_code': 'Area code',
            'phone_number': 'Phone number',
        };

        for (const field in simpleFields) {
            if (formState[field]) {
                let fieldLabel = simpleFields[field];
                activeItems.push({
                    label: `${fieldLabel} = "${formState[field]}"`,
                    field: field,
                    delValue: '',
                });
            }
        }

        if (!!formState.county.length) {
            activeItems.push({
                label: `County = "${formState.county}"`,
                field: 'county',
                delValue: '',
            });
        }

        if (formState.first_name && formState.first_name.length) {
            let firstNameLabel = '';
            if (formState.first_name.length === 1) {
                firstNameLabel = `First Name = "${formState.first_name[0]}"`;
            } else if (formState.first_name.length <= 5) {
                firstNameLabel = `First Name is one of "${formState.first_name.join('", "')}"`;
            } else {
                firstNameLabel = `First Name is one of ${formState.first_name.length} values`;
            }
            activeItems.push({
                label: firstNameLabel,
                field: 'first_name',
                delValue: [],
            });
        }

        if (formState.last_name && formState.last_name.length) {
            let lastNameLabel = '';
            if (formState.last_name.length === 1) {
                lastNameLabel = `Last Name = "${formState.last_name[0]}"`;
            } else if (formState.last_name.length <= 5) {
                lastNameLabel = `Last Name is one of "${formState.last_name.join('", "')}"`;
            } else {
                lastNameLabel = `Last Name is one of ${formState.last_name.length} values`;
            }
            activeItems.push({
                label: lastNameLabel,
                field: 'last_name',
                delValue: [],
            });
        }

        if (formState.full_name && formState.full_name.length) {
            formState.full_name.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.full_names?.length) {
                    let label =`Full Names = ${row.full_names}`;

                    item.children.push({
                        label: label,
                        field: `full_name.${index}.full_names`,
                        delValue: [],
                    });
            }

                if (row.full_names?.length) {
                    activeItems.push(item);
                }
            });
        }


        if (formState.email_address && formState.email_address.length) {
            formState.email_address.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.emails?.length) {
                    let anyOrAll = row.email_address_bool === 'and' ? 'all' : 'any';
                    let label = row.emails.length === 1
                        ? `With Email Address in ${row.emails[0]}`
                        : `With Email Address in ${anyOrAll} of these emails: ${row.emails}`;

                    item.children.push({
                        label: label,
                        field: `email_address.${index}.emails`,
                        delValue: [],
                    });
            }

                if (row.emails?.length) {
                    activeItems.push(item);
                }
            });
        }

        // RIA individuals address states in the search param list
        if (formState.address_states && formState.address_states.length) {
            formState.address_states.forEach((row, index) => {
                let item = {
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.states?.length) {
                    item['label'] = row.exclude ? 'Excluding those:' : 'Including those:';
                    let anyOrAll = row.address_states_bool === 'and' ? 'all' : 'any';
                    let label = row.states.length === 1
                        ? `With address in state ${row.states[0]}`
                        : `With address in ${anyOrAll} of these states: ${row.states.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `address_states.${index}.states`,
                        delValue: [],
                    });
                }

                if (row.states?.length) {
                    activeItems.push(item);
                }
            });
        }

        // RIA individuals branch office states in the search param list
        if (formState.branch_office_states && formState.branch_office_states.length) {
            formState.branch_office_states.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };
                if (row.states?.length) {
                    let anyOrAll = row.branch_office_states_bool === 'and' ? 'all' : 'any';
                    let label = row.states.length === 1
                        ? `With branch office in state ${row.states[0]}`
                        : `With branch office in ${anyOrAll} of these states: ${row.states.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `branch office.${index}.states`,
                        delValue: [],
                    });
                }

                if (row.states?.length) {
                    activeItems.push(item);
                }
            });
        }

        // zip code + radius
        if (formState.zipcodes) {
            let orPrefix = '';
            formState.zipcodes.forEach((zipObj, i) => {
                if (zipObj.zip) {
                    if (zipObj.zip.length < 5) {
                        activeItems.push({
                            label: `${orPrefix}Zip code starts with ${zipObj.zip}`,
                            field: `zipcodes.${i}.zip`,
                            delValue: '',
                        });
                    } else if (zipObj.radius) {
                        activeItems.push({
                            label: `${orPrefix}Within ${zipObj.radius} miles of zip code ${zipObj.zip}`,
                            field: `zipcodes.${i}.radius`,
                            delValue: '',
                        });
                    } else {
                        activeItems.push({
                            label: `${orPrefix}Zip code = ${zipObj.zip}`,
                            field: `zipcodes.${i}.zip`,
                            delValue: '',
                        });
                    }
                    orPrefix = 'or, ';
                }
            });
        }
    }


    render() {
        let activeSearchParams = this.buildActiveSearchParamsLabels();
        return (
            <>
                {activeSearchParams.length
                    ? <div className={this.props.allowUnset ? "edit-search-params my-2" : "view-search-params my-2"}>
                        <strong>From search params:</strong>
                        <ParamsList searchParams={activeSearchParams}
                            onChange={this.props.onChange} allowUnset={this.props.allowUnset} />
                    </div>
                    : <></>
                }
            </>
        );
    } 
      
    buildRRIndivActiveSearchParamsLabels() {
        let activeItems = [];
        let formState = this.props.formState.forServer();

        this.addCRDLabels(formState, activeItems);
        this.addIndividualPiiLabels(formState, activeItems);
            
        //  RR Firm names
        if (formState.firm_names && formState.firm_names.length) {
            formState.firm_names.forEach((row) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };
    
                if (row.names?.length) {
                    let label = row.names.length === 1
                    ? `Firm name is "${formState.firm_names[0].names}"`
                    : `Firm name is one of "${formState.firm_names[0].names.join('", "')}"`;
    
                    item.children.push({
                        label: label,
                        field: `firm_names.${[0]}.names`,
                        delValue: [],
                    });
                }
    
                if (row.names?.length) {
                    activeItems.push(item);
                }
            });
        }
        // RR  Emails
        if (formState.email_level) {
            let label;
            if (formState.email_level === 'any') {
                label = 'Has Email Address'
            } else if (formState.email_level === '2') {
                label = 'Has Valid Email Address'
            } else if (formState.email_level === '1') {
                label = 'Has Email Verified for Mass Mailing'
            }

            activeItems.push({
                label: label,
                field: 'email_level',
                delValue: '',
            });
        }
        // RR LinkedIn profile
        if (formState.linkedin_profile) {
            let label;
            if (formState.linkedin_profile === '2') {
                label = "Doesn't has linkedin profile"
            } else if (formState.linkedin_profile === '1') {
                label = "Has linkedin profile"
            }

            activeItems.push({
                label: label,
                field: 'linkedin_profile',
                delValue: '',
            });
        }

        // RR Dually Registered
        if (formState.dually_licensed) {
            let label;
            if (formState.dually_licensed === '2') {
                label = "Doesn't has Dually Registered"
            } else if (formState.dually_licensed === '1') {
                label = "Has Dually Registered"
            }

            activeItems.push({
                label: label,
                field: 'dually_licensed',
                delValue: '',
            });
        }

        if (formState.finra_registered) {
            let label;
            if (formState.finra_registered === '1') {
                label = "Has Registered by FINRA"
            } else if (formState.finra_registered === '0') {
                label = "Has not Registered by FINRA"
            }

            activeItems.push({
                label: label,
                field: 'finra_registered',
                delValue: '',
            });
        }

        if (formState.gender) {
            let label
            if (formState.gender === 'M') {
                label = "Gender is Male"
            }
            else if (formState.gender === 'F') {
                label = "Gender is Female"
            }
            activeItems.push({
                label: label,
                field: 'gender',
                delValue: '',
            });
        }

        if (formState.disclosure) {
            let label
            if (formState.disclosure === 'TRUE') {
                label = "RR with disclosure"
            }
            else if (formState.disclosure === 'FALSE') {
                label = "RR with No disclosure"
            }
            activeItems.push({
                label: label,
                field: 'disclosure',
                delValue: '',
            });
        }

        // Filter firms using employed year counts 
        if (formState.years_employed_range_count && Object.keys(formState.years_employed_range_count).length) {
            let sliderTypes = {
                'count_range': {
                    label: 'year',
                    formatFunc: value => value
                }
            }
            let countWithFilterRange = 0;
            let filterCondition = '';
            for (let countType of Object.keys(formState.years_employed_range_count)) {
                if (!countType || countType === 'default') {
                    continue;
                }
                let countTypeLabel = findColumnWithValue(rrYearEmployed, countType).label;

                for (let sliderType of Object.keys(sliderTypes)) {
                    const sliderTypeProps = sliderTypes[sliderType];
                    const sliderTypeLabel = countTypeLabel.toLowerCase().endsWith(sliderTypeProps.label) ? '' : sliderTypeProps.label;

                    const range = formState.years_employed_range_count[countType][sliderType];
                    if (!range || !range.length || (range[0] === 1970 && range[1] === new Date().getFullYear())) {
                        // no filtering
                        continue;
                    }
                    countWithFilterRange ++;
                    
                    let label;
                    if (countWithFilterRange > 1) {
                        filterCondition = formState.years_employed_count_bool;
                    }
                    const prettyMin = sliderTypeProps.formatFunc(range[0]);
                    const prettyMax = sliderTypeProps.formatFunc(range[1]);
                    label = `Has ${countTypeLabel} in years between ${prettyMin} and ${prettyMax}`

                    activeItems.push({
                        label: label,
                        field: `years_employed_range_count.${countType}.${sliderType}`,
                        delValue: [1970,new Date().getFullYear()],
                    });
                }
            }
            if (filterCondition.length > 1) {
                let anyOrAll = filterCondition === 'and' ? 'all' : 'any';
                let label = `With rr individuals ${anyOrAll} of these ranges`
                activeItems.push({
                    label: label,
                });
            }
        }

        // RR individuals employment count range in the search param list
        if (formState.employment_count)
            if (!(formState.employment_count_range[0] == 0 && formState.employment_count_range[1] == 70)) {
                activeItems.push({
                    label: `Employment count between ${formState.employment_count_range[0]} and ${formState.employment_count_range[1]}`,
                    field: 'employment_count_range',
                    delValue: [0, 70],
                });
            }

        // RR individuals employeed year in the search param list
        if (formState.years_employeed)
        {
            activeItems.push({
                label: `Registered between ${formState.years_employeed_range[0]} and ${formState.years_employeed_range[1]}`,
                field: 'years_employeed',
                delValue: false,
            });
        }

        
        if (formState.license_types && formState.license_types.length) {
            formState.license_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };
    
                if (row.types?.length) {
                    let anyOrAll = row.license_type_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With license in type ${row.types[0]}`
                        : `With license in ${anyOrAll} of these types: ${row.types.join(', ')}`;
    
                    item.children.push({
                        label: label,
                        field: `license_types.${index}.types`,
                        delValue: [],
                    });
                }
    
                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        //RR firm crd
        if (formState.firm_crds_list && formState.firm_crds_list.length) {
            formState.firm_crds_list.forEach((row) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };
    
                if (row.crds?.length) {
                    let label = row.crds.length === 1
                    ? `Firm crd is "${formState.firm_crds_list[0].crds}"`
                    : `Firm crd is one of "${formState.firm_crds_list[0].crds.join('", "')}"`;
    
                    item.children.push({
                        label: label,
                        field: `firm_crds`,
                        delValue: [],
                    });
                }
    
                if (row.crds?.length) {
                    activeItems.push(item);
                }
            });
        }

        // RR Disclosure
        if (formState.disclosure_types && formState.disclosure_types.length) {
            formState.disclosure_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.disclosure_type_bool === 'and' ? 'all' : 'any';

                    let label;
                    if (row.label?.length) {
                        label = row.label.length === 1
                            ? `With disclosure in type ${row.label[0]}`
                            : `With disclosure in ${anyOrAll} of these types: ${row.label.join(', ')}`;
                    }
                    else {
                        label = row.types.length === 1
                            ? `With disclosure in type ${row.types[0]}`
                            : `With disclosure in ${anyOrAll} of these types: ${row.types.join(', ')}`;
                    }
                    item.children.push({
                        label: label,
                        field: `disclosure_types.${index}.types`,
                        delValue: [],
                    });
                
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        // RR Designation
        if (formState.designation_types && formState.designation_types.length) {
            formState.designation_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.designation_type_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With designation in ${row.label[0]}`
                        : `With designation in ${anyOrAll} of these types: ${row.label.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `designation_types.${index}.types`,
                        delValue: [],
                    });
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        // RR other Business Activities
        if (formState.otherbusiness_types && formState.otherbusiness_types.length) {
            formState.otherbusiness_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.otherbusiness_types_bool === 'and' ? 'all' : 'any';
                    let label = row.types.length === 1
                        ? `With other business Activities in ${row.label[0]}`
                        : `With other business Activities in ${anyOrAll} of these types: ${row.label.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `otherbusiness_types.${index}.types`,
                        delValue: [],
                    });
            }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        //  RR Clear All
        if (this.props.allowUnset && activeItems.length >= 0) {
            activeItems.push({
                label: <><i>Clear all</i></>,
                field: '',
                delValue: new RrSearchState(),
                className: 'mt-1'
            });
        }
    
        return activeItems;
    }

    buildBDFirmActiveSearchParamsLabels() {
        let activeItems = [];
        let formState = this.props.formState.forServer();

        if (formState.firm_names && formState.firm_names.length) {
            let label = formState.firm_names.length === 1
                ? `Firm name is "${formState.firm_names[0]}"`
                : `Firm name is one of "${formState.firm_names.join('", "')}"`;
            activeItems.push({
                label: label,
                field: 'firm_names',
                delValue: [],
            });
        }

        this.addCRDLabels(formState, activeItems);
        let simpleFields = {
            'first_name': 'First Name',
            'last_name': 'Last Name',
            'street_address': 'Street Address',
            'city': 'City',
            'area_code': 'Area code',
            'phone_number': 'Phone number',
        };

        for (const field in simpleFields) {
            if (formState[field]) {
                let fieldLabel = simpleFields[field];
                activeItems.push({
                    label: `${fieldLabel} = "${formState[field]}"`,
                    field: field,
                    delValue: '',
                });
            }
        }

        // BD Firm State
        if (formState.address_states && formState.address_states.length) {
            formState.address_states.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.states?.length) {
                    let anyOrAll = row.address_states_bool === 'and' ? 'all' : 'any';
                    let label = row.states.length === 1
                        ? `With address in state ${row.states[0]}`
                        : `With address in ${anyOrAll} of these states: ${row.states.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `address_states.${index}.states`,
                        delValue: [],
                    });
                }

                if (row.states?.length) {
                    activeItems.push(item);
                }
            });
        }

        // zip code + radius
        if (formState.zipcodes) {
            let orPrefix = '';
            formState.zipcodes.forEach((zipObj, i) => {
                if (zipObj.zip) {
                    if (zipObj.zip.length < 5) {
                        activeItems.push({
                            label: `${orPrefix}Zip code starts with ${zipObj.zip}`,
                            field: `zipcodes.${i}.zip`,
                            delValue: '',
                        });
                    } else if (zipObj.radius) {
                        activeItems.push({
                            label: `${orPrefix}Within ${zipObj.radius} miles of zip code ${zipObj.zip}`,
                            field: `zipcodes.${i}.radius`,
                            delValue: '',
                        });
                    } else {
                        activeItems.push({
                            label: `${orPrefix}Zip code = ${zipObj.zip}`,
                            field: `zipcodes.${i}.zip`,
                            delValue: '',
                        });
                    }
                    orPrefix = 'or, ';
                }
            });
        }

        //BD Firm branch count
        if (formState.branch_count_range) {
            const minCount = formState.branch_count_range[0]
            const maxCount = formState.branch_count_range[1]

            let label;
            if (minCount === 0 && maxCount != 'all') {
                label = `Branch count less than ${maxCount}`
            } else if (minCount != 0 && maxCount === 'all') {
                label = `Branch count more than ${minCount}`
            } else if (minCount != 0 && maxCount != 'all') {
                label = `Branch count between ${minCount} and ${maxCount}`
            }

            if (minCount != 0 || maxCount != 'all') {
                activeItems.push({
                    label: label,
                    field: 'branch_count_range',
                    delValue: [0, 'all'],
                });
            }
        }

        //BD Firm contact count
        if (formState.contact_count_range) {
            const minCount = formState.contact_count_range[0]
            const maxCount = formState.contact_count_range[1]

            let label;
            if (minCount === 0 && maxCount != 'all') {
                label = `Contact count less than ${maxCount}`
            } else if (minCount != 0 && maxCount === 'all') {
                label = `Contact count more than ${minCount}`
            } else if (minCount != 0 && maxCount != 'all') {
                label = `Contact count between ${minCount} and ${maxCount}`
            }

            if (minCount != 0 || maxCount != 'all') {
                activeItems.push({
                    label: label,
                    field: 'contact_count_range',
                    delValue: [0, 'all'],
                });
            }
        }

        // BD Firm County
        if (!!formState.county.length) {
            activeItems.push({
                label: `County = "${formState.county}"`,
                field: 'county',
                delValue: '',
            });
        }

        // BD Disclosure
        if (formState.disclosure) {
            let label;
            if (formState.disclosure === '2') {
                label = "Has no disclosure"
            } else if (formState.disclosure === '1') {
                label = "Has disclosure"
            }

            activeItems.push({
                label: label,
                field: 'disclosure',
                delValue: '',
            });
        }
        // BD Disclosure
        if (formState.disclosure_types && formState.disclosure_types.length) {
            formState.disclosure_types.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.types?.length) {
                    let anyOrAll = row.disclosure_type_bool === 'and' ? 'all' : 'any';

                    let label;
                    if (row.label?.length) {
                        label = row.label.length === 1
                            ? `With disclosure in type ${row.label[0]}`
                            : `With disclosure in ${anyOrAll} of these types: ${row.label.join(', ')}`;
                    }
                    else {
                        label = row.types.length === 1
                            ? `With disclosure in type ${row.types[0]}`
                            : `With disclosure in ${anyOrAll} of these types: ${row.types.join(', ')}`;
                    }
                    item.children.push({
                        label: label,
                        field: `disclosure_types.${index}.types`,
                        delValue: [],
                    });
                
                }

                if (row.types?.length) {
                    activeItems.push(item);
                }
            });
        }

        // BD firm executive titles in the search param list
        if (formState.executive_titles && formState.executive_titles.length) {
            formState.executive_titles.forEach((row, index) => {
                let item = {
                    label: row.exclude ? 'Excluding those:' : 'Including those:',
                    className: 'license-clause-parent',
                    children: [],
                };

                if (row.titles?.length) {
                    let anyOrAll = row.executive_titles_bool === 'and' ? 'all' : 'any';
                    let label = row.titles.length === 1
                        ? `With executive in title ${row.titles[0]}`
                        : `With executive in ${anyOrAll} of these titles: ${row.titles.join(', ')}`;

                    item.children.push({
                        label: label,
                        field: `executive_titles.${index}.titles`,
                        delValue: [],
                    });
                }

                if (row.titles?.length) {
                    activeItems.push(item);
                }
            });
        }


        // RR individuals executive titles in the search param list
        if (this.props.allowUnset && activeItems.length >= 0){
            let label;
            if (formState.search_executive) {
                label = 'Search by RR Individual'
            } else {
                label = 'Search by Firm' 
            }
            activeItems.push({
                label: label,
                field: 'search_executive',
                className: 'mt-1'
            });
        }

        // BD Clear All
        if (this.props.allowUnset && activeItems.length >= 0) {
            activeItems.push({
                label: <><i>Clear all</i></>,
                field: '',
                delValue: new BdSearchState(),
                className: 'mt-1'
            });
        }

        return activeItems;
    }
}

