// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import PageTitle from '../components/PageTitle';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';
import rrIndivColumns from '../constants/rrIndivColumns';
import { rrIndivReportTypes } from '../constants/reportTypes';
import RRIndivDetailsModal from '../components/RRIndivDetailsModel';
import BdFirmDetailsModal from "../components/BdFirmDetailsModel";
import IarDetailsModal from '../components/IarDetailsModal';
import RiaFirmDetailsModal from '../components/RiaFirmDetailsModal';


class rrSearchResultsPage extends React.Component {
    static propTypes = {
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            showAgentDetailsModal: false,
            IarIndivDetailsModal: false,
            IarModal: false,
            RRBdModal:false,
            agentDetailsId: null,
            riaDetailsId: null,
            rowId: null,
            iarRowId: null,
            hasProfileModel: null,
            RRBdRowId:null,
            bdRiaRowId:null,
        }
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.handleDetailsModalClose = this.handleDetailsModalClose.bind(this);
        this.handleIarIndividualDetails = this.handleIarIndividualDetails.bind(this);
        this.handleIarDetailsModalClose = this.handleIarDetailsModalClose.bind(this);
        this.onIarIndividualDetails = this.onIarIndividualDetails.bind(this);
        this.onBdRRIndividualDetails = this.onBdRRIndividualDetails .bind(this);
        this.onBdIarIndividualDetails = this.onBdIarIndividualDetails.bind(this);
    }

    handleIndividualDetails(id, employers) {
        this.setState({
            showAgentDetailsModal: true,
            agentDetailsId: id,
            hasProfileModel: employers,
            RRBdModal: false,
        })
    }

    handleDetailsModalClose() {
        this.setState({
            showAgentDetailsModal: false,
            IarIndivDetailsModal: false,
            IarModal: false,
            agentDetailsId: null,
            hasProfileModel: null,
            RRBdModal: false,
        })
    }

    handleIarIndividualDetails(id, hasIarProfile, rowValues) {
        this.setState({
            IarIndivDetailsModal: true,
            IarModal: false,
            hasProfileModel: hasIarProfile,
            rowId: id,
            showAgentDetailsModal: false,
            riaDetailsId: rowValues,
        })
    }

    handleIarDetailsModalClose() {
        this.setState({
            IarIndivDetailsModal: false,
            rowId: null,
            hasProfileModel: null,
            IarModal: false,
            showAgentDetailsModal: false,
            RRBdModal: false,
        })
    }

    onIarIndividualDetails(id) {
        this.setState({
            RRBdModal: true,
            RRBdRowId: id,
            IarIndivDetailsModal: false,
            hasProfileModel: null,
        })
    }

    onBdRRIndividualDetails(id,  rowValues=[], onClickValues) {
        this.setState({
            RRBdModal: true,
            RRBdRowId: id,
            riaDetailsId: rowValues,
            IarIndivDetailsModal: false,
            showAgentDetailsModal: false,
            hasProfileModel: onClickValues,
        })
    }


    onBdIarIndividualDetails(id, rowValues=[], hasIarProfile) {
        this.setState({
            IarIndivDetailsModal: false,
            IarModal: true,
            bdRiaRowId: id,
            hasProfileModel: hasIarProfile,
            riaDetailsId: rowValues,
            
        })
    }

    render() {
        const searchFormProps = this.props.allFormStates.rrIndivSearch;

        return <>
            <PageTitle title="<< Back to RR Search" className="btn"
                    onClick={() => this.props.history.push("/rr/search")} />

            <PaginatedSearchResultsTable searchFormState={searchFormProps.formState}
                recordCount={searchFormProps.rowCount}
                reportTypes={rrIndivReportTypes}
                possibleColumns={rrIndivColumns}
                detailsModalComponent={RRIndivDetailsModal}
                enableSaving={true}
                onModalChanged={this.handleIndividualDetails}
                onIarModalChanged={this.handleIarIndividualDetails}
                onIrModalChanged={this.onBdRRIndividualDetails}
                listBdRow={this.state.hasProfileModel}  />
            <BdFirmDetailsModal
                isOpen={this.state.showAgentDetailsModal}
                rowId={this.state.agentDetailsId}
                listRow={this.state.hasProfileModel}
                hideBdModel={this.state.hasProfileModel}
                listValue={this.state.riaDetailsId} 
                onClickChanged={this.handleIndividualDetails}
                onModalChanged={this.onBdRRIndividualDetails}
                onClose={this.handleDetailsModalClose} />
            <IarDetailsModal
                isOpen={this.state.IarIndivDetailsModal}
                rowId={this.state.rowId}
                listValue={this.state.hasProfileModel}
                rowIndiv={this.state.riaDetailsId}
                onClickChanged={this.handleIarIndividualDetails}
                onIrModalChanged={this.onBdRRIndividualDetails} 
                onModalChanged={this.onBdIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose}/>
            <RiaFirmDetailsModal
                isOpen={this.state.IarModal}
                rowId={this.state.bdRiaRowId}
                listValue={this.state.riaDetailsId}
                listRow={this.state.hasProfileModel}
                onModalChanged={this.handleIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />
            <RRIndivDetailsModal
                isOpen={this.state.RRBdModal}
                rowId={this.state.RRBdRowId}
                rowIndiv={this.state.hasProfileModel? this.state.hasProfileModel : this.state.riaDetailsId}
                listValue={this.state.riaDetailsId}
                onClickChanged={this.onBdRRIndividualDetails}
                onIarModalChanged={this.handleIarIndividualDetails}
                onModalChanged={this.handleIndividualDetails}
                onClose={this.handleDetailsModalClose} />
                </>
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
});

export default connect(mapStateToProps)(rrSearchResultsPage)