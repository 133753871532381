import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Card, CardBody, CardTitle, Spinner,
} from 'reactstrap';
import SearchParamsListAndUnset from './SearchParamsListAndUnset';


export default class GenerateReportButton extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        recordCount: PropTypes.number.isRequired,
        searchIsCounting: PropTypes.bool.isRequired,
        formState: PropTypes.object.isRequired,
    };
    static defaultProps = {
        recordCount: 0,
    };

    render() {
        return (
            <Card className={"position-sticky sticky-top"}>
                <CardBody>
                    <CardTitle tag="h5">
                        <span className={this.props.searchIsCounting ? 'text-muted' : ''}>
                            {this.props.recordCount.toLocaleString()} Records Matched
                        </span>
                        {this.props.searchIsCounting &&
                        <> <Spinner as="span" animation="border" size="sm"/></>}
                    </CardTitle>
                    <Button color="primary" disabled={this.props.searchIsCounting || this.props.recordCount === 0}
                            onClick={this.props.onSubmit}>
                        Generate and View Report
                    </Button>

                    <SearchParamsListAndUnset onChange={this.props.onChange} formState={this.props.formState} allowUnset={true} />
                </CardBody>
            </Card>
        );
    }
}
