export function splitCSVString(csvString) {
    if (Array.isArray(csvString)) {
        return csvString;
    }
    return csvString.replace(' ', ',')
        .split(',')
        .map(el => el.trim())
        .filter(el => !!el);
}

export function splitFullNameString(arrayString) {
    if (Array.isArray(arrayString)) {
        return arrayString;
    }
    if (typeof arrayString === 'string') {
        const splitted = arrayString.split(',')
        if (splitted[0] === '') {
            return []
        }

        return splitted
    }

    return ''
}

export function splitIntegerCSVString(csvString) {
    let values = splitCSVString(csvString);
    return values.map(el => Number(el)).filter(el => !isNaN(el));
}