// @flow
import React from 'react';
import {
    Col, CustomInput,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AddressStatesSearchSelects from './AddressStatesSearchSelects';
import IndividualLicenseTypeSelect from './IndividualLicenseTypeSelect';
import ZipCountySearchField from './ZipCountySearchField';
import EmploymentcountRR from '../Employmentcount';
import YearsEmployedRR from '../YearsEmployedRR';
import IndividualDisclosureTypeSelect from './IndividualDisclosureTypeSelect';
import IndividualotherbusinessActivities from './IndividualotherbusinessActivities';
import IndividualDesignationTypeSelect from './IndividualDesignationTypeSelect';

const toogleArrValue = (arr, val) => {
    const ind = arr.indexOf(val)
    if (ind === -1) {
        return [...arr, val]
    } else {
        return [...arr.slice(0, ind), ...arr.slice(ind + 1)]
    }
}

export default class ContactInfoSearchFields extends React.Component {
    static propTypes = {
        showAddressTypeCheckboxes: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
        formName: PropTypes.string,
    };
    static defaultProps = {
        showAddressTypeCheckboxes: true,
    }

    constructor(props) {
        super(props);
        this.state = {
            remountKey: (new Date()).getTime(),
        }
    }

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue;
        selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        this.props.onChange({
            target: {
                name: fieldName,
                value: selectedValue,
            }
        });
    }

    render() {
        let formState = this.props.formState;
        
        return <>
            <Row>
                <Col>
                    <h4 className="header-title">Contact Information</h4>
                </Col>
            </Row>
            {this.props.showAddressTypeCheckboxes &&
                <Row>
                    <Col xs={6}>
                        <Label>Search which addresses?</Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_office_address" checked={formState.search_office_address}
                                className="mr-1" />
                            Office
                        </Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_mailing_address" checked={formState.search_mailing_address}
                                className="mr-1" />
                            Mailing
                        </Label>
                    </Col>
                </Row>
            }
            {this.props.showflageTypeCheckboxes &&
                <Row>
                    <Col xs={3}>
                        <Label>Search which addresses?</Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_private_residence" checked={formState.search_private_residence}
                                className="mr-1" />
                            Is Private Residence
                        </Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_branch_office_location" checked={formState.search_branch_office_location}
                                className="mr-1" />
                            Is Branch Office Location
                        </Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_supervised_by_branch_office" checked={formState.search_supervised_by_branch_office}
                                className="mr-1" />
                            Is Supervised By Branch Office
                        </Label>
                    </Col>
                </Row>
            }
            {formState.search_type == 'bdFirmSearch' &&
                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <Input type="text" name="street_address" value={formState.street_address}
                                onChange={this.props.onChange} placeholder="Street Address" />
                        </FormGroup>
                        <FormGroup>
                            <Input type="text" name="city" value={formState.city}
                                onChange={this.props.onChange} placeholder="City" />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                            <FormGroup>
                                <Input type="text" name="area_code" value={formState.area_code}
                                    onChange={this.props.onChange} placeholder="Area Code" />
                            </FormGroup>
                            <FormGroup>
                                <Input type="text" name="phone_number" value={formState.phone_number}
                                    onChange={this.props.onChange} placeholder="Phone Number" />
                            </FormGroup>
                    </Col>
                </Row>
            }
            {formState.search_type == 'rrIndivSearch' &&
                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <Input type="text" name="street_address" value={formState.street_address}
                                onChange={this.props.onChange} placeholder="Street Address" />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                    <FormGroup>
                            <Input type="text" name="city" value={formState.city}
                                onChange={this.props.onChange} placeholder="City" />
                        </FormGroup>
                    </Col>
                </Row>
            }
            <Row className="pt-2">
                <AddressStatesSearchSelects formName={this.props.formName} />
            </Row>
            <ZipCountySearchField formName={this.props.formName} />
            {this.props.showIndividualLicenseTypeSelect &&
                <>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="pt-2">
                                    <Label for="finra_registered">FINRA Registered</Label>
                                </Col>
                                <Col className="pt-2">
                                    <Label for="gender">Gender</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="finra_registered" id="finra_registered"
                                            value={formState.finra_registered} data-ignore={true} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="1">Registered by FINRA</option>
                                            <option value="0">Not Registered by FINRA</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="gender" id="gender"
                                            value={formState.gender} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <EmploymentcountRR onChange={this.props.onChange} formState={formState} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="pt-2">
                                    <Label for="linkedin_profile">LinkedIn Profile</Label>
                                </Col>
                                <Col className="pt-2">
                                    <Label for="email_level">Email Address</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="linkedin_profile" id="linkedin_profile"
                                            value={formState.linkedin_profile} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="email_level" id="email_level"
                                            value={formState.email_level} data-ignore={true} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="1">Has Email Verified for Mass Mailing</option>
                                            <option value="2">Has Valid Email Address</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <YearsEmployedRR onChange={this.props.onChange} formState={this.props.formState} />
                        </Col>
                    </Row>

                    <Row className="otherbusinessActivities-selection">
                        <Col>
                            <IndividualotherbusinessActivities formName={this.props.formName} />
                        </Col>
                        <Col>
                            <IndividualDesignationTypeSelect formName={this.props.formName} />
                        </Col>
                    </Row>
                    <Row className="disclosure-type-selection">
                        <Col>
                            <IndividualDisclosureTypeSelect formName={this.props.formName} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <IndividualLicenseTypeSelect formName={this.props.formName} />
                    </Row>
                    <Row className="pt-2">

                    </Row>
                </>
            }
        </>
    }
}
