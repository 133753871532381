// @flow
import React from 'react';
import IarSearchForm from '../components/IarSearchForm';
import { IarSearchState } from '../redux/firstClassData/dtos';
import SearchPage from './SearchPage';


export default class IarSearch extends React.Component {

    render() {
        return <SearchPage pageTitle="IAR Search" formName="riaIndivSearch" searchFormStateClass={IarSearchState} searchFormComponent={IarSearchForm} />
    }
}
