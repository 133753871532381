// @flow
import React from 'react';
import {
    Col, CustomInput,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import ConsumerDataSelect from './ConsumerDataSelect';
import PropTypes from 'prop-types';
import YearOfBirthRange from '../YearOfBirthRange';
import APIClient from '../../utils/API';
import { consumerDataSearchFields } from '../../constants/agentSearchFields';
import { loggedInUserHasPermission } from '../../helpers/authUtils';


export default class AdditionalFiltersFields extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.getTypeaheadOptions = this.getTypeaheadOptions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.apiClient = new APIClient();
    }

    getTypeaheadOptions = (fieldName, inputValue, callback) => {
        this.apiClient.getTypeaheadOptions(fieldName, inputValue)
            .then(response => {
                callback(response.data.results);
            });
    };

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];

        this.props.onChange({
            target: {
                name: fieldName,
                value: selectedValue,
            }
        });
    }

    render() {
        let formState = this.props.formState;
        const hasSearchAgentsOnlyPermission = loggedInUserHasPermission('search_agents_only');
        const hasSearchAgenciesOnlyPermission = loggedInUserHasPermission('search_agencies_only');
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');

        let selectedCaptiveCompanies = formState.captive_companies.map(companyName => {
            return {value: companyName, label: companyName};
        });

        const ethnicityField = consumerDataSearchFields.find(field => field.searchFieldLabel === 'Ethnicity');
        const incomeField = consumerDataSearchFields.find(field => field.searchFieldLabel === 'Income');
        return <>
            <h4 className="header-title">Additional Filters</h4>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col lg={6} className = { !hasConsumerDataSearchPermission ? "permission-card-section restricted" : "" }>
                            <FormGroup>
                                <Label for="gender">Gender</Label>
                                <Input type="select" name="gender" id="gender"
                                    value={formState.gender} onChange={this.props.onChange}>
                                    <option value="">----</option>
                                    {hasConsumerDataSearchPermission &&
                                        <option value="F">Female</option>
                                    }
                                    {hasConsumerDataSearchPermission &&
                                        <option value="M">Male</option>
                                    }
                                </Input>
                            </FormGroup>
                            <ConsumerDataSelect onChange={this.props.onChange} formState={formState} {...ethnicityField} />

                            <YearOfBirthRange onChange={this.props.onChange} formState={formState} />
                            <ConsumerDataSelect onChange={this.props.onChange} formState={formState} {...incomeField} />
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <FormGroup>
                                    <Label for="email_level">Email Address</Label>
                                    <Input type="select" name="email_level" id="email_level"
                                        value={formState.email_level} onChange={this.props.onChange}>
                                        <option value="">----</option>
                                        <option value="1">Has Email Verified for Mass Mailing</option>
                                        <option value="2">Has Valid Email Address</option>
                                    </Input>
                                </FormGroup>

                                {(hasSearchAgentsOnlyPermission || hasSearchAgenciesOnlyPermission) ? <></> :
                                    <Input type="select" name="filter_is_agent" id="filter_is_agent" className="mt-2"
                                        value={formState.filter_is_agent} onChange={this.props.onChange}>
                                        <option value="both">Both Individuals and Agencies</option>
                                        <option value="agents">Only Individuals</option>
                                        <option value="agencies">Only Agencies</option>
                                    </Input>
                                }

                                <Input type="select" name="filter_captive" id="filter_captive"  className="mt-2"
                                    value={formState.filter_captive} onChange={this.props.onChange}>
                                    <option value="both">Both Captive and Independent</option>
                                    <option value="independent">Independent</option>
                                    <option value="captive">Captive</option>
                                    <option value="include">Independent, or captured by these specific companies</option>
                                    <option value="exclude">Independent or captive, but not captive by these specific companies</option>
                                    <option value="only">Only those captured by these specific companies</option>
                                </Input>

                                {['both', 'independent', 'captive'].indexOf(formState.filter_captive) === -1 &&
                                    <AsyncSelect
                                        placeholder="Captive Companies"
                                        isMulti
                                        value={selectedCaptiveCompanies || null}
                                        isClearable
                                        defaultOptions
                                        cacheOptions
                                        loadOptions={(inputValue, callback) => this.getTypeaheadOptions('captive_company', inputValue, callback)}
                                        onChange={value => this.handleSelectChange('captive_companies', value)}
                                    />
                                }
                            </FormGroup>

                            <FormGroup>
                                <Label for="quicksearch">Search in all data</Label>
                                <Input name="quicksearch" id="quicksearch" value={formState.quicksearch}
                                        onChange={this.props.onChange} placeholder="Quick Search" />
                                <div className="text-right">
                                    <CustomInput type="switch" id="q1" name="quicksearch_exclude" checked={formState.quicksearch_exclude}
                                                    onChange={this.props.onChange} label="Exclude these matches" value={true} />
                                </div>
                            </FormGroup>


                            <CustomInput type="switch" id="f3" name="filter_primary_phone" checked={formState.filter_primary_phone}
                                            onChange={this.props.onChange} label="Only Agents with Primary Phone" value={true} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }
}
