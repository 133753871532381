import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const otherbusinessactivities = [
{ label: 'Registered Broker Dealer', value: 'otheractivities_brokerdealer' },
{ label: 'Broker Dealer Representative', value: 'otheractivities_registeredrepresentative' },
{ label: 'Commodity Pool Operator or Commodity Trading Advisor', value: 'otheractivities_commoditypooloradvisor' },
{ label: 'Futures Commission Merchant', value: 'otheractivities_futurescommissionmerchant' },
{ label: 'Real Estate Broker Dealer or Agent', value: 'otheractivities_realestateagent' },
{ label: 'Insurance Broker or Agent', value: 'otheractivities_insuranceagent' },
{ label: 'Bank', value: 'otheractivities_bank' },
{ label: 'Trust Company', value: 'otheractivities_trustcompany' },
{ label: 'Registered Municipal Advisor', value: 'otheractivities_registeredmunicipaladvisor' },
{ label: 'Registered Security Based Swap Dealer', value: 'otheractivities_securityswapdealer' },
{ label: 'Major Security Based Swap Participant', value: 'otheractivities_securityswapparticipant' },
{ label: 'Accountant or Accounting Firm', value: 'otheractivities_accountant' },
{ label: 'Lawyer or Law Firm', value: 'otheractivities_lawyer' },
{ label: 'Other Financial Product Salesperson', value: 'otheractivities_otherfinancialproducts' }
];


export default class DesignationTypes extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideTypes: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOptions) {
        let selectedValue = null;
        let selectedLabel = null;
        if (this.props.multi) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
            selectedLabel = selectedOptions ? selectedOptions.map(el => el.label) : [];
        } else if (selectedOptions) {
            selectedValue = selectedOptions.value;
            selectedLabel = selectedOptions.label;
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedValue,
                label: selectedLabel,
            }
        });
    }

    render() {
        let selectedTypes = this.props.value
            ? otherbusinessactivities.filter(row => this.props.value.indexOf(row.value) >= 0)
            : [];
        let displayTypes = this.props.hideTypes
            ? otherbusinessactivities.filter(row => this.props.hideTypes.indexOf(row.value) < 0)
            : otherbusinessactivities;

        return <Select
            closeMenuOnSelect={!this.props.multi}
            value={selectedTypes}
            isMulti={this.props.multi}
            options={displayTypes}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={this.props.multi ? 'Select other Business Activitie(s)' : 'other Business Activities..'}
        />;
    }
}
