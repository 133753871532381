import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Label,
    CardBody,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    Row,
} from 'reactstrap';
import {PAGE_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import {CONSUMER_DATA_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import LicenseSearchFields from './SearchFormCards/LicenseSearchFields';
import AddressSearchFields from './SearchFormCards/AddressSearchFields';
import AdditionalFiltersFields from './SearchFormCards/AdditionalFiltersFields';
import ConsumerDataFiltersFields from './SearchFormCards/ConsumerDataFiltersFields';
import EmailAddressSelect from './SearchFormCards/EmailAddressSelect';
import FullNameSelect from './SearchFormCards/FullNameSelect';
import NpnSelect from './SearchFormCards/NpnSelect';
import { loggedInUserHasPermission } from '../helpers/authUtils';

export default class GroupSearchForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        innerRef: PropTypes.object,
        formState: PropTypes.object.isRequired,
        formName: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
        this.formatCsv = this.formatCsv.bind(this);
    }

    handleSearchFieldChange(event) {
        this.props.onFieldChange(event);
    }
    formatCsv(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }
    formatNames(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }

    render() {
        let formState = this.props.formState;
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');
        let hasPermission =  this.props.hasPermission
        return (
            <>
        <Form onSubmit={this.props.onSubmit} className = { !hasPermission ? "permission-card-section restricted" : "" }>
            <LicenseSearchFields formState={formState} onChange={this.handleSearchFieldChange} />
            <Card>
                <CardBody>
                    <AddressSearchFields formState={formState} onChange={this.handleSearchFieldChange} formName={this.props.formName} />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <AdditionalFiltersFields formState={formState} onChange={this.handleSearchFieldChange} />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <h4 className="header-title">Agent Profile</h4>

                    <Row className="mb-2">
                        <Col lg={12}>
                            <Row>
                                <Col lg={12}>
                                    <Row className="pt-2">
                                        <NpnSelect formName={this.props.formName} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col lg={12}>
                                    <CustomInput type="switch" id="s1" name="npn_search_states" checked={formState.npn_search_states}
                                                    onChange={this.props.onFieldChange} label="Also search state license numbers" value={true} />
                                </Col>
                            </Row>
                            <Row className="pt-3">
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="text" name="first_name" value={this.formatNames(formState.first_name)}
                                               onChange={this.props.onFieldChange} placeholder="First Name" />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="text" name="last_name" value={this.formatNames(formState.last_name)}
                                               onChange={this.props.onFieldChange} placeholder="Last Name" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <FullNameSelect formName={this.props.formName} />
                            </Row>
                            <Row className="pt-2">
                                <EmailAddressSelect formName={this.props.formName} />
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody className = { (!hasConsumerDataSearchPermission &&  hasPermission) ? "permission-card-section restricted" : "" }>
                    <ConsumerDataFiltersFields formState={formState} onChange={this.handleSearchFieldChange} />
                </CardBody>
                {(!hasConsumerDataSearchPermission &&  hasPermission) ?
                    <div className="permission-card">
                        <div className='card permission'>
                            <h6 className="consumer-data-permission-headers">{CONSUMER_DATA_RESTRICTING_MESSAGE}</h6>
                        </div>
                    </div> : ""
                }
            </Card>
        </Form>
                {!hasPermission ?
                    <div className="permission-card">
                        <div className='card permission'>
                            <h6 className="permission-header">{PAGE_RESTRICTING_MESSAGE}</h6>
                        </div>
                    </div> : ""
                }
        </>
        );
    }
}
