import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Card, CardBody, CardTitle, Col, Input, Row, Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import SaveReportModal from './SaveReportModal';
import { SaveAsReportModal } from './SaveReportModal';
import QuotaLimitModal from './QuotaLimitModal';
import FileDownloadForm from './FileDownloadForm';
import APIClient from '../utils/API';

import SearchParamsListAndUnset from './SearchParamsListAndUnset';
import agentColumns from '../constants/agentColumns';
import Paginator from './Paginator';
import AgentDetailsModal from './AgentDetailsModal';
import fieldLookups from '../constants/fieldLookups';
import EmailLevelIcon from './EmailLevelIcon';
import renderDollars from '../utils/renderDollars';
import { loggedInUserHasPermission } from '../helpers/authUtils';
import { consumerDataFields } from '../constants/reportTypes';


const LookupValueCell = ({ rowData, dataKey, ...props }) => {
    let val = rowData[dataKey]
    if (fieldLookups[dataKey]) {
        if (typeof val === 'string') {
            val = (val || '').split(', ');
            val = val.map(v => fieldLookups[dataKey][v] || v).join(', ');
        }
    }
    return (
        <Cell {...props}>{val}</Cell>
    );
};

export default class PaginatedSearchResultsTable extends React.Component {
    static propTypes = {
        searchFormState: PropTypes.object.isRequired,
        recordCount: PropTypes.number.isRequired,
        enableSaving: PropTypes.bool.isRequired,
        currentSearchName: PropTypes.string,
        defaultReportType: PropTypes.string,
        reportTypes: PropTypes.array,
        detailsModalComponent: PropTypes.any,  // TODO: can we say "component class reference"?
        possibleColumns: PropTypes.array,
        tableProps: PropTypes.object,
        onModalChanged: PropTypes.func,
        onIarModalChanged: PropTypes.func, 
        onIrModalChanged: PropTypes.func,
        listRow: PropTypes.any,
        listBdRow: PropTypes.any,
    };
    static defaultProps = {
        possibleColumns: agentColumns,
        detailsModalComponent: AgentDetailsModal,
    };

    constructor(props) {
        super(props);
        this.client = new APIClient();

        this.state = {
            isLoadingData: true,
            tableData: [],
            tableRedrawKey: 1,
            recordCount: props.recordCount,
            reportSizePerPage: 50,
            reportCurrentPage: 1,
            reportType: props.defaultReportType || 'default',
            reportTypes: props.reportTypes,
            sortColumn: '',
            sortColumnDirection: '',
            saveReportName: '',
            showSaveReportModal: false,
            showAsSaveReportModal: false,
            showExportReportQuotaErrorModal: false,
            showRowDetailsModal: false,
            showRowDetailsId: null,
            canViewAgentDetails: true,
            quotaDailyLimit: 0,
            quotaRemaining: 0,
            isExporting: false,
            downloadFileViaForm: null,
        };

        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.getReportType = this.getReportType.bind(this);
        this.getCurrentReportColumns = this.getCurrentReportColumns.bind(this);
        this.showSaveReportModal = this.showSaveReportModal.bind(this);
        this.showAsSaveReportModal = this.showAsSaveReportModal.bind(this);
        this.closeModals = this.closeModals.bind(this);
        this.handleSaveReport = this.handleSaveReport.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.handleConfirmExportReportSubset = this.handleConfirmExportReportSubset.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.confirmedExportReport = this.confirmedExportReport.bind(this);
        this.findPaginatedTableData = this.findPaginatedTableData.bind(this);
        this.findPaginatedTableDataDebounced = AwesomeDebouncePromise(this.findPaginatedTableData, 100);
        this.handleResultsRowClick = this.handleResultsRowClick.bind(this);
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeSizePerPage = this.handleChangeSizePerPage.bind(this);
        this.onClickIndividualDetails = this.onClickIndividualDetails.bind(this);
    }

    componentDidMount(): void {
        this.findPaginatedTableDataDebounced();
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {
        if (this.props.searchFormState && (this.props.searchFormState !== prevProps.searchFormState)) {
            this.findPaginatedTableDataDebounced();
        }
    }

    handleReportTypeChange(selectedOption) {
        this.setState({
            reportType: selectedOption.value,
        })
    }

    onClickIndividualDetails(id) {
        this.setState({
            showRowDetailsModal: true,
            showRowDetailsId: id,
        })
    }
    handleResultsRowClick(row, event, selectedReportType) {
        if (!this.state.canViewAgentDetails) {
            console.log('No permission to view agent details')
            return;
        }
        // we actually only want the fixed columns to be "clickable"
        // so check if such a cell was clicked, if not then just return
        if (
            !event.target.parentElement.classList.contains('clickable')
            && selectedReportType.value !== 'riaIndivData') {
            // console.log('row clicked, but not on a "clickable" cell', selectedReportType.value);
            return;
        }

        this.setState({
            showRowDetailsModal: true,
            showRowDetailsId: row.id,
            showRowDetailsUpdateId : this.state.tableData,
            showRowDetailsCountId : Math.min(this.state.reportCurrentPage * this.state.reportSizePerPage, this.state.recordCount),
        })
    }

    handleSortColumn(columnName) {
        let newState = {
            isLoadingData: true
        };
        if (columnName === this.state.sortColumn) {
            // just change the direction
            newState['sortColumnDirection'] = this.state.sortColumnDirection === 'asc' ? 'desc' : 'asc';
        } else {
            // sort by this new column, start with asc
            newState['sortColumn'] = columnName;
            newState['sortColumnDirection'] = 'asc';
        }
        this.setState(newState, () => {
            this.findPaginatedTableDataDebounced();
        });
    }

    handleChangePage(newPageNum) {
        this.setState({
            isLoadingData: true,
            reportCurrentPage: newPageNum,
        }, () => {
            this.findPaginatedTableDataDebounced();
        });
    }

    handleChangeSizePerPage(event) {
        this.setState({
            isLoadingData: true,
            reportSizePerPage: event.target.value,
        }, () => {
            this.findPaginatedTableDataDebounced();
        });
    }

    // make the ajax call to get a windowed set of search results, and display in the modal table
    findPaginatedTableData() {
        let searchParams = {
            ...this.props.searchFormState.forServer(),
            page_number: this.state.reportCurrentPage,
            page_size: this.state.reportSizePerPage,
            order_by: this.state.sortColumn,
            order_direction: this.state.sortColumnDirection,
        };


        return this.client.getSearchResults(searchParams)
            .then(response => {
                this.setState({
                    tableData: response.data.rows,
                    recordCount: response.data.total,
                    tableRedrawKey: this.state.tableRedrawKey + 1,
                    isLoadingData: false,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    showSaveReportModal = (event) => {
        let updateReport = this.props.searchFormState.export_name ? true : false;
        this.setState({
            isUpdateReport: updateReport,
            showSaveReportModal: true,
            showAsSaveReportModal: false,
            showExportReportQuotaErrorModal: false,
        });
    };

    showAsSaveReportModal = (event) => {
        this.setState({
            isUpdateReport: false,
            showSaveReportModal: false,
            showAsSaveReportModal: true,
            showExportReportQuotaErrorModal: false,
        });
    };

    closeModals = (event) => {
        this.setState({
            showSaveReportModal: false,
            showAsSaveReportModal: false,
            showExportReportQuotaErrorModal: false,
            showRowDetailsModal: false,
            showRiaFirmDetailsModal: false,
        });
    };

    // saving a report will put it into the user's "saved reports"
    // this actually copies all the results into a separate store
    handleSaveReport(reportName) {
        this.setState({
            saveReportName: reportName,
        });
        return this.saveReport(reportName);
    }

    saveReport(reportName = null) {
        let saveSearchParams = {
            name: reportName || this.state.saveReportName,
            report_type: this.state.reportType,
            search_params_json: JSON.stringify(this.props.searchFormState.forServer()),
            update_report: this.state.isUpdateReport || false
        };

        return this.client.saveSearch(saveSearchParams)
            .then(response => {
                this.closeModals();
            });
    }

    handleConfirmExportReportSubset() {
        this.setState({
            showExportReportQuotaErrorModal: false,
        });
        this.confirmedExportReport();
    }

    handleExport(event) {
        // confirm we can download all these rows, otherwise prompt about downloading just a subset
        this.client.checkExportQuotaSufficient(this.props.searchFormState)
            .then((quotaResults) => {
                if (quotaResults.data.search_count > quotaResults.data.remaining_quota) {
                    // confirm the user wants to download this
                    this.setState({
                        showExportReportQuotaErrorModal: true,
                        quotaDailyLimit: quotaResults.data.daily_limit,
                        quotaRemaining: quotaResults.data.remaining_quota,
                    })
                    return;
                }

                this.confirmedExportReport();
            });
    }

    confirmedExportReport() {
        let reportColumns = this.getCurrentReportColumns();
        // TODO: pass the full dict to the server, so we get the same column labels too
        let reportColumnFields = reportColumns.map(row => row.field);

        // now that we show an icon for the "email level", there is no column name
        // add these back in
        for (let i=reportColumnFields.length -1; i >= 0; i--) {
            if (reportColumnFields[i].indexOf('Email') >= 0) {
                reportColumnFields.splice(i + 1, 0, reportColumnFields[i] + ' Level');
            }
        }

        // if this is a saved search, use the search name in the exported filename
        const searchName = this.props.currentSearchName || 'export';

        // use the localized date in the exported filename
        const now = new Date();
        let exportFilename = `FCD-${searchName}-${now.toLocaleDateString()}-${now.toLocaleTimeString()}.csv`;

        const fileDownloadRequestParams = this.client.getExportFileDownloadParams(this.props.searchFormState, reportColumnFields, exportFilename);

        this.setState({
            isExporting: true,
            downloadFileViaForm: fileDownloadRequestParams,
        });

        // we fake the "exporting spinner" duration, by assuming how long it will take for the download to start
        let spinFor = Math.max(Math.min(this.state.recordCount * 0.5, 5000), 500);
        setTimeout(() => {
            this.setState({
                isExporting: false,
            });
        }, spinFor);
    }

    getReportType() {
        return this.props.reportTypes.find(report => report.value === this.state.reportType) || this.props.reportTypes[0];
    }

    filterReportColumns(reportColumnNames) {
        const updatedColumns = reportColumnNames.filter((item) => !consumerDataFields.includes(item));
        return updatedColumns;
    }

    getCurrentReportColumns() {
        let selectedReportType = this.getReportType();

        const searchConsumerData = loggedInUserHasPermission('search_consumerdata');
        let reportColumnNames = []
        if (!searchConsumerData && (this.props.searchFormState.search_type == 'groupSearch' || this.props.searchFormState.search_type == 'agentSearch')){
            reportColumnNames = this.filterReportColumns(selectedReportType.columns);
        }
        else {
            reportColumnNames = selectedReportType.columns;
        }

        let reportColumns = this.props.possibleColumns.filter(row => reportColumnNames.indexOf(row.text) >= 0);
        return reportColumns;
    }

    render() {
        let selectedReportType = this.getReportType();
        let reportColumns = this.getCurrentReportColumns();

        const {verticalAlign, wordWrap} = (this.props.tableProps || {});
        let columnComponents = reportColumns.map((col) => {
            if (verticalAlign) {
                col = {...col, verticalAlign: verticalAlign};
            }
            if (col.field.indexOf('Email') >= 0) {
                // use a column that shows the email level too
                return <Column {...col}>
                    <HeaderCell>{col.text}</HeaderCell>
                    <Cell>{rowData => <>
                        <EmailLevelIcon level={rowData[`${col.field} Level`]} />
                        &nbsp;
                        {rowData[col.field]}
                    </>
                    }</Cell>
                </Column>
            }
            if (col.field.endsWith(' $M')) {
                // use a column that renders a rounded amount
                return <Column {...col}>
                    <HeaderCell>{col.text}</HeaderCell>
                    <Cell>{rowData => renderDollars(rowData[col.field])}</Cell>
                </Column>
            }

            const cellClickableClassName = ('fixed' in col) ? 'clickable' : '';
            return <Column {...col}>
                <HeaderCell>{col.text}</HeaderCell>
                <LookupValueCell dataKey={col.field} className={cellClickableClassName}/>
            </Column>
        })

        let rowFrom = (this.state.reportCurrentPage - 1) * this.state.reportSizePerPage + 1;
        let rowTo = Math.min(this.state.reportCurrentPage * this.state.reportSizePerPage, this.state.recordCount);

        let tableHeight = Math.min(300 + this.state.reportSizePerPage * 20, window.innerHeight - 200);
        tableHeight = Math.max(tableHeight, 360);

        // the "sort column" icons are reversed?!
        let tableSortType = null;
        if (this.state.sortColumnDirection === 'asc') {
            tableSortType = 'desc';
        } else if (this.state.sortColumnDirection === 'desc') {
            tableSortType = 'asc';
        }

        return (
            <Row>
                <Col lg={9}>
                    <Card>
                        <CardBody>
                            <Table
                                // we need to recreate the table every time the data changes, as otherwise the scrollbars are janky
                                key={`agentTable${this.state.tableRedrawKey}`}
                                headerHeight={100}
                                height={tableHeight}
                                loading={this.state.isLoadingData}
                                data={this.state.tableData}
                                onRowClick={(row, event) => {
                                    this.handleResultsRowClick(row, event, selectedReportType);
                                }}
                                rowClassName={selectedReportType.value === 'riaIndivData' ? 'row-hovered' : ''}
                                onSortColumn={this.handleSortColumn}
                                sortColumn={this.state.sortColumn}
                                sortType={tableSortType}
                                className={'table-striped' + (this.state.canViewAgentDetails ? ' table-rows-clickable' : '')}
                                wordWrap={wordWrap === true}
                                children={columnComponents} >
                            </Table>

                            <Row className="mt-2">
                                <Col>
                                    <Input type="select"
                                        bsSize="sm"
                                        className="d-inline-block paginator-rows-per-page btn-secondary mr-2"
                                        value={this.state.reportSizePerPage}
                                        onChange={this.handleChangeSizePerPage}>
                                        <option value="5">5</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Input>

                                    <span>Showing rows {rowFrom} to {rowTo} of {this.state.recordCount.toLocaleString()}</span>
                                </Col>

                                <Col className="text-right">
                                    <Paginator
                                        currentPage={this.state.reportCurrentPage}
                                        maxPages={Math.ceil(this.state.recordCount / this.state.reportSizePerPage)}
                                        onPageChange={this.handleChangePage}
                                        className="d-inline-block" />
                                </Col>
                            </Row>
                            <SaveReportModal isOpen={this.state.showSaveReportModal}
                                            onSaveReport={this.handleSaveReport}
                                            onClose={this.closeModals}
                                            editReportName={this.props.searchFormState.export_name} />

                            <SaveAsReportModal isOpen={this.state.showAsSaveReportModal}
                                                    onSaveReport={this.handleSaveReport}
                                                    onClose={this.closeModals} />

                            <QuotaLimitModal isOpen={this.state.showExportReportQuotaErrorModal}
                                                quotaDailyLimit={this.state.quotaDailyLimit}
                                                quotaRemaining={this.state.quotaRemaining}
                                                onConfirmExport={this.handleConfirmExportReportSubset}
                                                onClose={this.closeModals} />

                            <this.props.detailsModalComponent
                                isOpen={this.state.showRowDetailsModal}
                                rowId={this.state.showRowDetailsId}
                                updateId={this.state.showRowDetailsUpdateId}
                                countId={this.state.showRowDetailsCountId}
                                hideModel={this.props.listRow}
                                hideBdModel={this.props.listBdRow}
                                onClose={this.closeModals}
                                onModalChanged={this.props.onModalChanged}
                                onClickChanged={this.onClickIndividualDetails}
                                onIrModalChanged={this.props.onIrModalChanged}
                                onIarModalChanged={this.props.onIarModalChanged}
                                zipCode={this.props.searchFormState.zipcodes[0].zip??''}
                            />

                            {this.state.downloadFileViaForm &&
                                <FileDownloadForm
                                    actionPath={this.state.downloadFileViaForm.url}
                                    onDownloadComplete={() => this.setState({downloadFileViaForm: null})}
                                    formData={this.state.downloadFileViaForm.formData}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={3}>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">
                                {this.state.recordCount.toLocaleString()} Records Matched
                            </CardTitle>

                            <SearchParamsListAndUnset allowUnset={false} formState={this.props.searchFormState} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">
                            Change report type:
                            </CardTitle>

                            <Select
                                value={selectedReportType}
                                options={this.props.reportTypes}
                                onChange={this.handleReportTypeChange}
                            />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Button color="primary" onClick={this.handleExport}
                                    disabled={this.state.isExporting}>
                                {this.state.isExporting
                                    ? <>Exporting <Spinner as="span" animation="border" size="sm"/></>
                                    : 'Export CSV'
                                }
                            </Button>

                            {this.props.enableSaving &&
                                <Button color="primary" className="mt-2" onClick={this.showSaveReportModal}>
                                    Save Report
                                </Button>
                            }

                            {this.props.enableSaving && this.props.searchFormState.export_name != '' &&
                                <Button color="primary" className="mt-2" onClick={this.showAsSaveReportModal}>
                                    Save Report As
                                </Button>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}
