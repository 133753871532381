// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';

const FullNameSelect = ({ formName }) => {
    const dispatch = useDispatch();
    const name = useSelector((state) => state.FirstClassData.formStates[formName].formState.full_name);
    const updateFullName = useSelector((state) => state.FirstClassData.formStates[formName].formState.full_names);
    const deleteFullName = useSelector((state) => state.FirstClassData.formStates[formName].formState.full_names = '');

    const [full_name, canAddAnotherFullNameClause] = useMemo(() => {
        const canAddAnotherFullNameClause = name.filter(row => {
            if (updateFullName) {
                row.full_names = updateFullName;
            }
                    return row.full_names.length === 0;
                }).length === 0;
        return [name, canAddAnotherFullNameClause];
    }, [name]);


    const onFieldChange = (event, index) => {
        let fullName = full_name.slice();
        fullName[index][event.target.name] = event.target.value;
        dispatch(mergeSearch({ full_name: fullName }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let fullName = full_name.slice();
        fullName[index].exclude = !exclude;
        dispatch(mergeSearch({ full_name: fullName }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let fullName = full_name.slice();
        fullName.splice(index, 1);
        dispatch(mergeSearch({ full_name: fullName }, formName));
    };

    return (
        <Col lg={12}>
            {full_name.map((fullNameClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(fullNameClause.exclude, index)}
                        >
                            {fullNameClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these Full Names:

                        {full_name.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Full Names</Label>
                                        <Col lg={13}>
                                            <Input type="text" name="full_names" value={fullNameClause.full_names}
                                            onChange={(event) => onFieldChange(event, index)} placeholder="Full Names" />
                                        </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
        </Col>
    );
};

export default FullNameSelect;