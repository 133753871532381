// @flow
import React from 'react';
import { Col, FormGroup, Label, Row, Input} from 'reactstrap';
import PropTypes from 'prop-types';
import { RangeSlider } from 'rsuite';


export default class YearsEmployedRR extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    handleSliderChange(name, value) {
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    handleStatesSliderChange(name, value) {
        if (value[0] > value[1]) {
            var temp = value[0]
            value[0] = value[1]
            value[1] = temp
        }
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    rangeTicks(mark) {
        if (mark % 10 === 0) {
            if ((new Date().getFullYear() - mark) < 10) {
                return <span>{new Date().getFullYear()}</span>;
            }
            return <span>{mark}</span>;
        }
        return null;
    }

    render() {
        let formState = this.props.formState;

        return (
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Label>Years Employed</Label>
                        <Row>
                            <Col xs={1}>
                                <input type="checkbox" name="years_employeed"
                                    checked={formState.years_employeed}
                                    onChange={this.props.onChange} />
                            </Col>
                            <Col>
                                <RangeSlider min={1950} max={new Date().getFullYear()} disabled={!formState.years_employeed}
                                    value={formState.years_employeed_range}
                                    onChange={value => this.handleSliderChange('years_employeed_range', value)}
                                    renderMark={this.rangeTicks}
                                    graduated={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Label>Dually Registered</Label>
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Input type="select" name="dually_licensed" id="dually_licensed"
                                        value={formState.dually_licensed} onChange={this.props.onChange}>
                                        <option value="">----</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}
