// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import PageTitle from '../components/PageTitle';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';
import { insuranceAgentReportTypes } from '../constants/reportTypes';
import { riaFirmReportTypes } from '../constants/reportTypes';
import { iarReportTypes } from '../constants/reportTypes';
import {rrIndivReportTypes} from '../constants/reportTypes';
import {bdFirmReportTypes} from '../constants/reportTypes'
import { loggedInUserHasPermission } from '../helpers/authUtils';
import iarColumns from '../constants/iarColumns';
import riaFirmColumns from '../constants/riaFirmColumns';
import rrIndivColumns from '../constants/rrIndivColumns';
import bdFirmColumns from '../constants/bdFirmColumns';
import agentColumns from '../constants/agentColumns';
import AgentDetailsModal from '../components/AgentDetailsModal';
import RiaFirmDetailsModal from '../components/RiaFirmDetailsModal';
import IarDetailsModal from '../components/IarDetailsModal';
import RrIndivDetailsModal from  '../components/RRIndivDetailsModel';
import BdFirmDetailsModal from  '../components/BdFirmDetailsModel';
import {
    RIA_INDIVIDUAL_SEARCH,
    IAR_FIRM_SEARCH,
    QUICK_SEARCH_PAGE,
    INSURANC_SEARCH_PAGE,
    RR_INDIVIDUAL_SEARCH,
    BD_FIRM_SEARCH,
} from '../redux/layout/constants';


class QuickSearchResultsPage extends React.Component {
    static propTypes = {
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            showAgentDetailsModal: false,
            IarIndivDetailsModal: false,
            IarModal: false,
            agentDetailsId: null,
            riaDetailsId: null,
            rowId: null,
            iarRowId: null,
            hasProfileModel: null,
            RRBdRowId:null,
            bdRiaRowId:null,
            iarDetailsId: null,
            hasProfileModel: null,
            iarIndivDetailsModal: false,
            bdRiaModel: false,
            RRBdModal: false,
            RRIarModal: false,
            RRIarRowId: null,
        }
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.handleDetailsModalClose = this.handleDetailsModalClose.bind(this);
        this.handleIarIndividualDetails = this.handleIarIndividualDetails.bind(this);
        this.handleIarDetailsModalClose = this.handleIarDetailsModalClose.bind(this);
        this.onIarIndividualDetails = this.onIarIndividualDetails.bind(this);
        this.onBdRRIndividualDetails = this.onBdRRIndividualDetails .bind(this);
        this.onBdIarIndividualDetails = this.onBdIarIndividualDetails.bind(this);

        this.riaHandleIndividualDetails = this.riaHandleIndividualDetails.bind(this);
        this.riaHandleDetailsModalClose = this.riaHandleDetailsModalClose.bind(this);
        this.riaHandleIarIndividualDetails = this.riaHandleIarIndividualDetails.bind(this);
        this.riaHandleIarDetailsModalClose = this.riaHandleIarDetailsModalClose.bind(this);
        this.riaOnIarIndividualDetails = this.riaOnIarIndividualDetails.bind(this);
        this.riaOnBdModalChanged = this.riaOnBdModalChanged.bind(this);
        this.riaOnBdIarIndividualDetails = this.riaOnBdIarIndividualDetails.bind(this);
        this.riaOnBdRRIndividualDetails = this.riaOnBdRRIndividualDetails.bind(this);
        this.riaOnBdIarRRIndividualDetails = this.riaOnBdIarRRIndividualDetails.bind(this);

    }

    handleIndividualDetails(id , employers) {
        this.setState({
            showAgentDetailsModal: true,
            agentDetailsId: id,
            riaDetailsId: employers,
            RRBdModal: false,
        })
    }

    handleDetailsModalClose() {
        this.setState({
            showAgentDetailsModal: false,
            agentDetailsId: null,
            RRBdModal: false,
            hasProfileModel: null,
        })
    }

    handleIarIndividualDetails(id, hasRrProfile, rowValues) {
        this.setState({
            IarIndivDetailsModal: true,
            hasProfileModel: hasRrProfile,
            rowId: id,
            showAgentDetailsModal: false,
            riaDetailsId: rowValues,
            IarModal: false,
        })
    }

    handleIarDetailsModalClose() {
        this.setState({
            IarIndivDetailsModal: false,
            rowId: null,
            hasProfileModel: null,
            IarModal: false,
            showAgentDetailsModal: false,
        })
    }

    onIarIndividualDetails(id) {
        this.setState({
            RRBdModal: true,
            RRBdRowId: id,
            IarIndivDetailsModal: false,
            hasProfileModel: null,
        })
    }

    onBdRRIndividualDetails(id,  rowValues=[], onClickValues) {
        this.setState({
            RRBdModal: true,
            RRBdRowId: id,
            riaDetailsId: rowValues,
            IarIndivDetailsModal: false,
            showAgentDetailsModal: false,
            hasProfileModel: onClickValues,
        })
    }


    onBdIarIndividualDetails(id, rowValues=[], hasIarProfile) {
        this.setState({
            IarIndivDetailsModal: false,
            IarModal: true,
            bdRiaRowId: id,
            hasProfileModel: hasIarProfile,
            riaDetailsId: rowValues,
            
        })
    }

    riaHandleIndividualDetails(id, rowValues=[], onClickValues) {
        this.setState({
            showAgentDetailsModal: true,
            RRBdModal: false,
            agentDetailsId: id,
            riaDetailsId: rowValues,
            iarDetailsId: onClickValues,
        })
    }

    riaHandleDetailsModalClose() {
        this.setState({
            showAgentDetailsModal: false,
            agentDetailsId: null,
            riaDetailsId: null,
            iarDetailsId: null,
            RRBdModal: false,
            RRIarModal: false,
        })
    }

    riaHandleIarIndividualDetails(id, hasIarProfile, rowvalues) {
        this.setState({
            iarIndivDetailsModal: true,
            hasProfileModel: hasIarProfile,
            riaDetailsId: hasIarProfile,
            iarDetailsId: rowvalues,
            showAgentDetailsModal: false,
            IarModal: false,
            rowId: id,
        })
    }

    riaHandleIarDetailsModalClose() {
        this.setState({
            iarIndivDetailsModal: false,
            IarModal: false,
            rowId: null,
            hasProfileModel: null,
        })
    }

    riaOnIarIndividualDetails(id,hasIarProfile) {

        this.setState({
            IarModal: false,
            iarIndivDetailsModal: false,
            showAgentDetailsModal: true,
            agentDetailsId: id,
            hasProfileModel: null,
            iarDetailsId: hasIarProfile,
        })
    }

    riaOnBdModalChanged(id) {
        this.setState({
            showAgentDetailsModal: true,
            iarDetailsId: id,
        })
    }

    riaOnBdIarIndividualDetails(id, hasIarProfile) {
        this.setState({
            IarModal: true,
            iarIndivDetailsModal: false,
            showAgentDetailsModal: false,
            bdRiaRowId: id,
            hasProfileModel: null,
            hasProfileModel: hasIarProfile,
        })
    }

    riaOnBdRRIndividualDetails(id, onClickValues) {

        this.setState({
            RRBdModal: true,
            iarDetailsId: onClickValues,
            showAgentDetailsModal: false,
            iarIndivDetailsModal: false,
            RRBdRowId: id,
            hasProfileModel: null,
            RRIarModal: false,
        })
    }

    riaOnBdIarRRIndividualDetails(id, rowValues=[], onClickValues) {
        this.setState({
            RRIarModal: true,
            showAgentDetailsModal: false,
            iarIndivDetailsModal: false,
            RRBdModal: false,
            RRIarRowId: id,
            hasProfileModel: null,
            riaDetailsId: rowValues,
            iarDetailsId: onClickValues,
        })
    }

    render() {
        const searchFormProps = this.props.allFormStates.quickSearch;
        const formState = searchFormProps.formState;
        let columnsHeader;
        let reportTypes;
        let modalComponent;
        // Added a function for passing the reportTypes and columnsHeader to the PaginatedSearchResultsTable component
        if (formState.search_type == RIA_INDIVIDUAL_SEARCH) {
            reportTypes = iarReportTypes.filter(report => loggedInUserHasPermission(report.permission));
            columnsHeader = iarColumns;
            return (
                <>
                    <PageTitle title={`Quicksearch results on "${QUICK_SEARCH_PAGE[formState.search_type] ?? INSURANC_SEARCH_PAGE}" page`} />
    
                    <PaginatedSearchResultsTable searchFormState={formState}
                        recordCount={searchFormProps.rowCount}
                        reportTypes={reportTypes}
                        possibleColumns={columnsHeader}
                        detailsModalComponent={IarDetailsModal}
                        enableSaving={true}
                        onModalChanged={this.handleIndividualDetails}
                        onIrModalChanged={this.handleIarIndividualDetails}
                        onIarIndividualDetails={this.onIarIndividualDetails} />

            <RiaFirmDetailsModal
                isOpen={this.state.showAgentDetailsModal}
                rowId={this.state.agentDetailsId}
                listRow={this.state.riaDetailsId}
                listValue={this.state.riaDetailsId} 
                onClickChanged={this.handleIndividualDetails}
                onModalChanged={this.onBdRRIndividualDetails}
                onClose={this.handleDetailsModalClose}
            />
            <RrIndivDetailsModal
                isOpen={this.state.IarIndivDetailsModal}
                rowId={this.state.rowId}
                // listRow={this.state.hasProfileModel}
                listValue={this.state.hasProfileModel}
                rowIndiv={this.state.riaDetailsId}
                onIarModalChanged={this.onBdRRIndividualDetails} 
                onClickChanged={this.handleIarIndividualDetails}
                onModalChanged={this.onBdIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />
            <BdFirmDetailsModal
                isOpen={this.state.IarModal}
                rowId={this.state.bdRiaRowId}
                listValue={this.state.riaDetailsId}
                listRow={this.state.hasProfileModel}
                onModalChanged={this.handleIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />
            <IarDetailsModal
                isOpen={this.state.RRBdModal}
                rowId={this.state.RRBdRowId}
                rowIndiv={this.state.hasProfileModel != undefined? this.state.hasProfileModel : this.state.riaDetailsId}
                listValue={this.state.riaDetailsId}
                onClickChanged={this.onBdRRIndividualDetails}
                onIrModalChanged={this.handleIarIndividualDetails}
                onModalChanged={this.handleIndividualDetails}
                onClose={this.handleDetailsModalClose} />
                </>
            );
        }
        else if (formState.search_type == IAR_FIRM_SEARCH) {

            reportTypes = riaFirmReportTypes.filter(report => loggedInUserHasPermission(report.permission));
            columnsHeader = riaFirmColumns;
            return (
                <>
                    <PageTitle title={`Quicksearch results on "${QUICK_SEARCH_PAGE[formState.search_type] ?? INSURANC_SEARCH_PAGE}" page`} />
    
                    <PaginatedSearchResultsTable searchFormState={formState}
                        recordCount={searchFormProps.rowCount}
                        reportTypes={reportTypes}
                        possibleColumns={columnsHeader}
                        detailsModalComponent={RiaFirmDetailsModal}
                        enableSaving={true}
                        onModalChanged={this.riaHandleIndividualDetails}
                        onIrModalChanged={this.riaOnIarIndividualDetails}
                        onBdModalChanged={this.riaOnIarIndividualDetails}
                        listRow={this.state.iarDetailsId} />

                    <IarDetailsModal
                        isOpen={this.state.showAgentDetailsModal}
                        rowId={this.state.agentDetailsId}
                        listValue={this.state.riaDetailsId}
                        rowIndiv={this.state.iarDetailsId}
                        onIrModalChanged={this.riaHandleIarIndividualDetails}
                        onModalChanged={this.riaOnBdRRIndividualDetails}
                        onClickChanged={this.riaHandleIndividualDetails}
                        onClose={this.riaHandleDetailsModalClose}
                    />
                    <RiaFirmDetailsModal
                        isOpen={this.state.RRBdModal}
                        rowId={this.state.RRBdRowId}
                        listRow={this.state.riaDetailsId}
                        onModalChanged={this.riaHandleIndividualDetails}
                        onClose={this.riaHandleDetailsModalClose}
                    />

                    <RrIndivDetailsModal
                        isOpen={this.state.iarIndivDetailsModal}
                        rowId={this.state.rowId}
                        listValue={this.state.riaDetailsId}
                        rowIndiv={this.state.iarDetailsId}
                        onModalChanged={this.riaOnBdIarIndividualDetails}
                        onIarModalChanged={this.riaHandleIndividualDetails}
                        onClickChanged={this.riaHandleIarIndividualDetails}
                        onClose={this.riaHandleIarDetailsModalClose} />

                    <BdFirmDetailsModal
                        isOpen={this.state.IarModal}
                        rowId={this.state.bdRiaRowId}
                        listRow={this.state.hasProfileModel}
                        onBdModalChanged={this.riaOnIarIndividualDetails}
                        onModalChanged={this.riaHandleIarIndividualDetails}
                        onClose={this.riaHandleIarDetailsModalClose} /></>
            );
        }
        else if (formState.search_type == RR_INDIVIDUAL_SEARCH) {

            reportTypes = rrIndivReportTypes.filter(report => loggedInUserHasPermission(report.permission));
            columnsHeader = rrIndivColumns;
            return (
                <>
                    <PageTitle title={`Quicksearch results on "${QUICK_SEARCH_PAGE[formState.search_type] ?? INSURANC_SEARCH_PAGE}" page`} />
    
                    <PaginatedSearchResultsTable searchFormState={formState}
                        recordCount={searchFormProps.rowCount}
                        reportTypes={reportTypes}
                        possibleColumns={columnsHeader}
                        detailsModalComponent={RrIndivDetailsModal}
                        enableSaving={true}
                        onModalChanged={this.handleIndividualDetails}
                        onIarModalChanged={this.handleIarIndividualDetails}
                        onIrModalChanged={this.onBdRRIndividualDetails}
                        listBdRow={this.state.hasProfileModel} />

                    <BdFirmDetailsModal
                        isOpen={this.state.showAgentDetailsModal}
                        rowId={this.state.agentDetailsId}
                        listRow={this.state.hasProfileModel}
                        hideBdModel={this.state.hasProfileModel}
                        listValue={this.state.riaDetailsId}
                        onClickChanged={this.handleIndividualDetails}
                        onModalChanged={this.onBdRRIndividualDetails}
                        onClose={this.handleDetailsModalClose} />
                    <IarDetailsModal
                        isOpen={this.state.IarIndivDetailsModal}
                        rowId={this.state.rowId}
                        listValue={this.state.hasProfileModel}
                        rowIndiv={this.state.riaDetailsId}
                        onClickChanged={this.handleIarIndividualDetails}
                        onIrModalChanged={this.onBdRRIndividualDetails}
                        onModalChanged={this.onBdIarIndividualDetails}
                        onClose={this.handleIarDetailsModalClose} />

                    <RiaFirmDetailsModal
                        isOpen={this.state.IarModal}
                        rowId={this.state.bdRiaRowId}
                        listValue={this.state.riaDetailsId}
                        listRow={this.state.hasProfileModel}
                        onModalChanged={this.handleIarIndividualDetails}
                        onClose={this.handleIarDetailsModalClose} />

                    <RrIndivDetailsModal
                        isOpen={this.state.RRBdModal}
                        rowId={this.state.RRBdRowId}
                        rowIndiv={this.state.hasProfileModel ? this.state.hasProfileModel : this.state.riaDetailsId}
                        listValue={this.state.riaDetailsId}
                        onClickChanged={this.onBdRRIndividualDetails}
                        onIarModalChanged={this.handleIarIndividualDetails}
                        onModalChanged={this.handleIndividualDetails}
                        onClose={this.handleDetailsModalClose} /></>
            );
        }
        else if (formState.search_type == BD_FIRM_SEARCH) {

            reportTypes = bdFirmReportTypes.filter(report => loggedInUserHasPermission(report.permission));
            columnsHeader = bdFirmColumns;
            return (
                <>
                    <PageTitle title={`Quicksearch results on "${QUICK_SEARCH_PAGE[formState.search_type] ?? INSURANC_SEARCH_PAGE}" page`} />
    
                    <PaginatedSearchResultsTable searchFormState={formState}
                        recordCount={searchFormProps.rowCount}
                        reportTypes={reportTypes}
                        possibleColumns={columnsHeader}
                        detailsModalComponent={BdFirmDetailsModal}
                        enableSaving={true}
                        onModalChanged={this.riaHandleIndividualDetails}
                        onIarModalChanged={this.riaHandleIarIndividualDetails}
                        onIrModalChanged={this.riaOnIarIndividualDetails}
                        onBdModalChanged={this.riaOnIarIndividualDetails}
                        listBdRow={this.state.iarDetailsId} />

                    <RrIndivDetailsModal
                        isOpen={this.state.showAgentDetailsModal}
                        rowId={this.state.agentDetailsId}
                        listValue={this.state.riaDetailsId}
                        rowIndiv={this.state.iarDetailsId}
                        hideBdModel={this.state.hasProfileModel}
                        onIarModalChanged={this.riaHandleIarIndividualDetails}
                        onModalChanged={this.riaOnBdRRIndividualDetails}
                        onClickChanged={this.riaHandleIndividualDetails}
                        onClose={this.riaHandleDetailsModalClose}
                    />

                    <IarDetailsModal
                        isOpen={this.state.iarIndivDetailsModal}
                        rowId={this.state.rowId}
                        listValue={this.state.riaDetailsId}
                        rowIndiv={this.state.iarDetailsId}
                        onClickChanged={this.riaHandleIarIndividualDetails}
                        onIrModalChanged={this.riaOnIarIndividualDetails}
                        onModalChanged={this.riaOnBdIarIndividualDetails}
                        onClose={this.riaHandleIarDetailsModalClose} />

                    <RiaFirmDetailsModal
                        isOpen={this.state.IarModal}
                        rowId={this.state.bdRiaRowId}
                        listRow={this.state.hasProfileModel}
                        onModalChanged={this.riaHandleIarIndividualDetails}
                        onClose={this.riaHandleIarDetailsModalClose} />


                    <BdFirmDetailsModal
                        isOpen={this.state.RRBdModal}
                        rowId={this.state.RRBdRowId}
                        listRow={this.state.iarDetailsId}
                        onModalChanged={this.riaHandleIndividualDetails}
                        onClose={this.riaHandleDetailsModalClose} /></>
            );
        }
        else {
            reportTypes = insuranceAgentReportTypes.filter(report => loggedInUserHasPermission(report.permission));
            columnsHeader = agentColumns;
            return (
                <>
                    <PageTitle title={`Quicksearch results on "${QUICK_SEARCH_PAGE[formState.search_type] ?? INSURANC_SEARCH_PAGE}" page`} />
    
                    <PaginatedSearchResultsTable searchFormState={formState}
                        recordCount={searchFormProps.rowCount}
                        reportTypes={reportTypes}
                        possibleColumns={columnsHeader}
                        detailsModalComponent={AgentDetailsModal}
                        enableSaving={true} />
                </>
            );
        }
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchResultsPage)
