import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { allFlattenRoutes as routes } from './index';
import { isUserAuthenticated } from '../helpers/authUtils';

// All layouts/containers
import AuthLayout from '../layouts/Auth';
import HorizontalLayout from '../layouts/Horizontal';


class Routes extends Component {
    // returns the layout
    getLayout = () => {
        if (!isUserAuthenticated()) return AuthLayout;

        return HorizontalLayout;
    };

    shouldComponentUpdate(nextProps, nextState) {
        let oldLayout = { ...this.props.layout };
        delete oldLayout.showRightSidebar;
        let newLayout = { ...nextProps.layout };
        delete newLayout.showRightSidebar;
        return (
            JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
            JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user)
        );
    }

    render() {
        const Layout = this.getLayout();
        // rendering the router with layout
        return (
            <BrowserRouter>
                <Layout {...this.props}>
                    <Switch>
                        {routes.map((route, index) => {
                            return (
                                !route.children && (
                                    <route.route
                                        key={index}
                                        path={route.path}
                                        roles={route.roles}
                                        name={route.name}
                                        exact={route.exact}
                                        component={route.component}></route.route>
                                )
                            );
                        })}
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
    };
};

export default connect(
    mapStateToProps,
    null
)(Routes);
