export default function renderDollars(amountInMillions, renderAs) {
    renderAs = renderAs || 'rounded';

    if (renderAs === 'dollars') {
        return '$ ' + Number(amountInMillions * 1000000).toLocaleString();
    }
    if (renderAs === 'rounded') {
        if (amountInMillions >= 1000000) {
            return '$ ' + Number(amountInMillions * 0.000001).toFixed(1).toLocaleString() + ' T';
        }
        if (amountInMillions >= 1000) {
            return '$ ' + Number(amountInMillions * 0.001).toFixed(1).toLocaleString() + ' B';
        }
        if (amountInMillions >= 1) {
            return '$ ' + Number(amountInMillions).toFixed(1).toLocaleString() + ' M';
        }
        if (amountInMillions >= 0.001) {
            return '$ ' + Number(amountInMillions * 1000).toFixed(1).toLocaleString() + ' K';
        }
        return '$ ' + Number(amountInMillions * 1000000).toLocaleString();
    }
}