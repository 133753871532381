// @flow
import React from 'react';
import RRSearchForm from '../components/RRSearchForm';
import { RrSearchState } from '../redux/firstClassData/dtos';
import SearchPage from './SearchPage';


export default class rrSearch extends React.Component {

    render() {
        return <SearchPage pageTitle="RR Search" formName="rrIndivSearch" searchFormStateClass={RrSearchState} searchFormComponent={RRSearchForm} />
    }
}