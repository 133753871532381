// @flow
import React from 'react';
import BDFirmSearchForm from '../components/BDSearchForm';
import { BdSearchState } from '../redux/firstClassData/dtos';
import SearchPage from './SearchPage';


export default class bdSearch extends React.Component {

    render() {
        return <SearchPage pageTitle="BD Firm Search" formName="bdFirmSearch" searchFormStateClass={BdSearchState} searchFormComponent={BDFirmSearchForm} />
    }
}