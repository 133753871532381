import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const designationTypes = [
    {label: 'Chartered Financial Consultant', value: 'CHARTERED FINANCIAL CONSULTANT'},
    {label: 'Personal Financial Specialist', value: 'PERSONAL FINANCIAL SPECIALIST'},
    {label: 'Chartered Investment Counselor', value: 'CHARTERED INVESTMENT COUNSELOR'},
    {label: 'Certified Financial Planner', value: 'CERTIFIED FINANCIAL PLANNER'},
    {label: 'Chartered Financial Analyst', value: 'CHARTERED FINANCIAL ANALYST'}
];


export default class DesignationTypes extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideTypes: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOptions) {
        let selectedValue = null;
        let selectedLabel = null;
        if (this.props.multi) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
            selectedLabel = selectedOptions ? selectedOptions.map(el => el.label) : [];
        } else if (selectedOptions) {
            selectedValue = selectedOptions.value;
            selectedLabel = selectedOptions.label;
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedValue,
                label: selectedLabel,
            }
        });
    }

    render() {
        let selectedTypes = this.props.value
            ? designationTypes.filter(row => this.props.value.indexOf(row.value) >= 0)
            : [];
        let displayTypes = this.props.hideTypes
            ? designationTypes.filter(row => this.props.hideTypes.indexOf(row.value) < 0)
            : designationTypes;

        return <Select
            closeMenuOnSelect={!this.props.multi}
            value={selectedTypes}
            isMulti={this.props.multi}
            options={displayTypes}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={this.props.multi ? 'Select Designation(s)' : 'Select Designation type'}
        />;
    }
}
