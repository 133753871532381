import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const licenceTypes = [
    { value: 'S63', label: 'Series 63' },
    { value: 'S65', label: 'Series 65' },
    { value: 'S66', label: 'Series 66' },
];


export default class LicenseTypes extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        multi: PropTypes.bool.isRequired,
        hideTypes: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOptions) {
        let selectedValue = null;
        if (this.props.multi) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        } else if (selectedOptions) {
            selectedValue = selectedOptions.value;
        }

        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedValue,
            }
        });
    }

    render() {
        let selectedTypes = this.props.value
            ? licenceTypes.filter(row => this.props.value.indexOf(row.value) >= 0)
            : [];
        let displayTypes = this.props.hideTypes
            ? licenceTypes.filter(row => this.props.hideTypes.indexOf(row.value) < 0)
            : licenceTypes;

        return <Select
            closeMenuOnSelect={!this.props.multi}
            value={selectedTypes}
            isMulti={this.props.multi}
            options={displayTypes}
            onChange={this.handleChange}
            isClearable={true}
            placeholder={this.props.multi ? 'Select License Type(s)...' : 'Select license type...'}
        />;
    }
}
