import React, { Component } from 'react';

import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions';
import { withRouter } from 'react-router-dom';

class Logout extends Component {
    /**
     * Redirect to login
     */
    componentDidMount = () => {
        // emit the event
        this.props.logoutUser(this.props.history);
        window.localStorage.clear();
        document.cookie = 'user=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };

    render() {
        return <></>;
    }
}

export default withRouter(
    connect(
        null, { logoutUser }
    )(Logout)
);