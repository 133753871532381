import React, { useState, useEffect } from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { mergeSearch } from '../../redux/actions';
import APIClient from '../../utils/API';

const apiClient = new APIClient();

const ZipCountySearchField = ({ formName }) => {
    const dispatch = useDispatch();
    const { address_states: storeStates, county: storeCounty, zipcodes } = useSelector((state) => state.FirstClassData.formStates[formName].formState);
    const [allowAddZip, setAllowAddZip] = useState(false);
    const [zipOptions, setZipOptions] = useState([]);
    const [countyOptions, setCountyOptions] = useState([]);
    const [countyOptionsList, setCountyOptionsList] = useState([]);
    const [activeCountsOptions, setActiveCountsOptions] = useState([]);

    const onRadiusChange = (index, value) => {
        let localZipCodes = zipcodes.slice();
        localZipCodes[index].radius = value;
        dispatch(mergeSearch({ zipcodes: localZipCodes }, formName));
    }

    const onZipChange = (activeOption, index) => {
        let localZipCodes = zipcodes.slice();
        localZipCodes[index].zip = activeOption ? String(activeOption.label) : '';
        dispatch(mergeSearch({ zipcodes: localZipCodes }, formName));

        if(!activeOption && localZipCodes.length != 1) {
            localZipCodes.splice(index,1);
        }
    }

    const onCountyChange = (activeOptions) => {
        if (!activeOptions) {
            setCountyOptions(countyOptionsList);
            dispatch(mergeSearch({ county: [] }, formName));
            return;
        }
        const value = activeOptions.map(({ value, label }) => {
            return label;
        });

        dispatch(mergeSearch({ county: value }, formName));
    }

    const handleAddZipcode = () => {
        let currentZipcodes = zipcodes.slice();
        currentZipcodes.push({
            zip: "",
            radius: "",
        });
        dispatch(mergeSearch({ zipcodes: currentZipcodes }, formName));
    }

    // Removed the cancel button in the Zipcode
    // const removeZipRow = (index) => {
    //     let currentZipcodes = zipcodes.slice();
    //     currentZipcodes.splice(index, 1);
    //     dispatch(mergeSearch({ zipcodes: currentZipcodes }, formName));
    // }

    useEffect(() => {
        const allowAdd = !zipcodes.some(row => row.zip.length === 0) && activeCountsOptions.length < 3;
        setAllowAddZip(allowAdd);
    }, [zipcodes, activeCountsOptions]);

    useEffect(() => {
        if (!storeStates.length) {
            return;
        }
        let filteredStates = [];
        storeStates.forEach(({ exclude, states }) => {
            if (!exclude && !!states.length) {
                states.forEach((state) => {
                    if (filteredStates.includes(state)) {
                        return;
                    }
                    filteredStates.push(state);
                });
            }
        });
        apiClient.getAddressOptions(filteredStates).then(({ data }) => {
            let getZip = []
            let zipCode = []
            let getCounty = []
            let countyName = []
            for(let iterateAddressOptions of data){
                if(!countyName.includes(iterateAddressOptions['county']) && !iterateAddressOptions['county'].length <= 0) {
                    getCounty.push({value: iterateAddressOptions['id'], label: iterateAddressOptions['county']})
                    countyName.push(iterateAddressOptions['county']);
                }
                if(iterateAddressOptions['zip'] && !zipCode.includes(iterateAddressOptions['zip'])) {
                    let zipcodeOptions = String(iterateAddressOptions['zip'])
                    if((zipcodeOptions.length) == 4) {
                        zipcodeOptions = '0' + iterateAddressOptions['zip'];
                    }
                    if((zipcodeOptions.length) == 3) {
                        zipcodeOptions = '00' + iterateAddressOptions['zip'];
                    }
                    getZip.push({value: iterateAddressOptions['id'], label: zipcodeOptions})
                    zipCode.push(iterateAddressOptions['zip']);
                }
            }
            setZipOptions(getZip);
            setCountyOptions(getCounty);
            setCountyOptionsList(getCounty);
        })

    }, [storeStates[0].states.length != 0 ? storeStates : '']);

    useEffect(() => {
        if (!storeCounty.length) {
            setActiveCountsOptions([]);
            return;
        }
        const activeCounties = storeCounty.map((item) => {
            return { value: item, label: item };
        })

        setActiveCountsOptions(activeCounties);

        let countyList = []
        let countyLabels = []
        activeCounties.map((item) => {
            countyLabels.push(item.label)
        })

        let selectedValuesList = []
        activeCounties.map((item) =>{
            selectedValuesList.push(item.label)
        })

        for (let county of countyOptionsList) {
            if(!(selectedValuesList.includes(county.label))) {
                countyList.push(county);
            }
        }
        setCountyOptions(countyList)

    }, [storeCounty]);

    return (
        <Row>
            <Label className="col-lg-12" for="zip">Zip Code</Label>
            <Col lg={6}>
                {zipcodes.map((zipObj, i) =>
                    <Row key={i} className="mb-2">
                        <Col lg={5}>
                            <div className="btn-toolbar">
                                <div className="btn-group mr-2 flex-grow-1">
                                    <div className="w-100">
                                        <CreatableSelect
                                            options={zipOptions}
                                            placeholder="zip"
                                            name={`zip-${i}`}
                                            closeMenuOnSelect={false}
                                            value={{ value:zipObj.zip, label:zipObj.zip }}
                                            isClearable
                                            defaultOptions
                                            cacheOptions
                                            onChange={(zipValue) => onZipChange(zipValue, i)}
                                        />
                                    </div>
                                </div>
                                {/* Remove the cancel button in the zipcode */}
                                {/* <div className="btn-group">
                                    <span
                                        className="btn btn-outline-warning btn-sm d-flex align-items-center"
                                        type="button"
                                        onClick={(event) => removeZipRow(i)}
                                    >
                                        <i className="uil-times-square"></i>
                                    </span>
                                </div> */}
                            </div>
                        </Col>
                        {String(zipObj.zip).length === 5 &&
                            <Col className="px-lg-0"  lg={5}>
                                <Row>
                                    <span className="small">or within</span>
                                    <CreatableSelect name="zip_radius_miles" className="w-50" 
                                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                        value={{ value:zipObj.radius, label:zipObj.radius }}
                                        noOptionsMessage={() => ''}
                                        onChange={event => onRadiusChange(i, event.value)}
                                        placeholder="10" />
                                    <span className="small">miles</span>
                                </Row>
                            </Col>
                        }
                    </Row>
                )}
                {allowAddZip &&
                <Row>
                    <Col className="pl-3">
                        <Button className="p-0" color="link" onClick={handleAddZipcode}>+ Add another zipcode</Button>
                    </Col>
                </Row>
                }
            </Col>
            <Col lg={6}>
                <CreatableSelect
                    options={countyOptions}
                    placeholder="County"
                    name="county"
                    closeMenuOnSelect={false}
                    value={activeCountsOptions}
                    isMulti
                    isClearable
                    onChange={onCountyChange}
                />
            </Col>
        </Row>
    );
};

export default ZipCountySearchField;