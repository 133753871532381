// @flow
import React from 'react';
import {
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';

import fieldLookups from '../../constants/fieldLookups';
import { searchFieldTypes } from '../../constants/agentSearchFields';
import { loggedInUserHasPermission } from '../../helpers/authUtils';


export default class ConsumerDataSelect extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        searchFieldLabel: PropTypes.string.isRequired,
        searchFieldName: PropTypes.string.isRequired,
        fieldType: PropTypes.number.isRequired,
        formState: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue;
        if (this.props.fieldType === searchFieldTypes.MULTI_SELECT) {
            selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        } else {
            selectedValue = selectedOptions ? selectedOptions.value : '';
        }
        this.props.onChange({
            target: {
                name: fieldName,
                value: selectedValue,
            }
        });
    }

    handleIncludeExcludeClick(selectedValue, fieldName) {
        if (selectedValue) {
            this.props.onChange({
                target: {
                    name: `additional_fields_exclude.${fieldName}_exclude`,
                    value: false,
                }
            });
        }
        else {
            this.props.onChange({
                target: {
                    name: `additional_fields_exclude.${fieldName}_exclude`,
                    value: true,
                }
            });
        }
    }

    render() {
        const {formState, searchFieldLabel: fieldLabel, searchFieldName: fieldName, fieldType} = this.props;
        const lookups = fieldLookups[fieldLabel];
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');
        if (!lookups) return <></>;

        let options = [];
        if (formState.search_type == 'riaFirmSearch') {
            options = Object.keys(lookups).map(key => ({value: key, label: lookups[key]}));
        }
        else {
            options = hasConsumerDataSearchPermission ? Object.keys(lookups).map(key => ({value: key, label: lookups[key]})): [];
        }
        
        let value = formState[fieldName];
        if (!Array.isArray(value)) {
            value = [value]
        }
        const selected = options.filter(row => value.indexOf(row.value) >= 0);

        return <>
            {fieldName != 'ethnicity'?
                <Card className="my-1 license-groups-card-border">
                    <CardHeader className="bg-light">
                        <a className="link"
                            onClick={() => this.handleIncludeExcludeClick(formState.additional_fields_exclude[fieldName+"_exclude"], fieldName)}
                        >
                            {formState.additional_fields_exclude[fieldName+"_exclude"] ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these {fieldLabel}:
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{fieldLabel}</Label>
                                    <Select
                                        closeMenuOnSelect={fieldType !== searchFieldTypes.MULTI_SELECT}
                                        isClearable={true}
                                        isMulti={fieldType === searchFieldTypes.MULTI_SELECT}
                                        options={options}
                                        name={fieldName} id={fieldName}
                                        value={selected}
                                        onChange={value => this.handleSelectChange(fieldName, value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>:
                <FormGroup>
                    <Label for={fieldName}>{fieldLabel}</Label>
                    <Select
                            closeMenuOnSelect={fieldType !== searchFieldTypes.MULTI_SELECT}
                            isClearable={true}
                            isMulti={fieldType === searchFieldTypes.MULTI_SELECT}
                            options={options}
                            name={fieldName} id={fieldName}
                            value={selected}
                            onChange={value => this.handleSelectChange(fieldName, value)}
                        />
                </FormGroup>
            }
        </>
    }
}
