// @flow
import React from 'react';
import SearchPage from './SearchPage';
import GroupSearchForm from '../components/GroupSearchForm';


export default class GroupSearch extends React.Component {

    render() {
        return <SearchPage pageTitle="Insurance Search" formName="groupSearch" searchFormComponent={GroupSearchForm} />
    }
}
