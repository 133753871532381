import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner
} from 'reactstrap';


export default class DeleteSavedSearchModal extends React.Component {
    static propTypes = {
        onConfirmDelete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        savedSearch: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isDeleting: false,
        };
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    onDeleteClick(event) {
        this.setState({
            isDeleting: true,
        });

        this.props.onConfirmDelete()
            .finally(() => {
                this.setState({
                    isDeleting: false,
                });
            });
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} >
                <ModalHeader>Confirm deletion</ModalHeader>
                <ModalBody>
                    {this.props.savedSearch &&
                        <p>Are you sure you want to delete the <strong>{this.props.savedSearch.name}</strong> saved search?</p>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="success" disabled={this.state.isDeleting} onClick={this.onDeleteClick}>
                        {this.state.isDeleting
                            ? <>Deleting <Spinner as="span" animation="border" size="sm"/></>
                            : 'Yes, delete'
                        }
                    </Button>

                    <Button color="warning" onClick={this.props.onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
