// @flow
import React from 'react';
import {Col, FormGroup, Label, Row} from 'reactstrap';
import PropTypes from 'prop-types';
import {RangeSlider} from 'rsuite';


export default class YearsEmployeedIA extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
    };

    handleSliderChange(name, value) {
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    handleStatesSliderChange(name, value) {
        if( value[0] > value[1] )
        {
            var temp = value[0]
            value[0] = value[1]
            value[1] = temp
        }
        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    }

    rangeTicks(mark) {
        if (mark % 10 === 0) {
            if ((new Date().getFullYear() - mark) < 10) {
                return <span>{new Date().getFullYear()}</span>;
            }
            return <span>{mark}</span>;
        }
        return null;
    }

    statesRangeTicks(mark) {
        if ([1, 5, 10, 20, 30, 50].includes(mark)) {
            return <span>{mark < 50 ? mark : 'all states'}</span>;
        }
        return null;
    }

    render() {
        let formState = this.props.formState;

        return (
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Label>Years Employed IA</Label>
                        <Row>
                            <Col xs={1}>
                                <input type="checkbox" name="years_employeed"
                                    checked={formState.years_employeed}
                                    onChange={this.props.onChange} />
                            </Col>
                            <Col>
                                <RangeSlider min={1970} max={new Date().getFullYear()} disabled={!formState.years_employeed}
                                            value={formState.years_employeed_range}
                                            onChange={value => this.handleSliderChange('years_employeed_range', value)}
                                            renderMark={this.rangeTicks}
                                            graduated={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                <Col>
                    <Label>Number of States Registered</Label>
                    <Row>
                    <Col xs={1}>
                                <input type="checkbox" name="states_registered"
                                    checked={formState.states_registered}
                                    onChange={this.props.onChange} />
                            </Col>
                        <Col>
                            <RangeSlider min={1} max={50} disabled={!formState.states_registered}
                                        value={formState.states_registered_range}
                                        onChange={value => this.handleStatesSliderChange('states_registered_range', value)}
                                        renderMark={this.statesRangeTicks}
                                        graduated={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
