function column(field, width) {
    return {field: field, text: field, width: width}
}

let executiveDataColumns = [
    // Executive columns
    {field: 'Exec CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'First Name', width: 200, fixed: 'left'},
    {field: 'Middle Name', width: 200, fixed: 'left'},
    {field: 'Last Name', width: 200, fixed: 'left'},
    {field: 'Suffix Name', width: 200, resizable: true},
    'Gender',
    'Firm CRD',
    'RIA Main Office Phone',
    'RIA Main Office Phone Ext',
    'Execs Insurance Business Phone',
    'Execs Insurance Business Phone Ext',
    'Execs Business Home Phone',
    {field: 'Primary Email Address', width: 250, resizable: true},
    {field: 'Primary Email Domain', width: 250, resizable: true},
    'Alternate Sourced Email Indicator',
    {field: 'Secondary Email Address', width: 250, resizable: true},
    {field: 'Secondary Email Domain', width: 250, resizable: true},
    {field: 'Alternate Email Address', width: 250, resizable: true},
    {field: 'Alternate Email Domain', width: 250, resizable: true},
    'Business Personal Email Address', 
    'Business Personal Email Domain',
    'Business Personal Email Domain',
    'Earliest Financial Svs Date',
    'Corporate Title or Role',
    'A_C Level or SR Mgmt',
    'A_Middle Mgm',
    'A__First Level Mgmt or Other',
    'B_Financial Ops',
    'B_Investment Research',
    'B_Marketing Sales',
    'B_Technology',
    'Investment Advisor Rep',
    'Registered Rep',
    'Insurance Agent',
    {field: 'IA SEC Profile Link', width: 450, resizable: true},
];

let allColumnNames = [
    // RIA firm columns
    {field: 'CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'Firm Name', width: 300, fixed: 'left'},
    {field: 'Firm Legal Name', width: 200, resizable: true},
    'Regulated By',
    'First Registration Date',
    {field: 'Headquarters Outside Us', width: 110},
    'Internet Investment Adviser',
    'Wrap Fee Program Adviser',
    'Number of Employees',
    'Number of IA Reps',
    column('Main Office Phone', 130),
    column('Main Office Phone Ext', 130),
    column('Main Office Fax', 130),
    'Number of BD Reps',
    'Number of Insurance Licensed reps',
    {field: 'Main Office Address 1', width: 200, resizable: true},
    {field: 'Main Office Address 2', width: 200, resizable: true},
    'Main Office City',
    'Main Office State',
    'Main Office Zip',
    'Main Office Zip4',
    'Main Office County',
    'Main Office Country',
    'Number of Registered States',
    {field: 'Custodian 1', width: 200, resizable: true},
    {field: 'Custodian 2', width: 200, resizable: true},
    {field: 'Custodian 3', width: 200, resizable: true},

    column('AUM Discretionary', 130),
    column('AUM Non Discretionary', 130),
    'AUM TOTAL',
    column('Accts Under Mgmt Discretionary', 130),
    column('Accts Under Mgmt Non Discretionary', 130),
    'Accts Under Mgmt TOTAL',

    'Individual (non-HNW) clients',
    'Individual (non-HNW) assets $M',
    'High net worth individual clients',
    'High net worth individual assets $M',
    'Banking or thrift institutions clients',
    'Banking or thrift institutions assets $M',
    'Investment companies clients',
    'Investment companies assets $M',
    'Business development companies clients',
    'Business development companies assets $M',
    'Pooled investment vehicles clients',
    'Pooled investment vehicles assets $M',
    'Pension and profit sharing plans clients',
    'Pension and profit sharing plans assets $M',
    'Charitable organizations clients',
    'Charitable organizations assets $M',
    'State or municipal government entities clients',
    'State or municipal government entities assets $M',
    'Other investment advisers clients',
    'Other investment advisers assets $M',
    'Insurance companies clients',
    'Insurance companies assets $M',
    'Sovereign wealth funds and foreign official institutions clients',
    'Sovereign wealth funds and foreign official institutions assets $M',
    'Corporations or other businesses clients',
    'Corporations or other businesses assets $M',
    'Other Clients',
    'Other Client Assets $M',
    'Sponsors Wrap Fee Program',
    'Portfolio Manager for a Wrap Fee Program',
    {field: 'LinkedIn Profile 1', width: 400, resizable: true},
    {field: 'LinkedIn Profile 2', width: 400, resizable: true},
    {field: 'LinkedIn Profile 3', width: 400, resizable: true},
    {field: 'Website 1', width: 400, resizable: true},
    {field: 'Website 2', width: 400, resizable: true},
    {field: 'Website 3', width: 400, resizable: true},
    {field: 'Instagram 1', width: 400, resizable: true},
    {field: 'Instagram 2', width: 400, resizable: true},
    {field: 'Instagram 3', width: 400, resizable: true},
    {field: 'Facebook 1', width: 400, resizable:true},
    {field: 'Facebook 2', width: 400, resizable:true},
    {field: 'Facebook 3', width: 400, resizable:true},
    {field: 'Twitter 1', width: 400, resizable:true},
    {field: 'Twitter 2', width: 400, resizable:true},
    {field: 'Twitter 3', width: 400, resizable:true},
    {field: 'YouTube 1', width:400, resizable:true},
    {field: 'YouTube 2', width:400, resizable:true},
    {field: 'YouTube 3', width:400, resizable:true},
];

allColumnNames = [...allColumnNames, ...executiveDataColumns];

// export a list suitable for the datatable, eg every one needs a "field" and "text"
let allColumns = [];
for (let i=0; i<allColumnNames.length; i++) {
    let col = allColumnNames[i];
    if (typeof col === 'string') {
        allColumns.push({ field: col, text: col, width: 100 });
    } else {
        if (!('text' in col)) {
            col['text'] = col['field'];
        }
        if (!('width' in col)) {
            col['width'] = 100;
        }
        allColumns.push(col);
    }
}

export default allColumns;