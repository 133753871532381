import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Container, Row, Col, Card, CardBody, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { isUserAuthenticated } from '../../helpers/authUtils';
import LoaderWidget from '../../components/Loader';
import logo from '../../assets/images/fcd-logo-orange.svg';
import APIClient from '../../utils/API';

class ForgotPassword extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.client = new APIClient();
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            error: null,
            success: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        this.setState({
            error: null
        });

        this.client.forgotPassword(values['username'])
            .then(response => {
                // any successful response means it worked
                this.setState({
                    success: 'A password reset email has been sent'
                });
            })
            .catch(err => {
                // there was some validation error
                let errorMessage = 'An error occurred, please try again';
                if (err && err.response && err.response.data) {
                    if (err.response.data.error) {
                        errorMessage = err.response.data.error;
                    } else if (err.response.data.email) {
                        errorMessage = err.response.data.email.join(', ');
                    }
                }
                this.setState({
                    error: errorMessage
                });
            })
        return false;
    };

    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        if (isAuthTokenValid) {
            return <Redirect to="/" />;
        }
    };

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        return (
            <React.Fragment>
                {this.renderRedirectToRoot()}

                {(this._isMounted || !isAuthTokenValid) && (
                    <div className="account-pages mt-5 mb-5">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={5}>
                                    <Card>
                                        <div className="card-header pt-4 pb-4 text-center bg-primary">
                                            <Link to="/">
                                                <span>
                                                    <img src={logo} alt="First Class Data" className="vectorlogo" />
                                                </span>
                                            </Link>
                                        </div>

                                        <CardBody className="p-4 position-relative">
                                            {/* preloader */}
                                            {this.props.loading && <LoaderWidget />}

                                            <div className="text-center w-75 m-auto">
                                                <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                                                    Reset Password
                                                </h4>
                                                <p className="text-muted mb-4">
                                                    Enter your username or email address and we'll send you an email with
                                                    instructions to reset your password
                                                </p>
                                            </div>

                                            {this.state.success ?
                                                <Alert
                                                    color="success"
                                                    isOpen={this.state.success ? true : false}>
                                                    {this.state.success}
                                                </Alert>
                                                :
                                                <>
                                                    {this.state.error && (
                                                        <Alert color="danger" isOpen={this.state.error ? true : false}>
                                                            {this.state.error}
                                                        </Alert>
                                                    )}

                                                    <AvForm onValidSubmit={this.handleValidSubmit}>
                                                        <AvField
                                                            type="text"
                                                            name="username"
                                                            placeholder="Enter your username or email"
                                                            required
                                                        />

                                                        <FormGroup>
                                                            <Button color="primary">Submit</Button>
                                                        </FormGroup>
                                                    </AvForm>
                                                </>
                                            }

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="mt-1">
                                <Col className="col-12 text-center">
                                    <p className="text-muted">
                                        Back to{' '}
                                        <Link to="/account/login" className="text-muted ml-1">
                                            <b>Sign In</b>
                                        </Link>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default ForgotPassword;
