// @flow
import React from 'react';
import {Col, CustomInput, FormGroup, Row} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import APIClient from '../../utils/API';


export default class LicenseSearchFields extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            remountKey: (new Date()).getTime(),
        }

        this.getTypeaheadOptions = this.getTypeaheadOptions.bind(this);
        this.reloadCarriers = this.reloadCarriers.bind(this);
        this.onChangeCarrierGroups = this.onChangeCarrierGroups.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.apiClient = new APIClient();
    }

    reloadCarriers = () => {
        this.setState({
            remountKey: (new Date()).getTime(),
        });
    }

    onChangeCarrierGroups = (event) => {
        this.props.onChange(event);
        this.reloadCarriers();
    }

    getTypeaheadOptions = (fieldName, inputValue, callback) => {
        let extra = null;
        if (fieldName === 'carrier_name') {
            // we only want to show carriers possible by the carrier group selection
            let formCarrierState = this.props.formState.carriers_and_groups[this.props.index];
            extra = {
                carrier_groups: formCarrierState.carrier_groups,
                carrier_groups_exclude: formCarrierState.carrier_groups_exclude,
            }
        }

        this.apiClient.getTypeaheadOptions(fieldName, inputValue, extra)
            .then(response => {
                // we limit the carriers to 101 rows, if we have that many show 100 and a "too many" message
                let results = response.data.results;
                if (fieldName === 'carrier_name' && results.length === 101) {
                    results = results.slice(0, 100);
                    results.unshift({
                        value: '', label: 'Showing only 100 results, start typing to filter results...',
                        isDisabled: true
                    })
                }

                callback(results);
            });
    };

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];

        this.props.onChange({
            target: {
                name: `carriers_and_groups.${this.props.index}.${fieldName}`,
                value: selectedValue,
            }
        });

        if (fieldName === 'carrier_groups') {
            this.reloadCarriers();
        }
    }

    render() {
        let formCarrierState = this.props.formState.carriers_and_groups[this.props.index];

        let {carrier_groups, carrier_groups_exclude, carriers, carriers_exclude} = formCarrierState;

        let selectedCarrierGroups = carrier_groups.map(groupName => {
            return {value: groupName, label: groupName};
        });
        let selectedCarriers = carriers.map(carrierName => {
            return {value: carrierName, label: carrierName};
        });

        return <Row>
            <Col>
                <FormGroup>
                    <AsyncSelect
                        placeholder="Carrier Group"
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedCarrierGroups || null}
                        isClearable
                        defaultOptions
                        cacheOptions
                        loadOptions={(inputValue, callback) => this.getTypeaheadOptions('carrier_group', inputValue, callback)}
                        onChange={value => this.handleSelectChange('carrier_groups', value)}
                    />
                    {selectedCarrierGroups.length > 0 &&
                    <CustomInput type="switch"
                                    id={`carrier_groups_exclude${this.props.index}`}
                                    name={`carriers_and_groups.${this.props.index}.carrier_groups_exclude`}
                                    checked={carrier_groups_exclude}
                                    onChange={this.onChangeCarrierGroups} label="Exclude these carrier groups" value={true} />
                    }
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <AsyncSelect
                        key={this.state.remountKey}
                        placeholder="Carrier Name / NAIC"
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedCarriers || null}
                        isClearable
                        defaultOptions
                        loadOptions={(inputValue, callback) => this.getTypeaheadOptions('carrier_name', inputValue, callback)}
                        onChange={value => this.handleSelectChange('carriers', value)}
                    />

                    {selectedCarriers.length > 0 &&
                    <CustomInput type="switch"
                                    id={`carriers_exclude${this.props.index}`}
                                    name={`carriers_and_groups.${this.props.index}.carriers_exclude`}
                                    checked={carriers_exclude}
                                    onChange={this.props.onChange} label="Exclude these carriers" value={true} />
                    }
                </FormGroup>
            </Col>
        </Row>

    }
}
