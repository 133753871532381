import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Label,
    CardBody,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    Row,
} from 'reactstrap';
import {CONSUMER_DATA_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import {PAGE_RESTRICTING_MESSAGE} from '../redux/layout/constants';
import AddressSearchFields from './SearchFormCards/AddressSearchFields';
import ConsumerDataFiltersFields from './SearchFormCards/ConsumerDataFiltersFields';
import { loggedInUserHasPermission } from '../helpers/authUtils';
import EmailAddressSelect from './SearchFormCards/EmailAddressSelect';
import FullNameSelect from './SearchFormCards/FullNameSelect';

export default class AgentSearchForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFieldChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
        formName: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.formatCsv = this.formatCsv.bind(this);
    }

    formatCsv(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }
    formatNames(value) {
        if (Array.isArray(value)) {
            return value.join(', ')
        }
        return value;
    }


    render() {
        let formState = this.props.formState;
        const hasConsumerDataSearchPermission = loggedInUserHasPermission('search_consumerdata');
        let hasPermission =  this.props.hasPermission
        return (
            <>
        <Form onSubmit={this.props.onSubmit} className = { !hasPermission ? "permission-card-section restricted" : "" }>
            <Card>
                <CardBody>
                    <h4 className="header-title">Agent Profile</h4>

                    <Row className="mb-2">
                        <Col lg={12}>
                            <Row>
                                <Col lg={8}>
                                    <FormGroup>
                                        <Input type="text" name="npns" value={this.formatCsv(formState.npns)}
                                            onChange={this.props.onFieldChange} placeholder="NPN(s)" />
                                    </FormGroup>
                                </Col>
                                <Col lg={4}>
                                    <CustomInput type="switch" id="s1" name="npn_search_states" checked={formState.npn_search_states}
                                                    onChange={this.props.onFieldChange} label="Also search state license numbers" value={true} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="text" name="first_name" value={this.formatNames(formState.first_name)}
                                               onChange={this.props.onFieldChange} placeholder="First Name" />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="text" name="last_name" value={this.formatNames(formState.last_name)}
                                               onChange={this.props.onFieldChange} placeholder="Last Name" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <FullNameSelect formName={this.props.formName} />
                            </Row>
                            <Row className="pt-2">
                                <EmailAddressSelect formName={this.props.formName} />
                            </Row>
                            <Row>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                    <Label for="email_level">Email Address</Label>
                                    <Input type="select" name="email_level" id="email_level"
                                        value={formState.email_level} onChange={this.props.onFieldChange}>
                                        <option value="">----</option>
                                        <option value="1">Has Email Verified for Mass Mailing</option>
                                        <option value="2">Has Valid Email Address</option>
                                    </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <AddressSearchFields formState={formState} onChange={this.props.onFieldChange} formName={this.props.formName} />
                </CardBody>
            </Card>
            <Card>
                <CardBody className = { (!hasConsumerDataSearchPermission &&  hasPermission) ? "permission-card-section restricted" : "" }>
                    <ConsumerDataFiltersFields formState={formState} onChange={this.props.onFieldChange} />
                </CardBody>
                {(!hasConsumerDataSearchPermission &&  hasPermission) ?
                    <div className="permission-card">
                        <div className='card permission'>
                            <h6 className="consumer-data-permission-headers">{CONSUMER_DATA_RESTRICTING_MESSAGE}</h6>
                        </div>
                    </div> : ""
                }
            </Card>
        </Form>     
            {!hasPermission ?
                    <div className="permission-card">
                        <div className='card permission'>
                            <h6 className="permission-header">{PAGE_RESTRICTING_MESSAGE}</h6>
                        </div>
                    </div> : ""
                }
        </>
        );
    }
}
