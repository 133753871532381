import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { isUserAuthenticated, loggedInUserHasPermission } from '../helpers/authUtils';

// auth
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import ChangePassword from '../pages/auth/ChangePassword';
import ForgetPassword from '../pages/auth/ForgotPassword';
import ResetPasswordFromEmail from '../pages/auth/ResetPasswordFromEmail';

// pages
import GroupSearch from '../pages/GroupSearch';
import AgentSearch from '../pages/AgentSearch';
import QuickSearchResultsPage from '../pages/QuickSearchResults';
import AgentSearchResultsPage from '../pages/AgentSearchResults';
import GroupSearchResultsPage from '../pages/GroupSearchResults';
import RiaFirmSearch from '../pages/RiaFirmSearch';
import IarSearch from '../pages/IarSearch';
import bdSearch from '../pages/bdSearch';
import rrSearch from '../pages/rrSearch';
import rrSearchResultsPage from '../pages/rrSearchResults'
import bdSearchResultsPage from '../pages/bdSearchResults'
import RiaFirmSearchResults from '../pages/RiaFirmSearchResults';
import IarSearchResults from '../pages/IarSearchResults';
import SavedSearches from '../pages/SavedSearches';

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, name, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            // the Permission are handled in the component level not in page level 

            // // check if route is restricted by role
            // if (!loggedInUserHasPermission(roles) && name === 'Group Search') {
            //     // role not authorised so redirect to RIA page
            //     return <Redirect to={{ pathname: '/riafirm/search' }} />;
            // }
            // else if (!loggedInUserHasPermission(roles) && name === 'Individual Agent Search') {
            //     // role not authorised so redirect to RIA page
            //     return <Redirect to={{ pathname: '/riafirm/search' }} />;
            // }

            // if (!loggedInUserHasPermission(roles) && name === 'RIA Firm Search') {
            //     // role not authorised so redirect to insurance page
            //     return <Redirect to={{ pathname: 'group/search' }} />;
            // }
            // else if (!loggedInUserHasPermission(roles) && name === 'IAR Search') {
            //     // role not authorised so redirect to insurance page
            //     return <Redirect to={{ pathname: 'group/search' }} />;
            // }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/group/search" />,
    route: PrivateRoute,
};

const navRoutes = [
    {
        path: '/group/search',
        name: 'Group Search',
        component: GroupSearch,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_insurance',
    },
    {
        path: '/group/results',
        component: GroupSearchResultsPage,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_insurance',
    },
    {
        path: '/agent/search',
        name: 'Individual Agent Search',
        component: AgentSearch,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_insurance',
    },
    {
        path: '/agent/results',
        component: AgentSearchResultsPage,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_insurance',
    },
    {
        path: '/riafirm/search',
        name: 'RIA Firm Search',
        component: RiaFirmSearch,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/riafirm/results',
        name: 'RIA Firm Search Results',
        component: RiaFirmSearchResults,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/iar/search',
        name: 'IAR Search',
        component: IarSearch,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/iar/results',
        name: 'IAR Search Results',
        component: IarSearchResults,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/bd/search',
        name: 'BD Search',
        component: bdSearch,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/bd/results',
        name: 'BD Search Results',
        component: bdSearchResultsPage,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/rr/search',
        name: 'RR Search',
        component: rrSearch,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/rr/results',
        name: 'RR Search Results',
        component: rrSearchResultsPage,
        route: PrivateRoute,
        header: 'Navigation',
        roles: 'search_ria',
    },
    {
        path: '/quicksearch',
        component: QuickSearchResultsPage,
        route: PrivateRoute,
    },
    {
        path: '/reports/saved',
        name: 'Saved Searches',
        component: SavedSearches,
        route: PrivateRoute,
    },
    {
        path: '/account/change-password',
        name: 'Change Password',
        component: ChangePassword,
        route: PrivateRoute,
    },
];


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/forgot-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/reset-password',
            name: 'Reset Password',
            component: ResetPasswordFromEmail,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...navRoutes, authRoutes];

const authProtectedRoutes = [...navRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
