// @flow
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import { mergeSearch } from '../../redux/actions';
import LicenseTypes from '../LicenseTypes';
import LicenseTypesRR from '../LicenseTypesRR';

const IndividualLicenseTypeSelect = ({ formName }) => {
    const dispatch = useDispatch();
    const types = useSelector((state) => state.FirstClassData.formStates[formName].formState.license_types);
    const search_type = useSelector((state) => state.FirstClassData.formStates[formName].formState.search_type);


    const [license_types, canAddAnotherLicenseClause] = useMemo(() => {
        const canAddAnotherLicenseClause = types.filter(row => {
                    return row.types.length === 0;
                }).length === 0;
        return [types, canAddAnotherLicenseClause];
    }, [types]);

    const onFieldChange = (event, index) => {
        let licenseTypes = license_types.slice();
        licenseTypes[index][event.target.name] = event.target.value;
        dispatch(mergeSearch({ license_types: licenseTypes }, formName));
    };

    const handleIncludeExcludeClick = (exclude, index) => {
        let licenseTypes = license_types.slice();
        licenseTypes[index].exclude = !exclude;
        dispatch(mergeSearch({ license_types: licenseTypes }, formName));
    };

    const handleDeleteButtonClick = (index) => {
        let licenseTypes = license_types.slice();
        licenseTypes.splice(index, 1);
        dispatch(mergeSearch({ license_types: licenseTypes }, formName));
    };

    const handleAddLicenseClause = () => {
        let licenseTypes = license_types.slice();
        licenseTypes.push({
            exclude: false,
            license_type_bool: 'or',
            types: []
        });
        dispatch(mergeSearch({ license_types: licenseTypes }, formName));
    };

    return (
        <Col lg={12}>
            {license_types.map((LicenseTypeClause, index) => (
                <Card className="my-1 license-groups-card-border" key={index}>
                    <CardHeader className="bg-light">
                        <a className="link"
                           onClick={() => handleIncludeExcludeClick(LicenseTypeClause.exclude, index)}
                        >
                            {LicenseTypeClause.exclude ? 'Exclude' : 'Include'}
                        </a>
                        &nbsp;records matching these license types:

                        {license_types.length > 1 &&
                        <button
                            onClick={() => handleDeleteButtonClick(index)}
                            type="button"
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        }
                    </CardHeader>
                    <CardBody className="pt-1">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>License type(s)</Label>
                                    {search_type == 'rrIndivSearch'&&
                                    <LicenseTypesRR name="types" multi={true}
                                                  value={LicenseTypeClause.types}
                                                  onChange={(event) => onFieldChange(event, index)} />}
                                    {search_type != 'rrIndivSearch'&&
                                    <LicenseTypes name="types" multi={true}
                                                  value={LicenseTypeClause.types}
                                                  onChange={(event) => onFieldChange(event, index)} />}

                                    {LicenseTypeClause.types.length > 1 &&
                                    <div className="form-inline flex-row-reverse pt-1">
                                        <p>
                                            Search for customers has &nbsp;
                                            <Input type="select"
                                                   name="license_type_bool"
                                                   bsSize="sm"
                                                   value={LicenseTypeClause.license_type_bool}
                                                   onChange={(event) => onFieldChange(event, index)}>
                                                <option value="and">all of these license types</option>
                                                <option value="or">any of these license types</option>
                                            </Input>
                                        </p>
                                    </div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ))}
            {canAddAnotherLicenseClause && (
                <Button
                    className="p-0 ml-3"
                    color="link"
                    onClick={handleAddLicenseClause}>
                    + Add another License Type clause
                </Button>
            )}
        </Col>
    );
};

export default IndividualLicenseTypeSelect;