// @flow
import React from 'react';
import { Col, Row } from 'reactstrap';


const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container-fluid">
                    <Row>
                        <Col md={6}>&copy; {currentYear} FirstClassData.com &mdash; All rights reserved.</Col>

                        <Col md={6}>
                            <div className="text-md-right footer-links d-none d-md-block">
                                <a href="https://www.firstclassdata.com/about/">About</a>
                                <a href="https://www.firstclassdata.com/contact-us/">Contact Us</a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
