import React, { useRef } from 'react';
import Select, { components } from 'react-select';

const Control = ({ children, ...props }) => {
  const { selectProps } = props;
  const { title } = selectProps;
  const style = { cursor: 'pointer' };

  return (
    <components.Control {...props}>
      <span style={style}>{title}</span>
      {children}
    </components.Control>
  );
};

const Option = (props) => {
  const { isSelected, innerProps } = props;
  const { id } = innerProps;

  return (
      <div
          aria-hidden="true"
          className="custom-control custom-checkbox d-flex align-items-center"
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}>
        <input
            value={isSelected}
            checked={isSelected}
            className="custom-control-input"
            type="checkbox"
            id={id}
            name="tosAcceptance"
            readOnly
        />
        <label htmlFor={id} className="custom-control-label d-block flex-grow-1">
          {' '}
        </label>
        <components.Option {...props} />
      </div>
  );
};

const MultiSelect = (props) => {
  const { value: propsValue, selectAllLabel } = props;
  const valueRef = useRef(propsValue);
  valueRef.current = propsValue;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: selectAllLabel,
  };

  const isSelectAllSelected = () => props.options.length && valueRef.current?.length === props.options.length;

  const isOptionSelected = (option) => {
    return valueRef?.current?.some(({ value }) => value === option.value) || isSelectAllSelected();
  };

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : props.value);

  const handleChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' && option.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta,
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  const styles = {
    control: (css) => ({
      ...css,
      paddingLeft: '0.25rem',
      boxShadow: 'none',
      borderRadius: '8px',
      borderColor: '#e5e9f2',
      '&:hover': {
        borderColor: '#e5e9f2',
      },
      ...props.styles,
    }),
    indicatorSeparator: (css) => ({
      ...css,
      width: '0',
    }),
    indicatorsContainer: (css) => ({
      ...css,
      minHeight: '40px',
    }),
    menu: (css) => ({
      ...css,
      // maxWidth: '248px',
      minWidth: '200px',
      // right: 0,
    }),
    option: (css) => ({
      ...css,
      padding: '8px 15px 8px 40px',
      backgroundColor: 'transparent !important',
      color: '#161616',
    }),
    multiValue: (css) => ({
      ...css,
      backgroundColor: 'transparent',
      borderRadius: '5px',
      margin: '1px 3px 1px 1px',
    }),
    multiValueLabel: (css) => ({
      ...css,
      color: '#161616',
      fontSize: '0.75rem',
    }),
    multiValueRemove: (css) => ({
      ...css,
      color: '#ff4d4d',
      opacity: 0.4,
      padding: 0,
      borderRadius: '4px',
      '&:hover': {
        background: 'transparent',
        opacity: 1,
      },
    }),
  };

  return (
    <Select
      {...props}
      className="custom-multiselect"
      components={{ Control, Option }}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={handleChange}
      closeMenuOnSelect={false}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#cb4d27',
        },
      })}
      styles={styles}
    />
  );
};

export default MultiSelect;
