// @flow
import React from 'react';
import {
    Col,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AddressStatesSearchSelects from './AddressStatesSearchSelects';
// import BranchOfficeStatesSearchSelects from './BranchOfficeStatesSearchSelects';
import IndividualLicenseTypeSelect from './IndividualLicenseTypeSelect';
import IndividualDisclosureTypeSelect from './IndividualDisclosureTypeSelect';
import IndividualotherbusinessActivities from './IndividualotherbusinessActivities';
import IndividualDesignationTypeSelect from './IndividualDesignationTypeSelect';
import IndividualRiaType from './IndividualRiaType';
import CreatableSelect from 'react-select/creatable';
import ZipCountySearchField from './ZipCountySearchField';
import YearsEmployeedIA from '../YearsEmployeedIA';

const toogleArrValue = (arr, val) => {
    const ind = arr.indexOf(val)
    if (ind === -1) {
        return [...arr, val]
    } else {
        return [...arr.slice(0, ind), ...arr.slice(ind + 1)]
    }
}
export default class AddressSearchFields extends React.Component {
    static propTypes = {
        showAddressTypeCheckboxes: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        formState: PropTypes.object.isRequired,
        formName: PropTypes.string,
    };
    static defaultProps = {
        showAddressTypeCheckboxes: true,
    }

    constructor(props) {
        super(props);
        this.state = {
            remountKey: (new Date()).getTime(),
        }
    }

    handleSelectChange(fieldName, selectedOptions) {
        let selectedValue;
        selectedValue = selectedOptions ? selectedOptions.map(el => el.value) : [];
        this.props.onChange({
            target: {
                name: fieldName,
                value: selectedValue,
            }
        });
    }

    render() {
        let formState = this.props.formState;
        return <>
            <Row>
                <Col>
                    <h4 className="header-title">Contact Information</h4>
                </Col>
            </Row>
            {this.props.showAddressTypeCheckboxes &&
                <Row>
                    <Col xs={4}>
                        <Label>Search which addresses?</Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_business_address" checked={formState.search_business_address}
                                className="mr-1" />
                            Business
                        </Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_mailing_address" checked={formState.search_mailing_address}
                                className="mr-1" />
                            Mailing
                        </Label>
                    </Col>
                    <Col>
                        <Label check>
                            <input type="checkbox" value="1" onChange={this.props.onChange}
                                name="search_home_address" checked={formState.search_home_address}
                                className="mr-1" />
                            Home
                        </Label>
                    </Col>
                </Row>
            }
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Input type="text" name="street_address" value={formState.street_address}
                            onChange={this.props.onChange} placeholder="Street Address" />
                    </FormGroup>
                    <FormGroup>
                        <Input type="text" name="city" value={formState.city}
                            onChange={this.props.onChange} placeholder="City" />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Input type="text" name="area_code" value={formState.area_code}
                            onChange={this.props.onChange} placeholder="Area Code" />
                    </FormGroup>
                    <FormGroup>
                        <Input type="text" name="phone_number" value={formState.phone_number}
                            onChange={this.props.onChange} placeholder="Phone Number" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="pt-2">
                <AddressStatesSearchSelects formName={this.props.formName} />
            </Row>
            <ZipCountySearchField formName={this.props.formName} />
            {this.props.showLicenseDataAndEmails &&
                <>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="pt-2">
                                    <Label for="email_level">Email Address</Label>
                                </Col>
                                <Col className="pt-2">
                                    <Label for="high_net_worth_clients">RIA Has High Net Worth Clients</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="email_level" id="email_level"
                                            value={formState.email_level} data-ignore={true} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="1">Has Email Verified for Mass Mailing</option>
                                            <option value="2">Has Valid Email Address</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="high_net_worth_clients" id="high_net_worth_clients"
                                            value={formState.high_net_worth_clients} data-ignore={true} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col className="pt-2">
                                    <Label for="gender">Gender</Label>
                                </Col>
                                <Col className="pt-2">
                                    <Label for="linkedin_profile">LinkedIn Profile</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="gender" id="gender"
                                            value={formState.gender} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input type="select" name="linkedin_profile" id="linkedin_profile"
                                            value={formState.linkedin_profile} onChange={this.props.onChange}>
                                            <option value="">----</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="otherbusinessActivities-selection">
                        <Col>
                            <IndividualotherbusinessActivities formName={this.props.formName} />
                        </Col>
                        <Col>
                            <IndividualDesignationTypeSelect formName={this.props.formName} />
                        </Col>
                    </Row>
                    <Row className="disclosure-type-selection">
                        <Col>
                            <IndividualDisclosureTypeSelect formName={this.props.formName} />
                        </Col>
                        <Col>
                            <IndividualRiaType formName={this.props.formName} />
                        </Col>
                    </Row><br/>
                    <YearsEmployeedIA onChange={this.props.onChange} formState={formState} />
                    {/* <Row>
                        <BranchOfficeStatesSearchSelects formName={this.props.formName} />
                    </Row> */}
                    <Row>
                        <Col className="pt-2">
                            <Label for="dually_licensed">Dually Registered</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Input type="select" name="dually_licensed" id="dually_licensed"
                                    value={formState.dually_licensed} onChange={this.props.onChange}>
                                    <option value="">----</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col lg={12}>
                            <IndividualLicenseTypeSelect formName={this.props.formName} />
                        </Col>
                    </Row>
                </>
            }
        </>
    }
}

export { AddressSearchFields }