// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import PageTitle from '../components/PageTitle';
import PaginatedSearchResultsTable from '../components/PaginatedSearchResultsTable';
import RrIndivDetailsModal from '../components/RRIndivDetailsModel';
import iarColumns from '../constants/iarColumns';
import { iarReportTypes } from '../constants/reportTypes';
import IarDetailsModal from '../components/IarDetailsModal';
import RiaFirmDetailsModal from "../components/RiaFirmDetailsModal";
import BdFirmDetailsModal from "../components/BdFirmDetailsModel";
import RRIndivDetailsModal from '../components/RRIndivDetailsModel';


class IarSearchResultsPage extends React.Component {
    static propTypes = {
        // this is the redux stored form states, for all forms
        allFormStates: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            showAgentDetailsModal: false,
            IarIndivDetailsModal: false,
            IarModal: false,
            agentDetailsId: null,
            riaDetailsId: null,
            rowId: null,
            iarRowId: null,
            hasRrProfileModel: null,
            RRBdRowId:null,
            bdRiaRowId:null,
        }
        this.handleIndividualDetails = this.handleIndividualDetails.bind(this);
        this.handleDetailsModalClose = this.handleDetailsModalClose.bind(this);
        this.handleIarIndividualDetails = this.handleIarIndividualDetails.bind(this);
        this.handleIarDetailsModalClose = this.handleIarDetailsModalClose.bind(this);
        this.onIarIndividualDetails = this.onIarIndividualDetails.bind(this);
        this.onBdRRIndividualDetails = this.onBdRRIndividualDetails .bind(this);
        this.onBdIarIndividualDetails = this.onBdIarIndividualDetails.bind(this);

    }

    handleIndividualDetails(id , employers) {
        this.setState({
            showAgentDetailsModal: true,
            agentDetailsId: id,
            riaDetailsId: employers,
            RRBdModal: false,
        })
    }

    handleDetailsModalClose() {
        this.setState({
            showAgentDetailsModal: false,
            agentDetailsId: null,
            RRBdModal: false,
            hasProfileModel: null,
        })
    }

    handleIarIndividualDetails(id, hasRrProfile, rowValues) {
        this.setState({
            IarIndivDetailsModal: true,
            hasRrProfileModel: hasRrProfile,
            rowId: id,
            showAgentDetailsModal: false,
            riaDetailsId: rowValues,
            IarModal: false,
        })
    }

    handleIarDetailsModalClose() {
        this.setState({
            IarIndivDetailsModal: false,
            rowId: null,
            hasRrProfileModel: null,
            IarModal: false,
            showAgentDetailsModal: false,
        })
    }

    onIarIndividualDetails(id) {
        this.setState({
            RRBdModal: true,
            RRBdRowId: id,
            IarIndivDetailsModal: false,
            hasRrProfileModel: null,
        })
    }

    onBdRRIndividualDetails(id,  rowValues=[], onClickValues) {
        this.setState({
            RRBdModal: true,
            RRBdRowId: id,
            riaDetailsId: rowValues,
            IarIndivDetailsModal: false,
            showAgentDetailsModal: false,
            hasRrProfileModel: onClickValues,
        })
    }


    onBdIarIndividualDetails(id, rowValues=[], hasIarProfile) {
        this.setState({
            IarIndivDetailsModal: false,
            IarModal: true,
            bdRiaRowId: id,
            hasRrProfileModel: hasIarProfile,
            riaDetailsId: rowValues,
            
        })
    }


    render() {
        const searchFormProps = this.props.allFormStates.riaIndivSearch;

        return <>
            <PageTitle title="<< Back to IAR Search" className="btn"
                    onClick={() => this.props.history.push("/iar/search")} />

            <PaginatedSearchResultsTable 
                searchFormState={searchFormProps.formState}
                recordCount={searchFormProps.rowCount}
                reportTypes={iarReportTypes}
                possibleColumns={iarColumns}
                detailsModalComponent={IarDetailsModal}
                onModalChanged={this.handleIndividualDetails}
                onIrModalChanged={this.handleIarIndividualDetails}
                onIarIndividualDetails={this.onIarIndividualDetails}
                enableSaving={true} />
            <RiaFirmDetailsModal
                isOpen={this.state.showAgentDetailsModal}
                rowId={this.state.agentDetailsId}
                listRow={this.state.riaDetailsId}
                listValue={this.state.riaDetailsId} 
                onClickChanged={this.handleIndividualDetails}
                onModalChanged={this.onBdRRIndividualDetails}
                onClose={this.handleDetailsModalClose}
            />
            <RRIndivDetailsModal
                isOpen={this.state.IarIndivDetailsModal}
                rowId={this.state.rowId}
                listValue={this.state.hasRrProfileModel}
                rowIndiv={this.state.riaDetailsId}
                onIarModalChanged={this.onBdRRIndividualDetails} 
                onClickChanged={this.handleIarIndividualDetails}
                onModalChanged={this.onBdIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />
            <BdFirmDetailsModal
                isOpen={this.state.IarModal}
                rowId={this.state.bdRiaRowId}
                listValue={this.state.riaDetailsId}
                listRow={this.state.hasRrProfileModel}
                onModalChanged={this.handleIarIndividualDetails}
                onClose={this.handleIarDetailsModalClose} />
            <IarDetailsModal
                isOpen={this.state.RRBdModal}
                rowId={this.state.RRBdRowId}
                rowIndiv={this.state.hasRrProfileModel != undefined? this.state.hasRrProfileModel : this.state.riaDetailsId}
                listValue={this.state.riaDetailsId}
                onClickChanged={this.onBdRRIndividualDetails}
                onIrModalChanged={this.handleIarIndividualDetails}
                onModalChanged={this.handleIndividualDetails}
                onClose={this.handleDetailsModalClose} />
        </>
    }
}

const mapStateToProps = storeState => ({
    allFormStates: storeState.FirstClassData.formStates,
});

export default connect(mapStateToProps)(IarSearchResultsPage)