/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR';
export const HIDE_RIGHT_SIDEBAR = 'HIDE_RIGHT_SIDEBAR';

/* PAGE LOADING STATE */
const LOADING = 'LOADING';
export const LOADING_SET_ON = `${LOADING}_SET_ON`;
export const LOADING_SET_OFF = `${LOADING}_SET_OFF`;

/* SAVED SEARCHES */
const SAVED_SEARCHES = 'SAVED_SEARCHES';
export const SAVED_SEARCHES_LAYOUT = `${SAVED_SEARCHES}_LAYOUT`;

// Restricted message 
export const PAGE_RESTRICTING_MESSAGE = 'To subscribe contact your account director.';
export const CONSUMER_DATA_RESTRICTING_MESSAGE = 'This feature has been disabled by your Administrator.';

// Search page name
export const IAR_SEARCH = 'IAR Search';
export const RIA_FIRM_SEARCH = 'RIA Firm Search';
export const RR_SEARCH = 'RR Search';
export const BD_SEARCH = 'BD Search';
export const GROUP_SEARCH = 'Insurance Search';
export const INDIVIDUAL_AGENT_SEARCH = 'Individual Agent Search';
export const SAVED_SEARCHES_PAGE = 'Saved Searches';

// Permission names 
export const SEARCH_RIA = 'search_ria'
export const SEARCH_INSURANCE = 'search_insurance'
// export const SEARCH_BD = 'search_bd'

// Elastic search document name
export const RIA_INDIVIDUAL_SEARCH = 'riaIndivSearch'
export const IAR_FIRM_SEARCH = 'riaFirmSearch'
export const RR_INDIVIDUAL_SEARCH = 'rrIndivSearch'
export const BD_FIRM_SEARCH = 'bdFirmSearch'

// Quick search page tile 
export const QUICK_SEARCH_PAGE ={
    'riaIndivSearch': 'IAR Search',
    'riaFirmSearch': 'RIA Firm Search',
    'rrIndivSearch': 'RR Search',
    'bdFirmSearch': 'BD Search',
}

export const INSURANC_SEARCH_PAGE = 'Insurance Search'

