function column(field, width) {
    return {field: field, text: field, width: width}
}

let allColumnNames = [
    // BD firm columns
    {field: 'Exec CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'First Name', width: 150, fixed: 'left'},
    {field: 'Last Name', width: 150 , fixed: 'left'},
    {field: 'CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'Firm Name', width: 200, fixed: 'left'},
    {field: 'Firm Status', width: 200, fixed: 'left'},
    'Branch Office 1 id',
    'Number of RRs',
    'Sec Number',
    {field: 'Main Office Phone', width: 150, resizable: true},
    'Main Office Phone Ext',
    'Branch Count',
    'Contact Count',
    'Disclosure',
    {field: 'Main Office Address 1', width: 250, resizable: true},
    {field: 'Main Office Address 2', width: 250, resizable: true},
    {field: 'Main Office City', width: 150, resizable: true},
    'Main Office State',
    'Main Office Zip',
    'Main Office Zip4',
    {field: 'Main Office County', width: 150, resizable: true},
    'Main Office Country',
    {field: 'Mailing Address 1', width: 250, resizable: true},
    {field: 'Mailing Address 2', width: 250, resizable: true},
    {field: 'Mailing City', width: 150, resizable: true},
    'Mailing State',
    'Mailing Zip',
    'Mailing Zip4',
    {field: 'Mailing County', width: 150, resizable: true},
    'Mailing Country',
    {field: 'Other Name 1', width: 250, resizable: true},
    {field: 'Other Name 2', width: 250, resizable: true},
    {field: 'Other Name 3', width: 250, resizable: true},
    'Branch Office 1 Individual CRD',
    {field: 'Branch Office 1 Address', width: 250, resizable: true},
    {field: 'Branch Office 2 Address', width: 250, resizable: true},
    'Branch Office 1 City',
    'Branch Office 1 State',
    'Branch Office 1 Zip',
    'Branch Office 1 Zip4',
    'Branch Office 1 County',
    'Branch Office 1 Country',
    'Branch Office 1 Located At Flag',
    'Branch Office 1 Supervised From Flag',
    'Branch Office 1 Private Residence Flag',
    'Branch Office 1 Non Registered Office Flag',
    'Branch Office 1 Ela Begin Date',
    'Branch Office 2 id',
    'Branch Office 2 Individual CRD',
    'Branch Office 2 City',
    'Branch Office 2 State',
    'Branch Office 2 County',
    'Branch Office 2 Country',
    'Branch Office 2 Zip',
    'Branch Office 2 Located At Flag',
    'Branch Office 2 Supervised From Flag',
    'Branch Office 2 Private Residence Flag',
    'Branch Office 2 Non Registered Office Flag',
    'Branch Office 2 Ela Begin Date',
    'Branch Office 3 id',
    'Branch Office 3 Individual CRD',
    'Branch Office 3 Address',
    'Branch Office 3 City',
    'Branch Office 3 State',
    'Branch Office 3 County',
    'Branch Office 3 Country',
    'Branch Office 3 Zip',
    'Branch Office 3 Located At Flag',
    'Branch Office 3 Supervised From Flag',
    'Branch Office 3 Private Residence Flag',
    'Branch Office 3 Non Registered Office Flag',
    'Branch Office 3 Ela Begin Date',
    'Number of Registered States',
];


// export a list suitable for the datatable, eg every one needs a "field" and "text"
let allColumns = [];
for (let i=0; i<allColumnNames.length; i++) {
    let col = allColumnNames[i];
    if (typeof col === 'string') {
        allColumns.push({ field: col, text: col, width: 100 });
    } else {
        if (!('text' in col)) {
            col['text'] = col['field'];
        }
        if (!('width' in col)) {
            col['width'] = 100;
        }
        allColumns.push(col);
    }
}

export default allColumns;