import React from 'react';
import PropTypes from 'prop-types';


export default class EmailLevelIcon extends React.Component {
    static propTypes = {
        level: PropTypes.any.isRequired,
    };
    render() {
        const emailIcons = {
            '1': <i className="uil uil-star" title="☆: Verified for Mass-Mailing"></i>,
            '2': <i className="uil uil-check" title="✓: Verified valid"></i>,
            '3': <i className="uil uil-question" title="?: Will likely bounce"></i>,
            '4': <i className="uil uil-ban" title="⊘: Problematic"></i>,
        }
        const emailLevelString = '' + this.props.level;
        if (emailLevelString in emailIcons) {
            return emailIcons[emailLevelString];
        }
        return <></>;
    }
}
