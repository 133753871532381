// @flow
import {
    SEARCH_FORM_CHANGED,
    SEARCH_COUNT_RETRIEVED,
    QUICKSEARCH_SUBMITTED, SEARCH_UPDATE
} from './constants';
import { SearchFormState, RiaFirmSearchState, IarSearchState, BdSearchState ,RrSearchState} from './dtos';
import { loggedInUserHasPermission } from '../../helpers/authUtils';


function saveStateToLocalStorage(formName, formState) {
    window.localStorage.setItem(formName, JSON.stringify(formState));
}

// store the search form state in localstorage, so if we refresh the page the last search is still stored
function loadStateFromLocalStorage(formName) {
    let encoded = window.localStorage.getItem(formName);
    let decoded = {}
    if (encoded) {
        decoded = JSON.parse(encoded);
    }
    if (formName == 'riaFirmSearch') {
        return new RiaFirmSearchState(decoded);
    }
    else if (formName == 'riaIndivSearch') {
        return new IarSearchState(decoded);
    }
    else if (formName == 'bdFirmSearch') {
        return new BdSearchState(decoded);
    }
    else if (formName == 'rrIndivSearch') {
        return new RrSearchState(decoded);
    }
    else {
        let dto = new SearchFormState(decoded);
        return ensureUserSearchPermissions(dto)
    }
}

function ensureUserSearchPermissions(formState) {
    const hasSearchAgentsOnlyPermission = loggedInUserHasPermission('search_agents_only');
    const hasSearchAgenciesOnlyPermission = loggedInUserHasPermission('search_agencies_only');

    // ensure that if their user permission says "only agents / agencies", then that's what
    // we are searching for
    if (hasSearchAgentsOnlyPermission && 'filter_is_agent' in formState && formState.filter_is_agent !== 'agents') {
        formState.filter_is_agent = 'agents';
    }

    if (hasSearchAgenciesOnlyPermission && 'filter_is_agent' in formState && formState.filter_is_agent !== 'agencies') {
        formState.filter_is_agent = 'agencies';
    }
    return formState;
}

const INIT_STATE = {
    formStates: {
        groupSearch: {
            formState: loadStateFromLocalStorage('groupSearch'),
            rowCount: 0,
        },
        agentSearch: {
            formState: loadStateFromLocalStorage('agentSearch'),
            rowCount: 0,
        },
        quickSearch: {
            formState: loadStateFromLocalStorage('quickSearch'),
            rowCount: 0,
        },
        riaFirmSearch: {
            formState:loadStateFromLocalStorage('riaFirmSearch'),
            rowCount: 0,
        },
        riaIndivSearch: {
            formState: loadStateFromLocalStorage('riaIndivSearch'),
            rowCount: 0,
        },
        bdFirmSearch: {
            formState: loadStateFromLocalStorage('bdFirmSearch'),
            rowCount: 0,
        },
        rrIndivSearch: {
            formState: loadStateFromLocalStorage('rrIndivSearch'),
            rowCount: 0,
        },
    },
};


const FirstClassData = (state: State = INIT_STATE, action) => {
    // console.log('FirstClassData reducer running with action: ', action);

    const { formName } = action;

    switch (action.type) {
        case SEARCH_FORM_CHANGED:
            action.formState.search_type = formName;
            action.formState = ensureUserSearchPermissions(action.formState);
            state.formStates[formName] = {
                formState: action.formState,
                rowCount: state.formStates[formName].rowCount || 0
            };
            saveStateToLocalStorage(formName, action.formState);
            return state;

        case SEARCH_COUNT_RETRIEVED:
            state.formStates[formName].rowCount = action.rowCount;
            return state;

        case QUICKSEARCH_SUBMITTED:
            let quicksearchFormState;
            quicksearchFormState = action.quicksearch
            quicksearchFormState.quicksearch = action.searchQuery;
            quicksearchFormState = ensureUserSearchPermissions(quicksearchFormState);

            state.formStates[formName] = {
                formState: quicksearchFormState,
                rowCount: action.rowCount,
            };
            saveStateToLocalStorage(formName, quicksearchFormState);
            return state;

        case SEARCH_UPDATE:
            state.formStates[formName] = {
                formState: action.formState,
                rowCount: action.rowCount,
            };
            return state;

        default:
            return { ...state };
    }
};

export default FirstClassData;
