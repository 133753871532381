// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { LOGIN_USER, LOGOUT_USER, CLEAR_USER } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
} from './actions';
import APIClient from '../../utils/API';


/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, userId }, history }) {
    let client = new APIClient();
    let userData = yield client.login(username, password, userId);
    if (userData) {
        yield put(loginUserSuccess(userData));
        yield history.push('/');
    } else {
        yield put(loginUserFailed('Username or password is incorrect'));
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        let client = new APIClient();
        client.logout();
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

function *clearUser() {
    try {
        let client = new APIClient();
        yield client.logout();
    } catch (error) {}
}

export function* watchLoginUser(): any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchClearUser(): any {
    yield takeEvery(CLEAR_USER, clearUser);
}

function* authSaga(): any {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchClearUser)]);
}

export default authSaga;
