// @flow
import React from 'react';
import AgentSearchForm from '../components/AgentSearchForm';
import SearchPage from './SearchPage';


export default class AgentSearch extends React.Component {

    render() {
        return <SearchPage pageTitle="Agent Search" formName="agentSearch" searchFormComponent={AgentSearchForm} />
    }
}

