import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';


export default class Paginator extends React.Component {
    static propTypes = {
        currentPage: PropTypes.number.isRequired,
        maxPages: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    render() {
        const pageChangeHandler = (pageNum) => {
            return () => {
                this.props.onPageChange(pageNum);
            }
        }

        let pageItems = [];
        const firstPageOption = Math.max(this.props.currentPage - 2, 1);
        const lastPageOption = Math.min(this.props.currentPage + 2, this.props.maxPages);
        for (let i=firstPageOption; i<=lastPageOption; i++) {
            pageItems.push(
                <PaginationItem className={i === this.props.currentPage ? 'active': ''} key={i}>
                    <PaginationLink onClick={pageChangeHandler(i)}>{i}</PaginationLink>
                </PaginationItem>
            );
        }
        if (this.props.currentPage > 1) {
            pageItems.unshift(
                <PaginationItem key="prev">
                    <PaginationLink previous onClick={pageChangeHandler(this.props.currentPage - 1)} />
                </PaginationItem>
            );
        }
        if (firstPageOption > 1) {
            pageItems.unshift(
                <PaginationItem key="first">
                    <PaginationLink first onClick={pageChangeHandler(1)} />
                </PaginationItem>
            );
        }
        if (this.props.currentPage < this.props.maxPages) {
            pageItems.push(
                <PaginationItem key="next">
                    <PaginationLink next onClick={pageChangeHandler(this.props.currentPage + 1)} />
                </PaginationItem>
            );
        }
        if (lastPageOption < this.props.maxPages) {
            pageItems.push(
                <PaginationItem key="last">
                    <PaginationLink last onClick={pageChangeHandler(this.props.maxPages)} />
                </PaginationItem>
            );
        }

        return (
            <Pagination children={pageItems} className={this.props.className}>
            </Pagination>
        );
    }
}
