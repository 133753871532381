function column(field, width) {
    return {field: field, text: field, width: width}
}
let allColumnNames = [
    // RIA individual columns
    {field: 'CRD', width: 100, fixed: 'left', sortable: true},
    {field: 'First Name', width: 140, fixed: 'left'},
     {field: 'Middle Name', width: 140, fixed: 'left'},
    {field: 'Last Name', width: 140, fixed: 'left'},
    column('Suffix', 70),
    'Gender',
    'Firm CRD',
    {field: 'Business Name', width: 250, resizable: true},
    {field: 'RIA Main Office Phone', width: 150, resizable: true},
    'RIA Main Office Phone Ext',
    {field: 'Primary Email Address', width: 250, resizable: true},
    {field: 'Primary Email Domain', width: 150, resizable: true},
    'Alternate Sourced Email Indicator',
    {field: 'Secondary Email Address', width: 250, resizable: true},
    {field: 'Secondary Email Domain', width: 150, resizable: true},
    {field: 'Alternate Email Address', width: 250, resizable: true},
    {field: 'Alternate Email Domain', width: 150, resizable: true},
    'Designation 1',
    'Designation 2',
    'Has Regulatory Action',
    'Has Criminal Record',
    'Has Declared Bankruptcy',
    'Has Civil Judgement',
    'Has Bond',
    'Has Judgement',
    'Has Investigation',
    'Has Customer Dispute',
    'Has Terminated',
    'Exam 1 Code',
    {field: 'Exam 1 Description', width: 250, resizable: true},
    'Exam 1 Date',
    'Exam 2 Code',
    {field: 'Exam 2 Description', width: 250, resizable: true},
    'Exam 2 Date',
    'Exam 3 Code',
    {field: 'Exam 3 Description', width: 250, resizable: true},
    'Exam 3 Date',
    'Registration 1 Authority',
    'Registration 1 Category',
    'Registration 1 Status',
    'Registration 1 Date',
    'Registration 2 Authority',
    'Registration 2 Category',
    'Registration 2 Status',
    'Registration 2 Date',
    'Registration 3 Authority',
    'Registration 3 Category',
    'Registration 3 Status',
    'Registration 3 Date',
    {field: 'Branch Office 1 Address', width: 250, resizable: true},
    {field: 'Branch Office 2 Address', width: 250, resizable: true},
    {field: 'Branch Office 1 City', width: 150, resizable: true},
    'Branch Office 1 State',
    'Branch Office 1 Zip',
    'Branch Office 1 Zip4',
    {field: 'Branch Office 1 County', width: 150, resizable: true},
    'Branch Office 1 Country',
    'Branch Office 2 Country',
    'Branch Office 2 County',
    'Branch Office 2 State',
    'Branch Office 2 Zip',
    'Branch Office 3 Address',
    'Branch Office 3 City',
    'Branch Office 3 Country',
    'Branch Office 3 County',
    'Branch Office 3 State',
    'Branch Office 3 Zip',
    {field: 'Main Office 1 Address', width: 250, resizable: true},
    {field: 'Main Office 2 Address', width: 250, resizable: true},
    'Main Office 1 CRD',
    'Main Office 1 Name',
    'Main Office 1 City',
    'Main Office 1 State',
    'Main Office 1 Zip',
    'Main Office 1 Zip4',
    'Main Office 1 County',
    'Main Office 1 Country',
    'Main Office 2 CRD',
    'Main Office 2 Name',
    'Main Office 2 City',
    'Main Office 2 Country',
    'Main Office 2 County',
    'Main Office 2 State',
    'Main Office 2 Zip',
    'Main Office 3 CRD',
    'Main Office 3 Name',
    'Main Office 3 City',
    'Main Office 3 Country',
    'Main Office 3 County',
    'Main Office 3 State',
    'Main Office 3 Zip',
    {field: 'Previous Registration 1 Name', width: 250, resizable: true},
    'Previous Registration 1 From Date',
    'Previous Registration 1 To Date',
    {field: 'Previous Registration 2 Name', width: 250, resizable: true},
    'Previous Registration 2 From Date',
    'Previous Registration 2 To Date',
    {field: 'Previous Registration 3 Name', width: 250, resizable: true},
    'Previous Registration 3 From Date',
    'Previous Registration 3 To Date',
    'Total IAs at Firm',
    'Earliest Exam Date',
    'Number of Exams',
    'Number of Years Licensed',
    'Earliest Employment Date IA',
    'Years Employed IA',
    'Number of States Registered',
    'Holds Series 65 ONLY',
    'RIA Has High Net Worth Clients',
    'RIA Executive',
    'Registered Rep',
    'Insurance Agent',
    {field: 'IA SEC Profile Link', width: 450, resizable: true},
    {field: 'LinkedIn Profile', width: 400, resizable: true},
];


// export a list suitable for the datatable, eg every one needs a "field" and "text"
let allColumns = [];
for (let i=0; i<allColumnNames.length; i++) {
    let col = allColumnNames[i];
    if (typeof col === 'string') {
        allColumns.push({ field: col, text: col, width: 100 });
    } else {
        if (!('text' in col)) {
            col['text'] = col['field'];
        }
        if (!('width' in col)) {
            col['width'] = 100;
        }
        allColumns.push(col);
    }
}

export default allColumns;