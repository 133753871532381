// @flow
import jwtDecode from 'jwt-decode';
import APIClient from '../utils/API';


const apiClient = new APIClient();

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user || !user.access_token) {
        return false;
    }

    // if they have a valid "access" token, they're logged in
    const accessToken = jwtDecode(user.access_token);
    const currentTime = Date.now() / 1000;
    if (accessToken.exp > currentTime) {
        return true;
    }

    // but the access token only lives for 5 minutes, and if that's expired but they have valid "refresh" token,
    // then we just want to ping to server to confirm if they should remain logged in or be forced to log out
    const refreshToken = jwtDecode(user.refresh_token);
    if (accessToken && refreshToken && refreshToken.exp > currentTime) {
        apiClient.testConnection();
        return true;
    }

    return false;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    return apiClient.getCurrentUser();
};

/**
 * returns true if the user has appropriate permission
 */
const loggedInUserHasPermission = (perm) => {
    const user = apiClient.getCurrentUser();
    if (!user) return false;
    const {permissions={}} = user;
    permissions.search_search_nbrhood_household_cluster = true;
    return !perm || permissions[perm] === true;
};

const checkStateUserPermission = (perm, user) => {
    if (!user) return false;
    const {permissions={}} = user;
    permissions.search_search_nbrhood_household_cluster = true;
    return !perm || permissions[perm] === true;
};

export { isUserAuthenticated, getLoggedInUser, loggedInUserHasPermission, checkStateUserPermission };
