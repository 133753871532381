import { loggedInUserHasPermission } from "../helpers/authUtils"

const filterColsByRole = (column) => {
    if (loggedInUserHasPermission('search_search_nbrhood_household_cluster')){
        return column
    }
    if (column === 'Nbrhood Cluster') return
    if (column === 'Household Cluster') return
    return column
}

let consumerDataFields = [
    'Gender',
    'Age',
    'Date Of Birth',
    'Ethnicity',
    'Homeowner Status',
    'Dwelling Type',
    'Home Value',
    'Home Built Year',
    'Income',
    'Net Worth',
    'Marital Status',
    'Children',
    'Pets',
    'Premium Credit Card',
    'Vehicles',
    'Donor',
    'Foods',
    'Collectibles',
    'Exercise',
    'Games',
    'Investments',
    'Music',
    'Reading',
    'Outdoor',
    'Spectator Sports',
    'Sports',
    'Travel',
    'Buyer',
    'Apparel',
    'Nbrhood Cluster',
    'Household Cluster'
]

let loaReportCols = [
    'NPN',
    'First Name',
    'Middle Name',
    'Last Name',
    'Suffix',
    'Total LOA count',
];
let carrierReportCols = [
    'NPN',
    'First Name',
    'Middle Name',
    'Last Name',
    'Suffix',
    'Total Appointment count',
];

for (let i=1; i<11; i++) {
    loaReportCols.push(...[
        `License State ${i}`,
        `License Type ${i}`,
        `LOA Name ${i}`,
        `LOA Category ${i}`,
        `License Start Date ${i}`,
        `License Expiration Date ${i}`,
    ])
    carrierReportCols.push(...[
        `Carrier Name ${i}`,
        `Carrier NAIC ${i}`,
        `Carrier Group ${i}`,
        `Carrier Start Date ${i}`,
        `Carrier End Date ${i}`,
    ])
}

let agentsReportCols = [
    'NPN',
    'First Name',
    'Middle Name',
    'Last Name',
    'Suffix',
    'Gender',
    'Date Of Birth',
    'Age',
    'Business Email',
    'Business Email Level',
    'Email 1',
    'Email 1 Level',
    'Email 2',
    'Email 2 Level',
    'Email 3',
    'Email 3 Level',
    'Business Primary Phone',
    'Phone 1',
    'Phone 2',
    'Phone 3',
    'Fax',
    'Business Address',
    'Business City',
    'Business State',
    'Business Zip',
    'Business County',
    'Mailing Address',
    'Mailing City',
    'Mailing State',
    'Mailing Zip',
    'Mailing County',
    'Home Address',
    'Home City',
    'Home State',
    'Home Zip',
    'Home County',
    'Holds Life License',
    'Holds Health License',
    'Holds Property License',
    'Holds Casualty License',
    'Holds Adjuster License',
    'Holds Accident License',
    'Holds Variable Licensed License',
    'Holds Annuity License',
    'Holds Surplus Lines License',
    'Holds Viatical / Life Settlement License',
    'Holds Limited Lines - Credit License',
    'Holds Reinsurance License',
    'Holds Surety License',
    'Holds Limited Lines - Title License',
    'Holds Limited Lines - Travel License',
    'Holds Other License',
];

const consumerDataReportCols = [
    'NPN',
    'First Name',
    'Middle Name',
    'Last Name',
    'Suffix',
    'Gender',
    'Date Of Birth',
    'Age',
    'Ethnicity',
    'Homeowner Status',
    'Dwelling Type',
    'Home Value',
    'Home Built Year',
    'Income',
    'Net Worth',
    'Marital Status',
    'Children',
    'Pets',
    'Premium Credit Card',
    'Vehicles',
    'Donor',
    'Foods',
    'Collectibles',
    'Exercise',
    'Games',
    'Investments',
    'Music',
    'Reading',
    'Outdoor',
    'Spectator Sports',
    'Sports',
    'Travel',
    'Buyer',
    'Apparel',
    'Nbrhood Cluster',
    'Household Cluster',
];

const allColumns = [...new Set([
    'NPN',
    'Agency Name',
    'Years Licensed',
    ...consumerDataReportCols,
    ...agentsReportCols,
    ...loaReportCols,
    ...carrierReportCols
])]

const insuranceAgentReportTypes = [
    {
        value: 'agents',
        label: "Professional's Information",
        columns: agentsReportCols,
        permission: 'search_insurance',
    },
    {
        value: 'agencies',
        label: 'Agency Information',
        columns: [
            'NPN',
            'Agency Name',
            'Business Email',
            'Business Email Level',
            'Email 1',
            'Email 1 Level',
            'Email 2',
            'Email 2 Level',
            'Email 3',
            'Email 3 Level',
            'Business Primary Phone',
            'Phone 1',
            'Phone 2',
            'Phone 3',
            'Fax',
            'Address1',
            'Address1 City',
            'Address1 State',
            'Address1 Zip',
            'Address2',
            'Address2 City',
            'Address2 State',
            'Address2 Zip',
            'Address3',
            'Address3 City',
            'Address3 State',
            'Address3 Zip',
            'Total LOA count',
            'Total Appointment count',
            'Holds Life License',
            'Holds Health License',
            'Holds Property License',
            'Holds Casualty License',
            'Holds Adjuster License',
            'Holds Accident License',
            'Holds Variable Licensed License',
            'Holds Annuity License',
            'Holds Surplus Lines License',
            'Holds Viatical / Life Settlement License',
            'Holds Limited Lines - Credit License',
            'Holds Reinsurance License',
            'Holds Surety License',
            'Holds Limited Lines - Title License',
            'Holds Limited Lines - Travel License',
            'Holds Other License',
        ],
        permission: 'search_insurance',
    },

    {
        value: 'carriers',
        label: "Carrier Appointment Detail",
        columns: carrierReportCols,
        permission: 'search_insurance',
    },

    {
        value: 'loas',
        label: "Licensing Detail",
        columns: loaReportCols,
        permission: 'search_insurance',
    },

    {
        value: 'all',
        label: "All Columns",
        columns: allColumns,
        permission: 'search_insurance',
    },

    {
        value: 'consumerData',
        label: "Consumer Data",
        columns: consumerDataReportCols.filter(column => filterColsByRole(column)),
        permission: 'search_consumerdata',
    },
];

const consumerDataReportTypes = [
    {
        value: 'consumerData',
        label: "Consumer Data",
        columns: consumerDataReportCols,
    },
    ...insuranceAgentReportTypes,
];

const ExecutiveDataColumns = [
    'Exec CRD',
    'First Name',
    'Middle Name',
    'Last Name',
    'Suffix Name',
    'Gender',
    'Firm CRD',
    'Firm Name',
    'RIA Main Office Phone',
    'RIA Main Office Phone Ext',
    'Execs Insurance Business Phone',
    'Execs Insurance Business Phone Ext',
    'Execs Business Home Phone',
    'Primary Email Address',
    'Primary Email Address Level',
    'Primary Email Domain',
    'Alternate Sourced Email Indicator',
    'Secondary Email Address',
    'Secondary Email Address Level',
    'Secondary Email Domain',
    'Alternate Email Address',
    'Alternate Email Address Level',
    'Alternate Email Domain',
    'Business Personal Email Address',
    'Business Personal Email Domain',
    'Alternate Email Domain',
    'Business Personal Email Address',
    'Business Personal Email Domain',
    'Earliest Financial Svs Date',
    'Corporate Title or Role',
    'A_C Level or SR Mgmt',
    'A_Middle Mgm',
    'A__First Level Mgmt or Other',
    'B_Financial Ops',
    'B_Investment Research',
    'B_Marketing Sales',
    'B_Technology',
    'Investment Advisor Rep',
    'Registered Rep',
    'Insurance Agent',
    'IA SEC Profile Link',
    'LinkedIn Profile',
]

const riaFirmExecutiveReportTypes = [

    {
        value: 'riaFirmExecutiveData',
        label: 'Executive Data',
        columns: ExecutiveDataColumns,
        permission: 'search_ria',
    },
];

const riaFirmReportTypes = [
    {
        value: 'riaFirminformation',
        label: "RIA Information",
        columns: [
            'CRD',
            'Firm Name',
            'Regulated By',
            'First Registration Date',
            'Headquarters Outside Us',
            'Internet Investment Adviser',
            'Wrap Fee Program Adviser',
            'Number of Employees',
            'Number of IA Reps',
            'Main Office Phone',
            'Main Office Phone Ext',
            'Main Office Fax',
            'Main Office Address 1',
            'Main Office Address 2',
            'Main Office City',
            'Main Office State',
            'Main Office Zip',
            'Main Office Zip4',
            'Main Office County',
            'Main Office Country',
            'Number of Registered States',
            'States Registered',
            'Custodian 1',
            'Custodian 2',
            'Custodian 3',
            'AUM Discretionary',
            'AUM Non Discretionary',
            'AUM TOTAL',
            'Accts Under Mgmt Discretionary',
            'Accts Under Mgmt Non Discretionary',
            'Accts Under Mgmt TOTAL',
            'Sponsors Wrap Fee Program',
            'Portfolio Manager for a Wrap Fee Program',
            'LinkedIn Profile 1',
            'LinkedIn Profile 2',
            'LinkedIn Profile 3',
            'Website 1',
            'Website 2',
            'Website 3',
            'Instagram 1',
            'Instagram 2',
            'Instagram 3',
            'Facebook 1',
            'Facebook 2',
            'Facebook 3',
            'Twitter 1',
            'Twitter 2',
            'Twitter 3',
            'YouTube 1',
            'YouTube 2',
            'YouTube 3',
        ],
        permission: 'search_ria',
    },
];

// Header values for displaying the IAR individual data in the IAR result page.
const iarReportTypes = [
    {
        value: 'riaIndivInformation',
        label: "IA Rep's Information",
        columns: [
            'CRD',
            'First Name',
            'Last Name',
            'Middle Name',
            'Suffix',
            'Gender',
            'Firm CRD',
            'Business Name',
            'RIA Main Office Phone',
            'RIA Main Office Phone Ext',
            'Primary Email Address',
            'Primary Email Address Level',
            'Primary Email Domain',
            'Alternate Sourced Email Indicator',
            'Secondary Email Address',
            'Secondary Email Address Level',
            'Secondary Email Domain',
            'Alternate Email Address',
            'Alternate Email Address Level',
            'Alternate Email Domain',
            'Branch Office 1 Address',
            'Branch Office 2 Address',
            'Branch Office 1 City',
            'Branch Office 1 State',
            'Branch Office 1 Zip',
            'Branch Office 1 Zip4',
            'Branch Office 1 County',
            'Branch Office 1 Country',
            'Main Office 1 Address',
            'Main Office 2 Address',
            'Main Office 1 City',
            'Main Office 1 State',
            'Main Office 1 Zip',
            'Main Office 1 Zip4',
            'Main Office 1 County',
            'Main Office 1 Country',
            'Total IAs at Firm',
            'Holds Series 65 ONLY',
            'Number of Years Licensed',
            'Earliest Exam Date',
            'Number of Exams',
            'Earliest Employment Date IA',
            'Years Employed IA',
            'Number of States Registered',
            'RIA Has High Net Worth Clients',
            'RIA Executive',
            'Registered Rep',
            'Insurance Agent',
            'IA SEC Profile Link',
            'LinkedIn Profile',
        ],
        permission: 'search_ria',
    },
    {
        value: 'riaIndivExam',
        label: "IAs Exam Information",
        columns: [
            'CRD',
            'First Name',
            'Last Name',
            'Middle Name',
            'Suffix',
            'Exam 1 Code',
            'Exam 1 Description',
            'Exam 1 Date',
            'Exam 2 Code',
            'Exam 2 Description',
            'Exam 2 Date',
            'Exam 3 Code',
            'Exam 3 Description',
            'Exam 3 Date',
        ],
        permission: 'search_ria',
    },
    {
        value: 'riaIndivEmployment',
        label: "IAs Previous Employment History",
        columns: [
            'CRD',
            'First Name',
            'Last Name',
            'Middle Name',
            'Suffix',
            'Gender',
            'Business Name',
            'Previous Registration 1 Name',
            'Previous Registration 1 From Date',
            'Previous Registration 1 To Date',
            'Previous Registration 2 Name',
            'Previous Registration 2 From Date',
            'Previous Registration 2 To Date',
            'Previous Registration 3 Name',
            'Previous Registration 3 From Date',
            'Previous Registration 3 To Date'
        ],
        permission: 'search_ria',
    },
    {
        value: 'riaIndivAllColumns',
        label: "All Columns",
        columns: [
            'CRD',
            'First Name',
            'Last Name',
            'Middle Name',
            'Suffix',
            'Gender',
            'Firm CRD',
            'Business Name',
            'RIA Main Office Phone',
            'RIA Main Office Phone Ext',
            'Primary Email Address',
            'Primary Email Address Level',
            'Primary Email Domain',
            'Alternate Sourced Email Indicator',
            'Secondary Email Address',
            'Secondary Email Address Level',
            'Secondary Email Domain',
            'Alternate Email Address',
            'Alternate Email Address Level',
            'Alternate Email Domain',
            'Exam 1 Code',
            'Exam 1 Description',
            'Exam 1 Date',
            'Exam 2 Code',
            'Exam 2 Description',
            'Exam 2 Date',
            'Exam 3 Code',
            'Exam 3 Description',
            'Exam 3 Date',
            'Branch Office 1 Address',
            'Branch Office 2 Address',
            'Branch Office 1 City',
            'Branch Office 1 State',
            'Branch Office 1 Zip',
            'Branch Office 1 Zip4',
            'Branch Office 1 County',
            'Branch Office 1 Country',
            'Main Office 1 Address',
            'Main Office 2 Address',
            'Main Office 1 City',
            'Main Office 1 State',
            'Main Office 1 Zip',
            'Main Office 1 Zip4',
            'Main Office 1 County',
            'Main Office 1 Country',
            'Previous Registration 1 Name',
            'Previous Registration 1 From Date',
            'Previous Registration 1 To Date',
            'Previous Registration 2 Name',
            'Previous Registration 2 From Date',
            'Previous Registration 2 To Date',
            'Previous Registration 3 Name',
            'Previous Registration 3 From Date',
            'Previous Registration 3 To Date',
            'Total IAs at Firm',
            'Holds Series 65 ONLY',
            'Number of Years Licensed',
            'Earliest Exam Date',
            'Number of Exams',
            'Earliest Employment Date IA',
            'Years Employed IA',
            'Number of States Registered',
            'RIA Has High Net Worth Clients',
            'RIA Executive',
            'Registered Rep',
            'Insurance Agent',
            'IA SEC Profile Link',
            'LinkedIn Profile',
        ],
        permission: 'search_ria',
    },
    
];
const bdExecutiveReportTypes = [

    {
        value: 'bdExecutiveData',
        label: 'Executive Data',
        columns: [
            'Exec CRD',
            'First Name',
            'Middle Name',
            'Last Name',
            'Suffix Name',
            'Gender',
            'Firm CRD',
            'Firm Name',
            'BD Main Office Phone',
            'BD Main Office Phone Ext',
            'Execs Insurance Business Phone',
            'Execs Insurance Business Phone Ext',
            'Execs Business Home Phone',
            'Primary Email Address',
            'Primary Email Address Level',
            'Primary Email Domain',
            'Alternate Sourced Email Indicator',
            'Secondary Email Address',
            'Secondary Email Address Level',
            'Secondary Email Domain',
            'Alternate Email Address',
            'Alternate Email Address Level',
            'Alternate Email Domain',
            'Business Personal Email Address',
            'Business Personal Email Domain',
            'Alternate Email Domain',
            'Business Personal Email Address',
            'Business Personal Email Domain',
            'Earliest Financial Svs Date',
            'Corporate Title or Role',
            'A_C Level or SR Mgmt',
            'A_Middle Mgm',
            'A__First Level Mgmt or Other',
            'B_Financial Ops',
            'B_Investment Research',
            'B_Marketing Sales',
            'B_Technology',
            'Investment Advisor Rep',
            'Registered Rep',
            'Insurance Agent',
            'Brocker check Profile Link',
            'LinkedIn Profile',
        ]
        ,
        permission: 'search_ria',
    },
];
// Header values for displaying the BD Firm data in the DB search result page.
const bdFirmReportTypes = [
    {
        value: 'bdFirmInformation',
        label: "Broker Dealer Information",
        columns: [
            'CRD',
            'Firm Name',
            'Number of RRs',
            'Main Office Phone',
            'Main Office Phone Ext',
            'Main Office Address 1',
            'Main Office Address 2',
            'Main Office City',
            'Main Office State',
            'Main Office Zip',
            'Main Office Zip4',
            'Main Office County',
            'Main Office Country',
            'Mailing Address 1',
            'Mailing Address 2',
            'Mailing City',
            'Mailing State',
            'Mailing Zip',
            'Mailing Zip4',
            'Mailing County',
            'Mailing Country',
            'Number of Registered States',
        ],
        permission: 'search_ria',
    },
    {
        value: 'bdFirmExecutives',
        label: "BD Executives",
        columns: [
            'Exec CRD',
            'First Name',
            'Last Name',
            'CRD',
            'Firm Name',
            'Main Office Phone',
            'Main Office Phone Ext',

        ],
        permission: 'search_ria',
    },
    {
        value: 'bdFirmProducts',
        label: "BD Products / Services",
        columns: [
            'CRD',
            'Firm Name',
        ],
        permission: 'search_ria',
    },
    {
        value: 'bdFirmBranches',
        label: "BD Branches",
        columns: [
            'CRD',
            'Branch Office 1 id',
            'Firm Name',
            'Number of RRs',
            'Branch Office 1 Address',
            'Branch Office 2 Address',
            'Branch Office 1 City',
            'Branch Office 1 State',
            'Branch Office 1 Zip',
            'Branch Office 1 Zip4',
            'Branch Office 1 County',
            'Branch Office 1 Country',
        ],
        permission: 'search_ria',
    },
    {
        value: 'bdFirmAllColumns',
        label: "All Columns",
        columns: [
            'CRD',
            'Firm Name',
            'Number of RRs',
            'Main Office Phone',
            'Main Office Phone Ext',
            'Main Office Address 1',
            'Main Office Address 2',
            'Main Office City',
            'Main Office State',
            'Main Office Zip',
            'Main Office Zip4',
            'Main Office County',
            'Main Office Country',
            'Mailing Address 1',
            'Mailing Address 2',
            'Mailing City',
            'Mailing State',
            'Mailing Zip',
            'Mailing Zip4',
            'Mailing County',
            'Mailing Country',
            'Branch Office 1 id',
            'Branch Office 1 Address',
            'Branch Office 2 Address',
            'Branch Office 1 City',
            'Branch Office 1 State',
            'Branch Office 1 Zip',
            'Branch Office 1 Zip4',
            'Branch Office 1 County',
            'Branch Office 1 Country',
            'Number of Registered States',
        ],
        permission: 'search_ria',
    },
];

// Header values for displaying the RR Individual data in the RR search result page.
const rrIndivReportTypes = [
    {
        value: 'rrIndivInformation',
        label: "Rep's Information",
        columns: [
            'CRD',
            'First Name',
            'Middle Name',
            'Last Name',
            'Suffix',
            'Gender',
            'Date Hired',
            'Firm CRD',
            'Firm Name',
            'BD Main Office Phone',
            'BD Main Office Phone Ext',
            'Primary Email Address',
            'Primary Email Address Level',
            'Primary Email Domain',
            'Alternate Sourced Email Indicator',
            'Secondary Email Address',
            'Secondary Email Address Level',
            'Secondary Email Domain',
            'Alternate Email Address',
            'Alternate Email Address Level',
            'Alternate Email Domain',
            'Branch Office 1 id',
            'Branch Office 1 Address',
            'Branch Office 2 Address',
            'Branch Office 1 City',
            'Branch Office 1 State',
            'Branch Office 1 Zip',
            'Branch Office 1 Zip4',
            'Branch Office 1 County',
            'Branch Office 1 Country',
            'Main Office Address 1',
            'Main Office Address 2',
            'Main Office City',
            'Main Office State',
            'Main Office Zip',
            'Main Office Zip4',
            'Main Office County',
            'Main Office Country',
            'Total RRs at Firm',
            'Earliest Exam Date',
            'Number of Exams',
            'Earliest Exam Date Year',
            'Number of Licenses',
            'Earliest Employment Date RR',
            'Years Employed RR',
            'Number of States Registered',
            'LinkedIn Profile',
            'Professional Designations 1',
            'Professional Designations 2',
            'BD Executive',
            'Insurance Agent',

        ],
        permission: 'search_ria',
    },
    {
        value: 'rrIndivExam',
        label: "Reps Exam Information",
        columns: [
            'CRD',
            'First Name',
            'Middle Name',
            'Last Name',
            'Suffix',
            'Exam 1 Code',
            'Exam 1 Description',
            'Exam 1 Date',
            'Exam 2 Code',
            'Exam 2 Description',
            'Exam 2 Date',
            'Exam 3 Code',
            'Exam 3 Description',
            'Exam 3 Date',
        ],
        permission: 'search_ria',
    },
    {
        value: 'rrIndivLicensingInformation',
        label: "Reps Licensing Information",
        columns: [
            'CRD',
            'First Name',
            'Middle Name',
            'Last Name',
            'Suffix',
        ],
        permission: 'search_ria',
    },
    {
        value: 'rrIndivEmployment',
        label: "Reps Previous Employment History",
        columns: [
            'CRD',
            'First Name',
            'Middle Name',
            'Last Name',
            'Suffix',
            'Business 1 Name',
            'Employment 1 Start Date',
            'Employment 1 End Date',
            'Business 2 Name',
            'Employment 2 Start Date',
            'Employment 2 End Date',
            'Business 3 Name',
            'Employment 3 Start Date',
            'Employment 3 End Date',
        ],
        permission: 'search_ria',
    },
    {
        value: 'rrIndivAllColumns',
        label: "All Columns",
        columns: [
            'CRD',
            'First Name',
            'Middle Name',
            'Last Name',
            'Suffix',
            'Gender',
            'Date Hired',
            'Firm CRD',
            'Firm Name',
            'BD Main Office Phone',
            'BD Main Office Phone Ext',
            'Exam 1 Code',
            'Exam 1 Description',
            'Exam 1 Date',
            'Exam 2 Code',
            'Exam 2 Description',
            'Exam 2 Date',
            'Exam 3 Code',
            'Exam 3 Description',
            'Exam 3 Date',
            'Business 1 Name',
            'Employment 1 Start Date',
            'Employment 1 End Date',
            'Business 2 Name',
            'Employment 2 Start Date',
            'Employment 2 End Date',
            'Business 3 Name',
            'Employment 3 Start Date',
            'Employment 3 End Date',
            'Primary Email Address',
            'Primary Email Address Level',
            'Primary Email Domain',
            'Alternate Sourced Email Indicator',
            'Secondary Email Address',
            'Secondary Email Address Level',
            'Secondary Email Domain',
            'Alternate Email Address',
            'Alternate Email Address Level',
            'Alternate Email Domain',
            'Branch Office 1 id',
            'Branch Office 1 Address',
            'Branch Office 2 Address',
            'Branch Office 1 City',
            'Branch Office 1 State',
            'Branch Office 1 Zip',
            'Branch Office 1 Zip4',
            'Branch Office 1 County',
            'Branch Office 1 Country',
            'Main Office Address 1',
            'Main Office Address 2',
            'Main Office City',
            'Main Office State',
            'Main Office Zip',
            'Main Office Zip4',
            'Main Office County',
            'Main Office Country',
            'Total RRs at Firm',
            'Earliest Exam Date',
            'Number of Exams',
            'Earliest Exam Date Year',
            'Number of Licenses',
            'Earliest Employment Date RR',
            'Years Employed RR',
            'Number of States Registered',
            'Professional Designations 1',
            'Professional Designations 2',
            'BD Executive',
            'Insurance Agent',
            'LinkedIn Profile',

        ],
        permission: 'search_ria',
    },
];

export {insuranceAgentReportTypes, consumerDataReportTypes, riaFirmReportTypes, iarReportTypes, bdFirmReportTypes, rrIndivReportTypes, riaFirmExecutiveReportTypes, bdExecutiveReportTypes, consumerDataFields}